import { useContext, useMemo } from 'react'

import UserLocationContext, {
  type UserLocationType,
} from '../contexts/userLocationContext'

export const useUserLocation = () => {
  const userLocation = useContext(UserLocationContext)

  if (!userLocation) {
    throw new Error('User location is not provided')
  }

  const userLocationHome = useMemo(
    () => getUserLocationHome(userLocation),
    [userLocation]
  )

  return { ...userLocation, userLocationHome }
}

export function getUserLocationHome(userLocation: UserLocationType) {
  if (!userLocation || userLocation.userLocations.length === 0) {
    return null
  }

  return (
    userLocation.userLocations.find(
      (userLocation) => userLocation.place === 'PLACE_TYPE_HOUSE'
    ) ?? null
  )
}
