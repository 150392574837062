import { AppScreen } from '@stackflow/plugin-basic-ui'
import { type ActivityComponentType } from '@stackflow/react'
import { useEffect, useState } from 'react'

import { BottomSheetProvider } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import MapProvider from '@src/core/components/karrot-map/map/MapProvider'
import { useHomePopState } from '@src/core/hooks/useHomePopState'
import { useSyncFeedCoordinatesEffect } from '@src/core/hooks/useSyncFeedCoordinatesEffect'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import VersionView from '@src/features/debug/version-view/VersionView'
import { useSetSearchQueryFromQueryParamsEffect } from '@src/features/search/hooks/useSetSearchQueryFromQueryParamsEffect'
import { HomeMapFragment } from '@src/fragments/HomeMapFragment'
import { HomeStepsFragment } from '@src/fragments/HomeStepsFragment/HomeStepsFragment'
import { useLandscapeAnchors } from '@src/services/hooks/useLandscapeAnchors'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

import ExternalPage from './home-feed-step/external/ExternalPage'
import { useSearchUIOnlyTypingText } from './home-search-result-step/_store/searchUIOnlyTypingText'

export interface HomeActivityParams {
  query?: string
  queryFrom?: string
  mapBounds?: string
  step?: string
  from?: string
  isBoundsSearch?: string
  isAddRecentKeyword?: string
}

const HomeActivity: ActivityComponentType<HomeActivityParams> = ({
  params: activityParams,
}) => {
  const { anchors } = useLandscapeAnchors()
  const geolocation = useGeolocation()
  const { stepType } = useStepRouter()
  const [isMapDragging, setIsMapDragging] = useState(false)

  // 검색 후 뒤로가기 했을 때 검색어 초기화를 위한 임시 대응
  useEffect(() => {
    if (stepType === 'home') {
      useSearchUIOnlyTypingText.getState().setSearchUIOnlyTypingText('')
    }
  }, [stepType])

  useSyncFeedCoordinatesEffect()

  useSetSearchQueryFromQueryParamsEffect()

  useHomePopState()

  return (
    <AppScreen>
      <MapProvider>
        <BottomSheetProvider anchors={anchors}>
          <HomeMapFragment
            geolocation={geolocation}
            onMapDraggingChange={setIsMapDragging}
          />
          <HomeStepsFragment
            anchors={anchors}
            query={activityParams.query}
            mapBounds={activityParams.mapBounds}
            isMapDragging={isMapDragging}
          />
          <VersionView />
          <ExternalPage />
        </BottomSheetProvider>
      </MapProvider>
    </AppScreen>
  )
}

export default HomeActivity
