import { useCallback } from 'react'

import {
  useMap,
  useMapLibreGL,
} from '@src/core/components/karrot-map/map/MapProvider'
import { getBoundsWithPadding } from '@src/core/components/karrot-map/utils/getBoundsWithPadding'
import { usePersistedRecentKeywords } from '@src/features/recent-keyword/store/usePersistedRecentKeywords'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import { useSearchLogParamActions } from '@src/services/log/store'
import { useSuggestedSearchParams } from '@src/services/log/store/suggestedSearchParams'
import {
  type LocalMapSearchExecutionLogParams,
  type LocalMapSearchQueryFromIdType,
} from '@src/services/log/types/search'

import { useVisibleMapViewportPadding } from './useVisibleMapViewportPadding'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '../_store/search-business-places-query-request-params'

/**
 * 검색 로그 관련 정책 링크 (2024.03.08)
 * - query id 갱신 규칙 (https://www.notion.so/query-id-fde0090370fb45f08ea2c09439a91d7f?pvs=24)
 *
 */

export const useSearchExecutions = () => {
  const { map } = useMap()
  const { mapLibreGL: mapLibre } = useMapLibreGL()

  const { setRecentKeyword } = usePersistedRecentKeywords()
  const { updateQueryId, updateQueryFromId } = useSearchLogParamActions()
  const { setParams: setSuggestedSearchLogParams } = useSuggestedSearchParams()
  const {
    setQuerySearchKey,
    setSearchCenterCoordinates,
    setSearchBounds,
    setCoordinatesForDistance,
  } = useSearchBusinessPlacesQueryRequestParamsStore((state) => ({
    setQuerySearchKey: state.setQuery,
    setSearchCenterCoordinates: state.setSearchCenterCoordinates,
    setSearchBounds: state.setSearchBounds,
    setCoordinatesForDistance: state.setCoordinatesForDistance,
  }))
  const geolocation = useGeolocation()
  const visibleMapViewportPadding = useVisibleMapViewportPadding()

  /**
   * Search execution functions
   */

  // 이 지역 검색하기
  const boundsReSearch = useCallback(() => {
    if (!map || !mapLibre) {
      throw new Error(
        '지도를 찾을 수 없습니다. 지도 Provider 내부에서 호출해주세요.'
      )
    }

    const bounds = getBoundsWithPadding({
      map,
      mapLibre,
      padding: visibleMapViewportPadding,
    })

    const searchBounds = {
      centerLatitude: bounds.getCenter().lat,
      centerLongitude: bounds.getCenter().lng,
      northWestLatitude: bounds.getNorthWest().lat,
      northWestLongitude: bounds.getNorthWest().lng,
      southEastLatitude: bounds.getSouthEast().lat,
      southEastLongitude: bounds.getSouthEast().lng,
    }

    setSearchBounds({
      userViewBounds: searchBounds,
      searchAreaBounds: searchBounds,
    })

    const center = bounds.getCenter()
    setSearchCenterCoordinates({
      latitude: center.lat,
      longitude: center.lng,
    })

    if (geolocation.currentPosition) {
      setCoordinatesForDistance({
        latitude: geolocation.currentPosition.lat,
        longitude: geolocation.currentPosition.lng,
      })
    }
  }, [
    map,
    mapLibre,
    visibleMapViewportPadding,
    setSearchBounds,
    setSearchCenterCoordinates,
    geolocation.currentPosition,
    setCoordinatesForDistance,
  ])

  // 검색어 검색, 전국 검색
  const search = useCallback(
    (
      params: Partial<LocalMapSearchExecutionLogParams> & {
        query: string
        queryFrom: LocalMapSearchQueryFromIdType
      },
      options?: {
        isBoundsReSearch?: boolean // bound search할지 여부 default는 false
        isAddRecentKeyword?: boolean // 최근 검색어에 추가할지 여부 default는 true
      }
    ) => {
      const { query, queryFrom, typedQuery, suggestionType } = params
      const isAddRecentKeyword = options?.isAddRecentKeyword ?? true

      if (!map || !mapLibre) {
        throw new Error(
          '지도를 찾을 수 없습니다. 지도 Provider 내부에서 호출해주세요.'
        )
      }

      if (!query) {
        return
      }

      updateQueryId()
      updateQueryFromId(queryFrom)

      const bounds = getBoundsWithPadding({
        map,
        mapLibre,
        padding: visibleMapViewportPadding,
      })
      const searchBounds = {
        centerLatitude: bounds.getCenter().lat,
        centerLongitude: bounds.getCenter().lng,
        northWestLatitude: bounds.getNorthWest().lat,
        northWestLongitude: bounds.getNorthWest().lng,
        southEastLatitude: bounds.getSouthEast().lat,
        southEastLongitude: bounds.getSouthEast().lng,
      }

      setSearchBounds({
        userViewBounds: searchBounds,
        searchAreaBounds: options?.isBoundsReSearch ? searchBounds : null,
      })

      setSearchCenterCoordinates({
        latitude: bounds.getCenter().lat,
        longitude: bounds.getCenter().lng,
      })

      setQuerySearchKey(params.query)

      if (isAddRecentKeyword) {
        setRecentKeyword(params.query)
      }

      if (geolocation.currentPosition) {
        setCoordinatesForDistance({
          latitude: geolocation.currentPosition.lat,
          longitude: geolocation.currentPosition.lng,
        })
      }

      setSuggestedSearchLogParams({
        typedQuery,
        suggestionType,
      })
    },
    [
      map,
      mapLibre,
      updateQueryFromId,
      visibleMapViewportPadding,
      setSearchBounds,
      setSearchCenterCoordinates,
      setQuerySearchKey,
      setRecentKeyword,
      geolocation.currentPosition,
      setSuggestedSearchLogParams,
      updateQueryId,
      setCoordinatesForDistance,
    ]
  )

  return {
    boundsReSearch,
    search,
  }
}
