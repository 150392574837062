import { type V1LaunchpadItemTag } from '@daangn/local-map-api'
import { useCallback } from 'react'

import { useDisplayOnceStore } from '@src/services/store/displayOnceStore'

interface UseNBadgePolicyProps {
  key: string
  badgeTag?: V1LaunchpadItemTag
}

/**
 * N 뱃지의 표시 여부를 관리하는 훅
 */
export const useNBadgePolicy = ({ key, badgeTag }: UseNBadgePolicyProps) => {
  const { dismissLaunchpadItemNBadge, isDissmissedlaunchpadItemNBadge } =
    useDisplayOnceStore((s) => ({
      isDissmissedlaunchpadItemNBadge: s.isDissmissedlaunchpadItemNBadge,
      dismissLaunchpadItemNBadge: s.dismissLaunchpadItemNBadge,
    }))

  const isNBadgeDismissed =
    badgeTag === 'LAUNCHPAD_ITEM_TAG_CLICK_TO_DISMISS_N_BADGE' &&
    isDissmissedlaunchpadItemNBadge[key]

  const nBadgeDismiss = useCallback(() => {
    dismissLaunchpadItemNBadge(key)
  }, [dismissLaunchpadItemNBadge, key])

  return {
    nBadgeDismiss,
    isNBadgeDismissed,
  }
}
