export const evaluateStatus = (
  status: 'pending' | 'error' | 'success'
): {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
} => {
  const isLoading = status === 'pending'
  const isError = status === 'error'
  const isSuccess = status === 'success'

  return {
    isLoading,
    isError,
    isSuccess,
  }
}
