import { type ReactNode } from 'react'

import { TimeoutHelpBubble } from '@src/core/components/timeout-help-bubble/TimeoutHelpBubbleAnchor'

import { padItem, padItemBadge } from './Launchpad.css'

interface LaunchpadItemProps {
  label: string
  icon: ReactNode
  badge?: ReactNode | null
  onClick: (label: string) => void
  tooltip?: string | null
  onTooltipClose?: (() => void) | null
  impressionElementRef?: React.RefObject<HTMLAnchorElement>
}

export const LaunchpadItem = ({
  label,
  icon,
  badge,
  onClick,
  tooltip,
  onTooltipClose,
  impressionElementRef,
}: LaunchpadItemProps) => {
  const LaunchpadItem = (
    <a
      onClick={() => onClick(label)}
      className={padItem}
      ref={impressionElementRef}
      key={label}
    >
      {badge && <div className={padItemBadge}>{badge}</div>}
      {icon}
      <div>{label}</div>
    </a>
  )

  if (tooltip) {
    return (
      <TimeoutHelpBubble
        title={tooltip}
        portalled={false}
        positioning={{
          placement: 'top',
          flip: false,
        }}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            onTooltipClose?.()
          }
        }}
      >
        {LaunchpadItem}
      </TimeoutHelpBubble>
    )
  }

  return LaunchpadItem
}
