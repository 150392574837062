import { useRef } from 'react'

import { BottomSheet } from '@src/core/components/bottom-sheet/BottomSheet'
import { dom } from '@src/core/components/bottom-sheet/dom'
import {
  type Anchor,
  type BottomSheetInitialProps,
} from '@src/core/components/bottom-sheet/types'
import { useBottomSheet } from '@src/core/components/bottom-sheet/useBottomSheet'
import { getAnchorPosition } from '@src/core/components/bottom-sheet/utils'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import withSuspense from '@src/core/components/suspense/withSuspense'
import { useScrollTop } from '@src/core/hooks/useScrollTop'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { getOffset, useMapPanTo } from '@src/features/map/hooks/useMapPanTo'
import { useHomeBottomSheetAnchorPolicy } from '@src/pages/home-feed-step/_hooks/useHomeBottomSheetAnchorPolicy'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import LaunchpadRoot from '@src/pages/home-feed-step/section/launchpad/LaunchpadRoot'
import PlaceIndicatorRoot from '@src/pages/home-feed-step/section/PlaceIndicatorRoot'
import SectionBenefitCurationRoot from '@src/pages/home-feed-step/section/SectionBenefitCurationRoot'
import SectionCreateAndSuggestionRoot from '@src/pages/home-feed-step/section/SectionCreateAndSuggestionRoot'
import SectionExpertRoot from '@src/pages/home-feed-step/section/SectionExpertRoot'
import SectionFollowPostRoot from '@src/pages/home-feed-step/section/SectionFollowPostRoot'
import SectionOpenSurveyRoot from '@src/pages/home-feed-step/section/SectionOpenSurveyRoot'
import SectionPublicRoot from '@src/pages/home-feed-step/section/SectionPublicRoot'
import SectionStoryRoot from '@src/pages/home-feed-step/section/SectionStoryRoot'
import SectionTabbedBenefitCurationRoot from '@src/pages/home-feed-step/section/SectionTabbedBenefitCurationRoot'
import SectionThemePostRoot from '@src/pages/home-feed-step/section/SectionThemePost'

import ListFeedViewTypesException, {
  useListFeedViewTypesErrorType,
} from './ListFeedViewTypesException'
import LocalMapBottomSheetSkeleton from './LocalMapBottomSheetSkeleton'

interface LocalMapBottomSheetProps extends BottomSheetInitialProps {
  bottomSheetZIndex: number
  preventMoveMap: boolean
}

const LocalMapBottomSheet = ({
  bottomSheetZIndex,
  anchors,
  preventMoveMap,
}: LocalMapBottomSheetProps) => {
  const { stepType, params } = useStepRouter()
  const isSelected = !!params.selectedPin // TODO. 새로운 selectedStepType 추가되면 수정 필요
  const disabled = isSelected
  const { map } = useMap()

  const contentRef = useRef<HTMLDivElement>(null)

  const { panToWithOffset } = useMapPanTo(map)

  const offsetBeforeMovingRef = useRef<[number, number] | null>(null)

  const distancedCoordinates = coordinatesStore((s) => s.distancedCoordinates)

  const bottomSheetProps = useBottomSheet({
    anchors,
    onTaskCompletedIdle(id, anchor) {
      const anchorPosition = getAnchorPosition(
        anchor,
        dom.getPositionerHeight(id)
      )
      const { positionFromBottom } = dom.getComputedPosition(id, anchorPosition)

      if (positionFromBottom > 400) {
        return
      }

      // scrollToTop
      contentRef.current?.scrollTo(0, 0)

      // 바텀 시트 최소화 되면 섹션 임프레스 reserving으로 변경
      if (positionFromBottom < 100) {
        coordinatesStore.getState().setSectionImpressReset()
      }

      offsetBeforeMovingRef.current = getOffset(stepType, positionFromBottom)
    },
    onTransitionEnd(id: string, anchor: Anchor) {
      const anchorPosition = getAnchorPosition(
        anchor,
        dom.getPositionerHeight(id)
      )
      const { positionFromBottom } = dom.getComputedPosition(id, anchorPosition)
      const offsetBeforeMoving = offsetBeforeMovingRef.current

      if (positionFromBottom > 400 || !offsetBeforeMoving) {
        return
      }

      if (!map) {
        throw new Error('onTransitionAnimationEnd, map is not initialized')
      }

      if (preventMoveMap) {
        return
      }

      const currentCenter = map.getCenter() // 현재 중심점
      const [offsetX, offsetY] = offsetBeforeMoving // x축 offset, y축 offset

      // 현재 중심의 픽셀 좌표를 얻습니다.
      const currentCenterPixel = map.project(currentCenter)

      // 적용된 offset을 역으로 적용합니다.
      const originalCenterPixel = {
        x: currentCenterPixel.x + offsetX,
        y: currentCenterPixel.y + offsetY,
      }

      // 수정된 픽셀 좌표를 지리적 좌표로 변환합니다. (역산된 중심점)
      const originalCenter = map.unproject([
        originalCenterPixel.x,
        originalCenterPixel.y,
      ])

      panToWithOffset({
        center: {
          lat: originalCenter.lat,
          lng: originalCenter.lng,
        },
      })
    },
    disabled,
  })

  const listFeedViewTypesErrorType = useListFeedViewTypesErrorType()

  useScrollTop({
    scrollRef: contentRef,
    enabled: bottomSheetProps.snapPoint === 'top',
  })

  useHomeBottomSheetAnchorPolicy()

  return (
    <BottomSheet
      contentRef={contentRef}
      withHandle
      UNSAFE_style={{
        zIndex: isSelected ? -1 : bottomSheetZIndex,
      }}
      snapPoint={bottomSheetProps.snapPoint}
      position={bottomSheetProps.position}
      setPosition={bottomSheetProps.setPosition}
      bottomSheetRef={bottomSheetProps.bottomSheetRef}
      positionerProps={bottomSheetProps.positionerProps}
      rootProps={bottomSheetProps.rootProps}
      handleProps={bottomSheetProps.handleProps}
      handlebarProps={bottomSheetProps.handlebarProps}
      contentProps={bottomSheetProps.contentProps}
    >
      {(() => {
        if (listFeedViewTypesErrorType) {
          return (
            <ListFeedViewTypesException type={listFeedViewTypesErrorType} />
          )
        }

        return (
          <div>
            {/* 위치정보를 끌어올리고 cls 대응을 하기 (로딩 성능 향상을 위해) */}
            {distancedCoordinates?.coordinates ? (
              <>
                <PlaceIndicatorRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <LaunchpadRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionBenefitCurationRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionTabbedBenefitCurationRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionThemePostRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionStoryRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionExpertRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                {/* 혜택 중심의 가게 큐레이션(https://www.notion.so/daangn/19828c3a9f8f8085bca8d41e9236576c?pvs=4)을 실험 대신 교체 운영으로 검증하기로 해서 임시로 해당 섹션을 삭제해요. 검증이 끝난 후 주석을 제거할게요. */}
                {/* 논의 기록: https://daangn.slack.com/archives/C08C9BJCEQG/p1741944546908449?thread_ts=1740464007.646669&cid=C08C9BJCEQG */}
                {/* <SectionPopularOffersRoot
                  userCoordinates={distancedCoordinates.coordinates}
                /> */}
                <SectionFollowPostRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionPublicRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionOpenSurveyRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
                <SectionCreateAndSuggestionRoot
                  userCoordinates={distancedCoordinates.coordinates}
                />
              </>
            ) : null}
          </div>
        )
      })()}
    </BottomSheet>
  )
}

export default withSuspense(LocalMapBottomSheet, LocalMapBottomSheetSkeleton)
