import { create } from 'zustand'

type SearchUIOnlyTypingTextStore = {
  searchUIOnlyTypingText: string
  setSearchUIOnlyTypingText: (searchTypingText: string) => void
  clearSearchUIOnlyTypingText: () => void
}

export const useSearchUIOnlyTypingText = create<SearchUIOnlyTypingTextStore>(
  (set) => ({
    searchUIOnlyTypingText: '',
    setSearchUIOnlyTypingText: (searchUIOnlyTypingText: string) =>
      set({ searchUIOnlyTypingText }),
    clearSearchUIOnlyTypingText: () => set({ searchUIOnlyTypingText: '' }),
  })
)
