import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { LOCAL_MAP_PERSISTENT_STORAGE_STATE_KEY } from '@src/features/persistent/constants'

interface DisplayOnceState {
  isShowLPCertificatedBubble: boolean
  isDissmissedlaunchpadItemNBadge: {
    [padName: string]: boolean
  }
  isShowLaunchpadItemBubble: {
    [padName: string]: boolean
  }
}

interface DisplayOnceSetter {
  showLPCertificatedBubble: () => void
  dismissLaunchpadItemNBadge: (padName: string) => void
  showLaunchpadItemBubble: (padName: string) => void
}

type DisplayOnceStore = DisplayOnceState & DisplayOnceSetter

/**
 * 한번만 보여야되는 상태값들을 관리하는 persist store
 */
export const useDisplayOnceStore = create<
  DisplayOnceStore,
  [['zustand/persist', DisplayOnceStore]]
>(
  persist(
    (set) => {
      const {
        state: existedValues,
      }: {
        state: DisplayOnceState
      } = JSON.parse(
        window.localStorage.getItem(LOCAL_MAP_PERSISTENT_STORAGE_STATE_KEY) ??
          '{}'
      ) ?? {
        state: {},
      }

      return {
        // LP 인증 툴팁
        isShowLPCertificatedBubble:
          existedValues?.isShowLPCertificatedBubble ?? false,
        showLPCertificatedBubble: () =>
          set({ isShowLPCertificatedBubble: true }),

        // 런치패드 N 뱃지
        isDissmissedlaunchpadItemNBadge:
          existedValues?.isDissmissedlaunchpadItemNBadge ?? {},
        dismissLaunchpadItemNBadge: (padName: string) =>
          set((state) => ({
            isDissmissedlaunchpadItemNBadge: {
              ...state.isDissmissedlaunchpadItemNBadge,
              [padName]: true,
            },
          })),

        // 런치패드 툴팁
        isShowLaunchpadItemBubble:
          existedValues?.isShowLaunchpadItemBubble ?? {},
        showLaunchpadItemBubble: (padName: string) =>
          set((state) => ({
            isShowLaunchpadItemBubble: {
              ...state.isShowLaunchpadItemBubble,
              [padName]: true,
            },
          })),
      }
    },
    {
      name: `${LOCAL_MAP_PERSISTENT_STORAGE_STATE_KEY}/once-view`,
    }
  )
)
