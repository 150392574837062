import * as Sentry from '@sentry/react'
import { useCallback, useMemo } from 'react'

import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '@src/pages/home-search-result-step/_store/search-business-places-query-request-params'

import { useGetMapLogParamsCallback } from './useGetMapLogParamsCallback'
import { karrotBridgeLog } from '../bridgeLog'
import { getMapSearchLogParams } from '../paramsGroup/getMapSearchLogParams'
import { useSearchLogParamStore } from '../store'
import { useSuggestedSearchParams } from '../store/suggestedSearchParams'
import { type LocalMapMapFloatingButtonType } from '../types/home'
import {
  type LocalMapSearchViewTypeLogParams,
  type LocalMapSearchArticleLogParams,
  type LocalMapSearchViewTypeItemLogParams,
} from '../types/search'

export const useSearchLogger = () => {
  const getMapLogParams = useGetMapLogParamsCallback()
  const { querySearchKey } = useSearchBusinessPlacesQueryRequestParamsStore(
    (state) => ({
      querySearchKey: state.query,
    })
  )
  const { searchFunnelId, funnelFrom, queryId, queryFrom } =
    useSearchLogParamStore((state) => ({
      searchFunnelId: state.searchFunnelId,
      funnelFrom: state.funnelFromId,
      queryId: state.queryId,
      queryFrom: state.queryFromId,
    }))
  const { params: suggestedSearchParams } = useSuggestedSearchParams()
  const { currentPosition: currentUserPosition } = useGeolocation()

  const funnelParams = useMemo(
    () => ({
      searchFunnelId,
      funnelFrom,
    }),
    [funnelFrom, searchFunnelId]
  )

  const queryParams = useMemo(
    () => ({
      queryId,
      queryFrom,
      query: querySearchKey,
    }),
    [queryFrom, queryId, querySearchKey]
  )

  const shownSearchDiscoveryLog = useCallback(() => {
    karrotBridgeLog('client_shown_localMap_searchDiscovery_v1', {
      ...funnelParams,
    })
  }, [funnelParams])

  const shownSearchResultLog = useCallback(
    ({
      numberOfDocuments,
      numberOfDocumentsWithCoordinates,
      numberOfDocumentsShownOnMap,
    }: {
      numberOfDocuments: number
      numberOfDocumentsWithCoordinates: number
      numberOfDocumentsShownOnMap: number
    }) => {
      karrotBridgeLog('client_shown_localMap_searchResult_v1', {
        ...funnelParams,
        ...queryParams,
        ...getMapLogParams(),
        numberOfDocuments,
        numberOfDocumentsWithCoordinates,
        numberOfDocumentsShownOnMap,
      })
    },
    [funnelParams, getMapLogParams, queryParams]
  )

  const clickedSearchExecutionLog = useCallback(
    ({ query }: { query?: string }) => {
      if (queryParams.queryFrom === 'unknown') {
        Sentry.captureException('queryFrom is unknown', {
          data: {
            logParams: {
              ...funnelParams,
              ...queryParams,
              query: query ?? querySearchKey,
              ...(queryFrom === 'suggested' ? suggestedSearchParams : {}),
            },
          },
        })
      }

      karrotBridgeLog('client_clicked_localMap_searchExecution_v1', {
        ...funnelParams,
        ...queryParams,
        ...getMapSearchLogParams({
          currentUserPosition,
        }),
        query: query ?? querySearchKey,
        ...(queryFrom === 'suggested' ? suggestedSearchParams : {}),
      })
    },
    [
      funnelParams,
      queryFrom,
      queryParams,
      querySearchKey,
      suggestedSearchParams,
      currentUserPosition,
    ]
  )

  const clickedSearchResultFloatingButtonLog = useCallback(
    ({ actionType }: { actionType: LocalMapMapFloatingButtonType }) => {
      karrotBridgeLog(
        'client_clicked_localMap_searchResult_floatingButton_v1',
        {
          actionType,
          ...getMapLogParams(),
        }
      )
    },
    [getMapLogParams]
  )

  const getImpressedSearchResultArticleLogParams = useCallback(
    ({
      index,
      documentId,
      viewType,
      documentType,
    }: LocalMapSearchArticleLogParams) =>
      ({
        name: 'client_impressed_localMap_searchResult_article_v1',

        params: {
          ...funnelParams,
          ...queryParams,
          ...getMapSearchLogParams({
            currentUserPosition,
          }),
          documentId,
          documentType,
          index,
          viewType,
        },
      }) as const,
    [funnelParams, queryParams, currentUserPosition]
  )

  const clickedSearchResultArticleLog = useCallback(
    ({
      index = null,
      documentId,
      viewType,
      documentType,
    }: LocalMapSearchArticleLogParams) => {
      karrotBridgeLog('client_clicked_localMap_searchResult_article_v1', {
        ...funnelParams,
        ...queryParams,
        ...getMapSearchLogParams({ currentUserPosition }),
        documentId,
        documentType,
        index,
        viewType,
      })
    },
    [funnelParams, queryParams, currentUserPosition]
  )

  const getImpressedSearchResultViewTypeItemLogParams = useCallback(
    ({
      index,
      documentId,
      documentType,
      viewTypeGroup,
      viewTypeSegment,
      queryTypes,
      searchResultViewType,
    }: LocalMapSearchViewTypeItemLogParams) =>
      ({
        name: 'client_impressed_localMap_searchResult_list_viewType_item_v1',

        params: {
          ...funnelParams,
          ...queryParams,
          documentId,
          documentType,
          index,
          viewTypeGroup,
          viewTypeSegment,
          queryTypes,
          searchResultViewType,
        },
      }) as const,
    [funnelParams, queryParams]
  )

  const clickedSearchResultViewTypeItemLog = useCallback(
    ({
      index = null,
      documentId,
      documentType,
      viewTypeGroup,
      viewTypeSegment,
      searchResultViewType,
      queryTypes,
    }: LocalMapSearchViewTypeItemLogParams) => {
      karrotBridgeLog(
        'client_clicked_localMap_searchResult_list_viewType_item_v1',
        {
          ...funnelParams,
          ...queryParams,
          documentId,
          documentType,
          index,
          viewTypeGroup,
          viewTypeSegment,
          queryTypes,
          searchResultViewType,
        }
      )
    },
    [funnelParams, queryParams]
  )

  const getImpressedSearchResultViewTypeLogParams = useCallback(
    ({
      index,
      documentId,
      documentType,
      viewTypeGroup,
      viewTypeSegment,
      queryTypes,
      searchResultViewType,
    }: LocalMapSearchViewTypeLogParams) =>
      ({
        name: 'client_impressed_localMap_searchResult_list_viewType_v1',

        params: {
          ...funnelParams,
          ...queryParams,
          documentId,
          documentType,
          index,
          viewTypeGroup,
          viewTypeSegment,
          queryTypes,
          searchResultViewType,
        },
      }) as const,
    [funnelParams, queryParams]
  )

  const clickedSearchResultViewTypeLog = useCallback(
    ({
      index = null,
      documentId,
      documentType,
      viewTypeGroup,
      viewTypeSegment,
      queryTypes,
    }: LocalMapSearchViewTypeLogParams) => {
      karrotBridgeLog('client_clicked_localMap_searchResult_list_viewType_v1', {
        ...funnelParams,
        ...queryParams,
        documentId,
        documentType,
        index,
        viewTypeGroup,
        viewTypeSegment,
        queryTypes,
      })
    },
    [funnelParams, queryParams]
  )

  const clickedSearchResultChangeBoundaryButtonLog = useCallback(() => {
    karrotBridgeLog(
      'client_clicked_localMap_searchResult_changeBoundaryButton_v1',
      {
        ...funnelParams,
        ...queryParams,
        ...getMapLogParams(),
        ...getMapSearchLogParams({ currentUserPosition }),
      }
    )
  }, [funnelParams, getMapLogParams, queryParams, currentUserPosition])

  const clickedSearchResultChangeViewButtonLog = useCallback(
    ({ action_type }: { action_type: 'view_map' | 'view_list' }) => {
      karrotBridgeLog(
        'client_clicked_localMap_searchResult_changeViewButton_v1',
        {
          ...funnelParams,
          ...queryParams,
          action_type,
        }
      )
    },
    [funnelParams, queryParams]
  )

  const clickedSearchResultShareButtonLog = useCallback(() => {
    karrotBridgeLog('client_clicked_localMap_searchResult_shareButton_v1', {
      ...funnelParams,
      ...queryParams,
    })
  }, [funnelParams, queryParams])

  const getImpressedSearchResultCategorySuggestionButtonLogParams = useCallback(
    ({
      categorySuggestionName,
      categorySuggestionIndex,
    }: {
      categorySuggestionName: string
      categorySuggestionIndex: number
    }) =>
      ({
        name: 'client_impressed_localMap_searchResult_categorySuggestionButton_v1',

        params: {
          ...funnelParams,
          ...queryParams,
          categorySuggestionIndex,
          categorySuggestionName,
        },
      }) as const,
    [funnelParams, queryParams]
  )

  return {
    funnelParams,
    queryParams,
    shownSearchDiscoveryLog,
    shownSearchResultLog,
    clickedSearchExecutionLog,
    clickedSearchResultFloatingButtonLog,
    getImpressedSearchResultArticleLogParams,
    clickedSearchResultArticleLog,
    clickedSearchResultChangeBoundaryButtonLog,
    clickedSearchResultChangeViewButtonLog,
    clickedSearchResultShareButtonLog,
    getImpressedSearchResultCategorySuggestionButtonLogParams,
    getImpressedSearchResultViewTypeLogParams,
    clickedSearchResultViewTypeLog,
    getImpressedSearchResultViewTypeItemLogParams,
    clickedSearchResultViewTypeItemLog,
  }
}
