import { type V1ServiceOpenSurveySection } from '@daangn/local-map-api'
import { memo } from 'react'

import BannerBasicWithTitle from '@src/core/components/banner/BannerBasicWithTitle'
import {
  OPEN_SURVEY_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import { container } from './SectionOpenSurvey.css'

interface SectionOpenSurveyProps {
  openSurveyObject: V1ServiceOpenSurveySection
}

const REFERRER = genSectionReferrer(OPEN_SURVEY_SECTION_INFO.sectionKey)

const SectionOpenSurvey: React.FC<SectionOpenSurveyProps> = ({
  openSurveyObject,
}) => {
  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const { openTarget } = useLocalMapOpenTarget()

  const handleClick = () => {
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'feedback',
      ...getMapLogParams(),
      ...OPEN_SURVEY_SECTION_INFO,
    })
    openTarget({
      targetUri: openSurveyObject.targetUri,
      referrer: REFERRER,
    })
  }

  return (
    <>
      <div className={container}>
        <BannerBasicWithTitle
          title="동네지도 어떠셨나요?"
          body="여러분의 의견을 들려주세요."
          thumbnail="https://asset-town.krrt.io/production/illust/f4209973-d171-4998-8082-5438dc832c2f/122f5d14629f8a93da85785ddb761019d4974957.webp"
          onClick={handleClick}
        />
      </div>
    </>
  )
}

export default memo(SectionOpenSurvey)
