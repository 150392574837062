import { type Coordinates } from '@src/core/types/geo'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '@src/pages/home-search-result-step/_store/search-business-places-query-request-params'

import { karrotBridgeLog } from '../bridgeLog'
import { useSearchLogParamStore } from '../store'

type ClickedLocalMapSearchResultPickPoiV1Params = {
  item_id: string
  item_data_type: 'local_profile'
  zoom_level: number
}

function clickedLocalMapSearchResultPickPoiV1(
  params: ClickedLocalMapSearchResultPickPoiV1Params
) {
  karrotBridgeLog('client_clicked_localMap_searchResult_pickPoi_v1', params)
}

type ClickedLocalMapSearchResultPreviewV1Params = {
  item_id: string
  item_data_type: 'local_profile'
  funnel_from: 'backgroundPoi' | 'marker' | 'searchExcution_suggested'
  action_type:
    | 'viewLocalProfileHome'
    | 'chat'
    | 'call'
    | 'review'
    | 'suggest'
    | 'imageUpload'
    | 'delete'
  zoom_level: number
  center_coordinates: Coordinates
}

function clickedLocalMapSearchResultPreviewV1(
  params: ClickedLocalMapSearchResultPreviewV1Params
) {
  karrotBridgeLog('client_clicked_localMap_searchResult_preview_v1', params)
}

export function useLpSearchLogger() {
  const { querySearchKey } = useSearchBusinessPlacesQueryRequestParamsStore(
    (state) => ({
      querySearchKey: state.query,
    })
  )
  const { searchFunnelId, funnelFrom, queryId, queryFrom } =
    useSearchLogParamStore((state) => ({
      searchFunnelId: state.searchFunnelId,
      funnelFrom: state.funnelFromId,
      queryId: state.queryId,
      queryFrom: state.queryFromId,
    }))

  type ClientImpressedLocalMapSearchResultPreviewV1Params = {
    zoom_level: number
    center_coordinates: Coordinates
    item_id: string
    item_data_type: 'local_profile'
    funnel_from: 'backgroundPoi' | 'marker' | 'searchExcution_suggested'
  }

  function impressedLocalMapSearchResultPreviewV1(
    params: ClientImpressedLocalMapSearchResultPreviewV1Params
  ) {
    karrotBridgeLog('client_impressed_localMap_searchResult_preview_v1', params)
    karrotBridgeLog('client_impressed_localMap_searchResult_article_v1', {
      searchFunnelId,
      funnelFrom,
      queryId,
      queryFrom,
      query: querySearchKey,
      documentId: params.item_id,
      documentType: params.item_data_type,
      viewType: 'single_pre_view',
    })
  }

  return {
    clickedLocalMapSearchResultPickPoiV1,
    impressedLocalMapSearchResultPreviewV1,
    clickedLocalMapSearchResultPreviewV1,
  }
}
