import { useEffect, useMemo } from 'react'

import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { type BottomSheetInitialProps } from '@src/core/components/bottom-sheet/types'
import ContributionBottomSheet from '@src/features/contribution/components/contribution-bottom-sheet/ContributionBottomSheet'
import { useContributionBottomSheetHandler } from '@src/features/contribution/hooks/useContributionBottomSheetHandler'
import LocalMapBottomSheet from '@src/features/feed/components/LocalMapBottomSheet'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '@src/pages/home-search-result-step/_store/search-business-places-query-request-params'
import { HOME_Z_INDEX } from '@src/services/styles/zIndex/homeZIndex'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

interface HomeStepFragmentProps {
  shownHomeLogHistoryRecordRef: React.MutableRefObject<boolean>
  isMapDragging: boolean
  bottomSheetProps: BottomSheetInitialProps
}

const HomeStepFragment = ({
  isMapDragging,
  bottomSheetProps,
  shownHomeLogHistoryRecordRef,
}: HomeStepFragmentProps) => {
  const { shownHomeLog } = useHomeLogger()
  const { setQuery } = useSearchBusinessPlacesQueryRequestParamsStore()
  const { state } = useContributionBottomSheetHandler()
  const { snapPoint } = useBottomSheetSnapPoint()

  const isMovingContributionBottomSheet = useMemo(() => {
    if (state === 'idle') return false

    // 기여하기 바텀시트가 열고 닫힐 때 지도가 패닝되지 않도록 해요.
    return (
      (snapPoint === 'middle' && state === 'close') ||
      (snapPoint == 'bottom' && state === 'open')
    )
  }, [snapPoint, state])

  useEffect(() => {
    setQuery('')
  }, [setQuery])

  useEffect(() => {
    if (shownHomeLogHistoryRecordRef.current) {
      return
    }

    shownHomeLogHistoryRecordRef.current = true

    shownHomeLog()
  }, [shownHomeLog, shownHomeLogHistoryRecordRef])

  return (
    <>
      <LocalMapBottomSheet
        anchors={bottomSheetProps.anchors}
        preventMoveMap={isMapDragging || isMovingContributionBottomSheet}
        bottomSheetZIndex={HOME_Z_INDEX.BOTTOM_SHEET_LAYER}
      />
      <ContributionBottomSheet />
    </>
  )
}

export default HomeStepFragment
