import { type TypeLatLng } from '@daangn/local-map-api'
import { create } from 'zustand'

export interface DistancedCoordinatesTypes {
  type: 'userLocation' | 'currentPosition'
  coordinates: TypeLatLng
}

interface coordinatesStoreProps {
  distancedCoordinates: DistancedCoordinatesTypes | null
  setDistancedCoordinates: (
    coordinates: DistancedCoordinatesTypes | null
  ) => void
  region3Name: string
  setRegion3Name: (region: string) => void
  sectionImpressReset: number
  setSectionImpressReset: () => void
}

export const coordinatesStore = create<coordinatesStoreProps>((set) => ({
  distancedCoordinates: null,
  setDistancedCoordinates: (
    distancedCoordinates: DistancedCoordinatesTypes | null
  ) => set({ distancedCoordinates: distancedCoordinates }),
  region3Name: '',
  setRegion3Name: (region: string) => set({ region3Name: region }),
  sectionImpressReset: 0,
  setSectionImpressReset: () =>
    set((state) => ({
      sectionImpressReset: state.sectionImpressReset + 1,
    })),
}))
