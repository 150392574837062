import {
  ThemeBusinessPostSectionThemeType,
  type V1ThemeBusinessPostSection,
} from '@daangn/local-map-api'
import { BoxButton } from '@daangn/sprout-components-button'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanelGroup,
  Tabs,
} from '@daangn/sprout-components-tabs'
import React, { useMemo, useRef, useState } from 'react'

import { useTouchGesture } from '@src/core/components/touch-gesture/useTouchGesture'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import BottomSheetPostSection from '@src/pages/home-feed-step/section/lib/BottomSheetPostSection'
import {
  bottomButtonWrapper,
  postSectionPaddingAdjust,
} from '@src/pages/home-feed-step/section/lib/BottomSheetSection.css'
import {
  BUSINESS_POST_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { karrotBridgeLog } from '@src/services/log/bridgeLog'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import SectionThemePostItem from './SectionThemePostItem'

interface SectionThemePostProps {
  title: string
  themePostResult: V1ThemeBusinessPostSection
}

const themeTabObj = {
  먹거리: ThemeBusinessPostSectionThemeType.Food,
  미용: ThemeBusinessPostSectionThemeType.Beauty,
  건강: ThemeBusinessPostSectionThemeType.Health,
  교육: ThemeBusinessPostSectionThemeType.Education,
  생활: ThemeBusinessPostSectionThemeType.Life,
  전체: ThemeBusinessPostSectionThemeType.All,
}
type themeKeyTypes = keyof typeof themeTabObj

const themeTabName = {
  먹거리: 'food',
  미용: 'beauty',
  건강: 'health',
  교육: 'education',
  생활: 'life',
  전체: 'all',
}

const REFERRER = genSectionReferrer(BUSINESS_POST_SECTION_INFO.sectionKey)

const SectionThemePost = ({
  title,
  themePostResult,
}: SectionThemePostProps) => {
  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const [selectedTheme, setSelectedTheme] = useState<themeKeyTypes>('먹거리')
  const { openTarget } = useLocalMapOpenTarget()

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const handleMoreButtonClick = () => {
    const target = getTargetTheme({
      themePostResult,
      themeName: themeTabObj[selectedTheme],
    })
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'more',
      ...getMapLogParams(),
      ...BUSINESS_POST_SECTION_INFO,
    })
    if (target?.viewMoreTargetUri) {
      openTarget({
        targetUri: target?.viewMoreTargetUri,
        referrer: REFERRER,
        additionalQueryParams: {
          regionId: themePostResult.region3Id,
          regionName: themePostResult.region3Name,
        },
      })
    }
  }

  const existThemeList = themePostResult.themeBusinessPosts.map(
    (posts) => posts.themeType
  )
  const ThemeTab = useMemo(() => {
    const currentTheme = themeTabObj[selectedTheme]
    if (!existThemeList.includes(currentTheme)) {
      setSelectedTheme('전체')
    }
    return (
      <TabList UNSAFE_style={{ paddingRight: '16px', touchAction: 'none' }}>
        {Object.keys(themeTabObj)
          .filter((themeKey) =>
            existThemeList.includes(themeTabObj[themeKey as themeKeyTypes])
          )
          .map((themeKey) => (
            <Tab key={themeKey} value={themeKey} flex={'auto'}>
              {themeKey}
            </Tab>
          ))}
      </TabList>
    )
  }, [themePostResult])

  const [isSwiping, setIsSwiping] = useState(false)

  const handleChangeTheme = (value: string | null) => {
    const actionType = `tab_click_${themeTabName[value as themeKeyTypes]}`
    karrotBridgeLog(
      'client_clicked_localMap_home_feed_sectionActionButton_v1',
      {
        actionType,
        ...getMapLogParams(),
        ...BUSINESS_POST_SECTION_INFO,
      }
    )
    setSelectedTheme(value as themeKeyTypes)
  }

  useTouchGesture({
    targetElementRef: scrollContainerRef,
    onSwipe: (_, e) => {
      if (isSwiping) {
        e.preventDefault()
      }
    },
  })

  return (
    <BottomSheetPostSection title={title}>
      <Tabs
        ref={scrollContainerRef}
        layout="hug"
        defaultValue={selectedTheme}
        onChange={handleChangeTheme}
        onSwipeStart={() => setIsSwiping(true)}
        onSwipeEnd={() => setIsSwiping(false)}
      >
        {ThemeTab}
        <TabPanelGroup>
          {Object.keys(themeTabObj)
            .filter((themeKey) =>
              existThemeList.includes(themeTabObj[themeKey as themeKeyTypes])
            )
            .map((themeKey) => {
              const target = themePostResult.themeBusinessPosts.find(
                (themeGroup) =>
                  themeGroup.themeType ===
                  themeTabObj[themeKey as themeKeyTypes]
              )
              return target ? (
                <TabPanel key={themeKey} value={themeKey}>
                  {target?.businessPosts.map((postItem, index) => (
                    <div key={postItem.id} className={postSectionPaddingAdjust}>
                      <SectionThemePostItem index={index} postItem={postItem} />
                    </div>
                  ))}
                </TabPanel>
              ) : (
                <></>
              )
            })}
        </TabPanelGroup>
      </Tabs>
      <div className={`${bottomButtonWrapper} ${postSectionPaddingAdjust}`}>
        <BoxButton
          variant="secondary"
          width="100%"
          size="medium"
          onClick={handleMoreButtonClick}
        >
          더보기
        </BoxButton>
      </div>
    </BottomSheetPostSection>
  )
}

interface getTargetThemeProps {
  themePostResult: V1ThemeBusinessPostSection
  themeName: ThemeBusinessPostSectionThemeType
}
const getTargetTheme = ({
  themePostResult,
  themeName,
}: getTargetThemeProps) => {
  return themePostResult.themeBusinessPosts.find(
    (themeGroup) => themeGroup.themeType === themeName
  )
}

export default React.memo(SectionThemePost)
