import { type V1LaunchpadItem } from '@daangn/local-map-api'
import { useRef, useMemo } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import { useLaunchpadBadge } from './hooks/useLaunchpadBadge'
import { useLaunchpadTooltip } from './hooks/useLaunchpadTooltip'
import { getPadItemIcon } from './icon/getPadItemIcon'
import { padIconWrapper } from './Launchpad.css'
import { LaunchpadItem } from './LaunchpadItem'

interface LaunchpadItemRootProps {
  index: number
  padItem: V1LaunchpadItem
  onClick?: (padItem: V1LaunchpadItem) => void
}
const LaunchpadItemRoot = ({
  index,
  padItem,
  onClick,
}: LaunchpadItemRootProps) => {
  const impressionElementRef = useRef(null)
  const { stepMove } = useLocalMapStepMovePolicy()
  const { map } = useMap()
  const { openTarget } = useLocalMapOpenTarget()

  const {
    getImpressedHomeRecommendationChipLogParams,
    clickedHomeRecommendationChipLog,
  } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const { badge, clickLaunchpadActionAffectToBadge } =
    useLaunchpadBadge(padItem)

  const { tooltip, dismissTooltip } = useLaunchpadTooltip(padItem)

  const handlePadItemClick = () => {
    clickedHomeRecommendationChipLog({
      chipName: padItem.name,
      chipIndex: index,
      ...getMapLogParams(),
    })

    clickLaunchpadActionAffectToBadge()

    switch (padItem.entryType) {
      case 'LAUNCHPAD_ITEM_ENTRY_TYPE_SEARCH': {
        stepMove('home', 'click_launchpad', {
          query: padItem.searchEntry?.searchKeyword ?? padItem.name,
          selectedPin: null,
          queryFrom: 'launchpad',
          mapBounds: map ? map.getBounds().toArray().toString() : null,
        })
        break
      }
      case 'LAUNCHPAD_ITEM_ENTRY_TYPE_EXTERNAL_SERVICE': {
        if (!padItem.externalServiceEntry?.schemeUri) {
          return
        }

        openTarget({
          targetUri: padItem.externalServiceEntry.schemeUri,
          referrer: 'localMap_home_feed_launchpad',
        })
        break
      }
    }

    onClick?.(padItem)
  }

  const impressedLogParams = useMemo(
    () =>
      getImpressedHomeRecommendationChipLogParams({
        chipName: padItem.name,
        chipIndex: index,
      }),
    [getImpressedHomeRecommendationChipLogParams, index, padItem.name]
  )

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={impressionElementRef}
    >
      <LaunchpadItem
        label={padItem.name}
        icon={<div className={padIconWrapper}>{getPadItemIcon(padItem)}</div>}
        onClick={handlePadItemClick}
        impressionElementRef={impressionElementRef}
        badge={badge}
        tooltip={tooltip}
        onTooltipClose={dismissTooltip}
      />
    </LocalMapImpressionObserver>
  )
}

export default LaunchpadItemRoot
