import React from 'react'

interface Props {
  size?: number
  color?: string
}

const IconLocationCustom = ({ size = 16, color = '#212124' }: Props = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.46664 7.55335C1.46664 3.94509 4.39172 1.02002 7.99998 1.02002C11.6082 1.02002 14.5333 3.94509 14.5333 7.55335C14.5333 9.82008 13.0657 11.9262 11.6145 13.4177C10.8783 14.1744 10.1211 14.7989 9.50243 15.2372C9.19357 15.4559 8.91298 15.6327 8.68167 15.7573C8.56654 15.8193 8.45624 15.8724 8.35596 15.9114C8.27062 15.9446 8.13875 15.9896 7.99998 15.9896C7.86121 15.9896 7.72934 15.9446 7.64399 15.9114C7.54371 15.8724 7.43341 15.8193 7.31828 15.7573C7.08698 15.6327 6.80639 15.4559 6.49752 15.2372C5.87883 14.7989 5.12165 14.1744 4.38547 13.4177C2.93427 11.9262 1.46664 9.82008 1.46664 7.55335ZM5.58962 7.2362C5.58962 5.905 6.66877 4.82585 7.99998 4.82585C9.33118 4.82585 10.4103 5.905 10.4103 7.2362C10.4103 8.5674 9.33118 9.64656 7.99998 9.64656C6.66877 9.64656 5.58962 8.5674 5.58962 7.2362Z"
        fill={color}
      />
    </svg>
  )
}

export default React.memo(IconLocationCustom)
