import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import { cloneDeep } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'

import { BottomSheet } from '@src/core/components/bottom-sheet/BottomSheet'
import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { type Anchor } from '@src/core/components/bottom-sheet/types'
import { useBottomSheet } from '@src/core/components/bottom-sheet/useBottomSheet'
import { type WithLngLat } from '@src/core/components/karrot-map/types'
import { getBottomSheetAnchors } from '@src/services/constants/anchor'
import { SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX } from '@src/services/constants/homeActivitySize'
import { checkHeightViewType } from '@src/services/hooks/useLandscapeAnchors'
import LocalProfileItem from '@src/pages/home-search-result-step/search-result-viewtype/viewtype/LocalProfileItem'

interface SearchedPreviewListBottomSheetProps {
  localProfileList: WithLngLat<V1SearchResultLocalProfile>[]
  isExistCategorySuggestions: boolean
}

const anchors = getBottomSheetAnchors({
  heightViewType: checkHeightViewType(),
  stepType: 'searchResult',
})

const SearchedPreviewListBottomSheet = ({
  localProfileList,
  isExistCategorySuggestions,
}: SearchedPreviewListBottomSheetProps) => {
  const { setSnapPoint } = useBottomSheetSnapPoint()

  const [adjustedAnchors, setAdjustedAnchors] = useState(anchors)
  const contentRef = useRef<HTMLDivElement>(null)

  const bottomSheetProps = useBottomSheet({
    anchors: adjustedAnchors,
  })

  useEffect(() => {
    setSnapPoint('middle')
  }, [setSnapPoint])

  useEffect(() => {
    setAdjustedAnchors(
      getAdjustAnchors({
        anchors,
        isExistCategorySuggestions,
      })
    )

    contentRef.current?.scrollTo(0, 0)
  }, [isExistCategorySuggestions])

  return (
    <BottomSheet
      contentRef={contentRef}
      snapPoint={bottomSheetProps.snapPoint}
      bottomSheetRef={bottomSheetProps.bottomSheetRef}
      handleProps={bottomSheetProps.handleProps}
      handlebarProps={bottomSheetProps.handlebarProps}
      positionerProps={bottomSheetProps.positionerProps}
      rootProps={bottomSheetProps.rootProps}
      contentProps={bottomSheetProps.contentProps}
      position={bottomSheetProps.position}
      setPosition={bottomSheetProps.setPosition}
    >
      {localProfileList.map((localProfile, index) => (
        <LocalProfileItem
          key={localProfile.id}
          localProfile={localProfile}
          index={index}
        />
      ))}
    </BottomSheet>
  )
}

export default SearchedPreviewListBottomSheet

/**
 * 바텀시트의 앵커를 조정합니다.
 */
const getAdjustAnchors = ({
  anchors,
  isExistCategorySuggestions,
}: {
  anchors: Anchor[]
  isExistCategorySuggestions: boolean
}) => {
  if (!isExistCategorySuggestions) {
    return anchors
  }

  const newAnchors = cloneDeep(anchors)
  const anchorTop = newAnchors.find((anchor) => anchor.snapPoint === 'top')

  if (anchorTop) {
    // category 추천 영역이 있으면 top 앵커의 offset을 조정합니다.
    anchorTop.offset = isExistCategorySuggestions
      ? anchorTop.offset + SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX
      : anchorTop.offset
  }

  return newAnchors
}
