import { useRef, useEffect, useState, useCallback } from 'react'

import {
  type BottomSheetStateProps,
  type Interaction,
} from '@src/core/components/bottom-sheet/types'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useLatestRef } from '@src/core/hooks/useLatestRef'

import { dom } from './dom'

const ANIMATE_DURATION = 200

interface BottomSheetTaskCompletedIdleProps {
  interaction: Interaction
}

export const useBottomSheetTaskCompletedIdle = (
  props: BottomSheetTaskCompletedIdleProps,
  callback: () => void
) => {
  const { interaction } = props

  const [isEndOfTransition, setEndOfTransition] = useState<boolean>(true)
  const { map } = useMap()

  const onTaskCompletedIdle = useLatestRef(callback)

  useEffect(() => {
    if (!map || interaction !== 'idle') {
      return
    }

    if (isEndOfTransition) {
      onTaskCompletedIdle.current?.()
      setEndOfTransition(false)
    }
  }, [map, interaction, onTaskCompletedIdle, isEndOfTransition])

  return {
    isEndOfTransition,
    setEndOfTransition,
  }
}

export function useBottomSheetTouch() {
  const prevClientY = useRef<number | null>(null)
  const initialClientY = useRef<number | null>(null)

  const initClientY = useCallback(() => {
    initialClientY.current = null
    prevClientY.current = null
  }, [])

  return {
    prevClientY,
    initialClientY,
    initClientY,
  }
}

export const useBottomSheetInteraction = (
  props: BottomSheetStateProps,
  position: number
) => {
  const [interaction, setInteraction] = useState<Interaction>('idle')
  const onInteractionChange = useLatestRef(props.onInteractionChange)
  const onInteractionAnimationEnd = useLatestRef(
    props.onInteractionAnimationEnd
  )

  const interactionSetter = useCallback(
    (next: Interaction) => {
      if (next === interaction) return
      setInteraction(next)
      onInteractionChange.current?.(
        next,
        dom.getComputedPosition(props.id, position)
      )

      setTimeout(() => {
        onInteractionAnimationEnd.current?.(
          next,
          dom.getComputedPosition(props.id, position)
        )
      }, ANIMATE_DURATION)
    },
    [
      interaction,
      onInteractionChange,
      onInteractionAnimationEnd,
      position,
      props.id,
      setInteraction,
    ]
  )

  return {
    interaction,
    interactionSetter,
  }
}
