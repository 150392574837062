import { type LocalMapServiceCreateUserLocationRequest } from '@daangn/local-map-api'

import { localMapApi } from '@src/services/api'
import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'

export type CreateUserLocationProps = {
  bridgeInfo: BridgeInfoType
  data: LocalMapServiceCreateUserLocationRequest
}

export type CreateUserLocationResponse = ReturnType<typeof createUserLocation>

export function createUserLocation({
  bridgeInfo,
  data,
}: CreateUserLocationProps) {
  return localMapApi(bridgeInfo).localMapServiceCreateUserLocation(data)
}
