import { type V1LaunchpadItem } from '@daangn/local-map-api'
import { IconChevronDownLine } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'
import React, { forwardRef, useState } from 'react'

import { padContainer, padMoreWrapper } from './Launchpad.css'
import { LaunchpadItem } from './LaunchpadItem'
import LaunchpadItemRoot from './LaunchpadItemRoot'

const calcViewCount = () => {
  const width = window.innerWidth
  if (width >= 16 * 2 + 60 * 6 + 10 * 5) return 12
  if (width >= 16 * 2 + 60 * 5 + 10 * 4) return 10 // 양옆 padding + 아이템개수 * 5 + 아이템간격 * 4
  return 8
}

interface LaunchpadProps {
  padItems: V1LaunchpadItem[]
}

const Lunchpad = forwardRef<HTMLDivElement, LaunchpadProps>(
  ({ padItems }, ref) => {
    const [viewCount, setViewCount] = useState(calcViewCount())

    const handleShowAllItem = () => {
      setViewCount(12)
    }

    return (
      <div ref={ref} role="grid" aria-label="카테고리 리스트">
        <div className={padContainer}>
          {padItems.slice(0, 7).map((item, index) => (
            <LaunchpadItemRoot key={item.name} padItem={item} index={index} />
          ))}
          {viewCount > 8 &&
            padItems
              .slice(7, 9)
              .map((item, index) => (
                <LaunchpadItemRoot
                  key={item.name}
                  padItem={item}
                  index={index}
                />
              ))}
          {viewCount === 12 &&
            padItems
              .slice(9)
              .map((item, index) => (
                <LaunchpadItemRoot
                  key={item.name}
                  padItem={item}
                  index={index}
                />
              ))}
          {viewCount < 11 && (
            <LaunchpadItem
              label="더보기"
              icon={
                <div className={padMoreWrapper}>
                  <IconChevronDownLine
                    size={22}
                    color={vars.$scale.color.gray500}
                  />
                </div>
              }
              onClick={handleShowAllItem}
            />
          )}
        </div>
      </div>
    )
  }
)

export default React.memo(Lunchpad)
