import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useMemo, useRef } from 'react'

import { useGetSectionQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import SectionOpenSurvey from '@src/pages/home-feed-step/section/section-open-survey/SectionOpenSurvey'
import { OPEN_SURVEY_SECTION_INFO } from '@src/pages/home-feed-step/section/sectionKeyInfo'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

interface SectionOpenSurveyRootProps {
  userCoordinates: TypeLatLng
}
const SectionOpenSurveyRoot = ({
  userCoordinates,
}: SectionOpenSurveyRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { data } = useGetSectionQuery({
    sectionViewTypes: 'SECTION_VIEW_TYPE_SERVICE_OPEN_SURVEY_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_SERVICE_OPEN_SURVEY_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...OPEN_SURVEY_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const result = data?.sectionItems[0]?.serviceOpenSurveySection
  if (!result) return null

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div ref={sectionRef}>
        <SectionOpenSurvey openSurveyObject={result} />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(SectionOpenSurveyRoot)
