import { Marker } from '@src/core/components/karrot-map'
import { type LngLat } from '@src/core/types/geo'

interface MarkerProps {
  id?: string
  label?: string
  lngLat: LngLat
  isSelected?: boolean
  useSymbolLabel?: boolean
  marker: React.ReactNode
  labelMarker?: React.ReactNode
  selectedMarker?: React.ReactNode
  selectedLabelMarker?: React.ReactNode
}

const MarkerWithLabel = (props: MarkerProps) => {
  return (
    <>
      {/* 핀 마커 */}
      {/* TODO. [논의필요] selected zIndex 상수화 필요 */}
      <Marker
        lngLat={props.lngLat}
        anchor={'bottom'}
        zIndex={props.isSelected ? 9 : 0}
      >
        {props.isSelected ? props.selectedMarker : props.marker}
      </Marker>
    </>
  )
}

export default MarkerWithLabel
