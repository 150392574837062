import { type LocalMapEventPartsName } from '@src/services/log/eventNames/types'

export const LOCATION_INDICATOR_SECTION_INFO = {
  sectionKey: 'LOCATION_INDICATOR',
  sectionName: '위치 인디케이터',
  sectionIndex: 0,
}

export const LAUNCHPAD_SECTION_INFO = {
  sectionKey: 'RECOMMEND_KEYWORD',
  sectionName: '런치패드',
  sectionIndex: 1,
}

export const BENEFIT_CURATION_SECTION_INFO = {
  sectionKey: 'BENEFIT_CURATION',
  sectionName: '처음 방문하는 곳이라면 놓치지 마세요',
  sectionIndex: 2,
}

export const TABBED_BENEFIT_CURATION_SECTION_INFO = {
  sectionKey: 'TABBED_BENEFIT_CURATION',
  sectionName: '우리 동네 혜택 모음',
  sectionIndex: 3,
}

export const BUSINESS_POST_SECTION_INFO = {
  sectionKey: 'BIZ_POST',
  sectionName: '업체 소식',
  sectionIndex: 4,
}

export const STORY_SECTION_INFO = {
  sectionKey: 'LOCAL_BUSINESS_STORY',
  sectionName: '내 주변 이웃들의 스토리',
  sectionIndex: 5,
}

export const KEYWORD_SECTION_INFO = {
  sectionKey: 'POPULAR_KEYWORD',
  sectionName: '이웃들이 찾고있는 키워드',
  sectionIndex: 6,
}

export const EXPERT_QUEST_SECTION_INFO = {
  sectionKey: 'EXPERT_QUEST',
  sectionName: '전문가 견적 한 번에 받기',
  sectionIndex: 7,
}

export const POPULAR_OFFERS_SECTION_INFO = {
  sectionKey: 'RECOMMEND_COUPON',
  sectionName: '인기 쿠폰',
  sectionIndex: 8,
}

export const FOLLOWER_POST_SECTION_INFO = {
  sectionKey: 'FOLLOW_POST',
  sectionName: '내 단골 업체 소식',
  sectionIndex: 9,
}

export const PUBLIC_SECTION_INFO = {
  sectionKey: 'PUBLIC_INSTITUTION_POST',
  sectionName: '공공기관 소식',
  sectionIndex: 10,
}

export const OPEN_SURVEY_SECTION_INFO = {
  sectionKey: 'ACTIONABLE_CALLOUT',
  sectionName: '새롭게 오픈한 동네지도탭 어떠셨나요?',
  sectionIndex: 11,
}

export const CREATE_AND_SUGGESTION_SECTION_INFO = {
  sectionKey: 'ACTIONABLE_BOX_BUTTON',
  sectionName: '내 업체 등록, 장소 등록 제안',
  sectionIndex: 12,
}

export const genSectionReferrer: (
  sectionKey: string
) => LocalMapEventPartsName = (sectionKey: string) => {
  return `localMap_home_feed_section_${sectionKey}`
}
