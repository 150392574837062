import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import { useMemo } from 'react'

import { LazyImage } from '@src/core/components/lazy-load-image'
import { commaNumber } from '@src/core/utils/unit'

import Badges from './Badges'
import * as css from './CardLocalProfile.css'
import TagArea from './TagArea'
import { getBigPictureImageUrl } from '../big-picture/getBigPictureImageUrl'

interface CardLocalProfileProps {
  localProfile: V1SearchResultLocalProfile
  distance: string
  titlePostfix: React.ReactNode
}

const CardLocalProfile = ({
  distance,
  titlePostfix,
  localProfile: {
    reviewStarRatingAverage,
    reviewCount,
    followCount,
    description,
    addressRoad,
    addressLand,
    categoryName,
    regionName,
    backgroundImage,
    name,
    isReservable,
    isStoreOnSale,
    hasCoupon,
    calculatedDistanceMeter,
    pickupEnabled,
  },
}: CardLocalProfileProps) => {
  const showStarRating = reviewStarRatingAverage > 0
  const showReviewCount = reviewCount > 0
  const commaReviewCount = commaNumber(reviewCount)

  const showFollowerCount = followCount > 0
  const commaFollowerCount = commaNumber(followCount)

  const content = useMemo(() => {
    return description || addressRoad || addressLand
  }, [description, addressRoad, addressLand])

  const showDistance =
    calculatedDistanceMeter !== undefined && calculatedDistanceMeter > 0

  const thumbnail = backgroundImage ?? undefined

  const a11yLabel = `동네업체 ${name} ${regionName} ${
    content ? `${content} ` : ''
  }후기${reviewCount} 단골${followCount} ${
    categoryName ? `${categoryName} ` : ''
  }${isReservable ? '바로예약 ' : ''}${isStoreOnSale ? '바로결제 ' : ''}${
    hasCoupon ? '쿠폰 ' : ''
  }${distance}`

  return (
    <button className={css.button} aria-label={a11yLabel}>
      <div className={css.contents}>
        <div className={css.titleContainer}>
          <span className={css.title}>{name}</span>
          <span className={css.categoryName}>{categoryName}</span>
          {titlePostfix}
        </div>

        <div className={css.body}>
          {content ? <div className={css.bodyContents}>{content}</div> : null}
          <TagArea
            showStarRating={showStarRating}
            starRating={`${reviewStarRatingAverage}`}
            regionName={regionName}
            showReviews={showReviewCount}
            reviewCount={commaReviewCount}
            showFollowers={showFollowerCount}
            followerCount={commaFollowerCount}
            showDistance={showDistance}
            distanceText={distance}
            isSelected={false}
          />
          <Badges
            reservable={isReservable}
            showPurchase={isStoreOnSale}
            hasCoupon={hasCoupon}
            pickupEnabled={pickupEnabled}
          />
        </div>
      </div>
      {thumbnail ? (
        <div className={css.imageContainer}>
          <LazyImage
            className={css.image}
            src={getBigPictureImageUrl(thumbnail, {
              s: {
                width: 92 * 3,
                height: 92 * 3,
              },
            })}
          />
        </div>
      ) : null}
    </button>
  )
}

export default CardLocalProfile
