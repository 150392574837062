import * as css from './CardLocalProfile.css'
import { Badge } from '../badge'

interface BadgesProps {
  reservable?: boolean
  availableDailyReservation?: boolean
  showPurchase?: boolean
  hasCoupon?: boolean
  pickupEnabled?: boolean
  isSelected?: boolean
}
const Badges = (props: BadgesProps) => {
  if (
    !props.reservable &&
    !props.showPurchase &&
    !props.hasCoupon &&
    !props.availableDailyReservation &&
    !props.pickupEnabled
  ) {
    return null
  }

  return (
    <div className={css.badgeContainer}>
      {props.pickupEnabled ? (
        <Badge
          className={css.badge}
          variant="accent"
          size="medium"
          type="normal"
          shape="square"
        >
          포장주문
        </Badge>
      ) : null}
      {props.reservable ? (
        <Badge
          className={css.badge}
          variant={props.isSelected ? 'primary' : 'accent'}
          size="medium"
          type="normal"
          shape="square"
        >
          예약
        </Badge>
      ) : null}
      {props.showPurchase ? (
        <Badge
          className={css.badge}
          variant="basic"
          size="medium"
          type="normal"
          shape="square"
        >
          결제
        </Badge>
      ) : null}
      {props.hasCoupon ? (
        <Badge
          className={css.badge}
          variant="basic"
          size="medium"
          type="normal"
          shape="square"
        >
          쿠폰
        </Badge>
      ) : null}
      {props.availableDailyReservation ? (
        <Badge
          className={css.badge}
          variant="accent"
          size="medium"
          type="normal"
          shape="square"
        >
          당일예약
        </Badge>
      ) : null}
    </div>
  )
}

export default Badges
