import {
  type V1LaunchpadItemTag,
  type V1LaunchpadItem,
} from '@daangn/local-map-api'
import { IconNUppercaseCircleFill } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'
import { useCallback, useMemo } from 'react'

import { useNBadgePolicy } from './useNBadgePolicy'
import IconDiscountBadge from '../icon/IconDiscountBadge'

const BADGE_PRIORITY: V1LaunchpadItemTag[] = [
  'LAUNCHPAD_ITEM_TAG_FIRST_ORDER_BENEFIT',
  'LAUNCHPAD_ITEM_TAG_CLICK_TO_DISMISS_N_BADGE',
]

export const useLaunchpadBadge = (padItem: V1LaunchpadItem) => {
  const badgeTag = getBadgeTag(padItem.itemTags)

  const { nBadgeDismiss, isNBadgeDismissed } = useNBadgePolicy({
    key: padItem.name,
    badgeTag,
  })

  const badge = useMemo(() => {
    switch (badgeTag) {
      case 'LAUNCHPAD_ITEM_TAG_CLICK_TO_DISMISS_N_BADGE': {
        return isNBadgeDismissed ? null : (
          <IconNUppercaseCircleFill
            width={16}
            height={16}
            color={vars.$semantic.color.primary}
          />
        )
      }
      case 'LAUNCHPAD_ITEM_TAG_FIRST_ORDER_BENEFIT': {
        return <IconDiscountBadge size={16} />
      }
    }
  }, [badgeTag, isNBadgeDismissed])

  /**
   * 런치패드 클릭 시 뱃지에 영향을 주는 액션 함수
   */
  const clickLaunchpadActionAffectToBadge = useCallback(() => {
    switch (badgeTag) {
      case 'LAUNCHPAD_ITEM_TAG_CLICK_TO_DISMISS_N_BADGE': {
        nBadgeDismiss()
        break
      }
    }
  }, [badgeTag, nBadgeDismiss])

  return {
    badge,
    clickLaunchpadActionAffectToBadge,
  }
}

const getBadgeTag = (itemTags: V1LaunchpadItemTag[]) => {
  return BADGE_PRIORITY.find((badge) => itemTags.includes(badge))
}
