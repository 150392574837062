import { type V1LocalProfileWithBenefitPerTabSection } from '@daangn/local-map-api'
import {
  IconArrowClockwiseCircularLine,
  IconILowercaseSerifCircleLine,
} from '@daangn/react-monochrome-icon'
import { BoxButton } from '@daangn/sprout-components-button'
import { HelpBubbleTrigger } from '@daangn/sprout-components-help-bubble'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanelGroup,
  Tabs,
} from '@daangn/sprout-components-tabs'
import { vars } from '@seed-design/design-token'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'

import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import * as css from './SectionTabbedBenefitCuration.css'
import TabbedBenefitCurationItem from './TabbedBenefitCurationItem'
import BottomSheetSection from '../lib/BottomSheetSection'
import { TABBED_BENEFIT_CURATION_SECTION_INFO } from '../sectionKeyInfo'

const PAGE_SIZE = 4

const SectionTabbedBenefitCuration = forwardRef<
  HTMLElement,
  { section: V1LocalProfileWithBenefitPerTabSection }
>(({ section }, ref) => {
  const { title, tabs, tooltipDescription } = section
  const displayedTabs = useMemo(
    () =>
      tabs
        .filter((tab) => tab.items.length >= PAGE_SIZE)
        .map((tab) => ({
          ...tab,
          items: tab.items.slice(
            0,
            PAGE_SIZE * Math.floor(tab.items.length / PAGE_SIZE)
          ),
        })),
    [tabs]
  )
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const selectedTab = useMemo(
    () => displayedTabs[selectedTabIndex],
    [displayedTabs, selectedTabIndex]
  )
  const viewMoreButtonText = selectedTab?.viewMoreButtonText
  const maxPages = useMemo(
    () => Math.ceil((selectedTab?.items.length ?? 0) / PAGE_SIZE),
    [selectedTab]
  )

  const [page, setPage] = useState(0)
  const offset = PAGE_SIZE * page

  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const handleChangeTab = useCallback(
    (tabTitle: string | null) => {
      const foundTabIndex = displayedTabs.findIndex(
        (tab) => tab.title === tabTitle
      )
      if (foundTabIndex >= 0 && tabTitle) {
        clickedHomeFeedSectionActionButtonLog({
          actionType: 'tab',
          subSection: {
            sectionIndex: foundTabIndex,
            sectionKey: tabTitle,
            sectionName: tabTitle,
          },
          ...getMapLogParams(),
          ...TABBED_BENEFIT_CURATION_SECTION_INFO,
        })
        setPage(0)
        setSelectedTabIndex(foundTabIndex)
      }
    },
    [clickedHomeFeedSectionActionButtonLog, displayedTabs, getMapLogParams]
  )

  if (!displayedTabs.length) {
    return null
  }

  return (
    <BottomSheetSection
      title={title}
      ref={ref}
      className={css.section}
      titleClassName={css.title}
      titleRightNode={
        <HelpBubbleTrigger
          title={tooltipDescription}
          portalled={false}
          UNSAFE_className={css.helpBubble}
        >
          <IconILowercaseSerifCircleLine
            size="1.125rem"
            color={vars.$scale.color.gray500}
            className={css.helpIcon}
          />
        </HelpBubbleTrigger>
      }
    >
      {displayedTabs.length < 2 ? (
        <>
          {displayedTabs.map((tab) => (
            <ul key={tab.title} className={css.untabbedList}>
              {tab.items
                .slice(offset, offset + PAGE_SIZE)
                .map((item, index) => (
                  <li
                    key={`${item.localProfileId}-${item.couponBoxId}`}
                    className={css.benefitItem}
                  >
                    <TabbedBenefitCurationItem item={item} index={index} />
                  </li>
                ))}
            </ul>
          ))}
        </>
      ) : (
        <Tabs
          layout="fill"
          UNSAFE_style={{ marginTop: '0.38rem' }}
          defaultValue={displayedTabs[0].title}
          value={selectedTab.title}
          isSwipeable={false}
          onChange={handleChangeTab}
        >
          <TabList>
            {displayedTabs.map((tab) => (
              <Tab key={tab.title} value={tab.title}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          <TabPanelGroup>
            {displayedTabs.map((tab) => {
              return (
                <TabPanel
                  key={tab.title}
                  value={tab.title}
                  UNSAFE_className={css.tabPanel}
                >
                  <ul>
                    {tab.items
                      .slice(offset, offset + PAGE_SIZE)
                      .map((item, index) => (
                        <li
                          key={`${item.localProfileId}-${item.couponBoxId}`}
                          className={css.benefitItem}
                        >
                          <TabbedBenefitCurationItem
                            item={item}
                            index={index}
                          />
                        </li>
                      ))}
                  </ul>
                </TabPanel>
              )
            })}
          </TabPanelGroup>
        </Tabs>
      )}
      {maxPages > 1 && (
        <div className={css.buttonContainer}>
          <BoxButton
            UNSAFE_className={css.refreshButton}
            variant="secondary"
            size="medium"
            prefix={<IconArrowClockwiseCircularLine size="1rem" />}
            onClick={() => {
              clickedHomeFeedSectionActionButtonLog({
                actionType: 'more',
                ...getMapLogParams(),
                ...TABBED_BENEFIT_CURATION_SECTION_INFO,
                subSection: {
                  sectionIndex: selectedTabIndex,
                  sectionKey: selectedTab.title,
                  sectionName: selectedTab.title,
                },
              })
              setPage((prev) => (prev + 1) % maxPages)
            }}
          >
            {viewMoreButtonText ?? '혜택 새로보기'} {page + 1}/{maxPages}
          </BoxButton>
        </div>
      )}
    </BottomSheetSection>
  )
})

SectionTabbedBenefitCuration.displayName = 'SectionTabbedBenefitCuration'

export default SectionTabbedBenefitCuration
