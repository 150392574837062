import { type TypeLatLng } from '@daangn/local-map-api'
import { type StateCreator } from 'zustand'

export interface CoordinatesForDistanceSlice {
  coordinatesForDistance: TypeLatLng | null
  setCoordinatesForDistance: (distanceAnchor: TypeLatLng) => void
}
export const coordinatesForDistanceSlice: StateCreator<
  CoordinatesForDistanceSlice
> = (set) => ({
  coordinatesForDistance: null,
  setCoordinatesForDistance: (coordinatesForDistance: TypeLatLng) =>
    set({ coordinatesForDistance }),
})
