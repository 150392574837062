import { type LngLat } from '@src/core/types/geo'

import CurrentPosition from './CurrentPosition'
import * as css from './CurrentPositionMarker.css'
import Marker from './Marker'

interface Props {
  lngLat: LngLat
}

export const CurrentPositionMarker = ({ lngLat }: Props) => {
  return (
    <Marker className={css.marker} lngLat={lngLat}>
      <CurrentPosition />
    </Marker>
  )
}
