import React from 'react'

interface Props {
  size?: number
}

const IconBanchan = ({ size = 24 }: Props = {}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 1.5H5.5C3.84315 1.5 2.5 2.84315 2.5 4.5V19.5C2.5 21.1569 3.84315 22.5 5.5 22.5H18.5C20.1569 22.5 21.5 21.1569 21.5 19.5V4.5C21.5 2.84315 20.1569 1.5 18.5 1.5Z"
        fill="#B55918"
      />
      <path
        d="M5.0804 7.94222C5.0804 9.77967 6.56994 11.2692 8.40739 11.2692C10.2448 11.2692 11.7344 9.77967 11.7344 7.94222C11.7344 6.10478 10.2448 4.61523 8.40739 4.61523C6.56994 4.61523 5.0804 6.10478 5.0804 7.94222Z"
        fill="#FFAA00"
      />
      <path
        d="M11.2503 15.066C12.0103 15.5124 12.0103 16.6102 11.2503 17.0566C10.9004 17.2617 10.6832 17.6357 10.6832 18.0459C10.6832 18.9266 9.71809 19.4816 8.95803 19.0473C8.60816 18.8422 8.17384 18.8422 7.81191 19.0473C7.03979 19.4816 6.0867 18.9387 6.0867 18.0459C6.0867 17.6357 5.86954 17.2617 5.51967 17.0566C4.75962 16.6102 4.75962 15.5124 5.51967 15.066C5.86954 14.8609 6.0867 14.4869 6.0867 14.0767C6.0867 13.196 7.05185 12.6411 7.81191 13.0754C8.16178 13.2805 8.5961 13.2805 8.95803 13.0754C9.73015 12.6411 10.6832 13.184 10.6832 14.0767C10.6832 14.4869 10.9004 14.8609 11.2503 15.066Z"
        fill="white"
      />
      <path
        d="M13.6428 5.0626C13.6506 4.81344 13.8549 4.61548 14.1041 4.61548H14.7645C15.0194 4.61548 15.2261 4.82212 15.2261 5.07702V18.9232C15.2261 19.1781 15.0194 19.3847 14.7645 19.3847H13.6715C13.4109 19.3847 13.202 19.1692 13.2102 18.9088L13.6428 5.0626Z"
        fill="#FFE2A0"
      />
      <path
        d="M18.2009 5.0626C18.1931 4.81344 17.9888 4.61548 17.7396 4.61548H17.0793C16.8244 4.61548 16.6178 4.82212 16.6178 5.07702V18.9232C16.6178 19.1781 16.8244 19.3847 17.0793 19.3847H18.1723C18.4328 19.3847 18.6417 19.1692 18.6336 18.9088L18.2009 5.0626Z"
        fill="#FFE2A0"
      />
    </svg>
  )
}

export default React.memo(IconBanchan)
