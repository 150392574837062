import { type SearchCategoryKeywordsSuggestionSuggestion } from '@daangn/local-map-api'

import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useMapMoved } from '@src/features/map/store/mapMoved'

import SuggestionRootCategoryChip from './category-chip/SuggestionRootCategoryChip'
import SuggestionSubCategoryChip from './category-chip/SuggestionSubCategoryChip'

interface SearchSuggestionChipProps {
  index: number
  suggestion: SearchCategoryKeywordsSuggestionSuggestion
  isActive: boolean
}

const SearchSuggestionChip = ({
  index,
  suggestion,
  isActive,
}: SearchSuggestionChipProps) => {
  const { resetMapMoved } = useMapMoved()
  const { stepMove, stepType, stepBack } = useLocalMapStepMovePolicy()

  const handleChipClick = ({ keyword }: { keyword: string }) => {
    resetMapMoved()

    if (stepType === 'searchedPreview') {
      stepBack()
    }

    // 이전에 searchedPreview로 접근했을 경우 stepBack으로 검색결과로 돌아온 이후에 검색을 하기 위해서 setTimeout 적용
    // searchedPreview -> searchResult로 갔을 때 검색이 되지 않음
    setTimeout(() => {
      stepMove('searchResult', 'click_categorySuggestions', {
        query: keyword,
        queryFrom: 'category_suggestion',
        isBoundsSearch: true,
        isAddRecentKeyword: false,
        selectedPin: null,
      })
    }, 60)
  }

  switch (suggestion.keywordType) {
    case 'KEYWORD_TYPE_ROOT_CATEGORY':
      return (
        <SuggestionRootCategoryChip
          index={index}
          isActive={isActive}
          suggestion={suggestion}
          onClick={handleChipClick}
        />
      )
    case 'KEYWORD_TYPE_SUB_CATEGORY':
      return (
        <SuggestionSubCategoryChip
          index={index}
          isActive={isActive}
          suggestion={suggestion}
          onClick={handleChipClick}
        />
      )
  }
}

export default SearchSuggestionChip
