import React from 'react'

interface Props {
  className?: string
  size?: number
  color?: string
}

const IconDiscountBadge = ({
  className,
  size = 16,
  color = '#FF6F0F',
}: Props = {}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M11.7672 5.36568C12.0797 5.05327 12.0797 4.54673 11.7672 4.23431C11.4548 3.9219 10.9483 3.9219 10.6359 4.23431L4.23588 10.6343C3.92346 10.9467 3.92346 11.4533 4.23588 11.7657C4.5483 12.0781 5.05483 12.0781 5.36725 11.7657L11.7672 5.36568Z"
        fill="white"
      />
      <path
        d="M9.60313 10.7996C9.60313 10.1369 10.1404 9.59961 10.8031 9.59961C11.4659 9.59961 12.0031 10.1369 12.0031 10.7996C12.0031 11.4624 11.4659 11.9996 10.8031 11.9996C10.1404 11.9996 9.60313 11.4624 9.60313 10.7996Z"
        fill="white"
      />
      <path
        d="M5.2 4.00039C4.53726 4.00039 4 4.53765 4 5.20039C4 5.86313 4.53726 6.40039 5.2 6.40039C5.86274 6.40039 6.4 5.86313 6.4 5.20039C6.4 4.53765 5.86274 4.00039 5.2 4.00039Z"
        fill="white"
      />
    </svg>
  )
}

export default React.memo(IconDiscountBadge)
