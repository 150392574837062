import { Fab } from '@daangn/sprout-components-fab'
import { vars } from '@seed-design/design-token'
import { useActions } from '@stackflow/react'
import { forwardRef } from 'react'

import { DEFAULT_ZOOM_LEVEL } from '@src/core/components/karrot-map/map/constants'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useMapPanTo } from '@src/features/map/hooks/useMapPanTo'
import { useUserLocation } from '@src/features/user-location/hooks/useUserLocation'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { useVisibleMapViewportPadding } from '@src/pages/home-search-result-step/_hooks/useVisibleMapViewportPadding'
import { IconHouseDots } from '@src/services/assets/IconHouseDots'
import { IconHousePlus } from '@src/services/assets/IconHousePlus'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import { useSearchLogger } from '@src/services/log/hooks/useSearchLogger'

import * as css from './index.css'

const FloatingHomeShortcutButton = forwardRef<HTMLElement>((_, ref) => {
  const { map } = useMap()
  const { stepType } = useStepRouter()
  const { push } = useActions()
  const { panToWithOffset } = useMapPanTo(map)
  const { userLocationHome } = useUserLocation()
  const setDistancedCoordinates = coordinatesStore(
    (s) => s.setDistancedCoordinates
  )
  const { clickedHomeFloatingButtonLog } = useHomeLogger()
  const { clickedSearchResultFloatingButtonLog } = useSearchLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const mapPadding = useVisibleMapViewportPadding()

  const handleClick = () => {
    switch (stepType) {
      case 'searchResult':
        clickedSearchResultFloatingButtonLog({
          actionType: 'user_location',
        })
        break
      case 'home':
        clickedHomeFloatingButtonLog({
          actionType: 'user_location',
          ...getMapLogParams(),
        })
        break
    }

    if (!map) return

    if (!userLocationHome) {
      const mapCenterPixel = map.project(map.getCenter())
      const projectionX = mapCenterPixel.x + mapPadding.left - mapPadding.right
      const projectionY = mapCenterPixel.y + mapPadding.top - mapPadding.bottom
      const safeProjection = [
        projectionX > 0 ? projectionX : mapCenterPixel.x,
        projectionY > 0 ? projectionY : mapCenterPixel.y,
      ] as [number, number]
      const paddedMapCenterLngLat = map.unproject(safeProjection)

      push('UserLocationInMap', {
        centerLatitude: paddedMapCenterLngLat.lat,
        centerLongitude: paddedMapCenterLngLat.lng,
        actionType: 'CREATE',
        funnelFrom: 'LOCAL_MAP',
        locationTermsAgreementStatus: 'DISAGREED',
      })

      return
    }

    panToWithOffset({
      center: {
        lat: userLocationHome.point.latitude!,
        lng: userLocationHome.point.longitude!,
      },
      zoom: DEFAULT_ZOOM_LEVEL,
    })

    setDistancedCoordinates({
      type: 'userLocation',
      coordinates: {
        latitude: userLocationHome.point.latitude!,
        longitude: userLocationHome.point.longitude!,
      },
    })
  }

  return (
    <Fab
      UNSAFE_className={css.floatingButton}
      aria-label="집으로 이동"
      onClick={handleClick}
      ref={ref}
    >
      {userLocationHome ? (
        <IconHouseDots color={vars.$scale.color.gray900} />
      ) : (
        <IconHousePlus color={vars.$scale.color.gray900} />
      )}
    </Fab>
  )
})

export default FloatingHomeShortcutButton
