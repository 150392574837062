import { useEffect, useRef } from 'react'

export function useMapZoomend(
  map: maplibregl.Map | null,
  callback: () => void
) {
  const listeners = useRef({ callback })
  listeners.current.callback = callback

  useEffect(() => {
    function onZoomend() {
      listeners.current.callback()
    }

    map?.on('zoomend', onZoomend)

    return () => {
      map?.off('zoomend', onZoomend)
    }
  }, [map])
}
