import { type V1FollowBusinessPostSection } from '@daangn/local-map-api'
import { BoxButton } from '@daangn/sprout-components-button'
import { useMemo, useRef } from 'react'

import CarouselContent from '@src/core/components/carousel/CarouselContent'
import { usePreventParentHorizontalScroll } from '@src/core/components/scroll-control/usePreventParentHorizontalScroll copy'
import { usePreventSelfHorizontalScrollOnVerticalScroll } from '@src/core/components/scroll-control/usePreventSelfHorizontalScrollOnVerticalScroll'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { chunkArray } from '@src/core/utils/snippet'
import BottomSheetSection from '@src/pages/home-feed-step/section/lib/BottomSheetSection'
import {
  bottomButtonWrapper,
  sectionScrollWrapper,
} from '@src/pages/home-feed-step/section/lib/BottomSheetSection.css'
import {
  FOLLOWER_POST_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import SectionFollowPostItem from './SectionFollowPostItem'

interface SectionFollowPostProps {
  title: string
  followPostResult: V1FollowBusinessPostSection
}

const VIEW_ITEM_COUNT = 3

const REFERRER = genSectionReferrer(FOLLOWER_POST_SECTION_INFO.sectionKey)

const SectionFollowPost = ({
  title,
  followPostResult,
}: SectionFollowPostProps) => {
  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const { openTarget } = useLocalMapOpenTarget()

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  usePreventParentHorizontalScroll({
    targetRef: scrollContainerRef,
  })
  usePreventSelfHorizontalScrollOnVerticalScroll({
    targetRef: scrollContainerRef,
  })

  const listItems = useMemo(() => {
    const posts = followPostResult.businessPosts
    return chunkArray(posts, VIEW_ITEM_COUNT).map((postGroup, index) => (
      <div key={index}>
        {postGroup.map((postItem) => (
          <SectionFollowPostItem
            key={postItem.id}
            index={index}
            followPost={postItem}
          />
        ))}
      </div>
    ))
  }, [followPostResult.businessPosts])

  const handleMoreButtonClick = () => {
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'more',
      ...getMapLogParams(),
      ...FOLLOWER_POST_SECTION_INFO,
    })

    openTarget({
      targetUri: followPostResult.viewMoreTargetUri,
      referrer: REFERRER,
    })
  }

  return (
    <BottomSheetSection title={title}>
      <CarouselContent
        ref={scrollContainerRef}
        injectClass={sectionScrollWrapper}
        listItems={listItems}
        onListSwipe={() => {}}
      />
      <div className={bottomButtonWrapper}>
        <BoxButton
          variant="secondary"
          width="100%"
          size="medium"
          onClick={handleMoreButtonClick}
        >
          더보기
        </BoxButton>
      </div>
    </BottomSheetSection>
  )
}

export default SectionFollowPost
