import { type HTMLAttributes } from 'react'

import { DefaultPinImage, SelectedPinImage } from './DefaultPinResource'

export interface PinProps extends HTMLAttributes<HTMLDivElement> {
  isSelected?: boolean
  pinOption?: {
    width: number
    height: number
  }
}

const Pin = (props: PinProps) => {
  const { isSelected = false, pinOption, ...restProps } = props
  const defaultPinSize = isSelected ? 62 : 36

  const width = props.pinOption?.width ?? defaultPinSize
  const height = props.pinOption?.height ?? defaultPinSize

  return (
    <div {...restProps}>
      {isSelected ? (
        <SelectedPinImage {...props} width={width} height={height} />
      ) : (
        <DefaultPinImage {...props} width={width} height={height} />
      )}
    </div>
  )
}

export default Pin
