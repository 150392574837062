import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useRef, useMemo } from 'react'

import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import SectionTabbedBenefitCuration from './section-tabbed-benefit-curation/SectionTabbedBenefitCuration'
import { TABBED_BENEFIT_CURATION_SECTION_INFO } from './sectionKeyInfo'
import { useGetSectionQuery } from '../_api-queries/useGetSectionQuery'
import { coordinatesStore } from '../_store/coordinatesStore'

interface SectionTabbedBenefitCurationRootProps {
  userCoordinates: TypeLatLng
}
const SectionTabbedBenefitCurationRoot = ({
  userCoordinates,
}: SectionTabbedBenefitCurationRootProps) => {
  const sectionRef = useRef<HTMLElement>(null)
  const { data } = useGetSectionQuery({
    sectionViewTypes:
      'SECTION_VIEW_TYPE_LOCAL_PROFILE_WITH_BENEFIT_PER_TAB_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_LOCAL_PROFILE_WITH_BENEFIT_PER_TAB_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...TABBED_BENEFIT_CURATION_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const { localProfileWithBenefitPerTabSection } = data?.sectionItems[0] ?? {}

  return (
    localProfileWithBenefitPerTabSection && (
      <LocalMapImpressionObserver
        log={impressedLogParams}
        observeTargetRef={sectionRef}
      >
        <SectionTabbedBenefitCuration
          section={localProfileWithBenefitPerTabSection}
          ref={sectionRef}
        />
      </LocalMapImpressionObserver>
    )
  )
}

export default React.memo(SectionTabbedBenefitCurationRoot)
