import { type TypeLatLng } from '@daangn/local-map-api'
import { useMemo, useRef } from 'react'

import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import { useLaunchpadItems } from './hooks/useLaunchpadItems'
import Launchpad from './Launchpad'
import { getSectionImpressLogParams } from '../../_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '../../_store/coordinatesStore'
import { LAUNCHPAD_SECTION_INFO } from '../sectionKeyInfo'

interface LaunchpadRootProps {
  userCoordinates: TypeLatLng
}

const LaunchpadRoot = ({ userCoordinates }: LaunchpadRootProps) => {
  const { launchpadItems, isSuccess } = useLaunchpadItems({
    userCoordinates,
  })

  const sectionRef = useRef<HTMLDivElement>(null)

  const sectionImpressReset = coordinatesStore(
    (state) => state.sectionImpressReset
  )

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...LAUNCHPAD_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  if (!isSuccess || !launchpadItems) {
    return null
  }

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <Launchpad ref={sectionRef} padItems={launchpadItems} />
    </LocalMapImpressionObserver>
  )
}

export default LaunchpadRoot
