import * as Sentry from '@sentry/react'

import { InitdatadogRum } from '@src/core/lib/analytics/dataDogRum'

import {
  CLIENT_VERSION,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  IS_DEBUG_APP,
  IS_PRODUCTION,
  SENTRY_DSN,
  STAGE,
} from '../constants/constants'

/**
 * datadog, sentry 설정
 */
export const initTools = async () => {
  // datadog
  InitdatadogRum({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'local-map-tab-webview',
    env: STAGE,
    version: CLIENT_VERSION,
    sessionSampleRate: 100, // native sdk 설정과 동일하게 설정됨.  https://daangn.slack.com/archives/CUV91G2MT/p1714472289728429
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: false,
    trackLongTasks: false,
    trackSessionAcrossSubdomains: false,
    defaultPrivacyLevel: 'mask',
  })

  // sentry
  Sentry.init({
    environment: STAGE,
    release: CLIENT_VERSION,
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    ignoreErrors: [
      'Java exception was raised during method invocation', // 안드로이드 앱 인스턴스가 내려갔는데, 브릿지 호출 시 발생하는 에러로 무시처리 (유저 영향 X)
      'ResizeObserver loop limit exceeded', // 크롬 일부 버전에서만 발생하는 에러로, 무해하다고 함.
      'Request aborted',
      'timeout of 0ms exceeded',
      'timeout exceeded',
      'Render null by ErrorBoundary', // https://daangn.slack.com/archives/C0783HA3L66/p1741312078799779?thread_ts=1741310914.769449&cid=C0783HA3L66
    ],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    sampleRate: 0.1,
  })

  if (IS_DEBUG_APP || !IS_PRODUCTION) {
    const { default: VConsole } = await import('@daangn/vconsole')
    new VConsole({})
  }
}
