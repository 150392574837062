import { type Map as MapType } from 'maplibre-gl'
import { createContext, type ReactNode, useContext, useRef } from 'react'
import { createStore, useStore } from 'zustand'

import { makeConcurrentResource } from '@src/core/utils/concurrentResource'

import {
  loadMapGL,
  loadMapStyle,
  type MapStyleType,
  type MapGLType,
} from './mapResources'

type MapResource = {
  mapLibreGL: MapGLType
  mapStyle: MapStyleType
}

interface MapState {
  map: MapType | null
  setMap: (map: MapType | null) => void
  resource: MapResource
}

type MapStore = ReturnType<typeof createMapStore>
const createMapStore = (resource: MapResource) => {
  return createStore<MapState>()((set) => ({
    map: null,
    setMap: (map: MapType | null) => {
      return set({ map })
    },
    resource,
  }))
}

// map resource preload
const mapResource = makeConcurrentResource(
  Promise.all([loadMapGL(), loadMapStyle(true)])
)

const MapContext = createContext<MapStore>(createMapStore(null as any))

interface MapProps {
  children: ReactNode
}

const MapProvider = (props: MapProps) => {
  const [mapLibreGL, mapStyle] = mapResource.read()
  const store = useRef(
    createMapStore({
      mapLibreGL,
      mapStyle,
    })
  ).current

  return (
    <MapContext.Provider value={store}>{props.children}</MapContext.Provider>
  )
}

export const withMapProvider = <T extends object>(
  Component: React.ComponentType<T>
) => {
  return (props: T) => {
    return (
      <MapProvider>
        {/*<map element>*/}
        <Component {...props} />
      </MapProvider>
    )
  }
}

export const useMap = () => {
  const store = useContext(MapContext)

  const map = useStore(store, (s) => s.map)
  const setMap = useStore(store, (s) => s.setMap)
  return { map, setMap }
}

export const useMapLibreGL = () => {
  const store = useContext(MapContext)

  const mapLibreGL = useStore(store, (s) => s.resource.mapLibreGL)
  return { mapLibreGL }
}

export const useMapResource = () => {
  const store = useContext(MapContext)

  return useStore(store, (s) => s.resource)
}

export default MapProvider
