/**
 * pad를 추가한 숫자를 반환. (eg. 1 -> 01 / 10 -> 10)
 */
export const getPadNumber = (number: number) => {
  return `0${number}`.slice(-2)
}

/**
 * Date객체의 년, 월, 일, 시, 초를 포맷팅하여 반환.
 */
export const getDateFormatWithPad = (date: Date) => {
  const year = `${date.getFullYear()}`
  const month = getPadNumber(date.getMonth() + 1)
  const _date = getPadNumber(date.getDate())
  const hour = getPadNumber(date.getHours())
  const minute = getPadNumber(date.getMinutes())

  return {
    year,
    month,
    date: _date,
    hour,
    minute,
  }
}

/**
 *
 * @param {date|string} date
 * @returns {string} formatted string. e.g. yyyy.mm.dd
 */
export const dateFormatConverterNormal = (date: Date | string) => {
  try {
    const parseDate = new Date(date)
    const year = parseDate.getFullYear()
    const month = getPadNumber(parseDate.getMonth() + 1)
    const day = getPadNumber(parseDate.getDate())
    return `${year}.${month}.${day}`
  } catch (e) {
    return ''
  }
}

/**
 *
 * @param {string} x
 * @returns {string} formatted string. e.g. mm:ss
 */
export const SecondFullPrint = (x: string) => {
  const parsedNumber = parseInt(x) // floor and
  const second = parsedNumber % 60
  const minute = Math.floor(parsedNumber / 60) % 60
  const strSecond = getPadNumber(second)
  const strMinute = getPadNumber(minute)
  return `${strMinute}:${strSecond}`
}

/**
 * 현재 날짜로부터 얼마나 지났는지 반환.
 */
export const getDateDiffFromNow = (date: Date) => formatDistanceToNow(date)
/**
 * @name formatDistanceToNow
 * @category Common Helpers
 * @summary Return the distance between the given date and now in words.
 * @pure false
 *
 * @description
 * Return the distance between the given date and now in words.
 *
 * | Distance to now                                                   | Result              |
 * |-------------------------------------------------------------------|---------------------|
 * | 0 ... 30 secs                                                     | less than a minute  |
 * | 30 secs ... 1 min 30 secs                                         | 1 minute            |
 * | 1 min 30 secs ... 44 mins 30 secs                                 | [2..44] minutes     |
 * | 44 mins ... 30 secs ... 89 mins 30 secs                           | about 1 hour        |
 * | 89 mins 30 secs ... 23 hrs 59 mins 30 secs                        | about [2..24] hours |
 * | 23 hrs 59 mins 30 secs ... 41 hrs 59 mins 30 secs                 | 1 day               |
 * | 41 hrs 59 mins 30 secs ... 29 days 23 hrs 59 mins 30 secs         | [2..30] days        |
 * | 29 days 23 hrs 59 mins 30 secs ... 44 days 23 hrs 59 mins 30 secs | about 1 month       |
 * | 44 days 23 hrs 59 mins 30 secs ... 59 days 23 hrs 59 mins 30 secs | about 2 months      |
 * | 59 days 23 hrs 59 mins 30 secs ... 1 yr                           | [2..12] months      |
 * | 1 yr ... 1 yr 3 months                                            | about 1 year        |
 * | 1 yr 3 months ... 1 yr 9 month s                                  | over 1 year         |
 * | 1 yr 9 months ... 2 yrs                                           | almost 2 years      |
 * | N yrs ... N yrs 3 months                                          | about N years       |
 * | N yrs 3 months ... N yrs 9 months                                 | over N years        |
 * | N yrs 9 months ... N+1 yrs                                        | almost N+1 years    |
 *
 */

const ONE_MINUTE = 60
const ONE_HOUR = 60 * ONE_MINUTE
const ONE_DAY = 24 * ONE_HOUR
const ONE_WEEK = 7 * ONE_DAY
const ONE_MONTH = 30 * ONE_DAY
const ONE_YEAR = 12 * ONE_MONTH

const formatDistanceToNow = (date: Date) => {
  const now = new Date()
  const past = date
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000)

  if (diffInSeconds < 30) {
    return '1분 미만'
  } else if (diffInSeconds < ONE_MINUTE + 30) {
    return '1분'
  } else if (diffInSeconds < 44 * ONE_MINUTE + 30) {
    return `${Math.round(diffInSeconds / 60)}분`
  } else if (diffInSeconds < 90 * ONE_MINUTE - 30) {
    return '약 1시간'
  } else if (diffInSeconds < 24 * ONE_HOUR - 30) {
    return `약 ${Math.round(diffInSeconds / ONE_HOUR)}시간`
  } else if (diffInSeconds < 42 * ONE_HOUR - 30) {
    return '1일'
  } else if (diffInSeconds < 30 * ONE_DAY - 30) {
    return `${Math.round(diffInSeconds / ONE_DAY)}일`
  } else if (diffInSeconds < 45 * ONE_DAY - 30) {
    return '약 1개월'
  } else if (diffInSeconds < 60 * ONE_DAY - 30) {
    return '약 2개월'
  } else if (diffInSeconds < 365 * ONE_DAY) {
    return `${Math.round(diffInSeconds / ONE_MONTH)}개월`
  } else if (diffInSeconds < 365 * ONE_DAY + 90 * ONE_DAY) {
    return '약 1년'
  } else if (diffInSeconds < 365 * ONE_DAY + 270 * ONE_DAY) {
    return '1년 이상'
  } else if (diffInSeconds < 2 * 365 * ONE_DAY) {
    return '거의 2년'
  } else if (diffInSeconds < 3 * 365 * ONE_DAY) {
    return `약 ${Math.round(diffInSeconds / (365 * ONE_DAY))}년`
  }
}

export function strictFormatDistanceToNow(past: Date) {
  if (isNaN(past.getTime())) {
    return ''
  }

  const now = new Date()
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000)

  if (diffInSeconds < ONE_MINUTE) {
    return `${diffInSeconds}초 전`
  } else if (diffInSeconds < ONE_HOUR) {
    return `${Math.floor(diffInSeconds / ONE_MINUTE)}분 전`
  } else if (diffInSeconds < ONE_DAY) {
    return `${Math.floor(diffInSeconds / ONE_HOUR)}시간 전`
  } else if (diffInSeconds < ONE_WEEK) {
    return `${Math.floor(diffInSeconds / ONE_DAY)}일 전`
  } else if (diffInSeconds < ONE_MONTH) {
    return `${Math.floor(diffInSeconds / ONE_WEEK)}주 전`
  } else if (diffInSeconds < ONE_YEAR) {
    return `${Math.floor(diffInSeconds / ONE_MONTH)}달 전`
  } else {
    return `${Math.floor(diffInSeconds / ONE_YEAR)}년 전`
  }
}

interface TimeDifference {
  totalSeconds: number
  seconds: number
  minutes: number
  hours: number
  days: number
}
/**
 * Calculates the difference between two dates and returns the result in days, hours, minutes, seconds, and total seconds.
 *
 * @param {Date} [params.startDate=new Date()] - The start date. Defaults to the current date and time if not provided.
 * @param {Date} params.endDate - The end date.
 * @returns {TimeDifference} An object containing the time difference in days, hours, minutes, seconds, and total seconds.
 */
export function calculateTimeDifference({
  startDate = new Date(),
  endDate,
}: {
  startDate?: Date
  endDate: Date
}): TimeDifference {
  const timeDifference = endDate.getTime() - startDate.getTime()

  const SECOND = 1000
  const MINUTE = SECOND * 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24

  const totalSeconds = Math.floor(timeDifference / SECOND)
  const seconds = totalSeconds % MINUTE
  const minutes = Math.floor((totalSeconds % HOUR) / MINUTE)
  const hours = Math.floor((totalSeconds % DAY) / HOUR)
  const days = Math.floor(totalSeconds / DAY)

  return { totalSeconds, seconds, minutes, hours, days }
}

interface DateComparisonResult {
  isBeforeStart: boolean
  isWithinRange: boolean
  isAfterEnd: boolean
}
/**
 * Compares a reference date to a given date range and returns the comparison result.
 *
 * @param {Date} params.startDate - The start date of the range.
 * @param {Date} params.endDate - The end date of the range.
 * @param {Date} [params.referenceDate=new Date()] - The reference date to compare with the range. Defaults to the current date and time.
 * @returns {DateComparisonResult} An object containing the comparison result:
 * - `isBeforeStart`: `true` if the reference date is before the start date, otherwise `false`.
 * - `isWithinRange`: `true` if the reference date is within the date range, otherwise `false`.
 * - `isAfterEnd`: `true` if the reference date is after the end date, otherwise `false`.
 */
export const compareDateToRange = ({
  startDate,
  endDate,
  referenceDate = new Date(),
}: {
  startDate: Date
  endDate: Date
  referenceDate?: Date
}): DateComparisonResult => {
  const timeDiffToStart = calculateTimeDifference({
    startDate,
    endDate: referenceDate,
  })
  const timeDiffToEnd = calculateTimeDifference({
    startDate: referenceDate,
    endDate,
  })

  const isBeforeStart = timeDiffToStart.totalSeconds < 0
  const isAfterEnd = timeDiffToEnd.totalSeconds < 0
  const isWithinRange = !isBeforeStart && !isAfterEnd

  return {
    isBeforeStart,
    isWithinRange,
    isAfterEnd,
  }
}
