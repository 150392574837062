import { type ExpertQuestSectionExpertService } from '@daangn/local-map-api'
import { useMemo, useRef } from 'react'

import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { commaNumber } from '@src/core/utils/unit'
import {
  EXPERT_QUEST_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import * as css from './SectionExpert.css'
import { bottomSheetScrollItemWrapper } from '../lib/BottomSheetSection.css'

const REFERRER = genSectionReferrer(EXPERT_QUEST_SECTION_INFO.sectionKey)

interface ExpertItemProps {
  expertPost: ExpertQuestSectionExpertService
  index: number
}
const ExpertItem = ({ expertPost, index }: ExpertItemProps) => {
  const itemRef = useRef<HTMLDivElement>(null)

  const { clickedHomeFeedSectionItemLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const { openTarget } = useLocalMapOpenTarget()

  const impressionLogParams = useMemo(() => {
    return {
      name: 'client_impressed_localMap_home_feed_sectionItem_v1',
      params: {
        itemDataType: 'expert_quest_service',
        itemIndex: index,
        itemId: expertPost.name,
        itemName: expertPost.name,
        ...EXPERT_QUEST_SECTION_INFO,
      },
    } as const
  }, [index, expertPost.name])

  const handleClick = () => {
    clickedHomeFeedSectionItemLog({
      itemIndex: index,
      itemDataType: 'expert_quest_service',
      itemId: expertPost.name || '',
      ...EXPERT_QUEST_SECTION_INFO,
      ...getMapLogParams(),
    })
    openTarget({
      targetUri: expertPost.targetUri,
      referrer: REFERRER,
    })
  }

  return (
    <LocalMapImpressionObserver
      log={impressionLogParams}
      observeTargetRef={itemRef}
    >
      <div
        ref={itemRef}
        className={bottomSheetScrollItemWrapper}
        key={expertPost.imageUrl}
      >
        <article className={css.expertCardItem} onClick={handleClick}>
          <div className={css.expertCardImageWrapper}>
            <img
              loading="lazy"
              className={css.expertCardImage}
              alt={expertPost.name}
              src={expertPost.imageUrl}
            />
            <div className={css.expertCardBorder} />
          </div>
          <div className={css.expertCardTitle}>{expertPost.name}</div>
          <div className={css.expertCardDes}>{`${commaNumber(
            Number(expertPost.useCount)
          )}명 이용`}</div>
        </article>
      </div>
    </LocalMapImpressionObserver>
  )
}

export default ExpertItem
