import { useCallback, useMemo } from 'react'

import { karrotBridge } from '@src/core/lib/bridge/bridge'

export function useBridgeRouter() {
  const push: (typeof karrotBridge)['pushRouter'] = useCallback(
    ({ router }) => {
      console.info('# move to: ', router)
      return karrotBridge.pushRouter({
        router: {
          ...router,
        },
      })
    },
    []
  )

  const replace: (typeof karrotBridge)['replaceRouter'] = useCallback(
    ({ router }) => {
      return karrotBridge.pushRouter({
        router: {
          ...router,
        },
      })
    },
    []
  )

  return useMemo(
    () => ({
      push,
      replace,
    }),
    [push, replace]
  )
}
