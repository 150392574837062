import { useCallback, useMemo } from 'react'

import { type Target, useOpenTarget } from '@src/core/hooks/useTarget'
import { type LocalMapEventPartsName } from '@src/services/log/eventNames/types'
import { getExternalReferrerEntry } from '@src/services/store/externalAccessValues'

type LocalMapOpenTarget = Target & {
  referrer: LocalMapEventPartsName
}

/**
 * 동네지도에서 외부 서비스를 열 때 동네지도 정책이 담긴 targetOpener를 생성.
 */
export function useLocalMapOpenTarget() {
  const { openTarget: _openTarget } = useOpenTarget()
  const { referrer: rootFunnel } = getExternalReferrerEntry()

  /**
   * 동네지도에서 외부 서비스를 열 때 동네지도 정책이 담긴 targetOpener
   */
  const openTarget = useCallback(
    (localMapTarget: LocalMapOpenTarget) => {
      const {
        referrer: currentFunnel,
        updateReferrer,
        updateEntry,
        ...target
      } = localMapTarget

      _openTarget({
        ...target,
        updateReferrer: updateReferrer ?? rootFunnel ?? currentFunnel,
        updateEntry: updateEntry ?? currentFunnel,
      })
    },
    [_openTarget, rootFunnel]
  )

  return useMemo(() => ({ openTarget }), [openTarget])
}
