import { runtime, LocalMapServiceApi, BASE_PATH } from '@daangn/local-map-api'

import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'
import { makePlantaeFetch } from '@src/core/lib/plantae/makePlantaeFetch'
import { IS_PRODUCTION } from '@src/services/constants/constants'

const BASE_PATH_WITH_STAGE = IS_PRODUCTION
  ? BASE_PATH.replace('.alpha.', '.')
  : BASE_PATH

export const localMapApi = (bridgeInfo: BridgeInfoType) => {
  const config = new runtime.Configuration({
    basePath: BASE_PATH_WITH_STAGE,
    fetchApi: makePlantaeFetch(bridgeInfo),
  })

  return new LocalMapServiceApi(config)
}
