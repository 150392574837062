import { useEffect } from 'react'

export const useHandleSearchStateEffect = (
  callback: () => void,
  deps?: any[]
) => {
  useEffect(() => {
    callback()
  }, [...(deps ?? [])])
}
