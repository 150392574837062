import { type V1BusinessPost } from '@daangn/local-map-api'
import { useMemo, useRef } from 'react'

import { getBigPictureImageUrl } from '@src/core/components/big-picture/getBigPictureImageUrl'
import { AVATAR_BIZ_PROFILE_DEFAULT_IMG } from '@src/core/components/common-components/constants'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { getDateDiffFromNow } from '@src/core/utils/date'
import { extractPostContent } from '@src/pages/home-feed-step/_utils/bottomSheetUtil'
import * as css from '@src/pages/home-feed-step/section/bottomSheetPostSection.css'
import {
  PUBLIC_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

interface SectionPublicPostItemProps {
  publicPost: V1BusinessPost
  index: number
}

const REFERRER = genSectionReferrer(PUBLIC_SECTION_INFO.sectionKey)

const SectionPublicPostItem = ({
  publicPost,
  index,
}: SectionPublicPostItemProps) => {
  const {
    getImpressedHomeFeedSectionItemLogParams,
    clickedHomeFeedSectionItemLog,
  } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const itemRef = useRef<HTMLDivElement>(null)

  const thumbnailImageUrl = publicPost.imagesUrls[0]

  const { openTarget } = useLocalMapOpenTarget()

  const handleClick = () => {
    clickedHomeFeedSectionItemLog({
      itemIndex: index,
      itemDataType: 'post',
      itemId: publicPost.id,
      ...PUBLIC_SECTION_INFO,
      ...getMapLogParams(),
    })

    openTarget({
      targetUri: `${publicPost.targetUri}`,
      referrer: REFERRER,
    })

    if (publicPost.clickLoggingUrl) {
      fetch(publicPost.clickLoggingUrl, {
        method: 'POST',
      })
    }
  }

  const formattedCreatedAt = `${getDateDiffFromNow(publicPost.createdAt)} 전`

  const impressionLogParams = useMemo(
    () =>
      getImpressedHomeFeedSectionItemLogParams({
        itemDataType: 'post',
        itemIndex: index,
        itemId: publicPost.id,
        ...PUBLIC_SECTION_INFO,
      }),
    [getImpressedHomeFeedSectionItemLogParams, index, publicPost.id]
  )

  return (
    <LocalMapImpressionObserver
      log={impressionLogParams}
      observeTargetRef={itemRef}
    >
      <article className={css.container} ref={itemRef}>
        <div className={css.flexContainer} onClick={handleClick}>
          <div className={css.imageWrapper}>
            <img
              loading="lazy"
              alt={publicPost.title}
              className={css.image}
              src={
                thumbnailImageUrl
                  ? getBigPictureImageUrl(thumbnailImageUrl, {
                      s: {
                        width: 94 * 3,
                        height: 94 * 3,
                      },
                    })
                  : AVATAR_BIZ_PROFILE_DEFAULT_IMG
              }
            />
            <div className={css.imageBorder} />
          </div>
          <div className={css.main}>
            <div className={css.bio}>
              <span className={css.bioLabel({ type: 'profileName' })}>
                {publicPost.localProfile?.name}
              </span>
              <span className={css.bioLabel({ type: 'category' })}>
                {publicPost.localProfile?.categoryName}
              </span>
            </div>
            <div className={css.postTitle}>{publicPost.title}</div>
            <div className={css.postContent}>
              {extractPostContent(publicPost.content)}
            </div>
            <div className={css.captions}>
              <span className={css.caption}>{formattedCreatedAt}</span>
            </div>
          </div>
        </div>
      </article>
    </LocalMapImpressionObserver>
  )
}

export default SectionPublicPostItem
