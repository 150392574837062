import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useMemo, useRef } from 'react'

import { useGetSectionQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import SectionCreateAndSuggestion from '@src/pages/home-feed-step/section/section-create-and-suggestion/SectionCreateAndSuggestion'
import { CREATE_AND_SUGGESTION_SECTION_INFO } from '@src/pages/home-feed-step/section/sectionKeyInfo'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

interface SectionCreateAndSuggestionRootProps {
  userCoordinates: TypeLatLng
}
const SectionCreateAndSuggestionRoot = ({
  userCoordinates,
}: SectionCreateAndSuggestionRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)

  const { data } = useGetSectionQuery({
    sectionViewTypes:
      'SECTION_VIEW_TYPE_CREATE_BUSINESS_PROFILE_AND_SUGGEST_POI_BUTTON_SECTION',
    queryKey:
      'SECTION_VIEW_TYPE_CREATE_BUSINESS_PROFILE_AND_SUGGEST_POI_BUTTON_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...CREATE_AND_SUGGESTION_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const result =
    data?.sectionItems[0]?.createBusinessProfileAndSuggestPoiButtonSection
  if (!result) return null

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div ref={sectionRef}>
        <SectionCreateAndSuggestion createAndSuggestion={result} />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(SectionCreateAndSuggestionRoot)
