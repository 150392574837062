import {
  type ReactNode,
  useState,
  forwardRef,
  type Ref,
  type CSSProperties,
} from 'react'

import {
  CarouselIndicator,
  ScrollSnapCarousel,
} from '@src/core/components/carousel/ScrollSnapCarousel'
import { type PropOf } from '@src/core/types/utilTypes'

interface CarouselContentProps {
  listItems: ReactNode[]
  scrollRef?: Ref<HTMLUListElement>
  indicatorStyle?: CSSProperties
  onCarouselOffsetChange?: (offset: number) => void
  onListSwipe?: (offset: number) => void
  injectClass?: string
}

const CarouselContent = forwardRef(
  (
    {
      listItems,
      onListSwipe,
      scrollRef,
      indicatorStyle,
      onCarouselOffsetChange,
      injectClass,
    }: CarouselContentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [carouselOffset, setCarouselOffset] = useState(0)
    const maximumPage = listItems.length

    const handleCarouselOffsetChange: PropOf<
      typeof ScrollSnapCarousel,
      'onOffsetChange'
    > = (offset) => {
      if (offset === carouselOffset) {
        return
      }

      setCarouselOffset(offset)
      onCarouselOffsetChange?.(offset)
    }

    const handleItemListImpression: PropOf<
      typeof ScrollSnapCarousel,
      'onImpressionItem'
    > = (offset) => {
      if (offset === 0) {
        return
      }

      onListSwipe?.(offset)
    }

    return (
      <div ref={ref} className={injectClass || ''}>
        <ScrollSnapCarousel
          ref={scrollRef}
          items={listItems}
          onOffsetChange={handleCarouselOffsetChange}
          onImpressionItem={handleItemListImpression}
        />
        <CarouselIndicator
          activeOffset={carouselOffset}
          maxOffset={maximumPage}
          style={indicatorStyle}
        />
      </div>
    )
  }
)

export default CarouselContent
