import { type Coordinates } from '@src/core/types/geo'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '@src/pages/home-search-result-step/_store/search-business-places-query-request-params'

import { karrotBridgeLog } from '../bridgeLog'
import { useSearchLogParamStore } from '../store'

export function useSearchExtendLogger() {
  const { querySearchKey } = useSearchBusinessPlacesQueryRequestParamsStore(
    (state) => ({
      querySearchKey: state.query,
    })
  )
  const { searchFunnelId, funnelFrom, queryId, queryFrom } =
    useSearchLogParamStore((state) => ({
      searchFunnelId: state.searchFunnelId,
      funnelFrom: state.funnelFromId,
      queryId: state.queryId,
      queryFrom: state.queryFromId,
    }))

  type impressedLocalMapSearchResultViewDistantResultsButtonV1Params = {
    zoom_level: number
    center_coordinates: Coordinates
  }

  function impressedLocalMapSearchResultViewDistantResultsButtonV1(
    params: impressedLocalMapSearchResultViewDistantResultsButtonV1Params
  ) {
    karrotBridgeLog(
      'client_impressed_localMap_searchResult_viewDistantResultsButton_v1',
      {
        search_funnel_id: searchFunnelId,
        funnel_from: funnelFrom,
        query: querySearchKey,
        query_id: queryId,
        query_from: queryFrom,
        ...params,
      }
    )
  }

  type clickedLocalMapSearchResultViewDistantResultsButtonV1Params = {
    zoom_level: number
    center_coordinates: Coordinates
  }

  function clickedLocalMapSearchResultViewDistantResultsButtonV1(
    params: clickedLocalMapSearchResultViewDistantResultsButtonV1Params
  ) {
    karrotBridgeLog(
      'client_clicked_localMap_searchResult_viewDistantResultsButton_v1',
      {
        search_funnel_id: searchFunnelId,
        funnel_from: funnelFrom,
        query: querySearchKey,
        query_id: queryId,
        query_from: queryFrom,
        ...params,
      }
    )
  }

  return {
    impressedLocalMapSearchResultViewDistantResultsButtonV1,
    clickedLocalMapSearchResultViewDistantResultsButtonV1,
  }
}
