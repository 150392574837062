import {
  type ReactNode,
  useMemo,
  useReducer,
  useRef,
  forwardRef,
  type Ref,
} from 'react'

import * as css from './ScrollSnapCarousel.css'
import {
  useIntersectionObserver,
  type useIntersectionObserverProp,
} from '../intersection/useIntersectionObserver'

interface ScrollSnapCarouselProps {
  items: ReactNode[]
  onOffsetChange: (offset: number) => void
  onImpressionItem?: (offset: number) => void
}

export const ScrollSnapCarousel = forwardRef(
  (
    { items, onOffsetChange, onImpressionItem }: ScrollSnapCarouselProps,
    ref: Ref<HTMLUListElement>
  ) => {
    const handleVisibleItem = (index: number) => {
      onOffsetChange(index)
    }

    return (
      <ul className={css.carousel} ref={ref}>
        {items.map((item, index) => (
          <CarouselItem
            key={index}
            offset={index}
            onVisibleItem={handleVisibleItem}
            onImpressionItem={onImpressionItem}
          >
            {item}
          </CarouselItem>
        ))}
      </ul>
    )
  }
)

interface CarouselItemProps {
  offset: number
  onVisibleItem: (index: number) => void
  onImpressionItem?: (index: number) => void
  children: ReactNode
}

const CarouselItem = ({
  offset,
  onVisibleItem,
  onImpressionItem,
  children,
}: CarouselItemProps) => {
  const itemElementRef = useRef<HTMLLIElement>(null)
  const [impressed, impress] = useReducer(() => true, false)

  const searchSectionIntersectionControl: useIntersectionObserverProp = useMemo(
    () => ({
      onChangeVisibility: (entry) => {
        if (entry.isIntersecting) {
          if (!impressed) {
            onImpressionItem?.(offset)
          }
          impress()
          onVisibleItem(offset)
        }
      },
      targetElementRef: itemElementRef,
      options: {
        threshold: 0.7,
      },
    }),
    [offset, onVisibleItem, impressed, onImpressionItem]
  )

  useIntersectionObserver(searchSectionIntersectionControl)

  return (
    <li className={css.carouselItem} ref={itemElementRef}>
      {children}
    </li>
  )
}

interface CarouselIndicatorProps {
  activeOffset: number
  maxOffset: number
  style?: React.CSSProperties
}

export const CarouselIndicator = ({
  activeOffset,
  maxOffset,
  style,
}: CarouselIndicatorProps) => {
  return (
    <div className={css.carousleIndicator} style={style}>
      <div className={css.dots}>
        {maxOffset > 1 &&
          [...new Array(maxOffset)].map((_, i) =>
            i === activeOffset ? (
              <div
                className={css.dot({
                  active: true,
                })}
                key={i}
                data-active
              />
            ) : (
              <div className={css.dot()} key={i} />
            )
          )}
      </div>
    </div>
  )
}
