import { create } from 'zustand'

import { type LocalMapSearchExecutionLogParams } from '../types/search'

export const useSuggestedSearchParams = create<{
  params: LocalMapSearchExecutionLogParams
  setParams: (newParams: LocalMapSearchExecutionLogParams) => void
}>((set) => ({
  params: {
    typedQuery: '',
    suggestionType: 'keyword',
  },
  setParams: (newParams: LocalMapSearchExecutionLogParams) =>
    set({ params: newParams }),
}))
