import { type Environment } from '@daangn/karrotbridge/lib/core/getCurrentEnvironment'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'
import { vars } from '@seed-design/design-token'
import { basicUIPlugin, IconBack } from '@stackflow/plugin-basic-ui'
import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackflow } from '@stackflow/react'

import { karrotBridge } from '@src/core/lib/bridge/bridge'
import { type TupleToMap } from '@src/core/types/utilTypes'
import { getDeviceType } from '@src/core/utils/deviceType'
import { ANDROID_ONLY_getSafeAreaInsetTopOverrideValue } from '@src/core/utils/safeAreaVariable'
import { IS_ANDROID } from '@src/services/constants/constants'
import routes from '@src/services/routes'

import depthChangePlugin from './plugins/depthChange'
import { iOSScrollToTopPlugin } from './plugins/iosScrollToTopPlugin'

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */
export const stackflowThemeBy = (environment: Environment) => {
  if (environment === 'Cupertino') {
    return 'cupertino'
  }
  if (environment === 'Android') {
    return 'android'
  }
  // Web
  return 'android'
}

export const theme = getDeviceType() === 'ios' ? 'cupertino' : 'android'

export const activities = routes.reduce(
  (activities, route) => ({ ...activities, [route.key]: route.activity }),
  {}
) as TupleToMap<typeof routes, 'key', 'activity'>

export const historyRoutes = routes.reduce(
  (activities, route) => ({ ...activities, [route.key]: route.path }),
  {}
) as TupleToMap<typeof routes, 'key', 'path'>

const { Stack, useFlow } = stackflow({
  transitionDuration: 270,
  activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor:
          theme === 'cupertino'
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        minSafeAreaInsetTop:
          IS_ANDROID || ANDROID_ONLY_getSafeAreaInsetTopOverrideValue()
            ? `${ANDROID_ONLY_getSafeAreaInsetTopOverrideValue()}px`
            : undefined,
        closeButton: {
          renderIcon: () => <IconBack />,
          onClick() {
            karrotBridge.closeRouter({})
          },
        },
      },
    }),
    historySyncPlugin({
      routes: historyRoutes,
      fallbackActivity: () => 'Home',
    }),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'local-map',
    }),
    depthChangePlugin({
      theme,
    }),
    iOSScrollToTopPlugin(),
  ],
})

export { Stack }
export type TypeUseFlow = typeof useFlow
export type ActivitiesKeyType = keyof typeof activities
