import { type TypeLatLng } from '@daangn/local-map-api'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getLocalStorageKey } from '@src/core/utils/keys'
import { localMapApi } from '@src/services/api'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

type UseLaunchpadQueryData = Awaited<
  ReturnType<
    Awaited<ReturnType<typeof localMapApi>['localMapServiceGetLaunchpad']>
  >
>

interface UseLaunchpadProps {
  userCoordinates: TypeLatLng
  queryOptions?: {
    refetchOnWindowFocus?: UseQueryOptions<UseLaunchpadQueryData>['refetchOnWindowFocus']
    refetchOnReconnect?: UseQueryOptions<UseLaunchpadQueryData>['refetchOnReconnect']
  }
}

const LAUCNHPAD_CACHE_KEY = getLocalStorageKey('v1/launchpad')

export const useLaunchpadItems = ({
  userCoordinates,
  queryOptions,
}: UseLaunchpadProps) => {
  const bridgeInfo = useBridgeInfo()

  const { data, isLoading, refetch, isSuccess } = useQuery({
    queryKey: ['launchpad'],
    queryFn: async () => {
      return localMapApi(bridgeInfo).localMapServiceGetLaunchpad({
        latlngLatitude: userCoordinates.latitude,
        latlngLongitude: userCoordinates.longitude,
      })
    },
    refetchOnWindowFocus: queryOptions?.refetchOnWindowFocus,
    refetchOnReconnect: queryOptions?.refetchOnReconnect,
    initialData: () => {
      try {
        return JSON.parse(localStorage.getItem(LAUCNHPAD_CACHE_KEY) ?? '')
      } catch {
        return null
      }
    },
  })

  useEffect(() => {
    refetch()
  }, [userCoordinates.latitude, userCoordinates.longitude, refetch])

  useEffect(() => {
    if (isSuccess && data) {
      localStorage.setItem(LAUCNHPAD_CACHE_KEY, JSON.stringify(data))
    }
  }, [isSuccess, data])

  return { launchpadItems: data?.launchpadItems, isLoading, isSuccess, refetch }
}
