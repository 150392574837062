import { type NonNullableMap } from '@src/core/types/utilTypes'
import { getQueryFromUrl } from '@src/core/utils/getQueryFromUrl'

interface ExternalAccessValues {
  referrer: string | null
  entry: string | null
  // TODO openInSeparateWebView: boolean
}

/**
 * 외부 접근 값을 관리하는 해쉬맵
 */
const externalAccessValues: ExternalAccessValues = {
  referrer: null,
  entry: null,
}

/**
 * 외부 접근 값 초기화 함수
 * @values
 * - referrer: referrer 값
 * - entry: entry 값
 */
export const initializeExternalAccessValues = ({
  onInialize,
}: {
  onInialize: (values: ExternalAccessValues) => void
}) => {
  const { referrer, entry } = getQueryFromUrl<
    NonNullableMap<Partial<ExternalAccessValues>>
  >(window.location.href)

  externalAccessValues.referrer = referrer ?? null
  externalAccessValues.entry = entry ?? null

  onInialize(externalAccessValues)
}

/**
 * externalAccessValues에서 특정 key의 값을 가져오는 함수
 */
export const getExternalAccessValue = (key: keyof ExternalAccessValues) => {
  return externalAccessValues[key]
}

/**
 * 외부에서 동네지도 서비스로 접근한 referrer와 entry를 가져오는 함수
 */
export const getExternalReferrerEntry = () => {
  return {
    referrer: externalAccessValues.referrer,
    entry: externalAccessValues.entry,
  }
}

/**
 * externalAccessValues 전체 값을 가져오는 함수
 */
export const getAllExternalAccessValues = () => {
  return { ...externalAccessValues }
}
