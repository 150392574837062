import { identity, pickBy } from 'lodash-es'
import { type PaddingOptions, type FitBoundsOptions } from 'maplibre-gl'
import { useCallback } from 'react'

import {
  DEFAULT_ZOOM_LEVEL,
  type MapCustomEventActionType,
} from '@src/core/components/karrot-map/map/constants'
import {
  useMap,
  useMapLibreGL,
} from '@src/core/components/karrot-map/map/MapProvider'
import { coordinatesToBounds } from '@src/core/components/karrot-map/map/utils'
import { type WithLngLat } from '@src/core/components/karrot-map/types'

import { getOffsetY } from './useMapPanTo'

interface fitBoundsWithPaddingProps<T> {
  places: WithLngLat<T>[]
  options?: FitBoundsOptions & { padding: PaddingOptions }
}

/**
 * 동네지도 정책이 적용된 fitBounds를 반환하는 hook
 */
export function useMapFitBounds() {
  const { map } = useMap()
  const { mapLibreGL } = useMapLibreGL()

  const localMapFitBounds = useCallback(
    <T>({ places, options }: fitBoundsWithPaddingProps<T>) => {
      if (!map) {
        return
      }

      // 조회된 검색결과 마커가 모두 보여질 수 있는 지역 범위로 노출한다
      const boundCoordinates = places.map<[number, number]>((marker) => [
        marker.lngLat.lng,
        marker.lngLat.lat,
      ])

      const isBoundsCountOne = boundCoordinates.length === 1

      const bounds = coordinatesToBounds({
        coordinates: boundCoordinates,
        mapLibreGL,
      })

      // 동네지도 정책에 따라 zoom level을 설정한다
      const optionPolicy: FitBoundsOptions | undefined = isBoundsCountOne
        ? cleanData({
            zoom: DEFAULT_ZOOM_LEVEL,
            offset: options?.padding
              ? [
                  0,
                  getOffsetY(
                    options?.padding?.top ?? 0,
                    options?.padding?.bottom ?? 0
                  ),
                ]
              : undefined,
          })
        : undefined

      map.fitBounds(
        bounds,
        {
          ...options,
          ...optionPolicy,
        },
        {
          action: 'prevent-bound-change' as MapCustomEventActionType,
        }
      )
    },
    [map, mapLibreGL]
  )

  return {
    /**
     * 동네지도 정책이 적용된 fitBounds
     */
    localMapFitBounds,
  }
}

/**
 * undefiend 제거용도
 */
function cleanData<T extends object>(data: T) {
  return pickBy(data, identity)
}
