/** 홈 스탭 검색 input height px*/
export const HOME_SEARCH_INPUT_HEIGHT_PX = 48
/** 홈 스탭 검색 input 상하 padding px */
export const HOME_SEARCH_INPUT_TOP_PADDING_PX = 12
/** 홈 스탭 검색 추천 칩 height px */
export const HOME_SEARCH_RECOMMENDATION_CHIP_HEIGHT_PX = 32

/** 검색 스탭 검색 input height px */
export const SEARCH_SEARCH_INPUT_HEIGHT_PX = 44
/** 검색 스탭 검색바 하단 padding px */
export const SEARCH_SEARCH_INPUT_BOTTOM_PADDING_PX = 4
/** 검색 스탭 카테고리 추천 영역 크기 px */
export const SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX = 48

export const SEARCH_SEARCH_BAR_HEIGHT_PX =
  HOME_SEARCH_INPUT_TOP_PADDING_PX + // 홈 Input과 동일한 높이를 유지하기 위해 홈의 상단 영역 높이를 그대로 적용
  SEARCH_SEARCH_INPUT_HEIGHT_PX + // 검색 input 사이즈
  (HOME_SEARCH_INPUT_HEIGHT_PX - SEARCH_SEARCH_INPUT_HEIGHT_PX) + // 홈 input 사이즈에서 가운데 위치시키기 위한 여백값
  SEARCH_SEARCH_INPUT_BOTTOM_PADDING_PX // 아래 패딩

/** 바텀시트 핸들바 높이 */
export const BOTTOM_SHEET_HANDLE_BAR_HEIGHT_PX = 24

/**
 * 검색 스텝에서 바텀시트 앵커 바텀 오프셋
 */
export const ANCHOR_SEARCH_BOTTOM_OFFSET = 40

/**
 * 검색 스텝에서 바텀시트 앵커 탑 오프셋
 */
export const ANCHOR_SEARCH_TOP_OFFSET =
  SEARCH_SEARCH_BAR_HEIGHT_PX - BOTTOM_SHEET_HANDLE_BAR_HEIGHT_PX
