import { useCallback, useRef } from 'react'

import { useTouchGesture } from '@src/core/components/touch-gesture/useTouchGesture'

interface usePreventParentHorizontalScrollProps {
  targetRef: React.RefObject<HTMLElement>
}

export function usePreventParentHorizontalScroll({
  targetRef,
}: usePreventParentHorizontalScrollProps) {
  const initialMoveTypeRef = useRef<'None' | 'Horizontal' | 'Vertical'>('None')

  const handleOnGestureSwipe: Required<
    Parameters<typeof useTouchGesture>[0]
  >['onSwipe'] = useCallback(({ moveType }, event) => {
    // 초기 움직임을 기록해요.
    if (initialMoveTypeRef.current === 'None') {
      initialMoveTypeRef.current = moveType
    }

    if (initialMoveTypeRef.current !== 'Vertical') {
      event.stopPropagation()
    }
  }, [])

  const handleOnGestureEnd: Required<
    Parameters<typeof useTouchGesture>[0]
  >['onGestureEnd'] = useCallback((e) => {
    if (initialMoveTypeRef.current === 'Horizontal') {
      e.stopPropagation()
    }
    initialMoveTypeRef.current = 'None'
  }, [])

  const { isTouchMove } = useTouchGesture({
    targetElementRef: targetRef,
    onSwipe: handleOnGestureSwipe,
    onGestureEnd: handleOnGestureEnd,
  })

  return {
    isSwiping: isTouchMove,
  }
}
