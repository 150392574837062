import { useCallback } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { bizProfileSdk } from '@src/core/lib/bizProfileSdk'
import { getExternalReferrerEntry } from '@src/services/store/externalAccessValues'

/**
 * 장소 생성/수정/삭제 웹뷰는 다양한 진입점을 가지고 있어요.
 * 각 진입점에서 웹뷰의 URI를 따로 관리하게 되는 파편화를 막고,
 * 쿼리파마리터 인터페이스 등을 안전하게 관리하기 위헤 openTarget 대신 SDK 를 사용했어요. */
export const useOpenPoiSuggestionCreate = () => {
  const { map } = useMap()

  return useCallback(
    ({
      entry,
      referrer: updateReferrer,
      query,
      categoryId,
    }: {
      query?: string
      referrer: string
      entry: string
      categoryId?: number
    }) => {
      const { referrer: externalReferrer } = getExternalReferrerEntry()
      const center = map?.getCenter()
      const zoom = map?.getZoom()

      bizProfileSdk.webviewRouterWithEvent.openCreatePoiSuggestionWebview({
        onlyWebview: true,
        lat: center?.lat,
        lng: center?.lng,
        zoomLevel: zoom,
        searchKeyword: query,

        entry,
        referrer: externalReferrer ?? updateReferrer,
        categoryId,
      })
    },
    [map]
  )
}
