import type { Plugin } from 'plantae'

import { getKarrotSessionInfo } from '@src/core/lib/bridge/utils'

const plantaeKarrotSessionIdPlugin = (): Plugin => {
  return {
    name: 'plugin-karrot-session-id',
    hooks: {
      beforeRequest: async (req) => {
        const karrotSessionInfo = await getKarrotSessionInfo()
        const karrotSessionId = karrotSessionInfo?.id

        if (karrotSessionId) {
          req.headers.set('x-karrot-session-id', karrotSessionId)
        }

        return req
      },
    },
  }
}

export default plantaeKarrotSessionIdPlugin
