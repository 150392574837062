import { useActivityParams } from '@stackflow/react'
import { useEffect } from 'react'

import {
  type LocalMapSteps,
  useStepRouter,
} from '@src/services/hooks/useStepRouter'
import { useSearchUIOnlyTypingText } from '@src/pages/home-search-result-step/_store/searchUIOnlyTypingText'

export const useSetSearchQueryFromQueryParamsEffect = () => {
  const activityParams = useActivityParams<{
    query: string
    step?: string
  }>()
  const { step, stepPush } = useStepRouter()
  const { setSearchUIOnlyTypingText } = useSearchUIOnlyTypingText()

  /**
   * 페이지 리프레시 시에 간헐적으로 ['search', 'searchTyping'] step이 url에는 남아있지만,
   * stepType이 'home'으로 랜딩되는 이슈를 해결해요.
   *
   * step의 값이 null이고 activityParams.step이 'search'이나 'searchTyping'인 경우,
   * stepPush를 통해 url에 존재하는 step으로 변경해줘요.
   */
  useEffect(() => {
    if (
      !step &&
      (activityParams.step === 'searchResult' ||
        activityParams.step === 'searchDiscovery')
    ) {
      const { step: stepName, ...restParams } = activityParams

      stepPush({ step: stepName, params: restParams } as LocalMapSteps)
      setSearchUIOnlyTypingText(activityParams.query)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
