import * as Sentry from '@sentry/react'
import { useSuspenseQuery } from '@tanstack/react-query'

import { localMapApi } from '@src/services/api/local-map-service/localMap'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'
export const useGetUserProfile = () => {
  const bridgeInfo = useBridgeInfo()

  const { data, status } = useSuspenseQuery({
    queryKey: ['get-user-profile'] as const,
    queryFn: async ({ queryKey }) => {
      try {
        return await localMapApi(bridgeInfo).localMapServiceGetUserProfile()
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)

        return null
      }
    },
  })

  return {
    userProfile: data?.userProfile,
    status,
  }
}
