import './bottomSheet.css'

import type { Ref } from 'react'
import React from 'react'

import { type useBottomSheet } from './useBottomSheet'

export interface SeedBottomSheetProps
  extends ReturnType<typeof useBottomSheet> {
  children?: React.ReactNode
  withHandle?: boolean
  UNSAFE_style?: React.CSSProperties
  UNSAFE_root_style?: React.CSSProperties
  contentRef?: Ref<HTMLDivElement>
}

export const BottomSheet = (props: SeedBottomSheetProps) => {
  const {
    children,
    UNSAFE_style,
    UNSAFE_root_style,
    withHandle = true,
    contentRef,
  } = props
  const {
    positionerProps,
    rootProps,
    handleProps,
    handlebarProps,
    contentProps,
    bottomSheetRef,
  } = props

  return (
    <div
      className="seed-bottom-sheet"
      {...positionerProps}
      style={{ ...positionerProps.style, ...UNSAFE_style }}
    >
      <div
        className="seed-bottom-sheet"
        ref={bottomSheetRef}
        {...rootProps}
        style={{ ...rootProps.style, ...UNSAFE_root_style }}
      >
        {withHandle && (
          <div className="seed-bottom-sheet" {...handleProps}>
            <div className="seed-bottom-sheet" {...handlebarProps} />
          </div>
        )}
        <div className="seed-bottom-sheet" {...contentProps} ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

BottomSheet.displayName = 'BottomSheet'
