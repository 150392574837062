import { useEffect, useRef } from 'react'

import { TIME } from '@src/core/utils/constants'

import { MAP_STYLE_URL } from './constants'
import { useMap, useMapResource } from './MapProvider'
import { loadMapStyleAccordingToTheme } from './theme'

const DEFAULT_DURATION = 10 * TIME.MINUTE

/**
 * @param duration 단위 : ms
 */
export function useMapStyleUpdater(duration = DEFAULT_DURATION) {
  const { map } = useMap()
  const { mapStyle } = useMapResource()

  const ref = useRef({ mapStyle })
  ref.current.mapStyle = mapStyle

  useEffect(() => {
    if (map) {
      const intervalId = setInterval(async () => {
        const response = await fetch(MAP_STYLE_URL)
        if (response.ok) {
          const json = await response.json()

          const mapStyle = ref.current.mapStyle
          if (mapStyle.light !== json.light || mapStyle.dark !== json.dark) {
            mapStyle.light = json.light
            mapStyle.dark = json.dark

            map.setStyle(loadMapStyleAccordingToTheme(mapStyle), {
              diff: true,
            })
          }
        }
      }, duration)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [map, duration])
}
