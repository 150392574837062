import React, { useMemo, useState } from 'react'

import { cacheLocalStorageWithStaleWhileRevalidate } from '@src/core/utils/cacheLocalStorageWithStaleWhileRevalidate'
import { initConcurrentResource } from '@src/core/utils/concurrentResource'
import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'

import { UserLocationAPIProvider } from './UserLocationAPIProvider'
import { getUserLocation } from '../api/getUserLocation'
import { LOCAL_STORAGE_CACHE_KEY } from '../constants/constants'
import UserLocationContext, {
  type UserLocationType,
} from '../contexts/userLocationContext'
import { ZOD_SCHEMA_USER_LOCATION_TYPE } from '../zod/userLocationZodSchema'

type UserLocationProviderProps = {
  children: React.ReactNode
  bridgeInfo: BridgeInfoType
  useLocalStorageCache?: boolean
}

const makeConcurrentResource = initConcurrentResource<UserLocationType>()

const UserLocationProvider = ({
  bridgeInfo,
  children,
  useLocalStorageCache = true,
}: UserLocationProviderProps) => {
  // 캐시를 적용하는 userLocationFetch
  const fetchUserLocation = useMemo(
    () =>
      useLocalStorageCache
        ? cacheLocalStorageWithStaleWhileRevalidate(
            LOCAL_STORAGE_CACHE_KEY,
            async () => {
              return await getUserLocation({ bridgeInfo })
            },
            ZOD_SCHEMA_USER_LOCATION_TYPE
          )
        : getUserLocation({ bridgeInfo }),
    [bridgeInfo, useLocalStorageCache]
  )

  const userLocationResource = useMemo(
    () => makeConcurrentResource(fetchUserLocation),
    [fetchUserLocation]
  )

  // 실행시점에서 userLocation Concurrent Mode Fetching
  const userLocationData = userLocationResource.read()

  const [userLocation, setUserLocation] =
    useState<UserLocationType>(userLocationData)

  return (
    <UserLocationContext.Provider value={userLocation}>
      <UserLocationAPIProvider
        bridgeInfo={bridgeInfo}
        setUserLocation={setUserLocation}
        useLocalStorageCache={useLocalStorageCache}
      >
        {children}
      </UserLocationAPIProvider>
    </UserLocationContext.Provider>
  )
}

export default UserLocationProvider
