import { captureException } from '@sentry/react'
import { lazy, useRef } from 'react'

import { type Anchor } from '@src/core/components/bottom-sheet/types'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useMapMoved } from '@src/features/map/store/mapMoved'
import { UserLocationEditCard } from '@src/features/user-location/components/user-location-edit/UserLocationEditCard'
import HomeStepFragment from '@src/fragments/HomeStepsFragment/steps/HomeStepFragment'
import PreviewStepFragment from '@src/fragments/HomeStepsFragment/steps/PreviewStepFragment'
import SearchedPreviewStepFragment from '@src/fragments/HomeStepsFragment/steps/SearchedPreviewStepFragment'
import { LocalProfileHomeForExtract } from '@src/pages/home-feed-step/external/ExtractLocalProfile'
import HomeSearchInput from '@src/pages/home-feed-step/search-input/HomeSearchInput'
import { useHandleSearchStateEffect } from '@src/pages/home-search-result-step/_hooks/useHandleSearchStateEffect'
import { useSearchExecutions } from '@src/pages/home-search-result-step/_hooks/useSearchExecutions'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

// 레이지 로드 할 Step Fragment 준비
const LazySearchResultStepFragment = lazy(
  () =>
    import('@src/fragments/HomeStepsFragment/steps/SearchResultStepFragment')
)
const LazySearchDiscoveryStepFragment = lazy(
  () =>
    import('@src/fragments/HomeStepsFragment/steps/SearchDiscoveryStepFragment')
)

export const HomeStepsFragment = ({
  anchors,
  mapBounds,
  query,
  isMapDragging,
}: {
  anchors: Anchor[]
  query?: string
  mapBounds?: string
  isMapDragging: boolean
}) => {
  const { map } = useMap()
  const {
    stepType,
    params,
    getValidatedParams,
    prevStepInfo: { prevStep, method },
  } = useStepRouter()

  const { resetMapMoved } = useMapMoved((s) => ({
    resetMapMoved: s.resetMapMoved,
  }))

  const shownHomeLogHistoryRecordRef = useRef<boolean>(false)

  const { search } = useSearchExecutions()

  useHandleSearchStateEffect(() => {
    if (!map) {
      return
    }

    // // 검색결과가 1개있을 때, poi를 클릭했을 때 등 검색한 이후이기 때문에 이 때(searchedPreview)에서는 재검색 할 필요가 없음
    if (stepType === 'searchedPreview') {
      return
    }

    if (
      method === 'pop' &&
      (prevStep === 'preview' || prevStep === 'searchedPreview')
    ) {
      return
    }

    // // searchDiscovery에서 다시 검색결과로 갔을 때 재검색을 하지 말아야함
    if (prevStep === 'searchDiscovery' && stepType === 'searchResult') {
      return
    }

    try {
      resetMapMoved()

      if (stepType === 'searchResult') {
        const { query, queryFrom, isBoundsSearch, isAddRecentKeyword } =
          getValidatedParams('searchResult')

        search(
          {
            query: query ?? '',
            queryFrom: queryFrom === 'unknown' ? 'scheme' : queryFrom,
          },
          {
            isBoundsReSearch: isBoundsSearch,
            isAddRecentKeyword: isAddRecentKeyword,
          }
        )
      }
    } catch (e) {
      // error report
      captureException(e)
    }
  }, [query, mapBounds, map])

  return (
    <>
      {/* LMD-501 POI 클릭시점에 get-user-profile 패치 및 프로필 이미지 깜빡임 이슈 */}
      {/* TODO: 추후 관심사에 맞게 분리 구성하면서도 깜빡이지 않게 하면 좋을거 같아요. */}
      {['home', 'preview'].includes(stepType) && <HomeSearchInput />}

      {(() => {
        switch (stepType) {
          case 'home':
          default: {
            return (
              <HomeStepFragment
                shownHomeLogHistoryRecordRef={shownHomeLogHistoryRecordRef}
                bottomSheetProps={{
                  anchors: anchors,
                }}
                isMapDragging={isMapDragging}
              />
            )
          }
          case 'searchResult': {
            return (
              <LazySearchResultStepFragment
                bottomSheetProps={{
                  anchors: anchors,
                }}
                initialQuery={query ?? ''}
              />
            )
          }
          case 'searchDiscovery': {
            return (
              <LazySearchDiscoveryStepFragment initialQuery={query ?? ''} />
            )
          }
          case 'preview': {
            LocalProfileHomeForExtract.preload()
            return <PreviewStepFragment />
          }
          case 'externalPage': {
            return <></>
          }
          case 'searchedPreview': {
            return <SearchedPreviewStepFragment initialQuery={query ?? ''} />
          }
        }
      })()}
      {params.selectedPin === 'userLocation' && <UserLocationEditCard />}
    </>
  )
}
