import { type PropsOf } from '@src/core/types/utilTypes'

import SectionContainer from './SectionContainer'
import SectionTitleSkeleton from './SectionTitleSkeleton'

interface SectionSkeletonProps {
  children: React.ReactNode
  sectionTitleProps: PropsOf<typeof SectionTitleSkeleton>
}

const SectionSkeleton = ({
  children,
  sectionTitleProps,
}: SectionSkeletonProps) => {
  return (
    <SectionContainer>
      <SectionTitleSkeleton {...sectionTitleProps} />
      {children}
    </SectionContainer>
  )
}

export default SectionSkeleton
