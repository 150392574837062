import { useCallback, useMemo } from 'react'

import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'

import { karrotBridgeLog } from '../bridgeLog'
import {
  type LocalMapMapFloatingButtonType,
  type LocalMapFeedSectionActionButtonType,
  type LocalMapFeedSectionItemActionButtonType,
  type LocalMapFeedSectionItemLogParams,
  type LocalMapFeedSectionLogParams,
  type LocalMapHomeRecommendationChipLogParams,
  type ImpressedLocalMapContributionFABLogParams,
} from '../types/home'
import { type MapLogParams } from '../types/mapLogParams'

export const useHomeLogger = () => {
  const pageType = useMemo(() => 'default', [])
  const distancedCoordinates = coordinatesStore((s) => s.distancedCoordinates)
  const distancedCoordinatesType = useMemo(() => {
    if (distancedCoordinates) {
      switch (distancedCoordinates.type) {
        case 'userLocation':
          return 'user_location'
        case 'currentPosition':
          return 'current_position'
      }
    }

    return 'current_position'
  }, [distancedCoordinates])

  const shownHomeLog = useCallback(() => {
    karrotBridgeLog('client_shown_localMap_home_v1', {
      pageType,
    })
  }, [pageType])

  const getImpressedHomeRecommendationChipLogParams = useCallback(
    ({ chipName, chipIndex }: LocalMapHomeRecommendationChipLogParams) => {
      return {
        name: 'client_impressed_localMap_home_recommendationChip_v1',
        params: {
          chipName,
          chipIndex,
        },
      } as const
    },
    []
  )

  const clickedHomeDeleteUserLocationLog = useCallback(() => {
    karrotBridgeLog('client_clicked_localMap_home_deleteUserLocation_v1', {})
  }, [])

  const getImpressedHomeContributionFabLogParams = useCallback(
    ({
      center_coordinates,
      zoom_level,
    }: ImpressedLocalMapContributionFABLogParams) => {
      return {
        name: 'client_impressed_localMap_home_createUgc_v1',
        params: {
          zoom_level,
          center_coordinates,
        },
      } as const
    },
    []
  )
  const clickedHomeContributionFABLog = useCallback(
    ({
      center_coordinates,
      zoom_level,
    }: ImpressedLocalMapContributionFABLogParams) => {
      karrotBridgeLog('client_clicked_localMap_home_createUgc_v1', {
        center_coordinates,
        zoom_level,
      })
    },
    []
  )

  const getImpressedHomeFeedSectionItemLogParams = useCallback(
    ({
      itemId,
      itemIndex,
      itemDataType,
      ...feedLogParams
    }: LocalMapFeedSectionItemLogParams & LocalMapFeedSectionLogParams) => {
      return {
        name: 'client_impressed_localMap_home_feed_sectionItem_v1',
        params: {
          itemId,
          itemIndex,
          itemDataType,
          pageType,
          feedCoordinatesType: distancedCoordinatesType,
          ...feedLogParams,
        },
      } as const
    },
    [distancedCoordinatesType, pageType]
  )

  const clickedHomeSearchBoxLog = useCallback(
    ({
      zoomLevel,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter,
    }: MapLogParams) => {
      karrotBridgeLog('client_clicked_localMap_home_searchBox_v1', {
        zoomLevel,
        centerCoordinates,
        distanceBetweenUserLocationAndCenter,
      })
    },
    []
  )

  const clickedHomeUserProfileClickLog = useCallback(() => {
    karrotBridgeLog('client_clicked_localMap_home_userProfile_v1', {})
  }, [])

  const clickedHomeRecommendationChipLog = useCallback(
    ({
      chipName,
      chipIndex,
      zoomLevel,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter,
    }: LocalMapHomeRecommendationChipLogParams & MapLogParams) => {
      karrotBridgeLog('client_clicked_localMap_home_recommendationChip_v1', {
        chipName,
        chipIndex,
        zoomLevel,
        centerCoordinates,
        distanceBetweenUserLocationAndCenter,
      })
    },
    []
  )

  const clickedHomeFloatingButtonLog = useCallback(
    ({
      actionType,
      zoomLevel,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter,
    }: {
      actionType: LocalMapMapFloatingButtonType
    } & MapLogParams) => {
      karrotBridgeLog('client_clicked_localMap_home_floatingButton_v1', {
        actionType,
        zoomLevel,
        centerCoordinates,
        distanceBetweenUserLocationAndCenter,
      })
    },
    []
  )

  const clickedHomeFeedSectionActionButtonLog = useCallback(
    ({
      actionType,
      zoomLevel,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter,
      ...feedLogParams
    }: {
      actionType: LocalMapFeedSectionActionButtonType
    } & LocalMapFeedSectionLogParams &
      MapLogParams) => {
      karrotBridgeLog(
        'client_clicked_localMap_home_feed_sectionActionButton_v1',
        {
          pageType,
          actionType,
          zoomLevel,
          centerCoordinates,
          distanceBetweenUserLocationAndCenter,
          feedCoordinatesType: distancedCoordinatesType,
          ...feedLogParams,
        }
      )
    },
    [distancedCoordinatesType, pageType]
  )

  const clickedHomeFeedSectionItemLog = useCallback(
    ({
      itemId,
      itemIndex,
      itemDataType,
      itemName,
      itemImageType,
      zoomLevel,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter,
      ...feedLogParams
    }: LocalMapFeedSectionItemLogParams &
      LocalMapFeedSectionLogParams &
      MapLogParams) => {
      karrotBridgeLog('client_clicked_localMap_home_feed_sectionItem_v1', {
        itemId,
        itemIndex,
        itemDataType,
        itemName,
        itemImageType,
        pageType,
        zoomLevel,
        centerCoordinates,
        distanceBetweenUserLocationAndCenter,
        feedCoordinatesType: distancedCoordinatesType,
        ...feedLogParams,
      })
    },
    [distancedCoordinatesType, pageType]
  )

  const clickedHomeFeedSectionItemActionButtonLog = useCallback(
    ({
      itemId,
      itemIndex,
      itemDataType,
      actionType,
      zoomLevel,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter,
      ...feedLogParams
    }: LocalMapFeedSectionItemLogParams &
      LocalMapFeedSectionLogParams & {
        actionType: LocalMapFeedSectionItemActionButtonType
      } & MapLogParams) => {
      karrotBridgeLog(
        'client_clicked_localMap_home_feed_sectionItemActionButton_v1',
        {
          itemId,
          itemIndex,
          itemDataType,
          actionType,
          pageType,
          zoomLevel,
          centerCoordinates,
          distanceBetweenUserLocationAndCenter,
          feedCoordinatesType: distancedCoordinatesType,
          ...feedLogParams,
        }
      )
    },
    [distancedCoordinatesType, pageType]
  )

  return {
    shownHomeLog,
    getImpressedHomeRecommendationChipLogParams,
    clickedHomeDeleteUserLocationLog,
    getImpressedHomeFeedSectionItemLogParams,
    clickedHomeSearchBoxLog,
    clickedHomeUserProfileClickLog,
    clickedHomeRecommendationChipLog,
    clickedHomeFloatingButtonLog,
    clickedHomeFeedSectionActionButtonLog,
    clickedHomeFeedSectionItemLog,
    clickedHomeFeedSectionItemActionButtonLog,
    getImpressedHomeContributionFabLogParams,
    clickedHomeContributionFABLog,
  }
}
