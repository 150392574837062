import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  EVENT_NAME_SEED_SCALE_COLOR_CHANGED,
  type SeedColorTheme,
  getDocumentSeedScaleColor,
} from '@src/services/styles/seedDesign'

interface AppThemeContext {
  colorTheme: SeedColorTheme
}

const ColorThemeContext = createContext<AppThemeContext>({
  colorTheme: 'light',
})

/**
 * Application 전체적인 Theme Context를 관리하는 Provider
 */
export const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  const [colorTheme, setColorTheme] = useState(getDocumentSeedScaleColor())

  useEffect(() => {
    const seedScaleColorChangedHandler = (event: Event) => {
      const style = (event as CustomEvent).detail === 'dark' ? 'dark' : 'light'

      setColorTheme(style)
    }

    window.addEventListener(
      EVENT_NAME_SEED_SCALE_COLOR_CHANGED,
      seedScaleColorChangedHandler
    )

    return () => {
      window.removeEventListener(
        EVENT_NAME_SEED_SCALE_COLOR_CHANGED,
        seedScaleColorChangedHandler
      )
    }
  }, [])

  return (
    <ColorThemeContext.Provider value={{ colorTheme }}>
      {children}
    </ColorThemeContext.Provider>
  )
}

export const useAppColorTheme = () => {
  return useContext(ColorThemeContext)
}
