import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import { useCallback, useEffect, useRef } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { type WithLngLat } from '@src/core/components/karrot-map/types'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useSearchLocalProfilesApiQuery } from '@src/pages/home-search-result-step/_api-queries/searchLocalProfiles'
import { useOptimizeMapViewportEffect } from '@src/pages/home-search-result-step/_hooks/useOptimizeMapViewportEffect'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '@src/pages/home-search-result-step/_store/search-business-places-query-request-params'

import { type ClusterWithLeaves } from './clustering/cluster'
import { useLocalProfileMarkerList } from './hooks/useMarkerList'

const MapContents = () => {
  const { placedLocalProfiles, isFetchingNextPage, isLoading } =
    useSearchLocalProfilesApiQuery()
  const { map } = useMap()
  const { stepType, params, getValidatedParams } = useStepRouter()

  const apiSearchedQuery = useSearchBusinessPlacesQueryRequestParamsStore(
    (state) => state.query
  )

  const { stepMove } = useLocalMapStepMovePolicy()

  const handleMarkerClick = useCallback(
    (index: number, poi: WithLngLat<V1SearchResultLocalProfile>) => {
      if (!poi.id) {
        return
      }

      // 마커는 searchResult, searchedPreview에서 존재
      const curStep = stepType as 'searchResult' | 'searchedPreview'
      const { query, queryFrom } = getValidatedParams(curStep)

      stepMove(curStep, 'click_marker', {
        localProfileId: poi.id,
        query,
        queryFrom,
        selectedPin: null,
      })
    },
    [getValidatedParams, stepMove, stepType]
  )

  const handleClusterMarkerClick = useCallback(
    (cluster: ClusterWithLeaves<V1SearchResultLocalProfile>) => {
      if (!map) {
        return
      }

      const curStep = stepType as 'searchResult' | 'searchedPreview'
      const { query, queryFrom } = getValidatedParams(curStep)

      const localProfileIds = cluster.leaves
        .map((localProfile) => localProfile.properties.id)
        .filter((id): id is string => Boolean(id))

      stepMove(curStep, 'click_clusterMarker', {
        query,
        queryFrom,
        selectedPin: null,
        localProfileIds,
      })
    },
    [getValidatedParams, map, stepMove, stepType]
  )

  useLocalProfileMarkerList({
    map,
    localProfileId: params.localProfileId,
    localProfileIds: getLocalProfileIdsFromParams(params),
    localProfiles: placedLocalProfiles,
    onLocalProfileClick: handleMarkerClick,
    onClusterClick: handleClusterMarkerClick,
    disabled: params.query !== apiSearchedQuery,
  })

  useOptimizeMapViewportEffect()

  function handleSingleSearchResult() {
    // 검색 결과가 1개이고, 검색 뷰 타입이 1개일 때만 미리보기로 보여주기
    const isPreviewView = placedLocalProfiles.length === 1

    if (isPreviewView) {
      const localProfile = placedLocalProfiles[0]

      const searchResultParams = getValidatedParams('searchResult')

      stepMove('searchResult', 'afterSearch_singleSearchResult', {
        ...searchResultParams,
        localProfileId: localProfile.id,
      })
    }
  }

  const ref = useRef({
    handleSingleSearchResult,
  })
  ref.current.handleSingleSearchResult = handleSingleSearchResult

  useEffect(() => {
    if (!isLoading) {
      ref.current.handleSingleSearchResult()
    }
  }, [isLoading])

  if (!map || isFetchingNextPage) {
    return null
  }

  return null
}

export default MapContents

const getLocalProfileIdsFromParams = (params: {
  [key: string]: string | undefined
}) => {
  if (!params.localProfileIds) {
    return null
  }

  return Array.isArray(params.localProfileIds)
    ? params.localProfileIds
    : params.localProfileIds.split(',')
}
