import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type TouchEvent,
  type UIEvent,
} from 'react'

import {
  useBottomSheetInteraction,
  useBottomSheetTaskCompletedIdle,
  useBottomSheetTouch,
} from '@src/core/components/bottom-sheet/hooks'
import { useLatestRef } from '@src/core/hooks/useLatestRef'

import {
  useBottomSheetSnapPoint,
  useBottomSheetPosition,
  useBottomSheetRef,
} from './context/bottomSheetContext'
import { dom } from './dom'
import { type SnapPointType, type BottomSheetStateProps } from './types'
import {
  DOWN,
  getAnchorSizingModePosition,
  getDragBoundary,
  getMovementDirection,
  getMovementY,
  hasScrollBar,
  isScrollAtTop,
} from './utils'

export function useBottomSheetState(props: BottomSheetStateProps) {
  const { anchors } = props

  const { snapPoint, setSnapPoint } = useBottomSheetSnapPoint()
  const { position, setPosition } = useBottomSheetPosition()
  const { prevClientY, initialClientY, initClientY } = useBottomSheetTouch()

  const dragPositionRef = useRef<number>(position)
  dragPositionRef.current = position
  const { interaction, interactionSetter } = useBottomSheetInteraction(
    props,
    dragPositionRef.current
  )

  const [isReady, setIsReady] = useState<boolean>(false)

  const bottomSheetRef = useBottomSheetRef()
  const positionerRef = useRef<HTMLDivElement | null>(null)

  const currentAnchor =
    anchors.find((x) => x.snapPoint === snapPoint) ??
    anchors.find((x) => x.snapPoint === 'middle')
  const currentScrollBehavior = currentAnchor?.scrollBehavior ?? 'dragSheet'

  const snapPointSetter = useCallback(
    (id: SnapPointType) => {
      if (id === snapPoint) {
        return
      }

      setSnapPoint(id)
    },
    [snapPoint, setSnapPoint]
  )

  const getNextAnchorIndex = useCallback(
    (currentIndex: number, deltaY: number) => {
      const isDraggingUp = deltaY > 0
      const nextIndex = currentIndex + (isDraggingUp ? -1 : 1)
      return Math.max(0, Math.min(nextIndex, anchors.length - 1))
    },
    [anchors]
  )

  const { setEndOfTransition } = useBottomSheetTaskCompletedIdle(
    {
      interaction: interaction,
    },
    () => {
      const anchor = props.anchors.find((x) => x.snapPoint === snapPoint)

      if (!anchor) {
        console.warn(`useBottomSheet: anchor with id "${snapPoint}" not found`)
        return
      }

      props.onTaskCompletedIdle?.(props.id, anchor)
    }
  )

  const onReady = useLatestRef(props.onReady)
  const isReadySetter = useCallback(() => {
    if (isReady) return

    setIsReady(true)
    onReady.current?.()
  }, [isReady, onReady])

  const resetPosition = useCallback(() => {
    if (currentAnchor == null) return

    setPosition(getAnchorSizingModePosition(currentAnchor, props.id))
  }, [currentAnchor, props.id, setPosition])

  // effects
  useEffect(() => {
    isReadySetter()
  }, [isReadySetter])

  useEffect(() => {
    resetPosition()
  }, [interaction, resetPosition])

  const onPositionChange = useLatestRef(props.onPositionChange)

  useEffect(() => {
    onPositionChange.current?.(dom.getComputedPosition(props.id, position))
  }, [onPositionChange, position, props.id])

  // 화면 크기 변환이 있거나, visibility가 바뀌었을 때 바텀시트 포지셔닝을 snapPoint에 맞게 다시해요.
  useEffect(() => {
    const repositionBottomSheet = () => {
      resetPosition()
    }

    window.addEventListener('resize', repositionBottomSheet)

    return () => {
      window.removeEventListener('resize', repositionBottomSheet)
    }
  }, [resetPosition])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        resetPosition()
      }
    })

    if (positionerRef.current) {
      observer.observe(positionerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [resetPosition])

  function handleTouchStart(event: TouchEvent) {
    initialClientY.current = event.changedTouches[0].clientY
    prevClientY.current = event.changedTouches[0].clientY

    if (
      interaction === 'stopScrollAtTop' &&
      currentScrollBehavior === 'scrollContent'
    ) {
      interactionSetter('startScrollAtTop')
    }
  }

  function contentTouchStart(event: TouchEvent) {
    initialClientY.current = event.changedTouches[0].clientY
    prevClientY.current = event.changedTouches[0].clientY

    if (
      interaction === 'stopScrollAtTop' &&
      currentScrollBehavior === 'scrollContent'
    ) {
      interactionSetter('startScrollAtTop')
    }
  }

  function touchMove(event: TouchEvent<HTMLElement>) {
    const movementY = getMovementY(
      prevClientY.current,
      event.changedTouches[0].clientY
    )
    const direction = getMovementDirection(
      prevClientY.current,
      event.changedTouches[0].clientY
    )
    prevClientY.current = event.changedTouches[0].clientY

    if (
      interaction === 'startScrollAtTop' &&
      currentScrollBehavior === 'scrollContent'
    ) {
      // 상단이 아니거나 스크롤이 아래로 내려갈 때
      if (!isScrollAtTop(event.currentTarget) || direction === DOWN) {
        interactionSetter('scrolling')
        return
      }

      interactionSetter('dragging')
    }

    if (interaction === 'idle' && currentScrollBehavior === 'dragSheet') {
      interactionSetter('dragging')
      return
    }

    if (interaction === 'idle' && currentScrollBehavior === 'scrollContent') {
      if (!isScrollAtTop(event.currentTarget) || direction === DOWN) {
        interactionSetter('scrolling')
        return
      }

      interactionSetter('dragging')
      return
    }

    // 컨텐츠가 바텀시트보다 작아서 스크롤이 생기지 않았을 케이스
    if (
      interaction === 'scrolling' &&
      currentScrollBehavior === 'scrollContent'
    ) {
      if (!hasScrollBar(event.currentTarget)) {
        interactionSetter('dragging')
        return
      }
    }

    if (interaction === 'dragging') {
      // Set min max boundary based on top, bottom anchor position
      const { min: minPositionLimit, max: maxPositionLimit } = getDragBoundary(
        anchors,
        props.id
      )

      const newPosition = Math.min(
        Math.max(dragPositionRef.current + movementY, minPositionLimit),
        maxPositionLimit
      )

      dragPositionRef.current = newPosition
      if (bottomSheetRef.current) {
        bottomSheetRef.current.style.transition = 'none'
        bottomSheetRef.current.style.transform = `translateX(0px) translateY(${newPosition}px) translateZ(0px)`
      }

      return
    }
  }

  function contentScroll(event: UIEvent<HTMLElement>) {
    // 상단이 아니거나 스크롤이 아래로 내려갈 때
    if (!isScrollAtTop(event.currentTarget)) {
      interactionSetter('scrolling')
      return
    }
    interactionSetter('stopScrollAtTop')
  }

  function touchUp(event: TouchEvent<HTMLElement>) {
    if (
      interaction === 'dragging' &&
      initialClientY.current &&
      prevClientY.current
    ) {
      const deltaY = initialClientY.current - prevClientY.current
      const anchorIndex = anchors.findIndex((x) => x.snapPoint === snapPoint)
      const nextAnchorIndex = getNextAnchorIndex(anchorIndex, deltaY)
      const targetAnchor = anchors[nextAnchorIndex]

      // 목표 앵커 위치 계산 (필수)
      const targetPosition = getAnchorSizingModePosition(targetAnchor, props.id)

      if (bottomSheetRef.current) {
        // transition 재적용
        bottomSheetRef.current.style.transition = 'transform 0.2s ease-out'
        bottomSheetRef.current.style.transform = `translateX(0px) translateY(${targetPosition}px) translateZ(0px)`
      }

      // 상태 업데이트
      setPosition(targetPosition)
      snapPointSetter(targetAnchor.snapPoint)
    }
    // 스크롤하지 않고 클릭 시, anchor 업데이트 발생 방지
    if (interaction === 'idle') {
      return
    }

    if (!initialClientY.current || !prevClientY.current) {
      return
    }

    // 내부 스크롤링 중일 때
    if (
      interaction === 'scrolling' &&
      currentScrollBehavior === 'scrollContent'
    ) {
      initClientY()
      return
    }

    if (
      interaction === 'stopScrollAtTop' &&
      currentScrollBehavior === 'scrollContent'
    ) {
      initClientY()
      interactionSetter('idle')
      return
    }

    // 초기화
    initClientY()
    interactionSetter('idle')
  }

  function onTransitionEnd() {
    const anchor = anchors.find((x) => x.snapPoint === snapPoint)
    if (!anchor) {
      console.warn(`onTransitionEnd: anchor with id "${snapPoint}" not found`)
      return
    }
    props.onTransitionEnd?.(props.id, anchor)
    setEndOfTransition(true)
  }

  return {
    interaction,
    position,
    setPosition,
    resetPosition,
    anchors,
    snapPoint,
    setSnapPoint: snapPointSetter,
    isReady,
    currentAnchor,
    currentScrollBehavior,
    handleTouchStart,
    contentTouchStart,
    touchMove,
    touchUp,
    contentScroll,
    bottomSheetRef,
    onTransitionEnd,
    positionerRef,
  }
}
