import { useMap } from '@src/core/components/karrot-map/map/MapProvider'

import { type TargetParamsOf, type ActionNameByFrom } from './caseTypes'
import { MOVE_CASE } from './moveCase'
import { type ActionDetail } from './type'
import {
  useStepRouter,
  type LocalMapSteps,
  type LocalMapStepKeys,
} from '../useStepRouter'

export function useLocalMapStepMovePolicy() {
  const {
    stepPush,
    stepReplace,
    stepPop,
    stepType,
    params,
    getValidatedParams,
    prevStepInfo,
  } = useStepRouter()
  const { map } = useMap()

  const stepMove = <
    From extends LocalMapStepKeys,
    ActionName extends ActionNameByFrom<From>,
    TargetParams extends TargetParamsOf<From, ActionName>,
  >(
    from: From,
    action: ActionName,
    params: TargetParams
  ) => {
    const { target, method } = MOVE_CASE[from][action] as ActionDetail

    switch (method) {
      case 'replace': {
        stepReplace({
          step: target,
          params: {
            ...params,
            mapBounds: map ? map.getBounds().toArray().toString() : null,
          },
        } as LocalMapSteps)

        break
      }

      case 'push':
      default: {
        stepPush({
          step: target,
          params: {
            ...params,
            mapBounds: map ? map.getBounds().toArray().toString() : null,
          },
        } as LocalMapSteps)
      }
    }
  }

  /** stackflow의 히스토리 API가 비동기로 동작하기 때문에 await으로 가능하도록 개선했어요 */
  const stepBack = async () => {
    await stepPop()
  }

  return {
    params,
    prevStepInfo,
    getValidatedParams,
    stepType,
    stepMove,
    stepBack,
  }
}
