import { vars } from '@seed-design/design-token'

const mapObjectToColorProperty = <T extends Record<any, any>>(o: T) =>
  Object.entries(o).reduce(
    (accu, [k, v]) => ({
      ...accu,
      [k]: v,
    }),
    {} as typeof o
  )

const scaleColor = mapObjectToColorProperty(vars.$scale.color)
const semanticColor = mapObjectToColorProperty(vars.$semantic.color)
const staticColor = mapObjectToColorProperty(vars.$static.color)

export const color = { ...scaleColor, ...semanticColor, ...staticColor }
