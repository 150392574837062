import { BoxButton } from '@daangn/sprout-components-button'
import { assignInlineVars } from '@vanilla-extract/dynamic'

import { useBottomSheetHeight } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useMapPanTo } from '@src/features/map/hooks/useMapPanTo'
import { useUserLocation } from '@src/features/user-location/hooks/useUserLocation'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

import * as css from './BoundaryErrorBottomSheetContent.css'

export const BoundaryErrorBottomSheetContent = () => {
  const bottomSheetHeight = useBottomSheetHeight()
  const { map } = useMap()
  const { region } = useBridgeInfo()
  const setDistancedCoordinates = coordinatesStore(
    (s) => s.setDistancedCoordinates
  )
  const { panToWithOffset } = useMapPanTo(map)
  const userLocation = useUserLocation()

  const mainLocation = userLocation.userLocationHome?.point

  const handleClick = () => {
    const draftLocation = region.centerCoordinates

    // 우리집을 등록한 경우 이동할 좌표를 우리집 좌표로 설정
    if (mainLocation?.latitude && mainLocation?.longitude) {
      draftLocation.latitude = mainLocation.latitude
      draftLocation.longitude = mainLocation.longitude
    }

    // 지도 이동
    panToWithOffset({
      center: {
        lng: region.centerCoordinates.longitude,
        lat: region.centerCoordinates.latitude,
      },
    })

    // 바텀시트의 좌표 설정
    setDistancedCoordinates({
      type: 'userLocation',
      coordinates: {
        longitude: region.centerCoordinates.longitude,
        latitude: region.centerCoordinates.latitude,
      },
    })
  }

  return (
    <div
      className={css.container}
      style={assignInlineVars({
        [css.containerHeight]: `${Math.max(bottomSheetHeight - 26, 300)}px`,
      })}
    >
      <div className={css.centerContent}>
        <div className={css.text}>
          주변 정보를 제공할 수 없어요.
          <br />
          탐색이 가능한 곳으로 이동해주세요.
        </div>

        <BoxButton variant="secondary" onClick={handleClick}>
          {mainLocation ? '우리집으로 이동' : '내 동네로 이동'}
        </BoxButton>
      </div>
    </div>
  )
}
