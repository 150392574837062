import delay from 'lodash-es/delay'
import { useRef, useState, useEffect, useCallback, useMemo } from 'react'

import { getSafeAreaValue } from '@src/core/utils/getSafeAreaValue'
import { HOME_Z_INDEX } from '@src/services/styles/zIndex/homeZIndex'

import {
  ContentContainer,
  PreviewBottomBackground,
  PreviewContainer,
  PreviewHandleBar,
  PreviewHandleBarUI,
} from './PreviewBottomSheetStyle.css'

/** 핸들바 실제 높이 (픽셀) */
const HANDLE_HEIGHT = 12

/** 접힌 상태에서 아래로 드래그 가능한 최대 거리 (픽셀) */
const MAX_COLLAPSED_DOWN_DRAG = 60

const ANIMATE_DURATION = 0.2 // s

const EASING_CURVE = 'cubic-bezier(0.33, 1, 0.68, 1)'

interface NewPreviewBottomSheetProps {
  children: React.ReactNode
  onSwipeUp?: () => void
  onSwipeDown?: () => void
  minHeight?: number
  autoHeight?: boolean
  maxHeightRatio?: number
}

type SheetPosition = 'expanded' | 'collapsed' | 'dragging'

const NewPreviewBottomSheet = ({
  children,
  onSwipeUp,
  onSwipeDown,
  minHeight = 190,
  autoHeight = true,
  maxHeightRatio = 0.4,
}: NewPreviewBottomSheetProps) => {
  const topPosition = getSafeAreaValue('top')

  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState<SheetPosition>('collapsed')
  const [actualCollapsedHeight, setActualCollapsedHeight] = useState(minHeight)
  const [maxDragDown, setMaxDragDown] = useState(
    window.innerHeight - actualCollapsedHeight
  )

  const topPositionNumber = useMemo(() => {
    const parsed = parseInt(topPosition, 10)
    return isNaN(parsed) ? 0 : parsed
  }, [topPosition])

  const touchData = useRef({
    startY: 0,
    currentY: 0,
    startPosition: 'collapsed' as SheetPosition,
    currentDeltaY: 0,
  })

  const setSheetStyle = useCallback(
    ({ transform, transition }: { transform: string; transition: string }) => {
      if (wrapperRef.current) {
        wrapperRef.current.style.transform = transform
        wrapperRef.current.style.transition = transition
      }
    },
    [wrapperRef]
  )

  const getLimitedDeltaY = (
    state: SheetPosition,
    deltaY: number,
    maxDragDown: number
  ) => {
    if (state === 'expanded') {
      return Math.max(0, Math.min(maxDragDown, deltaY))
    }
    return Math.max(-maxDragDown, Math.min(MAX_COLLAPSED_DOWN_DRAG, deltaY))
  }

  const moveTo = useCallback(
    (deltaY: number) => {
      const limitedDeltaY = getLimitedDeltaY(
        touchData.current.startPosition,
        deltaY,
        maxDragDown
      )

      touchData.current.currentDeltaY = limitedDeltaY

      const baseTransform =
        touchData.current.startPosition === 'expanded'
          ? `calc(${topPosition})`
          : `calc(100% - ${actualCollapsedHeight}px)`

      let newTransform = ''
      if (touchData.current.startPosition === 'expanded') {
        newTransform = `translateY(calc(${limitedDeltaY}px + ${topPosition}))`
      } else {
        newTransform = `translateY(calc(${baseTransform} + ${limitedDeltaY}px))`
      }

      setSheetStyle({
        transform: newTransform,
        transition: 'none',
      })
    },
    [maxDragDown, actualCollapsedHeight, topPosition, setSheetStyle]
  )

  const animateTo = useCallback(
    async (targetPosition: SheetPosition) => {
      const newTransform =
        targetPosition === 'expanded'
          ? `translateY(${
              (window.innerHeight - actualCollapsedHeight - topPositionNumber) *
              -1
            }px)`
          : `translateY(calc(100% - ${actualCollapsedHeight}px))`

      setPosition(targetPosition)
      setSheetStyle({
        transform: newTransform,
        transition: `transform ${ANIMATE_DURATION}s ${EASING_CURVE}`,
      })
    },
    [actualCollapsedHeight, topPositionNumber, setSheetStyle]
  )

  useEffect(() => {
    if (!autoHeight || !contentRef.current) {
      return
    }

    const calculateHeight = () => {
      if (!contentRef.current) {
        return
      }

      const contentRect = contentRef.current.getBoundingClientRect()

      const calculatedHeight = Math.max(
        contentRect.height + HANDLE_HEIGHT,
        minHeight
      )
      setActualCollapsedHeight(calculatedHeight)
    }

    calculateHeight()

    const resizeObserver = new ResizeObserver(calculateHeight)
    resizeObserver.observe(contentRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [autoHeight, minHeight, maxHeightRatio])

  useEffect(() => {
    setMaxDragDown(
      window.innerHeight - actualCollapsedHeight - topPositionNumber
    )
    animateTo(position)
  }, [topPositionNumber, actualCollapsedHeight, position, animateTo])

  const handleTouchStart = useCallback(
    (e: React.TouchEvent) => {
      const touch = e.touches[0]
      touchData.current.startY = touch.clientY
      touchData.current.currentY = touch.clientY
      touchData.current.startPosition = position
      touchData.current.currentDeltaY = 0
      setPosition('dragging')
    },
    [position]
  )

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      const touch = e.touches[0]
      const deltaY = touch.clientY - touchData.current.startY
      touchData.current.currentY = touch.clientY
      moveTo(deltaY)
    },
    [moveTo]
  )

  const handleTouchEnd = useCallback(() => {
    const { startY, currentY } = touchData.current
    const diff = startY - currentY

    if (diff > 0) {
      animateTo('expanded')
      delay(() => {
        onSwipeUp?.()
      }, ANIMATE_DURATION * 1000)
    } else {
      animateTo('collapsed')
      delay(() => {
        onSwipeDown?.()
      }, ANIMATE_DURATION * 1000)
    }
  }, [animateTo, onSwipeUp, onSwipeDown])

  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        inset: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: HOME_Z_INDEX.PREVIEW_LAYER,
      }}
    >
      <div
        className={PreviewContainer}
        ref={wrapperRef}
        style={{
          transform: `translateY(calc(100% - ${actualCollapsedHeight}px))`,
          transition: `transform ${ANIMATE_DURATION}s ${EASING_CURVE}`,
          pointerEvents: 'auto',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        onClick={(e) => e.preventDefault()}
      >
        <div className={PreviewHandleBar}>
          <div className={PreviewHandleBarUI} />
        </div>
        <div
          ref={contentRef}
          role="region"
          aria-label="바텀 시트"
          className={ContentContainer}
        >
          {children}
        </div>
        <div className={PreviewBottomBackground}></div>
      </div>
    </div>
  )
}

export default NewPreviewBottomSheet
