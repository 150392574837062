import { useQuery } from '@tanstack/react-query'

import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

import { getAddressService } from '../services/AddressService'

/**
 * 역지오코딩
 */
export const useSearchAddressByPointQuery = (params: {
  latitude?: number
  longitude?: number
}) => {
  const bridgeInfo = useBridgeInfo()
  const services = getAddressService(bridgeInfo)

  return useQuery({
    queryKey: [
      'address-search-address-by-point',
      params.latitude,
      params.longitude,
    ],
    queryFn: () => {
      return services.getAddressByPoint(params)
    },
    enabled: !!params.latitude && !!params.longitude,
    select: (data) => {
      if (!data) {
        return {
          roadAddr: '',
          displayBuildName: '',
          jibunAddr: '',
          shortRoadAddr: '',
          shortJibunAddr: '',
        }
      }

      return {
        roadAddr: data?.addressRoad?.fullAddress ?? '',
        displayBuildName: data?.addressRoad?.buildingName ?? '',
        jibunAddr: data?.addressLand?.fullAddress ?? '',
        shortRoadAddr: data?.addressRoad?.shortAddress ?? '',
        shortJibunAddr: data?.addressLand?.shortAddress ?? '',
      } as SearchAddressByPointQueryType
    },
  })
}

export interface SearchAddressByPointQueryType {
  roadAddr: string
  displayBuildName: string
  jibunAddr: string
  shortRoadAddr: string
  shortJibunAddr: string
}
