import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useRef, useMemo } from 'react'

import { useGetSectionQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import SectionThemePost from '@src/pages/home-feed-step/section/section-theme-post/SectionThemePost'
import { BUSINESS_POST_SECTION_INFO } from '@src/pages/home-feed-step/section/sectionKeyInfo'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import SectionRecommendPostSkeleton from './section-recommend-post/SectionRecommendPostSkeleton'

interface SectionThemePostRootProps {
  userCoordinates: TypeLatLng
}
const SectionThemePostRoot = ({
  userCoordinates,
}: SectionThemePostRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { data, isLoading } = useGetSectionQuery({
    sectionViewTypes: 'SECTION_VIEW_TYPE_BUSINESS_THEME_POST_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_BUSINESS_THEME_POST_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...BUSINESS_POST_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const result = data?.sectionItems[0]?.themeBusinessPostSection

  if (isLoading) return <SectionRecommendPostSkeleton />

  if (!result) return null

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div ref={sectionRef}>
        <SectionThemePost
          title={`${result.region3Name} 업체 소식`}
          themePostResult={result}
        />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(SectionThemePostRoot)
