import {
  Avatar as SeedAvatar,
  type SeedAvatarProps,
} from '@daangn/sprout-components-avatar'

import * as css from './Avatar.css'
import {
  AVATAR_USER_PROFILE_DEFAULT_IMG,
  AVATAR_BIZ_PROFILE_DEFAULT_IMG,
} from './constants'

import '@daangn/sprout-components-avatar/index.css'

type AvatarFallbackProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

type AvatarProps = Omit<SeedAvatarProps, 'fallback'> & {
  type?: 'User' | 'Biz'
  fallback?: React.ReactNode
}

export const Avatar = (props: AvatarProps) => {
  const fallback = (
    <AvatarFallback
      src={
        props.type === 'User'
          ? AVATAR_USER_PROFILE_DEFAULT_IMG
          : AVATAR_BIZ_PROFILE_DEFAULT_IMG
      }
      alt={`${props.alt}-fallback`}
    />
  )

  return <SeedAvatar {...props} fallback={props.fallback ?? fallback} />
}

export const AvatarFallback = (props: AvatarFallbackProps) => {
  return (
    <img
      className={css.avatarFallback}
      alt="user-profile-fallback"
      {...props}
    />
  )
}
