import {
  BizProfileSDK,
  EventManager,
  WebviewRouterWithEvent,
} from '@daangn/bizprofile-sdk'

import { IS_PRODUCTION } from '@src/services/constants/constants'
import { karrotBridge } from '@src/core/lib/bridge/bridge'

export const bizProfileSdk = new BizProfileSDK(karrotBridge, IS_PRODUCTION)
  .addPlugin(EventManager)
  .addPlugin(WebviewRouterWithEvent)
