import { type LngLat } from '@src/core/types/geo'

import { type LngLatsMap, type WithLngLat } from '../types'

/** 동일 위경도 Marker 떨어뜨리기 */
export function separateLngLat<T>(
  list: ReadonlyArray<WithLngLat<T>> | WithLngLat<T>[],
  tolerance: number,
  fractionDigits = 14
): Array<WithLngLat<T>> {
  const lngLatsMap: LngLatsMap<T> = {}

  return list.map((item) => {
    const newItem: WithLngLat<T> = { ...item }

    const newItemLngLat = newItem.lngLat

    if (!newItemLngLat || !newItemLngLat.lat || !newItemLngLat.lng) {
      return newItem
    }

    recursiveseparateLngLat(
      lngLatsMap,
      newItemLngLat,
      newItem,
      tolerance,
      fractionDigits
    )

    return newItem
  })
}

function makeKey(lngLat: LngLat) {
  return `${lngLat.lng}-${lngLat.lat}`
}

function getRatio() {
  return Math.random() < 0.5 ? -1 : 1
}

function calculateTolerance(
  tolerance: number,
  ratio: number,
  precision: number
) {
  return Number(((tolerance * ratio) / 2).toFixed(precision))
}

/**
 * 재귀적으로 동일 위경도 Marker 떨어뜨리기
 */
function recursiveseparateLngLat<T>(
  lngLatMap: LngLatsMap<T>,
  insertLngLat: LngLat,
  lngLatItem: WithLngLat<T>,
  tolerance: number,
  fractionDigits: number
): void {
  const lngLatKey = makeKey(insertLngLat)

  if (!(lngLatKey in lngLatMap)) {
    lngLatItem.lngLat = insertLngLat
    lngLatMap[lngLatKey] = lngLatItem
    return
  }

  const overlappedItem = lngLatMap[lngLatKey]
  delete lngLatMap[lngLatKey]

  const halfLatTolerance = calculateTolerance(
    tolerance,
    getRatio(),
    fractionDigits
  )
  const halfLngTolerance = calculateTolerance(
    tolerance,
    getRatio(),
    fractionDigits
  )

  const separatedLngLat1: LngLat = {
    lat: overlappedItem.lngLat.lat + halfLatTolerance,
    lng: overlappedItem.lngLat.lng + halfLngTolerance,
  }

  const separatedLngLat2: LngLat = {
    lat: insertLngLat.lat - halfLatTolerance,
    lng: insertLngLat.lng - halfLngTolerance,
  }

  recursiveseparateLngLat(
    lngLatMap,
    separatedLngLat1,
    overlappedItem,
    tolerance,
    fractionDigits
  )
  recursiveseparateLngLat(
    lngLatMap,
    separatedLngLat2,
    lngLatItem,
    tolerance,
    fractionDigits
  )
}
