import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import { useEffect, useMemo, useRef } from 'react'

import CardLocalProfile from '@src/core/components/card-local-profile/CardLocalProfile'
import CertificatedBadge from '@src/core/components/certificated-badge/CertificatedBadge'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import { convertLngLatNullable } from '@src/core/utils/geo/convert'
import { getLocalProfileHomeURL } from '@src/features/local-profile/getLocalProfileHomeURL'
import { toDistanceTextFromLatLonInKm } from '@src/pages/preview-step/utils'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useSearchLogger } from '@src/services/log/hooks/useSearchLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import * as css from './viewType.css'

interface LocalProfileItemProps {
  localProfile: V1SearchResultLocalProfile
  collectionName?: string
  index: number
}

// help bubble을 하나만 보여주기 위해 사용하는 id값
let viewdId: string | null = null

const LocalProfileItem = ({ localProfile, index }: LocalProfileItemProps) => {
  const geolocation = useGeolocation()
  const {
    getImpressedSearchResultArticleLogParams,
    clickedSearchResultArticleLog,
  } = useSearchLogger()
  const itemRef = useRef<HTMLDivElement>(null)

  const { openTarget } = useLocalMapOpenTarget()

  const isCertificated =
    localProfile.businessVerified || localProfile.identityVerified
  const distance =
    toDistanceTextFromLatLonInKm(
      geolocation.currentPosition,
      convertLngLatNullable(localProfile.geoPoint)
    ) ?? ''

  useEffect(() => {
    if (isCertificated && !viewdId) {
      viewdId = localProfile.id
    }
  }, [isCertificated, localProfile.id])

  const handleClick = () => {
    const remoteUrl = getLocalProfileHomeURL(localProfile.id)

    openTarget({
      remote: remoteUrl,
      navbar: false,
      scrollable: false,
      referrer: `localMap_searchResult_list_localProfileItem`,
    })

    clickedSearchResultArticleLog({
      index,
      documentId: localProfile.id,
      documentType: 'local_profile',
      viewType: 'single_list_view',
    })
  }

  const impressionLogParams = useMemo(
    () =>
      getImpressedSearchResultArticleLogParams({
        index,
        documentId: localProfile.id,
        documentType: 'local_profile',
        viewType: 'single_list_view',
      }),
    [getImpressedSearchResultArticleLogParams, index, localProfile.id]
  )

  const bizCertificatedBadge = isCertificated ? (
    <CertificatedBadge
      parentRef={itemRef}
      onOutsideClick={() => {
        viewdId = null
      }}
      style={{
        marginLeft: '3px',
        transform: 'translate(0, 2px)',
      }}
      enableHelpBubble={viewdId === localProfile.id}
    />
  ) : null

  return (
    <LocalMapImpressionObserver
      log={impressionLogParams}
      observeTargetRef={itemRef}
    >
      <div
        key={localProfile.id}
        role="listitem"
        style={{
          position: 'relative',
        }}
        ref={itemRef}
        onClick={handleClick}
      >
        <CardLocalProfile
          localProfile={localProfile}
          distance={distance}
          titlePostfix={bizCertificatedBadge}
        />
        <div className={css.divider} />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default LocalProfileItem
