import { useQueryClient } from '@tanstack/react-query'

import { localMapApi } from '@src/services/api'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

export function useSafeNumberQuery() {
  const queryClient = useQueryClient()
  const bridgeInfo = useBridgeInfo()

  return {
    fetchSafeNumber(businessProfileId: string) {
      return queryClient.fetchQuery({
        queryKey: ['business-profile-safe-number'],
        queryFn: () => {
          return localMapApi(
            bridgeInfo
          ).localMapServiceGetSafeNumberByBusinessProfileId({
            businessProfileId,
          })
        },
      })
    },
  }
}
