import React from 'react'

import {
  InfoWrapper,
  ImageItem,
  ProfileWrapper,
  skeletonBase,
} from './ExternalPageStyle.css'

interface Props {
  hasBackgroundImage: boolean
  quickMenuListCount?: number
}

const ExternalSkeleton: React.FC<Props> = ({ hasBackgroundImage }) => {
  return (
    <div>
      <div className={InfoWrapper}>
        <div className={ProfileWrapper}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div
                className={skeletonBase}
                style={{ width: '8.125rem', height: '1.875rem' }}
              />
              <div
                className={skeletonBase}
                style={{
                  width: '9.375rem',
                  height: '1.1875rem',
                  margin: '0.5rem 0 0 0',
                }}
              />
              <div
                className={skeletonBase}
                style={{
                  width: '6.25rem',
                  height: '1.1875rem',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '0.1875rem',
                }}
              />
            </div>

            <div
              className={skeletonBase}
              style={{
                width: '5.1563rem',
                height: '2rem',
                borderRadius: '0.25rem',
              }}
            />
          </div>
        </div>

        {hasBackgroundImage && (
          <div style={{ padding: '0 1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={`${ImageItem} first-image`} />
              <div className={`${ImageItem}`} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExternalSkeleton
