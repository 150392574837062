import cn from 'classnames'
import { forwardRef } from 'react'

import * as css from './BottomSheetSection.css'

interface BottomSheetSectionProps {
  children: React.ReactNode
  title: string
  titleRightNode?: React.ReactNode
  subTitle?: string
  className?: string
  titleClassName?: string
}

const BottomSheetSection = forwardRef<HTMLElement, BottomSheetSectionProps>(
  (
    { children, title, titleRightNode, subTitle, className, titleClassName },
    ref
  ) => {
    return (
      <section ref={ref} className={cn(css.sectionContainer, className)}>
        <h2 className={cn(css.sectionTitleWrapper, titleClassName)}>
          {title}
          {titleRightNode ? titleRightNode : null}
        </h2>
        {subTitle ? (
          <div className={css.sectionSubTitle}>{subTitle}</div>
        ) : null}
        {children}
      </section>
    )
  }
)

BottomSheetSection.displayName = 'BottomSheetSection'

export default BottomSheetSection
