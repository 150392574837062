import { create } from 'zustand'

import {
  type CoordinatesForDistanceSlice,
  coordinatesForDistanceSlice,
} from './coordinatesForDistance'
import { type QuerySlice, querySlice } from './query'
import { type SearchBoundsSlice, searchBoundsSlice } from './searchBounds'
import {
  type SearchCenterCoordinatesSlice,
  searchCenterCoordinatesSlice,
} from './searchCenterCoordinates'

interface SearchLocalProfilesQueryKeySlice {
  getSearchLocalProfilesQueryKey: () => ReadonlyArray<unknown>
}

export const useSearchBusinessPlacesQueryRequestParamsStore = create<
  QuerySlice &
    SearchCenterCoordinatesSlice &
    SearchBoundsSlice &
    CoordinatesForDistanceSlice &
    SearchLocalProfilesQueryKeySlice
>((setState, getState, store) => ({
  ...querySlice(setState, getState, store),
  ...searchCenterCoordinatesSlice(setState, getState, store),
  ...searchBoundsSlice(setState, getState, store),
  ...coordinatesForDistanceSlice(setState, getState, store),
  getSearchLocalProfilesQueryKey: () => {
    const {
      query,
      searchCenterCoordinates,
      coordinatesForDistance,
      searchAreaBounds,
      userViewBounds,
    } = getState()

    return [
      'searchLocalProfiles',
      query,
      searchCenterCoordinates,
      coordinatesForDistance,
      searchAreaBounds,
      userViewBounds,
    ]
  },
}))
