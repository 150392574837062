import {
  type UserLocationPlaceType,
  type TypeLatLng,
} from '@daangn/local-map-api'

import { Marker } from '@src/core/components/karrot-map'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

import * as css from './UserLocationMarkers.css'
import { useUserLocationPointChange } from './useUserLocationPointChange'
import { useUserLocation } from '../hooks/useUserLocation'
import { IconUserLocationHomeMarker } from '../icons/IconUserLocationHomeMarker'

export const UserLocationMarkers = ({
  animatedPinRef,
}: {
  animatedPinRef?: React.RefObject<HTMLDivElement>
}) => {
  const { userLocations } = useUserLocation()
  const { map } = useMap()
  const { stepPush, stepType, params, getValidatedParams } = useStepRouter()

  useUserLocationPointChange()

  const generateHandleClick =
    ({
      latitude,
      longitude,
      place,
    }: TypeLatLng & { place: UserLocationPlaceType }) =>
    () => {
      if (!map || !latitude || !longitude) return

      map.panTo({ lat: latitude, lng: longitude })

      switch (stepType) {
        case 'searchResult': {
          const params = getValidatedParams('searchResult')

          stepPush({
            step: 'searchResult',
            params: {
              query: params.query,
              selectedPin: 'userLocation',
              queryFrom: params.queryFrom,
            },
          })
          break
        }
        case 'home':
          stepPush({
            step: 'home',
            params: {
              selectedPin: 'userLocation',
            },
          })
          break
      }
    }

  if (!userLocations) return null

  return userLocations.map(({ encryptedId, place, point }) => {
    const hasPoint = point && point.latitude && point.longitude

    if (!hasPoint || !place) return null

    switch (place) {
      case 'PLACE_TYPE_HOUSE':
        return (
          <div key={encryptedId}>
            <Marker
              lngLat={{
                lat: point.latitude!,
                lng: point.longitude!,
              }}
            >
              <div
                ref={animatedPinRef}
                onClick={generateHandleClick({
                  latitude: point.latitude!,
                  longitude: point.longitude!,
                  place,
                })}
              >
                <IconUserLocationHomeMarker
                  className={css.icon}
                  width={params.selectedPin === 'userLocation' ? '42' : '29'}
                  height={params.selectedPin === 'userLocation' ? '58' : '42'}
                />
              </div>
            </Marker>
          </div>
        )

      default:
        return null
    }
  })
}
