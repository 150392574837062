import { IconStarFill } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'

import * as css from './CardLocalProfile.css'
import { Tag } from '../tag'
import { TagGroup } from '../tag-group'

interface TagAreaProps {
  showStarRating?: boolean
  starRating?: string
  regionName: string
  showReviews: boolean
  showFollowers: boolean
  reviewCount: string
  followerCount: string
  showDistance: boolean
  distanceText: string
  isSelected: boolean
}

const TagArea = (props: TagAreaProps) => {
  const tagSize = props.isSelected ? 'large' : 'medium'
  return (
    <div className={css.reactionContainer}>
      {props.showReviews || props.showFollowers ? (
        <div className={css.reviewAndFollowerTagGroup}>
          <TagGroup medium isSelected={props.isSelected}>
            {props.showStarRating ? (
              <Tag className={css.starRatingContainer} size={tagSize}>
                <IconStarFill
                  color={vars.$scale.color.carrot500}
                  width={15}
                  height={15}
                />
                <span className={css.starRating}>{props.starRating}</span>
              </Tag>
            ) : null}
            {props.showReviews ? (
              <Tag size={tagSize} color="gray700">
                후기 {props.reviewCount}
              </Tag>
            ) : null}
            {props.showFollowers ? (
              <Tag size={tagSize} color="gray700">
                단골 {props.followerCount}
              </Tag>
            ) : null}
          </TagGroup>
        </div>
      ) : null}
      {props.showDistance || props.regionName ? (
        <TagGroup medium isSelected={props.isSelected}>
          {props.showDistance ? (
            <Tag size={tagSize} color="gray700">
              {props.distanceText}
            </Tag>
          ) : null}
          {props.regionName ? (
            <Tag size={tagSize} color="gray700">
              {props.regionName}
            </Tag>
          ) : null}
        </TagGroup>
      ) : null}
    </div>
  )
}

export default TagArea
