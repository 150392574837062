import NewPreviewBottomSheet from '@src/core/components/bottom-sheet/NewPreviewBottomSheet'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { ExternalPageRouter } from '@src/pages/home-feed-step/preview/previewRouter'
import Preview from '@src/pages/preview-step/Preview'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

const PAGE_CHANGE_DELAY = 0

const PreviewStepFragment = () => {
  const { map } = useMap()
  const { stepType } = useStepRouter()
  const { stepMove } = useLocalMapStepMovePolicy()

  const handleSwipeUp = () => {
    if (stepType !== 'externalPage') {
      setTimeout(() => {
        const localProfileId = ExternalPageRouter() || ''

        stepMove('preview', 'swipeUp_preview', {
          localProfileId,
          mapBounds: map ? map.getBounds().toArray().toString() : null,
        })
      }, PAGE_CHANGE_DELAY)
    }
  }

  return (
    <>
      <NewPreviewBottomSheet onSwipeUp={handleSwipeUp}>
        <Preview />
      </NewPreviewBottomSheet>
    </>
  )
}

export default PreviewStepFragment
