import {
  type V1SearchLocalProfilesResponse,
  type V1SearchResultItem,
  type V1SearchResultLocalProfile,
} from '@daangn/local-map-api'
import * as Sentry from '@sentry/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'
import { evaluateStatus } from '@src/core/lib/tanstack-query/evaluateStatus'
import { getNwSeByLngLat } from '@src/core/utils/geo'
import { validatedRequiredKeys } from '@src/core/utils/validatedRequiredKeys'
import { localMapApi } from '@src/services/api/local-map-service/localMap'
import { useSearchLogParamStore } from '@src/services/log/store'

import { placesWithCoordinates } from '../_parser/helper'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '../_store/search-business-places-query-request-params'

// Data is stale after 5 minutes, which is equal to default cache time of react-query
// after 5 minutes, react-query will refetch the data
const STALE_TIME = 1000 * 60 * 5
const PAGE_SIZE = 50

export const useSearchLocalProfilesApiQuery = () => {
  // Global states
  const bridgeInfo = useBridgeInfo()

  // Page states
  const {
    queryKey,
    query,
    searchCenterCoordinates,
    searchAreaBounds,
    userViewBounds,
    coordinatesForDistance,
  } = useSearchBusinessPlacesQueryRequestParamsStore((state) => ({
    query: state.query,
    searchCenterCoordinates: state.searchCenterCoordinates,
    searchAreaBounds: state.searchAreaBounds,
    userViewBounds: state.userViewBounds,
    coordinatesForDistance: state.coordinatesForDistance,
    queryKey: state.getSearchLocalProfilesQueryKey(),
  }))

  const searchLogParam = useSearchLogParamStore(
    useShallow((state) => ({
      funnelFromId: state.funnelFromId as string,
      queryFromId: state.queryFromId,
      queryId: state.queryId,
      searchFunnelId: state.searchFunnelId,
      screenDepthName: state.screenDepthName,
    }))
  )

  const { data, ...restInfiniteQueryResult } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      if (!searchCenterCoordinates || !userViewBounds) {
        throw new Error(
          'localMapServiceSearchLocalProfiles 호출에는 searchCenterCoordinates and userViewBounds가 필수입니다'
        )
      }

      return localMapApi(bridgeInfo).localMapServiceSearchLocalProfiles({
        v1SearchLocalProfilesRequest: {
          query,
          pageSize: PAGE_SIZE,
          nextPageToken: pageParam,
          coordinatesForSearch: {
            longitude: searchCenterCoordinates.longitude,
            latitude: searchCenterCoordinates.latitude,
          },
          coordinatesForDistance: coordinatesForDistance
            ? {
                longitude: coordinatesForDistance.longitude,
                latitude: coordinatesForDistance.latitude,
              }
            : undefined,
          boundsForSearch: searchAreaBounds
            ? {
                center: {
                  longitude: searchAreaBounds.centerLongitude,
                  latitude: searchAreaBounds.centerLatitude,
                },
                // for LMX-24
                ...getNwSeByLngLat([
                  {
                    lng: searchAreaBounds.northWestLongitude,
                    lat: searchAreaBounds.northWestLatitude,
                  },
                  {
                    lng: searchAreaBounds.southEastLongitude,
                    lat: searchAreaBounds.southEastLatitude,
                  },
                ]),
              }
            : undefined,
          userViewBounds: {
            center: {
              latitude: userViewBounds.centerLatitude,
              longitude: userViewBounds.centerLongitude,
            },
            ...getNwSeByLngLat([
              {
                lng: userViewBounds.northWestLongitude,
                lat: userViewBounds.northWestLatitude,
              },
              {
                lng: userViewBounds.southEastLongitude,
                lat: userViewBounds.southEastLatitude,
              },
            ]),
          },
          searchEventClientData: {
            queryId: searchLogParam.queryId,
            funnelFrom: searchLogParam.funnelFromId,
            queryFrom: searchLogParam.queryFromId,
            screenDepthName: searchLogParam.screenDepthName,
            searchFunnelId: searchLogParam.searchFunnelId,
          },
          searchLocalProfilesFilterTypes: [],
        },
      })
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: V1SearchLocalProfilesResponse) =>
      lastPage.nextPageToken,
    staleTime: STALE_TIME,
    enabled:
      validatedRequiredKeys(
        {
          query: query,
          searchCenterCoordinates: searchCenterCoordinates,
          userViewBounds: userViewBounds,
        },
        ['query', 'searchCenterCoordinates', 'userViewBounds']
      ) && query.length > 0,
    throwOnError: (error, query) => {
      const err = new Error(error.message + JSON.stringify(query.queryKey))

      console.error(err)

      Sentry.captureException(err)

      throw err
    },
  })

  const evaluatedStatus = useMemo(
    () => evaluateStatus(restInfiniteQueryResult.status),
    [restInfiniteQueryResult.status]
  )

  const searchViewTypes = useMemo(
    () =>
      data?.pages.reduce<V1SearchResultItem[]>(
        (acc, { searchResultItems }) =>
          !searchResultItems ? acc : [...acc, ...searchResultItems],
        []
      ) ?? ([] as V1SearchResultItem[]),
    [data?.pages]
  )

  // TODO: 간헐적으로 이전 페이지의 마지막 장소가 다음 페이지 첫 데이터로 오는 버그가 발생해서 추가했어요.
  // 해당 버그가 픽스되면 빼야해요.
  const searchLocalProfiles = useMemo(
    () =>
      uniqBy(
        searchViewTypes,
        (searchResult: V1SearchResultItem) =>
          searchResult.searchResultLocalProfile?.id
      )
        .filter(isLocalProfile)
        .map((searchResultItem) => searchResultItem.searchResultLocalProfile),
    [searchViewTypes]
  )

  const placedLocalProfiles = useMemo(() => {
    return placesWithCoordinates(searchLocalProfiles)
  }, [searchLocalProfiles])

  return {
    searchViewTypes,
    searchLocalProfiles,
    placedLocalProfiles,
    rawData: data,
    searchCategorySuggestions:
      data?.pages?.[0].searchCategoryKeywordsSuggestion,
    searchQueryTypes: data?.pages?.[0].searchQueryTypes,
    searchAreaBounds: searchAreaBounds,
    evaluatedStatus,
    ...restInfiniteQueryResult,
  }
}

const isLocalProfile = (
  searchResultItem: V1SearchResultItem
): searchResultItem is V1SearchResultItem & {
  searchResultLocalProfile: V1SearchResultLocalProfile
} => {
  if (
    searchResultItem.searchResultViewType ===
      'SEARCH_RESULT_VIEW_TYPE_LOCAL_PROFILE' &&
    !!searchResultItem.searchResultLocalProfile
  ) {
    return true
  }

  return false
}
