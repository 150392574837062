import { type RecipeVariants } from '@vanilla-extract/recipes'
import { Children, forwardRef, Fragment, memo, type ReactNode } from 'react'

import { type TagGroupSizeType, TAG_GROUP_SIZE } from './constants'
import * as css from './TagGroupEllipsis.css'

type DotDividerProps = {
  size?: TagGroupSizeType
}
const DotDivider = ({ size = TAG_GROUP_SIZE.MEDIUM }: DotDividerProps) => {
  return <div className={css.dotDivider({ size })} />
}

type TagGroupEllipsisProps = {
  children: ReactNode
  [TAG_GROUP_SIZE.SMALL]?: boolean
  [TAG_GROUP_SIZE.MEDIUM]?: boolean
  subGroup?: boolean
  color?: NonNullable<RecipeVariants<typeof css.dotDivider>>['color']
}
const TagGroupEllipsis = forwardRef<HTMLDivElement, TagGroupEllipsisProps>(
  (
    { small = false, medium = true, subGroup = false, color, ...props },
    ref
  ) => {
    return (
      <div className={css.tagGroupContainer({ subGroup })}>
        <div ref={ref} className={css.tagGroup} color={color}>
          {Children.toArray(props.children)
            .filter((child) => Boolean(child))
            .map((child, index, children) => {
              const lastIndexOfChilren = children.length - 1
              if (index < lastIndexOfChilren) {
                return (
                  <Fragment key={index}>
                    {child}
                    <DotDivider
                      size={
                        small ? TAG_GROUP_SIZE.SMALL : TAG_GROUP_SIZE.MEDIUM
                      }
                    />
                  </Fragment>
                )
              }
              return child
            })}
        </div>
      </div>
    )
  }
)

TagGroupEllipsis.displayName = 'TagGroupEllipsis'
export default memo(TagGroupEllipsis)
