import { type V1LaunchpadItem } from '@daangn/local-map-api'
import { IconPawprintFill } from '@daangn/react-monochrome-icon'
import {
  IconCupHeatwave,
  IconDumbbell,
  IconForkSpoon,
  IconForkSpoonBag,
  IconHospital,
  IconNailpolish,
  IconPaintroller,
  IconPalette,
  IconPencil,
  IconScissors,
  IconSpraybottleSponge,
  IconTruck,
  IconWrench,
} from '@daangn/react-multicolor-icon'

import IconBanchan from './IconBanchan'
import IconCategoryFishShapedBread from './IconCategoryFishShapedBread'

export const getPadItemIcon = (padItem: V1LaunchpadItem) => {
  switch (padItem.iconId) {
    case 'seed-multicolor-forkSpoon':
      return <IconForkSpoon size={26} />
    case 'seed-multicolor-cupHeatwave':
      return <IconCupHeatwave size={26} />
    case 'seed-multicolor-hospital':
      return <IconHospital size={26} />
    case 'seed-multicolor-dumbbell':
      return <IconDumbbell size={26} />
    case 'seed-multicolor-palette':
      return <IconPalette size={26} />
    case 'seed-multicolor-scissors':
      return <IconScissors size={26} />
    case 'seed-multicolor-nailpolish':
      return <IconNailpolish size={26} />
    case 'seed-multicolor-pencil':
      return <IconPencil size={26} />
    case 'seed-multicolor-truck':
      return <IconTruck size={26} />
    case 'seed-multicolor-spraybottleSponge':
      return <IconSpraybottleSponge size={26} />
    case 'seed-multicolor-wrench':
      return <IconWrench size={26} />
    case 'seed-multicolor-paintroller':
      return <IconPaintroller size={26} />
    case 'seed-multicolor-pawprintFill':
      return <IconPawprintFill size={26} color={'#B27768'} />
    case 'internal-multicolor-forkSpoonBag':
    case 'seed-multicolor-forkSpoonBag':
      return <IconForkSpoonBag size={26} />
    case 'internal-multicolor-fishShapedBread':
      return <IconCategoryFishShapedBread size={26} />
    case 'internal-multicolor-banchan':
      return <IconBanchan size={26} />

    default:
      return null
  }
}
