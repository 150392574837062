import { extractAppBuildType } from '@src/core/utils/userAgent'

/**
 * 스테이지
 */
export const STAGE = import.meta.env.VITE_STAGE as
  | 'production'
  | 'alpha'
  | 'development'

/**
 * 디바이스 정보
 */
export const IS_ANDROID = /Android/i.test(navigator.userAgent)

/**
 * 커밋 Hash
 */
export const CLIENT_VERSION = import.meta.env.VITE_VERSION ?? ''

export const IS_PRODUCTION = STAGE === 'production'
export const IS_LOCAL = import.meta.env.VITE_IS_LOCAL === 'true'

/**
 * debug - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.22.0 (242200; iOS 17.5.1; Alpha; debug)
 * release - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.26.0 (242600; iOS 17.5.1; Production; release)
 */
export const IS_DEBUG_APP =
  extractAppBuildType(window.navigator.userAgent) === 'debug'

export const DATADOG_CLIENT_TOKEN =
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN ?? ''
export const DATADOG_APPLICATION_ID =
  import.meta.env.VITE_DATADOG_APPLICATION_ID ?? ''

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN ?? ''
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN ?? ''

export const CLARITY_ENABLED = import.meta.env.VITE_CLARITY_ENABLED === 'true'

/**
 * 외부 api url
 */
export const BIZ_PROFILE_WEB_BASE_URL = IS_PRODUCTION
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

export const NEARBY_WEB_BASE_URL = IS_PRODUCTION
  ? 'https://nearby.karrotwebview.com'
  : 'https://nearby.alpha.karrotwebview.com'

export const PICKUP_WEBVIEW_BASE_URL = IS_PRODUCTION
  ? 'https://pickup.karrotwebview.com'
  : 'https://pickup.alpha.karrotwebview.com'

export const UGC_WEBVIEW_BASE_URL = IS_PRODUCTION
  ? 'https://local-business-ugc.karrotwebview.com'
  : 'https://local-business-ugc.alpha.karrotwebview.com'

export const DAANGN_PERMERLINK_BASE_URL = IS_PRODUCTION
  ? 'https://www.daangn.com/kr'
  : 'https://alpha.daangn.com/kr'

export const MAP_FADE_DURATION = 150
