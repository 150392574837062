import '@daangn/local-profile-home/index.css'
import { IconChevronDownLine } from '@daangn/react-monochrome-icon'
import * as Sentry from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import React, { Suspense, useEffect, useRef, useState } from 'react'

import { PreviewHandleBarUI } from '@src/core/components/bottom-sheet/PreviewBottomSheetStyle.css'
import { useOpenTarget } from '@src/core/hooks/useTarget'
import { clientEnvironment } from '@src/core/lib/bridge/utils'
import { RefetchFailedApiView } from '@src/features/error-report/RefetchFailedApiPageComponent'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'

import {
  ExternalContainer,
  ExternalHandleBar,
  ExternalPocContainer,
} from './ExternalPageStyle.css'
import ExternalSkeleton from './ExternalSkeleton'
import { LocalProfileHomeForExtract } from './ExtractLocalProfile'
import PreventSwipeBack from './PreventSwipeBack'
import { ExternalPageRouter } from '../preview/previewRouter'

interface localProfileNavigatorProps {
  activityName: string
  params: Record<string, string>
  url: string
}

const PAGE_CHANGE_DELAY = 0

const ExternalPage = () => {
  const touchData = useRef({
    startY: 0,
    currentY: 0,
    initialTop: 0,
    touchEnabled: true,
  })
  const previewRef = useRef<HTMLDivElement>(null)
  const { stepBack } = useLocalMapStepMovePolicy()

  const handleTouchStart = (e: React.TouchEvent) => {
    // 로컬프로필홈의 스크롤이 탑 이거나 사진 상세보기 중이면
    if (
      scrollElement?.scrollTop !== 0 ||
      document.querySelector('.swiper-initialized')
    ) {
      touchData.current.touchEnabled = false
    }
    const touch = e.touches[0]

    // if (touch.clientX < 40) {
    //   touchData.current.touchEnabled = false
    //   e.preventDefault()
    // }

    touchData.current.startY = touch.clientY
    touchData.current.currentY = touch.clientY
    if (previewRef.current) {
      touchData.current.initialTop =
        previewRef.current.getBoundingClientRect().top
    }
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchData.current.touchEnabled) {
      return
    }
    const touch = e.touches[0]
    const y = touch.clientY - touchData.current.startY
    if (y > 0) {
      scrollElement!.style.overflowY = 'hidden'
    }
    touchData.current.currentY = touch.clientY
    if (previewRef.current) {
      const newTop = touchData.current.initialTop + y
      previewRef.current.style.top = `${Math.max(0, newTop)}px`
    }
  }

  const handleTouchEnd = () => {
    touchData.current.touchEnabled = true
    scrollElement!.style.overflowY = 'auto'
    if (previewRef.current) {
      const { startY, currentY } = touchData.current
      const diff = startY - currentY
      const minDistance = 50 // 화면을 이동시키기 위한 최소 이동 거리 (50px)
      const topPosition = '0'
      const bottomPosition = 'calc(100vh - (181px + 24px))'

      if (Math.abs(diff) > minDistance) {
        if (diff > 0) {
          previewRef.current.style.top = topPosition
        } else {
          previewRef.current.style.top = bottomPosition
          setTimeout(() => {
            stepBack()
          }, PAGE_CHANGE_DELAY)
        }
      } else {
        // 가장 가까운 위치로 되돌리기
        const currentTop = previewRef.current.getBoundingClientRect().top
        const initialTop = touchData.current.initialTop
        if (
          Math.abs(initialTop - parseFloat(topPosition)) <
          Math.abs(currentTop - parseFloat(topPosition))
        ) {
          previewRef.current.style.top = topPosition
        }
      }
    }
  }

  const { openTarget } = useOpenTarget()
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
    null
  )
  const localProfileId = ExternalPageRouter() || ''
  const navigatorPush = ({ url }: localProfileNavigatorProps) => {
    // logLevel.log({ activityName, params })
    // const postId = params.postId || ''
    // const bizId = params.bizId || ''
    // const resourceId = params.resourceId || ''
    // const targetPath = activityParser({
    //   activityName,
    //   localProfileId,
    //   postId,
    //   bizId,
    //   resourceId,
    // })
    openTarget({
      remote: `${url}`,
      navbar: false,
      scrollable: false,
      updateReferrer: 'localmap',
    })
  }

  const activityParams = useActivityParams<{
    step?: string
  }>()
  useEffect(() => {
    if (previewRef.current) {
      previewRef.current.style.top = '0px'
    }
  }, [previewRef, activityParams.step])

  // 스크롤 내린 상태에서 물리 뒤로가기, navbar 뒤로가기 하면 스크롤 위치 초기화
  useEffect(() => {
    if (scrollElement) {
      scrollElement.scrollTo(0, 0)
    }
  }, [activityParams.step])

  const externalShow = activityParams.step === 'externalPage'
  const targetEl = document.getElementById('externalPageRoot')

  if (!targetEl) return null

  const iosClassInsert = clientEnvironment.isIOS ? 'ios-device' : ''
  return (
    <div
      ref={previewRef}
      className={`${ExternalContainer} ${externalShow ? 'externalShow' : ''}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onClick={(e) => e.preventDefault()}
    >
      <div className={`${ExternalHandleBar} ${iosClassInsert}`}>
        <div className={PreviewHandleBarUI} />
      </div>
      <div className={`${ExternalPocContainer} ${iosClassInsert}`}>
        <Sentry.ErrorBoundary>
          {localProfileId ? (
            <Suspense>
              <LocalProfileHomeForExtract
                lpId={localProfileId}
                referrer="localMap_home_map_preview_swipeUp"
                loadingFallback={<ExternalSkeleton hasBackgroundImage={true} />}
                errorFallback={
                  <div style={{ height: '100vh' }}>
                    <RefetchFailedApiView
                      message={'알 수 없는 오류가 발생했어요'}
                      buttonLabel={'뒤로가기'}
                      onRefetchClick={() => {
                        stepBack()
                      }}
                    />
                  </div>
                }
                onSetScrollRef={(scrollRef) => {
                  if (scrollRef.current) {
                    setScrollElement(scrollRef.current)
                  }
                }}
                overrideAppBar={(appBarState) => {
                  return {
                    ...appBarState,
                    closeButton: {
                      renderIcon: () => <IconChevronDownLine />,
                      onClick: (e) => {
                        e.preventDefault()
                        stepBack()
                      },
                    },
                    backButton: {
                      renderIcon: () => <IconChevronDownLine />,
                      onClick: (e) => {
                        e.preventDefault()
                        stepBack()
                      },
                    },
                  }
                }}
              />
            </Suspense>
          ) : null}
        </Sentry.ErrorBoundary>
      </div>
      <PreventSwipeBack isShow={localProfileId ? true : false} />
    </div>
  )
}

export default ExternalPage
