import type { MarkerOptions as MarkerOptionsType } from 'maplibre-gl'
import { type ReactNode, useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'

import { type LngLat } from '@src/core/types/geo'

import Pin from './Pin'
import { useMap, useMapLibreGL } from '../map/MapProvider'

export interface MarkerProps extends MarkerOptionsType {
  id?: string
  lngLat: LngLat
  children?: ReactNode
  zIndex?: number
}
const Marker = ({ id, lngLat, children, zIndex, ...options }: MarkerProps) => {
  const { map } = useMap()
  const { mapLibreGL } = useMapLibreGL()

  const markerRef = useRef<HTMLDivElement | null>(null)

  const marker = useMemo(() => {
    if (!mapLibreGL) return null

    const divEl = document.createElement('div')
    divEl.dataset.id = id
    divEl.dataset.type = 'marker'
    divEl.style.zIndex = zIndex?.toString() ?? '0'
    const marker = new mapLibreGL.Marker({
      ...options,
      anchor: options.anchor ?? 'bottom',
      element: divEl,
    })

    markerRef.current = divEl

    marker.setLngLat([lngLat.lng, lngLat.lat])

    return marker
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLibreGL?.Marker])

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.style.zIndex = zIndex?.toString() ?? '0'
    }
  }, [zIndex])

  useEffect(() => {
    if (!map || !marker) return
    marker.addTo(map)

    return () => {
      marker?.remove()
    }
  }, [map, marker])

  useEffect(() => {
    marker?.setLngLat([lngLat.lng, lngLat.lat])
  }, [marker, lngLat.lat, lngLat.lng])

  const markerContainer = marker?.getElement()

  return markerContainer && createPortal(children ?? <Pin />, markerContainer)
}

export default Marker
