import { type V1LocalStorySection } from '@daangn/local-map-api'
import {
  IconCameraFill,
  IconChevronRightFill,
} from '@daangn/react-monochrome-icon'
import { BoxButton } from '@daangn/sprout-components-button'
import { useRef } from 'react'

import { usePreventParentHorizontalScroll } from '@src/core/components/scroll-control/usePreventParentHorizontalScroll copy'
import { usePreventSelfHorizontalScrollOnVerticalScroll } from '@src/core/components/scroll-control/usePreventSelfHorizontalScrollOnVerticalScroll'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import BottomSheetSection from '@src/pages/home-feed-step/section/lib/BottomSheetSection'
import {
  bottomButtonWrapper,
  bottomSheetScrollItemWrapper,
  bottomSheetScrollWrapper,
  titleRightText,
  titleRightWrapper,
} from '@src/pages/home-feed-step/section/lib/BottomSheetSection.css'
import {
  STORY_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import SectionLocalBusinessStoryItem from './SectionLocalStoryItem'

interface SectionLocalBusinessStoryProps {
  localBusinessStory: V1LocalStorySection
}

const REFERRER = genSectionReferrer(STORY_SECTION_INFO.sectionKey)

const SectionLocalBusinessStory = ({
  localBusinessStory,
}: SectionLocalBusinessStoryProps) => {
  const { openTarget } = useLocalMapOpenTarget()

  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  usePreventParentHorizontalScroll({
    targetRef: scrollContainerRef,
  })
  usePreventSelfHorizontalScrollOnVerticalScroll({
    targetRef: scrollContainerRef,
  })

  const handleMoreButtonClick = () => {
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'more',
      ...getMapLogParams(),
      ...STORY_SECTION_INFO,
    })
    openTarget({
      targetUri: localBusinessStory.viewMoreTargetUri,
      referrer: REFERRER,
    })
  }

  const handleUploadButtonClick = () => {
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'upload_story',
      ...getMapLogParams(),
      ...STORY_SECTION_INFO,
    })
    openTarget({
      targetUri: localBusinessStory.uploadStoryTargetUri,
      referrer: REFERRER,
    })
  }

  const region3Name = coordinatesStore((state) => state.region3Name)
  const title = `${region3Name || ''} 이웃들의 스토리`
  return (
    <BottomSheetSection
      title={title}
      titleRightNode={
        <div onClick={handleUploadButtonClick} className={titleRightWrapper}>
          <IconCameraFill width={16} height={16} />
          <span className={titleRightText}>스토리 올리기</span>
          <IconChevronRightFill size={14} />
        </div>
      }
    >
      <div className={bottomSheetScrollWrapper} ref={scrollContainerRef}>
        {localBusinessStory.localStories.map((localBusinessStory, index) => (
          <div
            className={bottomSheetScrollItemWrapper}
            key={localBusinessStory.id}
          >
            <SectionLocalBusinessStoryItem
              index={index}
              localBusinessStory={localBusinessStory}
            />
          </div>
        ))}
      </div>
      <div className={bottomButtonWrapper}>
        <BoxButton
          variant="secondary"
          width="100%"
          size="medium"
          onClick={handleMoreButtonClick}
        >
          더보기
        </BoxButton>
      </div>
    </BottomSheetSection>
  )
}

export default SectionLocalBusinessStory
