import { vars } from '@seed-design/design-token'
import { Fragment, useEffect, useMemo, useRef } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import {
  AUTO_HIDE_SYMBOL_PREVIEW_SOURCE_ID,
  useAutoHideSymbol,
} from '@src/core/components/karrot-map/map/useAutoHideSymbol'
import MarkerWithLabel from '@src/core/components/karrot-map/marker/MarkerWithLabel'
import { useAppColorTheme } from '@src/core/hooks/useTheme'
import { type LngLatNullable } from '@src/core/types/geo'
import { toOptimizedMarkerText } from '@src/features/map/components/map-contents/utils'

import * as css from './Preview.css'

type PreviewMarkerProps = {
  localProfileId: string
  lngLat: LngLatNullable
  name: string
}

const PreviewMarker = ({
  localProfileId,
  name,
  lngLat,
}: PreviewMarkerProps) => {
  const { map } = useMap()
  const texts = useMemo(() => toOptimizedMarkerText(name), [name])
  const { colorTheme } = useAppColorTheme()

  const autoHideSymbol = useAutoHideSymbol(
    map,
    AUTO_HIDE_SYMBOL_PREVIEW_SOURCE_ID
  )

  const ref = useRef({ autoHideSymbol })
  ref.current.autoHideSymbol = autoHideSymbol

  useEffect(() => {
    if (!lngLat) {
      return
    }

    ref.current.autoHideSymbol.setData([
      {
        name,
        lngLat: [lngLat.lng, lngLat.lat],
      },
    ])
  }, [lngLat, name])

  if (!lngLat) {
    return null
  }

  return (
    <MarkerWithLabel
      isSelected
      id={localProfileId}
      marker={<></>}
      selectedMarker={
        <div className={css.pin({ theme: colorTheme })}>
          <PinTexts texts={texts} theme={colorTheme} />
        </div>
      }
      lngLat={{
        lat: lngLat.lat,
        lng: lngLat.lng,
      }}
    />
  )
}

export default PreviewMarker

const PinTexts = ({
  texts,
  theme,
}: {
  texts: string[]
  theme: 'light' | 'dark'
}) => {
  const fontColor =
    theme === 'light'
      ? vars.$static.color.staticGray900
      : vars.$static.color.staticWhite
  const strokeColor =
    theme === 'light'
      ? vars.$static.color.staticWhite
      : vars.$static.color.staticGray900

  if (texts.length === 0) {
    return null
  }

  return (
    <svg
      className={css.pinLabel}
      width="1"
      height="1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        fill={fontColor}
        fontSize="12px"
        fontWeight="bold"
        textAnchor="middle"
      >
        {texts.map((text, i) => {
          return (
            <Fragment key={i}>
              <tspan
                className={css.font}
                x="0"
                y={`${1.2 * (i + 1)}em`}
                stroke={strokeColor}
                strokeWidth="1.2px"
                strokeLinejoin="round"
              >
                {text}
              </tspan>
              <tspan className={css.font} x="0" y={`${1.2 * (i + 1)}em`}>
                {text}
              </tspan>
            </Fragment>
          )
        })}
      </text>
    </svg>
  )
}
