import type maplibregl from 'maplibre-gl'
import { type GeoJSONSource } from 'maplibre-gl'
import { useEffect, useRef } from 'react'

import { MAP_FADE_DURATION } from '@src/services/constants/constants'
import { type LngLatArray } from '@src/core/types/geo'

export const AUTO_HIDE_SYMBOL_PREVIEW_SOURCE_ID =
  '_AUTO_HIDE_SYMBOL_PREVIEW_SOURCE'
export const AUTO_HIDE_SYMBOL_SEARCH_MARKER_SOURCE_ID =
  '_AUTO_HIDE_SYMBOL_SEARCH_MARKER_SOURCE'
const AUTO_HIDE_SYMBOL_LAYER_ID = '_AUTH_HIDE_SYMBOL_LAYER'

const autoHideSourceIdList = [
  AUTO_HIDE_SYMBOL_PREVIEW_SOURCE_ID,
  AUTO_HIDE_SYMBOL_SEARCH_MARKER_SOURCE_ID,
]

export function initAutoHideSymbol(map: maplibregl.Map) {
  autoHideSourceIdList.forEach((sourceId) => {
    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })

      map.addLayer({
        id: [sourceId, AUTO_HIDE_SYMBOL_LAYER_ID].join('/'),
        type: 'symbol',
        source: sourceId,
        layout: {
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
          'icon-ignore-placement': false,
          'icon-image': 'etc',
          'icon-offset': [0, -1.5],
          'icon-optional': false,
          'icon-padding': [
            'interpolate',
            ['linear'],
            ['zoom'],
            14,
            28,
            16,
            17,
            18,
            10,
            20,
            5,
          ],
          'symbol-sort-key': Number.MIN_SAFE_INTEGER,
          'text-allow-overlap': true,
          'text-overlap': 'always',
          'text-anchor': 'top',
          'text-field': ['get', 'name'],
          'text-font': ['Pretendard Semi Bold'],
          'text-ignore-placement': false,
          'text-justify': 'auto',
          'text-letter-spacing': -0.01,
          'text-max-width': 5,
          'text-offset': [0, 0.9],
          'text-padding': 10,
          'text-size': ['interpolate', ['linear'], ['zoom'], 5, 8, 18, 13],
          visibility: 'visible',
        },
        paint: {
          'icon-opacity': 0,
          'text-opacity': 0,
        },
      })
    }
  })
}

export type MaplibreSymbol = { name: string; lngLat: LngLatArray }

export function useAutoHideSymbol(
  map: maplibregl.Map | null,
  sourceId: string
) {
  async function setData(symbols: MaplibreSymbol[]) {
    if (map) {
      map._fadeDuration = 0

      const source = map.getSource(sourceId) as GeoJSONSource
      if (source) {
        const data: GeoJSON.GeoJSON = {
          type: 'FeatureCollection',
          features: symbols.map(({ lngLat, name }) => {
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: lngLat,
              },
              properties: { name },
            }
          }),
        }
        ref.current._data = symbols
        source.setData(data)
      }

      resetFadeDuration(map)
    }
  }

  const ref = useRef({ map, _data: [] as MaplibreSymbol[], setData })
  ref.current.map = map
  ref.current.setData = setData

  useEffect(() => {
    function unmount() {
      ref.current._data = []
      ref.current.setData([])
    }

    return unmount
  }, [])

  useEffect(() => {
    function onStyleData() {
      if (map) {
        ref.current.setData(ref.current._data)
      }
    }

    map?.on('styledata', onStyleData)

    return () => {
      map?.off('styledata', onStyleData)
    }
  }, [map])

  return { setData }
}

function resetFadeDuration(map: maplibregl.Map) {
  function reset() {
    map._fadeDuration = MAP_FADE_DURATION

    map.off('touchstart', reset)
    map.off('zoomstart', reset)
  }

  map.on('touchstart', reset)
  map.on('zoomstart', reset)
}
