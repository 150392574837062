import { useEffect, useRef } from 'react'

import localProfileSuggestionSDK from '@src/core/lib/localProfileSuggestionSdk'
import { getExternalReferrerEntry } from '@src/services/store/externalAccessValues'

export const useOpenLocalProfileSuggestionUpdateBackgroundImage = () => {
  /**
   * 사진 제안 웹뷰로 넘어갈 때 stream 이벤트를 구독하고 있어요.
   * 구독은 사진 제안이 완료되었을 때 해지하는데, 사진을 선택하지 않고 뒤로가기를 할 경우 구독 상태가 유지돼요.
   * 이를 방지하기 위해 변수에 구독 해지 함수를 담아두고 적절한 시점에 해지해요.*/
  const unsubscribeStreamEvent = useRef<() => void>(() => {})

  useEffect(() => {
    return () => {
      unsubscribeStreamEvent.current()
    }
  }, [])

  return ({
    cb,
    entry,
    localProfileId,
  }: {
    localProfileId: number
    entry: string
    cb?: () => Promise<unknown>
  }) => {
    unsubscribeStreamEvent.current()

    const externalReferrerEntry = getExternalReferrerEntry()

    localProfileSuggestionSDK.update.fields.backgroundImage.open({
      entry: externalReferrerEntry.entry || entry,
      referrer: externalReferrerEntry.referrer || entry,
      localProfileId,
    })

    unsubscribeStreamEvent.current =
      localProfileSuggestionSDK.update.fields.backgroundImage.on(async () => {
        await cb?.()

        unsubscribeStreamEvent.current()
      })
  }
}
