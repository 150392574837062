import { useEffect, useMemo } from 'react'

import NewPreviewBottomSheet from '@src/core/components/bottom-sheet/NewPreviewBottomSheet'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { arrayIntersection } from '@src/core/utils/snippet'
import SearchedPreviewListBottomSheet from '@src/features/search/components/SearchedPreviewListBottomSheet'
import { ExternalPageRouter } from '@src/pages/home-feed-step/preview/previewRouter'
import { useSearchLocalProfilesApiQuery } from '@src/pages/home-search-result-step/_api-queries/searchLocalProfiles'
import ReSearchAreaButton from '@src/pages/home-search-result-step/re-search-area-button/ReSearchAreaButton'
import SearchPreviewSearchInput from '@src/pages/home-search-result-step/search-input/SearchPreviewSearchInput'
import SearchSuggestions from '@src/pages/home-search-result-step/search-suggestion/SearchSuggestions'
import Preview from '@src/pages/preview-step/Preview'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

interface SearchedPreviewStepFragmentProps {
  initialQuery: string
}

const PAGE_CHANGE_DELAY = 0

const SearchedPreviewStepFragment = ({
  initialQuery,
}: SearchedPreviewStepFragmentProps) => {
  const { map } = useMap()
  const { stepType, getValidatedParams } = useStepRouter()
  const { stepMove } = useLocalMapStepMovePolicy()

  const { placedLocalProfiles, searchCategorySuggestions } =
    useSearchLocalProfilesApiQuery()
  const isExistCategorySuggestions =
    !!searchCategorySuggestions?.suggestions &&
    searchCategorySuggestions?.suggestions.length > 0

  const { localProfileId, localProfileIds } = useMemo(
    () => getValidatedParams('searchedPreview'),
    [getValidatedParams]
  )

  const previewType = (() => {
    if (localProfileId) {
      return 'preview'
    }

    return 'previewList'
  })()

  const handleSwipeUp = () => {
    if (stepType !== 'externalPage') {
      setTimeout(() => {
        const localProfileId = ExternalPageRouter() || ''

        stepMove('preview', 'swipeUp_preview', {
          localProfileId,
          mapBounds: map ? map.getBounds().toArray().toString() : null,
        })
      }, PAGE_CHANGE_DELAY)
    }
  }

  const clusteredLocalProfiles =
    previewType === 'previewList' && localProfileIds
      ? arrayIntersection(
          placedLocalProfiles,
          localProfileIds,
          (placedLocalProfile, localProfileId) =>
            placedLocalProfile.id === localProfileId
        )
      : []

  useEffect(() => {
    if (previewType === 'preview' && clusteredLocalProfiles.length === 0) {
      stepMove
    }
  })

  return (
    <>
      <SearchPreviewSearchInput initialQuery={initialQuery} />
      <SearchSuggestions />
      <ReSearchAreaButton />
      {(() => {
        switch (previewType) {
          case 'preview': {
            return (
              <NewPreviewBottomSheet onSwipeUp={handleSwipeUp}>
                <Preview />
              </NewPreviewBottomSheet>
            )
          }
          case 'previewList': {
            return (
              <SearchedPreviewListBottomSheet
                localProfileList={clusteredLocalProfiles}
                isExistCategorySuggestions={isExistCategorySuggestions}
              />
            )
          }
        }
      })()}
    </>
  )
}

export default SearchedPreviewStepFragment
