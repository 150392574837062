import * as Sentry from '@sentry/react'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'

interface ImpressionProps {
  scrollContainerRef?: React.RefObject<HTMLElement>
  disableImpressionOnload?: boolean
  onImpression?: () => void
  threshold?: number
  children: React.ReactNode
}
const Impression = ({
  scrollContainerRef,
  disableImpressionOnload,
  onImpression,
  threshold,
  children,
}: ImpressionProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useImpression(
    {
      ref,
      scrollContainerRef,
      disableImpressionOnload,
      threshold,
    },
    () => {
      onImpression?.()
    },
    [onImpression]
  )

  return <div ref={ref}>{children}</div>
}

export function useImpression<T extends HTMLElement, U extends HTMLElement>(
  options: {
    scrollContainerRef?: React.RefObject<U>
    ref: React.RefObject<T>
    disableImpressionOnload?: boolean
    threshold?: number
    rootMargin?: string
  },
  onImpression: () => void,
  deps: any[]
) {
  const [impressed, impress] = useReducer(() => true, false)
  const onloadFlag = useRef(true)

  const onIntersection: IntersectionObserverCallback = useCallback(
    ([{ isIntersecting }]) => {
      if (isIntersecting && !impressed) {
        impress()

        if (onloadFlag.current && !options.disableImpressionOnload) {
          onImpression()
        }
        if (!onloadFlag.current) {
          onImpression()
        }
      }

      onloadFlag.current = false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [impressed, impress, options.disableImpressionOnload, ...deps]
  )

  useEffect(() => {
    if (!options.ref) return

    const $container = options.ref.current
    const $scrollContainer = options.scrollContainerRef?.current ?? null

    let obs: IntersectionObserver | null = null

    if (!!IntersectionObserver && $container && !impressed) {
      try {
        obs = new IntersectionObserver(onIntersection, {
          threshold: options.threshold ?? 1,
          root: $scrollContainer,
          rootMargin: options.rootMargin,
        })
        obs.observe($container)
      } catch (e) {
        Sentry.captureException(e)
      }

      return () => {
        if (obs) {
          obs.unobserve($container)
        }
      }
    }
  }, [
    options.scrollContainerRef,
    options.ref,
    options.threshold,
    options.rootMargin,
    impressed,
    onIntersection,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...deps,
  ])
}

export default Impression
