export function getLocalProfileId(
  map: maplibregl.Map,
  point: maplibregl.Point
) {
  const features = map.queryRenderedFeatures(point, {
    filter: ['==', ['get', 'data_source'], 'LP'],
    validate: false,
  })

  const localProfileFeature = features.at(0)

  return localProfileFeature?.properties?.feature_id?.toString() ?? null
}
