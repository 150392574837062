import * as Sentry from '@sentry/react'
import type {
  Map as TMap,
  MapLibreEvent,
  PaddingOptions,
  MapMouseEvent,
  MapTouchEvent,
} from 'maplibre-gl'
import {
  forwardRef,
  type HTMLAttributes,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react'

import { MAP_FADE_DURATION } from '@src/services/constants/constants'
import { haversineDistance } from '@src/core/utils/coordinate'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { LocalProfileHomeForExtract } from '@src/pages/home-feed-step/external/ExtractLocalProfile'
import { debugAppLogger, logLevel } from '@src/services/log/logLevel'
import { EVENT_NAME_SEED_SCALE_COLOR_CHANGED } from '@src/services/styles/seedDesign'

import {
  INITIAL_MAP_STATE,
  type MapCustomEventActionType,
  DEFAULT_MAX_BOUNDS,
} from './constants'
import { useMap, useMapResource } from './MapProvider'
import { type MapGLType } from './mapResources'
import {
  getSeedScaleColorChangedHandler,
  loadMapStyleAccordingToTheme,
} from './theme'
import { initAutoHideSymbol } from './useAutoHideSymbol'
import { useMapStyleUpdater } from './useMapStyleUpdater'

import './map.css'

export const EVENT_NAME_MAP_INSTANCE_SET = 'onMapInstanceSet'

declare global {
  interface Window {
    mapInstance?: MapType
  }
}

export type MapType = TMap
export type MapLibreGLType = MapGLType
export type MapLibreEventTypeWithLocalMapAction = MapLibreEvent<
  MouseEvent | TouchEvent | WheelEvent | undefined
> & {
  action?: MapCustomEventActionType
}

export interface MapProps extends HTMLAttributes<HTMLDivElement> {
  initialState?: Partial<typeof INITIAL_MAP_STATE>
  mapPadding?: PaddingOptions
  onMapLoaded?: (map: MapType) => void
  onMapInitialLoaded?: (map: MapType) => void
  onMapClick?: (map: MapType, e: MapMouseEvent) => void
  onMapMoveEnd?: (map: MapType, e: any) => void
  onMapDragStart?: (map: MapType) => void
  onMapDragEnd?: (map: MapType) => void
}

let onLoadOnceFlag = false

const IGNORE_NETWORK_ERRORS = ['Failed to fetch']

const Map = forwardRef<HTMLDivElement, MapProps>((props: MapProps, ref) => {
  const {
    initialState,
    mapPadding,
    onMapClick,
    onMapMoveEnd,
    onMapLoaded,
    onMapInitialLoaded,
    onMapDragStart,
    onMapDragEnd,
    children,
    ...restProps
  } = props
  const { map, setMap } = useMap()
  const { mapLibreGL, mapStyle } = useMapResource()
  const containerRef = useRef<HTMLDivElement>(null)
  const appLogger = debugAppLogger()
  const webglContextLostRefreshCountRef = useRef<number>(0)

  useImperativeHandle(ref, () => containerRef.current as HTMLDivElement, [
    containerRef,
  ])

  const listeners = useRef({ onMapClick })
  listeners.current.onMapClick = onMapClick

  useMapStyleUpdater()

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const $container = containerRef.current

    if (map) {
      return
    }

    const mapInstance = new mapLibreGL.Map({
      container: $container,
      style: loadMapStyleAccordingToTheme(mapStyle),
      center: initialState?.center
        ? {
            lat: initialState.center.lat,
            lng: initialState.center.lng,
          }
        : {
            lat: INITIAL_MAP_STATE.center.lat,
            lng: INITIAL_MAP_STATE.center.lng,
          },
      zoom: initialState?.zoom ?? INITIAL_MAP_STATE.zoom,
      interactive: initialState?.interactive ?? INITIAL_MAP_STATE.interactive,
      minZoom: initialState?.minZoom ?? INITIAL_MAP_STATE.minZoom,
      maxZoom: initialState?.maxZoom ?? INITIAL_MAP_STATE.maxZoom,
      bounds: initialState?.bounds,
      minPitch: 0,
      maxPitch: 0,
      dragRotate: false,
      pitchWithRotate: false,
      attributionControl: false,
      localIdeographFontFamily: undefined,
      maxBounds: DEFAULT_MAX_BOUNDS as maplibregl.LngLatBoundsLike,
      fadeDuration: MAP_FADE_DURATION,
    })

    if (!window.mapInstance) {
      window.mapInstance = mapInstance

      window.dispatchEvent(new Event(EVENT_NAME_MAP_INSTANCE_SET))
    }

    // disable map rotation using right click + drag
    mapInstance.dragRotate.disable()

    // disable map rotation using touch rotation gesture
    mapInstance.touchZoomRotate.disableRotation()

    const showScaleControl =
      initialState?.showScaleControl ?? INITIAL_MAP_STATE.showScaleControl

    if (showScaleControl) {
      mapInstance.addControl(
        new mapLibreGL.ScaleControl({
          maxWidth: 100,
          unit: 'metric',
        })
      )
    }

    const seedScaleColorHandler = getSeedScaleColorChangedHandler({
      mapInstance,
      mapStyle,
    })

    mapInstance.on('load', () => {
      appLogger.log('map loaded', mapInstance.getStyle().layers)

      setMap(mapInstance)
      onMapLoaded?.(mapInstance)

      if (!onLoadOnceFlag) {
        onMapInitialLoaded?.(mapInstance)
        onLoadOnceFlag = true
      }

      window.addEventListener(
        EVENT_NAME_SEED_SCALE_COLOR_CHANGED,
        seedScaleColorHandler
      )

      setTimeout(() => {
        LocalProfileHomeForExtract.preload()
      }, 0)
    })
    mapInstance.on('click', (e) => {
      listeners.current.onMapClick?.(mapInstance, e)
    })
    mapInstance.on('remove', () => {
      setMap(null)

      window.removeEventListener(
        EVENT_NAME_SEED_SCALE_COLOR_CHANGED,
        seedScaleColorHandler
      )
    })
    /**
     * iOS 웹뷰에서 간헐적으로 webgl context가 소실되는 케이스 대응
     *
     * 테스트 코드, 아래 코드를 통해 강제로 context lost를 발생시킬 수 있음
     * const canvas = map?.getCanvas()
     * const webgl = canvas?.getContext('webgl2')
     * webgl?.getExtension('WEBGL_lose_context')?.loseContext()
     */
    mapInstance.on('webglcontextlost', (ev) => {
      if (webglContextLostRefreshCountRef.current < 3) {
        setMap(null) // 인스턴스 초기화

        webglContextLostRefreshCountRef.current =
          webglContextLostRefreshCountRef.current + 1
        return
      }

      appLogger.error('webglcontextlost refresh 3 times')
      Sentry.captureException(
        new Error(`webglcontextlost refresh 3 times, ${ev}`)
      )
    })
    // TODO: 임시, 로깅을 위한 이벤트
    mapInstance.on('webglcontextrestored', (ev) => {
      appLogger.log('webglcontextrestored event', ev)
    })
    mapInstance.on('tiledataloading', (e) => {
      appLogger.error('tiledataloading event', e)
    })
    mapInstance.on('styledata', (e) => {
      initAutoHideSymbol(mapInstance)
      appLogger.log('styledata event', e)
    })
    mapInstance.on('styleimagemissing', (e) => {
      appLogger.error('styleimagemissing event', e)
    })
    mapInstance.on('dataabort', (e) => {
      appLogger.log('dataabort event', e)
    })
    mapInstance.on('tile.error', (e) => {
      appLogger.log('tile.error event', e)

      Sentry.captureException(e)
    })
    mapInstance.on('zoomend', () => {
      logLevel.log(`current zoom: ${mapInstance.getZoom()}`)
    })
    mapInstance.on('error', (errorEvent) => {
      appLogger.error(errorEvent)
      // NOTE: 에러 로깅만하고 UI 처리하지 않음
      Sentry.captureException(errorEvent.error)

      if (IGNORE_NETWORK_ERRORS.includes(errorEvent.error?.message)) {
        return
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }

    if (mapPadding) {
      map.setPadding(mapPadding)
    }
  }, [map, mapPadding])

  useEffect(() => {
    if (!map) {
      return
    }

    const handleMoveEnd = (e: MapLibreEventTypeWithLocalMapAction) => {
      onMapMoveEnd?.(map, e)
    }

    map.on('moveend', handleMoveEnd)

    return () => {
      map.off('moveend', handleMoveEnd)
    }
  }, [map, onMapMoveEnd])

  useEffect(() => {
    if (!map) {
      return
    }

    const handleMapDragStart = () => {
      onMapDragStart?.(map)
    }

    const handleMapDragEnd = (e: MapTouchEvent) => {
      const coordiate = coordinatesStore.getState()
      const originValue = coordiate.distancedCoordinates?.coordinates
      const originCoord = {
        latitude: Number(originValue?.latitude) || 0,
        longitude: Number(originValue?.longitude) || 0,
      }
      const desination = {
        latitude: e.target.transform.center.lat,
        longitude: e.target.transform.center.lng,
      }
      const distance = haversineDistance(originCoord, desination)
      if (distance > 70) {
        coordiate.setDistancedCoordinates({
          type: 'currentPosition',
          coordinates: { ...desination },
        })
      }
      onMapDragEnd?.(map)
    }

    map.on('dragstart', handleMapDragStart)
    map.on('dragend', handleMapDragEnd)

    return () => {
      map.off('dragstart', handleMapDragStart)
      map.off('dragend', handleMapDragEnd)
    }
  }, [map, onMapDragEnd, onMapDragStart])

  return (
    <>
      <div id={'karrot-map'} ref={containerRef} {...restProps} />
      {props.children}
    </>
  )
})

export default Map
