import { useEffect } from 'react'

import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

/**
 * home step에서 바텀시트 높이 정책을 설정하는 훅
 */
export function useHomeBottomSheetAnchorPolicy() {
  const {
    stepType,
    prevStepInfo: { prevStep },
  } = useStepRouter()

  if (stepType !== 'home') {
    throw new Error('This hook is only available on the home step')
  }

  const { setSnapPoint } = useBottomSheetSnapPoint()

  useEffect(() => {
    // 이전 단계가 preview일 경우 무시 (프리뷰 상태에서 검색결과로 돌아왔을 때 바텀시트가 middle로 설정되는 것을 방지)
    if (prevStep === 'preview') {
      return
    }

    setSnapPoint('middle')
  }, [prevStep, setSnapPoint, stepType])
}
