import { type SVGProps } from 'react'

export const IconHouseDots = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M11.8333 9.16667H13.1666C13.7189 9.16667 14.1666 9.61438 14.1666 10.1667V11.5C14.1666 12.0523 13.7189 12.5 13.1666 12.5H11.8333C11.281 12.5 10.8333 12.0523 10.8333 11.5V10.1667C10.8333 9.61438 11.281 9.16667 11.8333 9.16667Z"
      fill={props.color ?? '#1A1C20'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.541508 7.67559C0.779256 8.05967 1.27841 8.18321 1.6668 7.95864L1.66674 9.88411C1.66664 11.9195 1.66654 13.955 1.66675 15.9904C1.66689 17.371 2.78616 18.49 4.16667 18.49H15.8333C17.214 18.49 18.3333 17.3707 18.3333 15.99V7.95861C18.7209 8.18281 19.2193 8.06022 19.4576 7.67722C19.7008 7.28644 19.5811 6.77256 19.1903 6.52942C16.9402 5.12947 12.9197 2.64422 11.2259 1.5977C10.4725 1.13224 9.52404 1.13524 8.77343 1.59988L0.811464 6.52842C0.420134 6.77065 0.299271 7.28426 0.541508 7.67559ZM10.3498 3.01556C10.1362 2.88355 9.86655 2.88337 9.65065 3.01701L3.3335 6.92739L3.33341 9.88459C3.33331 11.9198 3.33321 13.955 3.33341 15.9902C3.33346 16.4503 3.70647 16.8233 4.16667 16.8233H15.8333C16.2935 16.8233 16.6666 16.4502 16.6666 15.99V6.92414C14.4514 5.5504 11.6821 3.83874 10.3498 3.01556Z"
      fill={props.color ?? '#1A1C20'}
    />
  </svg>
)
