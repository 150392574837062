export function chunkArray<T>(array: T[], size: number): T[][] {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

/**
 * await-to-js
 * @param { Promise } promise
 * @param { Object= } errorExt - Additional Information you can pass to the err object
 * @return { Promise }
 */
export function awaitToJs<T, U = Error>(
  promise: Promise<T>,
  errorExt?: object
): Promise<[U, undefined] | [null, T]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt)
        return [parsedError, undefined]
      }

      return [err, undefined]
    })
}

/**
 * 배열 중 validValue에서 포함되는 값이 있으면 true
 * @param arr 원본값
 * @param validValue 비교값
 * @returns true | false
 */
export function containsOneOf<T>(arr: T[], validValue: T[]): boolean {
  const validValueSet = new Set(validValue)

  return arr.some((value) => validValueSet.has(value))
}

/**
 * 배열 두개를 비교하여 equal true인 값만 반환
 * @param array1 배열1
 * @param array2 배열2
 * @param equal 비교함수
 * @returns 교집합 배열
 */
export function arrayIntersection<A, B>(
  array1: A[],
  array2: B[],
  equal: (a: A, b: B) => boolean
): A[] {
  // Set을 이용하여 중복 제거 및 효율적인 검색 수행
  const set1 = new Set(array1)
  const set2 = new Set(array2)

  // 두 배열의 교집합 생성
  const result: A[] = []
  for (const value1 of set1) {
    for (const value2 of set2) {
      if (equal(value1, value2)) {
        result.push(value1)
        break
      }
    }
  }

  return result
}

/**
 * 배열 두개를 비교해서 값이 모두 같으면 true 반환
 */
export function arraysAreEqual<A, B>(
  array1: A[],
  array2: B[],
  equal: (a: A, b: B) => boolean
): boolean {
  if (array1.length !== array2.length) {
    return false
  }

  for (let i = 0; i < array1.length; i++) {
    const val1 = array1[i]
    const val2 = array2[i]

    if (!equal(val1, val2)) {
      return false
    }
  }
  return true
}
