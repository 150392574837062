import { parse } from '@daangn/webview-target-uri'
import { useCallback, useMemo } from 'react'

import { STAGE } from '@src/services/constants/constants'

import { useBridgeRouter } from './useBridgeRouter'

export type UpdateReferrer =
  | ((currentReferrer: string | null) => string | null)
  | string
export type UpdateEntry =
  | ((currentEntry: string | null) => string | null)
  | string

export type Target = {
  additionalQueryParams?: { [key: string]: string }
  updateReferrer?: UpdateReferrer
  updateEntry?: UpdateEntry
} & OriginalTarget

export type OriginalTarget =
  | {
      targetUri: string
    }
  | {
      schemePath: string
    }
  | {
      app: string
      path: string
      present?: 'top'
      navbar?: boolean
      scrollable?: boolean
    }
  | {
      remote: string
      present?: 'top'
      navbar?: boolean
      scrollable?: boolean
    }

export function useOpenTarget() {
  const router = useBridgeRouter()

  const openTarget = useCallback(
    (target: Target): void => {
      if ('targetUri' in target) {
        try {
          const t = parse(target.targetUri)

          if ('app' in t) {
            return openTarget({
              app: t.app,
              path: t.path,
              additionalQueryParams: target.additionalQueryParams,
              updateReferrer: target.updateReferrer,
              updateEntry: target.updateEntry,
              navbar: t.navbar,
              scrollable: t.scrollable,
            })
          } else {
            return openTarget({
              remote: t.remote,
              additionalQueryParams: target.additionalQueryParams,
              updateReferrer: target.updateReferrer,
              updateEntry: target.updateEntry,
              navbar: t.navbar,
              scrollable: t.scrollable,
            })
          }
        } catch (error) {
          return openTarget({
            schemePath: target.targetUri.split('://')[1],
          })
        }
      }

      // eslint-disable-next-line prefer-const
      let { origin, pathname, hash, searchParams } = new URL(
        'schemePath' in target
          ? target.schemePath
          : 'remote' in target
          ? target.remote
          : target.path,
        /* dummy */ 'file://'
      )

      const isHashRouter =
        pathname === '/' &&
        searchParams.toString() === '' &&
        hash.split('#')[1]?.indexOf('/') === 0

      if (isHashRouter) {
        const hashUrl = new URL(hash.split('#')[1], /* dummy */ 'file://')
        pathname = hashUrl.pathname
        searchParams = hashUrl.searchParams
      }

      for (const key in target.additionalQueryParams) {
        searchParams.set(key, target.additionalQueryParams[key])
      }

      // referrer 수정적용
      if (typeof target.updateReferrer === 'string') {
        searchParams.set('referrer', target.updateReferrer)
      } else {
        const updatedReferrer = target.updateReferrer?.(
          searchParams.get('referrer')
        )
        if (updatedReferrer) {
          searchParams.set('referrer', updatedReferrer)
        }
      }

      // entry 수정적용
      if (typeof target.updateEntry === 'string') {
        searchParams.set('entry', target.updateEntry)
      } else {
        const updatedEntry = target.updateEntry?.(searchParams.get('entry'))
        if (updatedEntry) {
          searchParams.set('entry', updatedEntry)
        }
      }

      const nextPath =
        searchParams.toString().length > 0
          ? `${pathname}?${searchParams.toString()}`
          : pathname

      if ('schemePath' in target) {
        switch (STAGE) {
          case 'alpha':
          case 'development':
            return void (window.location.href = `karrot.alpha://${removeLeadingSlash(
              nextPath
            )}`)
          case 'production':
            return void (window.location.href = `karrot://${removeLeadingSlash(
              nextPath
            )}`)
        }
      }
      if ('app' in target) {
        router.push({
          router: {
            app: target.app,
            path: nextPath,
            navbar: target.navbar ?? false,
            scrollable: target.scrollable ?? false,
            present: target.present === 'top',
          },
        })
      }
      if ('remote' in target) {
        router.push({
          router: {
            remote: isHashRouter
              ? `${origin}/#/${nextPath}`
              : origin + nextPath,
            navbar: target.navbar ?? false,
            scrollable: target.scrollable ?? false,
            present: target.present === 'top',
          },
        })
      }
    },
    [router]
  )

  return useMemo(
    () => ({
      openTarget,
    }),
    [openTarget]
  )
}

function removeLeadingSlash(url: string): string {
  // 만약 url이 슬래시로 시작된다면, 첫 번째 문자를 제외한 나머지 문자열을 반환
  return url.startsWith('/') ? url.substring(1) : url
}
