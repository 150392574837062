import { type TypeLatLng } from '@daangn/local-map-api'
import { vars } from '@seed-design/design-token'
import React, { useEffect, useMemo, useRef } from 'react'

import { useGetPlaceIndicatorQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { bottomSheetRegionSetter } from '@src/pages/home-feed-step/_utils/bottomSheetRegion'
import { LOCATION_INDICATOR_SECTION_INFO } from '@src/pages/home-feed-step/section/sectionKeyInfo'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import IconLocationCustom from './launchpad/icon/IconLocationCustom'
import { padTitle, padTitleText } from './launchpad/Launchpad.css'

interface PlaceIndicatorRootProps {
  userCoordinates: TypeLatLng
}
const PlaceIndicatorRoot = ({ userCoordinates }: PlaceIndicatorRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { data } = useGetPlaceIndicatorQuery({
    queryKey: 'REGION_GET',
    userCoordinates,
  })
  const sectionImpressReset = coordinatesStore(
    (state) => state.sectionImpressReset
  )

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...LOCATION_INDICATOR_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  useEffect(() => {
    bottomSheetRegionSetter(data?.regionName3 || '')
  }, [data])

  if (!data) return <div className={padTitle}></div>

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div
        className={padTitle}
        ref={sectionRef}
        role="region"
        aria-label="위치 정보"
      >
        <IconLocationCustom size={16} color={vars.$scale.color.gray900} />
        <span className={padTitleText}>{`${data.regionName2 || ''} ${
          data.regionName3
        }`}</span>
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(PlaceIndicatorRoot)
