import {
  IconLocationpinFill,
  IconStorePenFill,
} from '@daangn/react-monochrome-icon'
import { useMemo } from 'react'

import SimpleBottomSheet from '@src/core/components/simple-bottom-sheet/SimpleBottomSheet'
import { UGC_WEBVIEW_BASE_URL } from '@src/services/constants/constants'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useOpenPoiSuggestionCreate } from '@src/core/hooks/useOpenPoiSuggestionCreate'
import {
  useContributionBottomSheetHandler,
  useContributionBottomSheetOpenStateHandlerEffect,
} from '@src/features/contribution/hooks/useContributionBottomSheetHandler'

import * as css from './ContributionBottomSheet.css'

const REFERRER = 'localMap_home_map'

const ContributionBottomSheet = () => {
  const { handleClose, isOpen } = useContributionBottomSheetHandler()
  const { openTarget } = useLocalMapOpenTarget()
  const openPoiSuggestionCreate = useOpenPoiSuggestionCreate()

  const contents = useMemo(
    () => [
      {
        title: '업체 추가하기',
        description: '아는 곳이 지도에 없다면',
        iconRender: ({ size }: { size: number }) => (
          <IconLocationpinFill color="#FF6F0F" width={size} height={size} />
        ),
        onClick: () => {
          openPoiSuggestionCreate({
            referrer: REFERRER,
            entry: REFERRER,
          })
        },
      },
      {
        title: '업체 정보 채워주기',
        description: '전화번호, 가격표, 사진 등',
        iconRender: ({ size }: { size: number }) => (
          <IconStorePenFill color="#55A7FF" width={size} height={size} />
        ),
        onClick: () => {
          openTarget({
            remote:
              UGC_WEBVIEW_BASE_URL +
              '/local-profiles/contribute/recommendations?contribution_type=local_profile_update',
            referrer: REFERRER,
          })
        },
      },
    ],
    [openPoiSuggestionCreate, openTarget]
  )

  useContributionBottomSheetOpenStateHandlerEffect()

  return (
    <SimpleBottomSheet isOpen={isOpen} onClose={handleClose}>
      <ul className={css.list}>
        {contents.map((content, index) => (
          <li key={index} className={css.listItem}>
            <button
              className={css.listItemButton}
              onClick={() => {
                content.onClick()

                handleClose()

                return
              }}
            >
              <div className={css.listItemIcon}>
                {content.iconRender({
                  size: 28,
                })}
              </div>

              <p className={css.listItemText}>
                <span className={css.listItemDescription}>
                  {content.description}
                </span>

                <b className={css.listItemTitle}>{content.title}</b>
              </p>
            </button>
          </li>
        ))}
      </ul>
    </SimpleBottomSheet>
  )
}

export default ContributionBottomSheet
