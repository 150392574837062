import { type V1PublicInstitutionBusinessPostSection } from '@daangn/local-map-api'
import { BoxButton } from '@daangn/sprout-components-button'
import React, { useMemo, useRef } from 'react'

import CarouselContent from '@src/core/components/carousel/CarouselContent'
import { usePreventParentHorizontalScroll } from '@src/core/components/scroll-control/usePreventParentHorizontalScroll copy'
import { usePreventSelfHorizontalScrollOnVerticalScroll } from '@src/core/components/scroll-control/usePreventSelfHorizontalScrollOnVerticalScroll'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { chunkArray } from '@src/core/utils/snippet'
import BottomSheetSection from '@src/pages/home-feed-step/section/lib/BottomSheetSection'
import {
  bottomButtonWrapper,
  sectionScrollWrapper,
} from '@src/pages/home-feed-step/section/lib/BottomSheetSection.css'
import {
  PUBLIC_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import SectionPublicPostItem from './SectionPublicPostItem'

interface SectionPublicPostProps {
  title: string
  publicPost: V1PublicInstitutionBusinessPostSection
}

const VIEW_ITEM_COUNT = 3

const REFERRER = genSectionReferrer(PUBLIC_SECTION_INFO.sectionKey)

const SectionPublicPost = ({ title, publicPost }: SectionPublicPostProps) => {
  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const { openTarget } = useLocalMapOpenTarget()

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  usePreventParentHorizontalScroll({
    targetRef: scrollContainerRef,
  })
  usePreventSelfHorizontalScrollOnVerticalScroll({
    targetRef: scrollContainerRef,
  })

  const listItems = useMemo(() => {
    const posts = publicPost.businessPosts
    return chunkArray(posts, VIEW_ITEM_COUNT).map((postGroup, index) => (
      <div key={index}>
        {postGroup.map((postItem) => (
          <SectionPublicPostItem
            key={postItem.id}
            index={index}
            publicPost={postItem}
          />
        ))}
      </div>
    ))
  }, [publicPost.businessPosts])

  const handleMoreButtonClick = () => {
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'more',
      ...getMapLogParams(),
      ...PUBLIC_SECTION_INFO,
    })
    openTarget({
      targetUri: publicPost.viewMoreTargetUri,
      referrer: REFERRER,
      additionalQueryParams: {
        regionId: publicPost.region3Id || '',
        regionName: publicPost.region3Name || '',
      },
    })
  }

  return (
    <BottomSheetSection title={title}>
      <CarouselContent
        ref={scrollContainerRef}
        injectClass={sectionScrollWrapper}
        listItems={listItems}
        onListSwipe={() => {}}
      />
      <div className={bottomButtonWrapper}>
        <BoxButton
          variant="secondary"
          width="100%"
          size="medium"
          onClick={handleMoreButtonClick}
        >
          더보기
        </BoxButton>
      </div>
    </BottomSheetSection>
  )
}

export default React.memo(SectionPublicPost)
