import {
  type LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum,
  type TypeLatLng,
  type LocalMapServiceListItemsBySectionViewTypesRequest,
  type LocalMapServiceGetRegionByLatLngRequest,
} from '@daangn/local-map-api'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'

import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { localMapApi } from '@src/services/api'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

type UseGetSectionQueryData = Awaited<
  ReturnType<
    Awaited<
      ReturnType<
        typeof localMapApi
      >['localMapServiceListItemsBySectionViewTypes']
    >
  >
>

interface SectionItemProps {
  sectionViewTypes: LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum
  queryKey: string
  userCoordinates: TypeLatLng
  queryOptions?: {
    refetchOnWindowFocus?: UseQueryOptions<UseGetSectionQueryData>['refetchOnWindowFocus']
    refetchOnReconnect?: UseQueryOptions<UseGetSectionQueryData>['refetchOnReconnect']
  }
}

export const useGetSectionQuery = ({
  sectionViewTypes,
  queryKey,
  userCoordinates,
  queryOptions,
}: SectionItemProps) => {
  const bridgeInfo = useBridgeInfo()
  const { snapPoint } = useBottomSheetSnapPoint()

  const prevUserCoordinatesRef = useRef(userCoordinates)

  const request: LocalMapServiceListItemsBySectionViewTypesRequest = {
    sectionViewTypes: [sectionViewTypes],
    userCoordinatesLatitude: userCoordinates.latitude,
    userCoordinatesLongitude: userCoordinates.longitude,
  }

  const { data, isLoading, refetch, isSuccess } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      return localMapApi(bridgeInfo).localMapServiceListItemsBySectionViewTypes(
        {
          ...request,
        }
      )
    },
    refetchOnWindowFocus: queryOptions?.refetchOnWindowFocus,
    refetchOnReconnect: queryOptions?.refetchOnReconnect,
  })

  useEffect(() => {
    // userCoordinates가 변경되었는지 확인
    const isUserCoordinatesChanged =
      prevUserCoordinatesRef.current.latitude !== userCoordinates.latitude ||
      prevUserCoordinatesRef.current.longitude !== userCoordinates.longitude

    // userCoordinates가 변경되었을 때 바텀시트가 열리면 refetch 실행
    if (snapPoint === 'top' && isUserCoordinatesChanged) {
      refetch()

      prevUserCoordinatesRef.current = userCoordinates
    }
  }, [snapPoint, userCoordinates, refetch])

  return { data, isLoading, isSuccess, refetch }
}

interface PlaceIndicatorQueryProps {
  queryKey: string
  userCoordinates: TypeLatLng
}
export const useGetPlaceIndicatorQuery = ({
  queryKey,
  userCoordinates,
}: PlaceIndicatorQueryProps) => {
  const bridgeInfo = useBridgeInfo()
  const request: LocalMapServiceGetRegionByLatLngRequest = {
    latitude: userCoordinates.latitude,
    longitude: userCoordinates.longitude,
  }
  const { data, isLoading, refetch } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      return localMapApi(bridgeInfo).localMapServiceGetRegionByLatLng({
        ...request,
      })
    },
  })
  useEffect(() => {
    refetch()
  }, [userCoordinates.latitude, userCoordinates.longitude, refetch])
  return { data, isLoading }
}
