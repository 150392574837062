import { IconArrowClockwiseCircularFill } from '@daangn/react-monochrome-icon'
import { ExtendedFab } from '@daangn/sprout-components-fab'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { useEffect, useMemo, useState } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import {
  SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX,
  SEARCH_SEARCH_BAR_HEIGHT_PX,
} from '@src/services/constants/homeActivitySize'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useSearchLogger } from '@src/services/log/hooks/useSearchLogger'

import * as css from './ReSearchAreaButton.css'
import { useSearchLocalProfilesApiQuery } from '../_api-queries/searchLocalProfiles'
import { useSearchExecutions } from '../_hooks/useSearchExecutions'

const ReSearchAreaButton = () => {
  const [moved, setMoved] = useState(false)
  const { map } = useMap()
  const { boundsReSearch } = useSearchExecutions()
  const { clickedSearchResultChangeBoundaryButtonLog } = useSearchLogger()

  const { searchCategorySuggestions } = useSearchLocalProfilesApiQuery()

  const isExistCategorySuggestions =
    !!searchCategorySuggestions?.suggestions &&
    searchCategorySuggestions?.suggestions.length > 0

  const { stepType, stepBack } = useLocalMapStepMovePolicy()

  const searchAreaTopHeight = useMemo(() => {
    if (stepType === 'searchedPreview') {
      return SEARCH_SEARCH_BAR_HEIGHT_PX
    }

    if (isExistCategorySuggestions) {
      return (
        SEARCH_SEARCH_BAR_HEIGHT_PX +
        SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX
      )
    }

    return SEARCH_SEARCH_BAR_HEIGHT_PX
  }, [stepType, isExistCategorySuggestions])

  useEffect(() => {
    let hasEvent = false

    function onChange() {
      setMoved(true)
    }

    function addEventListener() {
      map?.on('moveend', onChange)
      map?.on('zoomend', onChange)
      hasEvent = true
    }

    function removeEventListener() {
      if (hasEvent) {
        map?.off('moveend', onChange)
        map?.off('zoomend', onChange)
        hasEvent = false
      }
    }

    map?.once('touchstart', addEventListener)
    map?.once('wheel', addEventListener)

    return () => {
      removeEventListener()
    }
  }, [map])

  const handleClick = () => {
    setMoved(false)

    clickedSearchResultChangeBoundaryButtonLog()

    if (stepType === 'searchedPreview') {
      stepBack()
    }

    boundsReSearch()
  }

  if (!moved) {
    return null
  }

  return (
    <ExtendedFab
      UNSAFE_className={css.retryButton}
      UNSAFE_style={assignInlineVars({
        [css.searchAreaTopHeight]: `${searchAreaTopHeight}px`,
      })}
      variant="overImage"
      icon={<IconArrowClockwiseCircularFill width={16} height={16} />}
      onClick={handleClick}
    >
      이 지역 검색하기
    </ExtendedFab>
  )
}

export default ReSearchAreaButton
