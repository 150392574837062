import { type V1BusinessPost } from '@daangn/local-map-api'
import { useMemo, useRef } from 'react'

import { getBigPictureImageUrl } from '@src/core/components/big-picture/getBigPictureImageUrl'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { getDateDiffFromNow } from '@src/core/utils/date'
import { extractPostContent } from '@src/pages/home-feed-step/_utils/bottomSheetUtil'
import * as css from '@src/pages/home-feed-step/section/bottomSheetPostSection.css'
import {
  FOLLOWER_POST_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver, {
  type LocalMapLogType,
} from '@src/services/log/LocalMapImpressionObserver'

interface SectionFollowPostItemProps {
  followPost: V1BusinessPost
  index: number
}

const REFERRER = genSectionReferrer(FOLLOWER_POST_SECTION_INFO.sectionKey)

const SectionFollowPostItem = ({
  followPost,
  index,
}: SectionFollowPostItemProps) => {
  const {
    getImpressedHomeFeedSectionItemLogParams,
    clickedHomeFeedSectionItemLog,
  } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const itemRef = useRef<HTMLDivElement>(null)

  const thumbnailImageUrl = followPost.imagesUrls[0]

  const { openTarget } = useLocalMapOpenTarget()

  const handleClick = () => {
    clickedHomeFeedSectionItemLog({
      itemIndex: index,
      itemDataType: 'post',
      itemId: followPost.id,
      ...FOLLOWER_POST_SECTION_INFO,
      ...getMapLogParams(),
    })

    openTarget({
      targetUri: `${followPost.targetUri}`,
      referrer: REFERRER,
    })

    if (followPost.clickLoggingUrl) {
      fetch(followPost.clickLoggingUrl, {
        method: 'POST',
      })
    }
  }

  const formattedCreatedAt = `${getDateDiffFromNow(followPost.createdAt)} 전`

  const impressionLogParams = useMemo<LocalMapLogType>(
    () =>
      getImpressedHomeFeedSectionItemLogParams({
        itemDataType: 'post',
        itemIndex: index,
        itemId: followPost.id,
        ...FOLLOWER_POST_SECTION_INFO,
      }),
    [getImpressedHomeFeedSectionItemLogParams, index, followPost.id]
  )

  return (
    <LocalMapImpressionObserver
      log={impressionLogParams}
      observeTargetRef={itemRef}
    >
      <article className={css.container} ref={itemRef}>
        <div className={css.flexContainer} onClick={handleClick}>
          {thumbnailImageUrl && (
            <div className={css.imageWrapper}>
              <img
                loading="lazy"
                alt={followPost.title}
                className={css.image}
                src={getBigPictureImageUrl(thumbnailImageUrl, {
                  s: {
                    width: 94 * 3,
                    height: 94 * 3,
                  },
                })}
              />
              <div className={css.imageBorder} />
            </div>
          )}
          <div className={css.main}>
            <div className={css.bio}>
              <span className={css.bioLabel({ type: 'profileName' })}>
                {followPost.localProfile?.name}
              </span>
              <span className={css.bioLabel({ type: 'category' })}>
                {followPost.localProfile?.categoryName}
              </span>
            </div>
            <div className={css.postTitle}>{followPost.title}</div>
            <div className={css.postContent}>
              {extractPostContent(followPost.content)}
            </div>
            <div className={css.captions}>
              <span className={css.caption}>{formattedCreatedAt}</span>
            </div>
          </div>
        </div>
      </article>
    </LocalMapImpressionObserver>
  )
}

export default SectionFollowPostItem
