import { IS_PRODUCTION } from '@src/services/constants/constants'

export const FISH_SHAPED_BREAD_CATEGORY_ID = IS_PRODUCTION ? 3379 : 4101
export function isFishShapedBreadCategoryId(categoryId: number) {
  return categoryId === FISH_SHAPED_BREAD_CATEGORY_ID
}

export const FISH_SHAPED_BREAD_ALPHA_THEME_ID = '391'
export const FISH_SHAPED_BREAD_PRODUCTION_THEME_ID = '442'
export const FISH_SHAPED_BREAD_THEME_ID = IS_PRODUCTION
  ? FISH_SHAPED_BREAD_PRODUCTION_THEME_ID
  : FISH_SHAPED_BREAD_ALPHA_THEME_ID
export function isFishShapedBreadThemeId(themeId: string) {
  return themeId === FISH_SHAPED_BREAD_THEME_ID
}
