import { useMemo } from 'react'

import { useBottomSheetHeight } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { getMiddleOffset } from '@src/services/constants/anchor'
import { MAP_TOP_AREA_SIZE_MAPPER } from '@src/services/constants/homeActivitySizeMapper'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

/** 검색 bounds 잡을 때 패딩값(px) */
const SEARCH_AREA_PADDING_PX = 16

/**
 * 지도 뷰포트에 보이는 영역의 패딩값을 반환합니다.
 * - 상단은 헤더 (검색 input, 추천 칩) 높이만큼
 * - 하단은 bottomSheet 높이만큼
 * - 좌우는 16px만큼 - 핀이 잘리지 않을 정도의 적절한 패딩값
 */
export const useVisibleMapViewportPadding = (
  additionalPadding = SEARCH_AREA_PADDING_PX
) => {
  const { stepType } = useStepRouter()
  const bottomSheetHeight = useBottomSheetHeight()

  return useMemo(
    () => ({
      top: MAP_TOP_AREA_SIZE_MAPPER[stepType] + additionalPadding,
      bottom:
        Math.min(bottomSheetHeight, getMiddleOffset()) + additionalPadding,
      left: additionalPadding,
      right: additionalPadding,
    }),
    [stepType, additionalPadding, bottomSheetHeight]
  )
}
