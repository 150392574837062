import * as css from './BannerBasicWithTitle.css'

type Props = {
  title: string
  body: string
  thumbnail: string
  onClick: () => void
}

export default function BannerBasicWithTitle({
  title,
  body,
  thumbnail,
  onClick,
}: Props) {
  return (
    <div className={css.container} onClick={onClick}>
      <div className={css.contents}>
        <div className={css.title}>{title}</div>
        <div className={css.body}>{body}</div>
      </div>

      <img className={css.thumbnail} src={thumbnail} />
    </div>
  )
}
