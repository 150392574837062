import { CLIENT_VERSION, STAGE } from '@src/services/constants/constants'

declare global {
  interface Window {
    clarity?: (type: string, ...args: any[]) => void
  }
}

export const setClarityUserConfig = ({
  userId,
  deviceIdentity,
  region,
  referrer,
  userAgent,
  onError,
}: {
  userId: string
  deviceIdentity?: string
  region?: string
  referrer?: string
  userAgent?: string
  onError?: (err: Error) => void
}) => {
  setTimeout(() => {
    try {
      window.clarity?.('identify', userId)
      window.clarity?.('set', 'referrer', referrer)
      window.clarity?.('set', 'deviceIdentity', deviceIdentity)
      window.clarity?.('set', 'region', region)
      window.clarity?.('set', 'clientVersion', CLIENT_VERSION)
      window.clarity?.('set', 'env', STAGE)
      window.clarity?.('set', 'userAgent', userAgent)
    } catch (err: any) {
      if (err) {
        onError?.(err)
      }
    }
  }, 500)
}
