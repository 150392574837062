import {
  IconEraserHorizlineLine,
  IconTrashcanLine,
} from '@daangn/react-monochrome-icon'
import { ChipToggleButton } from '@daangn/sprout-components-chips'
import { useActions } from '@stackflow/react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useUserLocation } from '@src/features/user-location/hooks/useUserLocation'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { useSearchAddressByPointQuery } from '@src/pages/user-location-search/hooks/useSearchAddressByPointQuery'
import { useToastSnackbar } from '@src/core/lib/bridge/hooks/useToastSnackbar'
import { useSubscribeToGlobalEventCallback } from '@src/services/GlobalEventBus'

import * as css from './UserLocationEditCard.css'

export const UserLocationEditCard = () => {
  const { push } = useActions()
  const { map } = useMap()
  const { stepType, stepReplace, getValidatedParams } = useStepRouter()
  const { userLocationHome } = useUserLocation()
  const { openSnackbar } = useToastSnackbar()
  const setDistancedCoordinates = coordinatesStore(
    (s) => s.setDistancedCoordinates
  )
  const subscribeToCompleteUserLocationMutation =
    useSubscribeToGlobalEventCallback({
      type: 'COMPLETED_USER_LOCATION_MUTATION',
    })
  const subscribeToCompleteUserLocationDelete =
    useSubscribeToGlobalEventCallback({
      type: 'COMPLETED_USER_LOCATION_DELETE',
    })
  const { data: addressFetchData } = useSearchAddressByPointQuery({
    latitude: userLocationHome?.point?.latitude,
    longitude: userLocationHome?.point?.longitude,
  })

  // NOTE: 도로명주소 > 지번주소 의 우선순위를 가져요.
  const displayText =
    addressFetchData?.roadAddr || addressFetchData?.jibunAddr || ''

  const handleClickEdit = () => {
    if (
      !userLocationHome?.point.latitude ||
      !userLocationHome?.point.longitude
    ) {
      return
    }

    push('UserLocationInMap', {
      centerLatitude: userLocationHome.point.latitude,
      centerLongitude: userLocationHome.point.longitude,
      actionType: 'UPDATE',
      funnelFrom: 'LOCAL_MAP',
      locationTermsAgreementStatus: 'AGREED',
    })

    subscribeToCompleteUserLocationMutation((payload) => {
      if (
        payload?.data?.coordinates?.latitude &&
        payload?.data?.coordinates?.longitude
      ) {
        map?.setCenter({
          lat: parseFloat(payload.data.coordinates.latitude),
          lng: parseFloat(payload.data.coordinates.longitude),
        })

        setDistancedCoordinates({
          type: 'userLocation',
          coordinates: {
            latitude: parseFloat(payload.data.coordinates.latitude),
            longitude: parseFloat(payload.data.coordinates.longitude),
          },
        })

        if (stepType === 'searchResult') {
          const params = getValidatedParams('searchResult')

          stepReplace({
            step: stepType,
            params: {
              query: params.query,
              selectedPin: null,
              queryFrom: params.queryFrom,
            },
          })
        } else if (stepType === 'home') {
          stepReplace({
            step: stepType,
            params: {
              selectedPin: null,
            },
          })
        }

        openSnackbar({
          body: '우리집 주소를 수정했어요.',
        })
      }
    })
  }

  const handleClickDelete = () => {
    if (!userLocationHome?.encryptedId) return

    push('ModalsHome', {
      modalType: 'confirm-delete-user-location',
      userLocationId: userLocationHome.encryptedId,
    })

    subscribeToCompleteUserLocationDelete(({ data }) => {
      if (data.completed) {
        openSnackbar({
          body: '우리집 주소를 삭제했어요.',
        })

        if (stepType === 'searchResult') {
          const params = getValidatedParams('searchResult')

          stepReplace({
            step: stepType,
            params: {
              query: params.query,
              selectedPin: null,
              queryFrom: params.queryFrom,
            },
          })
        } else if (stepType === 'home') {
          stepReplace({
            step: stepType,
            params: {
              selectedPin: null,
            },
          })
        }
      }
    })
  }

  return (
    <div className={css.container}>
      <div className={css.textContainer}>
        <div className={css.title}>우리집</div>
        <div className={css.description}>{displayText}</div>
      </div>
      <div className={css.buttonContainer}>
        <ChipToggleButton
          isSelected={false}
          size={'small'}
          onClick={handleClickEdit}
        >
          <div className={css.buttonInner}>
            <IconEraserHorizlineLine size={14} /> 수정
          </div>
        </ChipToggleButton>
        <ChipToggleButton
          isSelected={false}
          size={'small'}
          onClick={handleClickDelete}
        >
          <div className={css.buttonInner}>
            <IconTrashcanLine size={14} />
            삭제
          </div>
        </ChipToggleButton>
      </div>
    </div>
  )
}
