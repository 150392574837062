import { useStepRouter } from '@src/services/hooks/useStepRouter'

export function useFrom() {
  const { stepType } = useStepRouter()

  const isFromSearch = stepType.includes('search')
  const from = isFromSearch ? 'search' : 'home'

  return { from }
}
