import { IconArrowUpRightArrowDownLeftLine } from '@daangn/react-monochrome-icon'
import { ExtendedFab } from '@daangn/sprout-components-fab'
import { vars } from '@seed-design/design-token'
import { useLayoutEffect, useReducer, useRef } from 'react'
import { createPortal } from 'react-dom'

import {
  useBottomSheetSnapPoint,
  useBottomSheetRef,
} from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { DEFAULT_ZOOM_LEVEL } from '@src/core/components/karrot-map/map/constants'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { type PropOf } from '@src/core/types/utilTypes'
import CurrentPositionButton from '@src/features/current-position/fab/CurrentPositionButton'
import { useMapPanTo } from '@src/features/map/hooks/useMapPanTo'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import { useSearchLogger } from '@src/services/log/hooks/useSearchLogger'

import ContributionButton from './contribution-button/ContributionButton'
import { useExtendBoundaryStore } from './extendStore'
import FloatingHomeShortcutButton from './FloatingHomeShortcutButton'
import * as css from './index.css'

const MapFloatingButtons = () => {
  const { snapPoint } = useBottomSheetSnapPoint()
  const { stepType } = useStepRouter()
  const bottomSheetRef = useBottomSheetRef()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { clickedHomeFloatingButtonLog } = useHomeLogger()
  const { map } = useMap()
  const { panToWithOffset } = useMapPanTo(map)
  const { clickedSearchResultFloatingButtonLog } = useSearchLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const distancedCoordinates = coordinatesStore(
    (s) => s.setDistancedCoordinates
  )
  const { showExtend, onExtend } = useExtendBoundaryStore()

  const homeFloatingButtonRef = useRef<HTMLElement>(null)

  const handleClickCurrentPosition: PropOf<
    typeof CurrentPositionButton,
    'onClick'
  > = (geolocation) => {
    if (geolocation?.currentPosition) {
      panToWithOffset({
        center: {
          lat: geolocation.currentPosition.lat,
          lng: geolocation.currentPosition.lng,
        },
        zoom: DEFAULT_ZOOM_LEVEL,
      })

      distancedCoordinates({
        type: 'currentPosition',
        coordinates: {
          longitude: geolocation.currentPosition.lng,
          latitude: geolocation.currentPosition.lat,
        },
      })
    }

    switch (stepType) {
      case 'searchResult':
        clickedSearchResultFloatingButtonLog({
          actionType: 'current_location',
        })
        break
      case 'home':
        clickedHomeFloatingButtonLog({
          actionType: 'current_location',
          ...getMapLogParams(),
        })
        break
    }
  }

  // Zustand로 bottom sheet를 사용하면서, MapFloatingButtons가 초기 마운팅되는 시점에
  // bottomSheetRef.current의 값이 보장되지 않아요.
  // LayoutEffect 타이밍에 state를 강제로 업데이트하여 bottomSheetRef.current값을 다시 참조하도록 해요.
  useLayoutEffect(() => {
    setTimeout(() => {
      forceUpdate()
    }, 0)
  }, [stepType])

  if (!bottomSheetRef.current || snapPoint === 'top') {
    return null
  }

  return createPortal(
    <div className={css.container}>
      <div className={css.position}>
        <FloatingHomeShortcutButton ref={homeFloatingButtonRef} />
        <CurrentPositionButton onClick={handleClickCurrentPosition} />
      </div>
      {stepType === 'home' && (
        <div className={css.contributionButton}>
          <ContributionButton />
        </div>
      )}
      {showExtend && (
        <div className={css.center}>
          <ExtendedFab
            icon={<IconArrowUpRightArrowDownLeftLine />}
            size="small"
            UNSAFE_style={{
              color: vars.$scale.color.gray900,
              background: vars.$semantic.color.paperFloating,
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.28)',
            }}
            onClick={onExtend}
          >
            먼 동네 업체도 보기
          </ExtendedFab>
        </div>
      )}
    </div>,
    bottomSheetRef.current
  )
}

export default MapFloatingButtons
