import { IconCheckmarkShieldFill } from '@daangn/react-monochrome-icon'
import { HelpBubbleAnchor } from '@daangn/sprout-components-help-bubble'
import { vars } from '@seed-design/design-token'
import { type SVGProps } from 'react'

import { useDisplayOnceStore } from '@src/services/store/displayOnceStore'

interface CertificatedBadgeProps extends SVGProps<SVGSVGElement> {
  parentRef?: React.RefObject<HTMLElement>
  onOutsideClick?: () => void
  enableHelpBubble?: boolean
}

const CertificatedBadge = ({
  parentRef,
  onOutsideClick,
  enableHelpBubble,
  ...props
}: CertificatedBadgeProps) => {
  const { isShowLPCertificatedBubble, showLPCertificatedBubble } =
    useDisplayOnceStore((s) => ({
      isShowLPCertificatedBubble: s.isShowLPCertificatedBubble,
      showLPCertificatedBubble: s.showLPCertificatedBubble,
    }))

  const handleClickOutsideBubble = () => {
    showLPCertificatedBubble()
    onOutsideClick?.()
  }

  return enableHelpBubble ? (
    <HelpBubbleAnchor
      portalled={false}
      title="업체 정보가 확인된 장소예요."
      isOpen={!isShowLPCertificatedBubble}
      positioning={{
        placement: 'bottom',
      }}
      onInteractOutside={handleClickOutsideBubble}
      isModal={false}
      closeOnInteractOutside={true}
    >
      <IconCheckmarkShieldFill
        width="15"
        height="15"
        color={vars.$scale.color.blue500}
        {...props}
      />
    </HelpBubbleAnchor>
  ) : (
    <IconCheckmarkShieldFill
      width="15"
      height="15"
      color={vars.$scale.color.blue500}
      {...props}
    />
  )
}

export default CertificatedBadge
