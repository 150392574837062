import { useQuery } from '@tanstack/react-query'

import { localMapApi } from '@src/services/api'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

export function usePreviewQuery(localProfileId: string) {
  const bridgeInfo = useBridgeInfo()

  return useQuery({
    queryKey: ['preview', localProfileId],
    queryFn: () => {
      return localMapApi(bridgeInfo).localMapServiceGetLocalProfilePreview({
        localProfileId,
      })
    },
    enabled: /^\d+$/.test(localProfileId),
  })
}
