import { type RecipeVariants } from '@vanilla-extract/recipes'
import classNames from 'classnames'
import {
  Children,
  forwardRef,
  Fragment,
  memo,
  type ReactNode,
  useCallback,
} from 'react'

import { TAG_GROUP_SIZE } from './constants'
import { TagDivider } from './TagDivider'
import type * as dividerCss from './TagDivider.css'
import * as css from './TagGroup.css'

type TagGroupProps = {
  children: ReactNode
  [TAG_GROUP_SIZE.SMALL]?: boolean
  [TAG_GROUP_SIZE.MEDIUM]?: boolean
  color?: NonNullable<RecipeVariants<typeof dividerCss.dotDivider>>['color']
  isSelected: boolean
  className?: string
}

const TagGroup = forwardRef<HTMLDivElement, TagGroupProps>(
  (
    { small = false, medium = true, color, isSelected, className, ...props },
    ref
  ) => {
    const renderTagsWithDotDivider = useCallback(() => {
      return Children.toArray(props.children)
        .filter((child) => Boolean(child))
        .map((child, index, children) => {
          const lastIndexOfChildren = children.length - 1
          if (index < lastIndexOfChildren) {
            return (
              <Fragment key={index}>
                {child}
                <TagDivider
                  size={small ? TAG_GROUP_SIZE.SMALL : TAG_GROUP_SIZE.MEDIUM}
                />
              </Fragment>
            )
          }
          return child
        })
    }, [props.children, small])
    return (
      <div
        ref={ref}
        className={classNames(css.tagGroup({ isSelected }), className)}
        color={color}
      >
        {renderTagsWithDotDivider()}
      </div>
    )
  }
)

TagGroup.displayName = 'TagGroup'
export default memo(TagGroup)
