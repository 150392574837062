import { IconPlusFill } from '@daangn/react-monochrome-icon'
import { PrimaryFab } from '@daangn/sprout-components-fab'
import { useMemo, useRef } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useContributionBottomSheetHandler } from '@src/features/contribution/hooks/useContributionBottomSheetHandler'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

const ContributionButton = () => {
  const { handleOpen } = useContributionBottomSheetHandler()
  const {
    getImpressedHomeContributionFabLogParams,
    clickedHomeContributionFABLog,
  } = useHomeLogger()
  const { map } = useMap()
  const ref = useRef<HTMLButtonElement>(null)
  const impressedLogParams = useMemo(
    () =>
      getImpressedHomeContributionFabLogParams({
        center_coordinates: {
          longitude: map?.getCenter().lng || 0,
          latitude: map?.getCenter().lat || 0,
        },
        zoom_level: map?.getZoom() || 0,
      }),
    [getImpressedHomeContributionFabLogParams, map]
  )

  return (
    <LocalMapImpressionObserver log={impressedLogParams} observeTargetRef={ref}>
      <PrimaryFab
        UNSAFE_style={{
          boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.16)',
        }}
        ref={ref}
        isExtended={true}
        icon={<IconPlusFill />}
        aria-label="추가하기"
        onClick={() => {
          clickedHomeContributionFABLog({
            center_coordinates: {
              longitude: map?.getCenter().lng || 0,
              latitude: map?.getCenter().lat || 0,
            },
            zoom_level: map?.getZoom() || 0,
          })
          handleOpen()
        }}
      >
        추가하기
      </PrimaryFab>
    </LocalMapImpressionObserver>
  )
}

export default ContributionButton
