import { type LocalMapBridgeSchema } from '@daangn/local-map-bridge/lib/__generated__/makeLocalMapBridge'
import { useMemo } from 'react'

import { satisfyVersion } from '@src/core/utils/versionCheck'

import { localMapBridge } from '../bridge'
import { useBridgeInfo } from '../context/BridgeInfoProvider'

// 이전 앱버전의 내근처에서 리다이렉트 되는 상황 고려
const IOS_VERSION = '>=24.8.0'
const ANDROID_VERSION = '>=24.8.0'

interface useSubscribeTabClickProps {
  onTabClick: (
    error: Error | null,
    response:
      | LocalMapBridgeSchema['ReqLocalMapTabClickEventSubscribeResponse']
      | null
  ) => void
}

export function useSubscribeTabClick({
  onTabClick,
}: useSubscribeTabClickProps) {
  const {
    app: { os, version },
  } = useBridgeInfo()

  const isEnableSubscribeTabClick = useMemo(
    () =>
      satisfyVersion({
        os,
        version,
        androidVersionCheck: ANDROID_VERSION,
        iosVersionCheck: IOS_VERSION,
      }),
    [os, version]
  )

  if (isEnableSubscribeTabClick) {
    localMapBridge?.subscribeTabClick({}, onTabClick)
  }
}
