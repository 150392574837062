import { type LngLatNullable, type LngLat } from '@src/core/types/geo'

function toDistanceFromLatLonInKm(lngLat1: LngLat, lngLat2: LngLat) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lngLat2.lat - lngLat1.lat) // deg2rad below
  const dLon = deg2rad(lngLat2.lng - lngLat1.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lngLat1.lat)) *
      Math.cos(deg2rad(lngLat2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km

  return d
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}

export function toDistanceTextFromLatLonInKm(
  lngLat1: LngLatNullable,
  lngLat2: LngLatNullable
) {
  if (!lngLat2 || !lngLat2.lat || !lngLat2.lng) {
    return null
  }

  if (!lngLat1 || !lngLat1.lat || !lngLat1.lng) {
    return null
  }

  const distance = toDistanceFromLatLonInKm(lngLat1, lngLat2)

  if (distance > 1) {
    return `${Math.floor(distance * 10) / 10}km`
  }

  return `${Math.floor(distance * 1000)}m`
}
