import { datadogRum } from '@datadog/browser-rum-slim'
import { type MapMouseEvent } from 'maplibre-gl'
import { useCallback } from 'react'

import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { Map } from '@src/core/components/karrot-map'
import {
  type MapLibreEventTypeWithLocalMapAction,
  type MapType,
} from '@src/core/components/karrot-map/map/Map'
import { CurrentPositionMarker } from '@src/core/components/karrot-map/marker/CurrentPositionMarker'
import { type Geolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import { getLocalProfileId } from '@src/features/local-profile/getLocalProfileId'
import MapContents from '@src/features/map/components/map-contents/MapContents'
import MapFloatingButtons from '@src/features/map/components/map-floating-buttons'
import { useInitialMapState } from '@src/features/map/hooks/useInitialMapState'
import { useMapMoved } from '@src/features/map/store/mapMoved'
import { UserLocationMarkers } from '@src/features/user-location/markers/UserLocationMarkers'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useLpSearchLogger } from '@src/services/log/hooks/useLpSearchLogger'
import { useZeroDepthContextLogger } from '@src/services/log/hooks/useZeroDepthContextLogger'
import { logLevel } from '@src/services/log/logLevel'
import { HOME_Z_INDEX } from '@src/services/styles/zIndex/homeZIndex'

export const HomeMapFragment = ({
  geolocation,
  onMapDraggingChange,
}: {
  geolocation: Geolocation
  onMapDraggingChange: (isMapDragging: boolean) => void
}) => {
  const { stepType, params, getValidatedParams } = useStepRouter()
  const { stepMove, stepBack } = useLocalMapStepMovePolicy()
  const { clickedLocalMapHomePickPoiV1Log } = useZeroDepthContextLogger()
  const { setSnapPoint } = useBottomSheetSnapPoint()
  const { clickedLocalMapSearchResultPickPoiV1 } = useLpSearchLogger()
  const { setMapMoved } = useMapMoved((s) => ({
    setMapMoved: s.setMapMoved,
  }))
  const initialMapState = useInitialMapState()

  const handleMapClick = useCallback(
    (map: maplibregl.Map, e: MapMouseEvent) => {
      const localProfileId = getLocalProfileId(map, e.point)

      if (params.selectedPin) {
        // 이전 상태로 돌아가기
        stepBack()

        return
      }

      switch (stepType) {
        case 'home': {
          if (localProfileId) {
            clickedLocalMapHomePickPoiV1Log({
              item_id: localProfileId,
              item_data_type: 'local_profile',
              zoom_level: map.getZoom(),
            })

            stepMove('home', 'click_pickablePoi', {
              localProfileId,
            })
          } else {
            setSnapPoint('bottom')
          }

          break
        }
        case 'searchResult': {
          if (localProfileId) {
            clickedLocalMapSearchResultPickPoiV1({
              item_id: localProfileId,
              item_data_type: 'local_profile',
              zoom_level: map.getZoom(),
            })

            const { query, queryFrom } = getValidatedParams('searchResult')

            stepMove('searchResult', 'click_pickablePoi', {
              localProfileId,
              query,
              queryFrom,
              selectedPin: null,
            })
          } else {
            setSnapPoint('bottom')
          }

          break
        }
        case 'preview': {
          if (localProfileId) {
            clickedLocalMapHomePickPoiV1Log({
              item_id: localProfileId,
              item_data_type: 'local_profile',
              zoom_level: map.getZoom(),
            })

            stepMove('preview', 'click_pickablePoi', {
              localProfileId,
            })
          } else {
            stepBack()
          }

          break
        }
        case 'searchedPreview': {
          if (localProfileId) {
            clickedLocalMapSearchResultPickPoiV1({
              item_id: localProfileId,
              item_data_type: 'local_profile',
              zoom_level: map.getZoom(),
            })

            const { query, queryFrom } = getValidatedParams('searchedPreview')

            stepMove('searchedPreview', 'click_pickablePoi', {
              localProfileId,
              query,
              queryFrom,
              selectedPin: null,
            })
          } else {
            stepBack()
          }

          break
        }
      }
    },
    [
      params,
      stepType,
      stepMove,
      clickedLocalMapHomePickPoiV1Log,
      setSnapPoint,
      clickedLocalMapSearchResultPickPoiV1,
      getValidatedParams,
      stepBack,
    ]
  )

  const handleMapDragStart = useCallback(() => {
    // Pickalbe 상태에서 지도 드래그를 했을 때에는 바텀시트를 내리지 않아요 (피커블 하기 전 바텀시트 상태 저장)
    if (stepType === 'preview' || stepType === 'searchedPreview') {
      return
    }

    setSnapPoint('bottom')
    onMapDraggingChange(true)
  }, [onMapDraggingChange, setSnapPoint, stepType])

  const handleMapDragEnd = useCallback(() => {
    onMapDraggingChange(false)
  }, [onMapDraggingChange])

  const handleMapMoveEnd = useCallback(
    (map: MapType, e: MapLibreEventTypeWithLocalMapAction) => {
      if (e.action === 'prevent-bound-change') {
        return
      }

      setMapMoved(true)
    },
    [setMapMoved]
  )

  const handleMapInitialLoaded = useCallback(() => {
    const renderTime = Number(performance.now().toFixed(2))

    datadogRum.addAction('MCP', {
      renderTime,
    })

    logLevel.info('map renderTime', `${renderTime}ms`, `${renderTime / 1000}s`)
  }, [])

  return (
    <>
      <Map
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          zIndex: HOME_Z_INDEX.MAP_LAYER,
        }}
        onMapLoaded={handleMapInitialLoaded}
        onMapMoveEnd={handleMapMoveEnd}
        onMapDragStart={handleMapDragStart}
        onMapDragEnd={handleMapDragEnd}
        onMapClick={handleMapClick}
        initialState={initialMapState}
      >
        {geolocation.currentPosition && (
          <CurrentPositionMarker lngLat={geolocation.currentPosition} />
        )}

        {stepType.startsWith('search') && <MapContents />}

        <UserLocationMarkers />
      </Map>

      <MapFloatingButtons />
    </>
  )
}
