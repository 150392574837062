import { type V1ListUserLocationsResponse } from '@daangn/local-map-api'
import { useMemo } from 'react'

import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'

import { createUserLocation } from '../api/createUserLocation'
import { deleteUserLocationById } from '../api/deleteUserLocation'
import { getUserLocation } from '../api/getUserLocation'
import { updateUserLocation } from '../api/updateUserLocation'
import { LOCAL_STORAGE_CACHE_KEY } from '../constants/constants'
import UserLocationAPIContext, {
  type UnpackPromise,
  type UserLocationAPIProps,
  type UserLocationAPIType,
} from '../contexts/userLocationAPIContext'
import { type UserLocationType } from '../contexts/userLocationContext'

export const UserLocationAPIProvider = ({
  children,
  bridgeInfo,
  setUserLocation,
  useLocalStorageCache,
}: {
  children: React.ReactNode
  bridgeInfo: BridgeInfoType
  setUserLocation: React.Dispatch<React.SetStateAction<UserLocationType>>
  useLocalStorageCache?: boolean
}) => {
  const apis: UserLocationAPIType = useMemo(
    () => ({
      refetchUserLocation: makeLocationAPIFunction(
        getUserLocation,
        bridgeInfo,
        (result) => {
          setUserLocation((prev) => {
            if (!prev) {
              return prev
            }

            const nextUserLocation: V1ListUserLocationsResponse = {
              ...prev,
              ...result,
            }

            if (useLocalStorageCache) {
              localStorage.setItem(
                LOCAL_STORAGE_CACHE_KEY,
                JSON.stringify(nextUserLocation)
              )
            }

            return nextUserLocation
          })
        }
      ),
      updateUserLocation: makeLocationAPIFunction(
        updateUserLocation,
        bridgeInfo,
        (result) => {
          setUserLocation((prev) => {
            if (!prev) {
              return prev
            }

            const nextUserLocation = {
              ...prev,
              userLocations: prev.userLocations.map((userLocation) => {
                if (
                  userLocation.encryptedId === result.userLocation.encryptedId
                ) {
                  return result.userLocation
                }
                return userLocation
              }),
            }

            if (useLocalStorageCache) {
              localStorage.setItem(
                LOCAL_STORAGE_CACHE_KEY,
                JSON.stringify(nextUserLocation)
              )
            }

            return nextUserLocation
          })
        }
      ),
      createUserLocation: makeLocationAPIFunction(
        createUserLocation,
        bridgeInfo,
        (result) => {
          setUserLocation((prev) => {
            if (!prev) {
              return prev
            }

            const nextUserLocation = {
              ...prev,
              userLocations: [...prev.userLocations, result.userLocation],
            }

            if (useLocalStorageCache) {
              localStorage.setItem(
                LOCAL_STORAGE_CACHE_KEY,
                JSON.stringify(nextUserLocation)
              )
            }

            return nextUserLocation
          })
        }
      ),
      deleteUserLocation: makeLocationAPIFunction(
        deleteUserLocationById,
        bridgeInfo,
        (_, params) => {
          setUserLocation((prev) => {
            if (!prev) {
              return prev
            }

            const existUserLocation = prev.userLocations.find(
              (prevUserLocation) =>
                prevUserLocation.encryptedId === params.data.encryptedId
            )

            if (!existUserLocation) {
              return prev
            }

            const nextUserLocation = {
              ...prev,
              userLocations: prev.userLocations.filter(
                (userLocation) =>
                  userLocation.encryptedId !== params.data.encryptedId
              ),
            }

            if (useLocalStorageCache) {
              localStorage.setItem(
                LOCAL_STORAGE_CACHE_KEY,
                JSON.stringify(nextUserLocation)
              )
            }

            return nextUserLocation
          })
        }
      ),
    }),
    [bridgeInfo, setUserLocation, useLocalStorageCache]
  )

  return (
    <UserLocationAPIContext.Provider value={apis}>
      {children}
    </UserLocationAPIContext.Provider>
  )
}

function makeLocationAPIFunction<T extends (...args: any) => any>(
  apiFunc: T,
  bridgeInfo: BridgeInfoType,
  callback?: (
    result: UnpackPromise<ReturnType<T>>,
    apiParams: Parameters<T>[0]
  ) => void
) {
  return async (
    props: UserLocationAPIProps<Parameters<T>[0], UnpackPromise<ReturnType<T>>>
  ) => {
    const { onError, onSuccess, ...rest } = props

    try {
      // API 함수에 bridgeInfo와 나머지 매개변수를 전달하여 호출
      const result = await apiFunc({ bridgeInfo, ...rest })

      callback?.(result, rest)

      onSuccess?.(result)
    } catch (error) {
      onError?.(error as Error)
    }
  }
}
