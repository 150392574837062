import {
  type V1LaunchpadItemTag,
  type V1LaunchpadItem,
} from '@daangn/local-map-api'

import { useDisplayOnceStore } from '@src/services/store/displayOnceStore'

const TOOLTIP_PRIORITY = [
  'LAUNCHPAD_ITEM_TAG_FIRST_ORDER_BENEFIT',
] as const satisfies V1LaunchpadItemTag[]

type TooltipTag = (typeof TOOLTIP_PRIORITY)[number]

const TOOLTIP_MAP: Record<TooltipTag, string> = {
  LAUNCHPAD_ITEM_TAG_FIRST_ORDER_BENEFIT: '첫 주문 10% 할인',
}

export const useLaunchpadTooltip = (padItem: V1LaunchpadItem) => {
  const tooltipTag = getTooltipTag(padItem.itemTags)

  const { isShowLaunchpadItemBubble, showLaunchpadItemBubble } =
    useDisplayOnceStore((s) => ({
      isShowLaunchpadItemBubble: s.isShowLaunchpadItemBubble,
      showLaunchpadItemBubble: s.showLaunchpadItemBubble,
    }))

  if (!tooltipTag) {
    return {
      tooltip: null,
      dismissTooltip: null,
    }
  }

  return {
    tooltip: isShowLaunchpadItemBubble[padItem.name]
      ? null
      : TOOLTIP_MAP[tooltipTag],
    dismissTooltip: () => {
      showLaunchpadItemBubble(padItem.name)
    },
  }
}

const getTooltipTag = (itemTags: V1LaunchpadItemTag[]) => {
  return TOOLTIP_PRIORITY.find((tooltip) => itemTags.includes(tooltip))
}
