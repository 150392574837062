import { create } from 'zustand'

import { logLevel } from '@src/services/log/logLevel'

import { type ExperimentsState, type Experiment } from './coreTypes'
import { type FrontDrivenExperimentKey } from './frontDrivenExperiment'
import { type ServerDrivenExperimentKey } from './serverDrivenExperiment'

export type LiveExperimentKey =
  | FrontDrivenExperimentKey
  | ServerDrivenExperimentKey

type LiveExperimentState = ExperimentsState<LiveExperimentKey>
type LiveExperiment = Experiment<LiveExperimentKey>

interface ExperimentStore {
  experiments: LiveExperimentState | null
  setExperiments: (experiments: LiveExperimentState) => void
  setExperiment: (
    experimentKey: LiveExperimentKey,
    nextExperiment: LiveExperiment
  ) => LiveExperiment
  getExperiment: (experimentKey: LiveExperimentKey) => LiveExperiment | null
}

export const useExperimentStore = create<ExperimentStore>((set, get) => ({
  experiments: null,
  setExperiments: (experiments) => {
    set(() => ({
      experiments,
    }))
  },
  setExperiment: (experimentKey, nextExperiment) => {
    const experiments = get().experiments

    if (!experiments) {
      throw new Error('Experiments not initialized')
    }

    set(() => ({
      experiments: {
        ...experiments,
        [experimentKey]: nextExperiment,
      },
    }))

    return nextExperiment
  },
  getExperiment: (experimentKey) => {
    const experiment = get().experiments?.[experimentKey]

    if (!experiment) {
      logLevel.error(`Experiment not found: ${experimentKey}`)
      return null
    }

    return experiment
  },
}))

export const useExperiment = (experimentKey: LiveExperimentKey) => {
  const { getExperiment } = useExperimentStore()

  return getExperiment(experimentKey)
}
