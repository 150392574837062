import { type LngLat, type Coordinates } from '@src/core/types/geo'

import { hav, toRad } from './math'

const radiusOfEarth = 6378137

export function haversineDistance(a: Coordinates, b: Coordinates) {
  const aLat = toRad(Array.isArray(a) ? a[1] : a.latitude)
  const bLat = toRad(Array.isArray(b) ? b[1] : b.latitude)
  const aLng = toRad(Array.isArray(a) ? a[0] : a.longitude)
  const bLng = toRad(Array.isArray(b) ? b[0] : b.longitude)

  const ht =
    hav(bLat - aLat) + Math.cos(aLat) * Math.cos(bLat) * hav(bLng - aLng)
  return 2 * radiusOfEarth * Math.asin(Math.sqrt(ht))
}

export function formatDistanceInMeterToString(distance: number) {
  if (isNaN(distance)) return

  if (distance >= 1000) {
    const fixedDistance = (distance / 1000).toFixed(1)
    const splitedFixedDistance = fixedDistance.split('.')
    const isInteger = splitedFixedDistance[1] === '0'
    if (isInteger) {
      return `${splitedFixedDistance[0]}km`
    } else {
      return `${fixedDistance}km`
    }
  }

  if (distance > 1) {
    const fixedDistance = distance.toFixed(0)
    return `${fixedDistance}m`
  }

  return '1m'
}

export function isEqualCoordinates(
  a: Coordinates,
  b: Coordinates,
  precision = 6
) {
  return (
    a.latitude.toFixed(precision) === b.latitude.toFixed(precision) &&
    a.longitude.toFixed(precision) === b.longitude.toFixed(precision)
  )
}
