import { type V1LocalProfileWithBenefitSection } from '@daangn/local-map-api'
import { IconILowercaseSerifCircleLine } from '@daangn/react-monochrome-icon'
import { HelpBubbleTrigger } from '@daangn/sprout-components-help-bubble'
import { vars } from '@seed-design/design-token'
import React, { forwardRef } from 'react'

import BenefitCurationItem from './BenefitCurationItem'
import * as css from './SectionBenefitCuration.css'
import BottomSheetSection from '../lib/BottomSheetSection'

const SectionBenefitCuration = forwardRef<
  HTMLElement,
  { section: V1LocalProfileWithBenefitSection }
>(({ section }, ref) => {
  const { title, items, tooltipDescription } = section
  if (items.length < 4) {
    return null
  }
  return (
    <BottomSheetSection
      title={title}
      ref={ref}
      titleClassName={css.title}
      titleRightNode={
        <HelpBubbleTrigger
          title={tooltipDescription}
          portalled={false}
          UNSAFE_className={css.helpBubble}
        >
          <IconILowercaseSerifCircleLine
            size="1.125rem"
            color={vars.$scale.color.gray500}
            className={css.helpIcon}
          />
        </HelpBubbleTrigger>
      }
    >
      <ul className={css.carousel}>
        {items.map((item, index) => (
          <li key={`${item.localProfileId}-${item.couponBoxId}`}>
            <BenefitCurationItem item={item} index={index} />
          </li>
        ))}
      </ul>
    </BottomSheetSection>
  )
})

SectionBenefitCuration.displayName = 'SectionBenefitCuration'

export default SectionBenefitCuration
