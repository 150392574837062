import { type LngLatBounds } from 'maplibre-gl'

import { type MapLibreGLType, type MapType } from '../map/Map'

/**
 * 현재 맵의 bounds에 padding을 적용하여 반환합니다.
 */
export const getBoundsWithPadding = ({
  map,
  mapLibre,
  padding,
}: {
  map: MapType
  mapLibre: MapLibreGLType
  padding: {
    top: number
    right: number
    bottom: number
    left: number
  }
}): LngLatBounds => {
  const bounds = map.getBounds()

  const sw = map.project(bounds.getSouthWest())
  const ne = map.project(bounds.getNorthEast())

  const swWithOffset = map.unproject([
    sw.x + padding.left,
    sw.y - padding.bottom,
  ])
  const neWithOffset = map.unproject([ne.x - padding.right, ne.y + padding.top])

  const newBounds = new mapLibre.LngLatBounds(swWithOffset, neWithOffset)

  return newBounds
}
