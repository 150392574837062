import { type MapOptions } from 'maplibre-gl'

import { IS_PRODUCTION } from '@src/services/constants/constants'
import { type LngLat } from '@src/core/types/geo'

export const MAP_STYLE_URL = IS_PRODUCTION
  ? 'https://map.kr.karrotmarket.com/props/style.json' // TODO: use alpha default until launch
  : 'https://map.alpha.kr.karrotmarket.com/props/style.json'

export type T_INITIAL_MAP_STATE = Partial<Omit<MapOptions, 'center'>> & {
  showScaleControl: boolean
  zoom: number
  center: LngLat
}

// 신논현
export const INITIAL_MAP_CENTER: LngLat = {
  lng: 127.024514,
  lat: 37.504451,
}

export const DEFAULT_ZOOM_LEVEL = 15 // 100m

export const INITIAL_MAP_STATE: T_INITIAL_MAP_STATE = {
  center: {
    lng: INITIAL_MAP_CENTER.lng,
    lat: INITIAL_MAP_CENTER.lat,
  },
  zoom: DEFAULT_ZOOM_LEVEL,
  maxZoom: 20,
  minZoom: 5,
  interactive: true,
  showScaleControl: true,
}

export type MapCustomEventActionType = 'prevent-bound-change'

export const PIN_CUSTOM_LAYER_PREFIX = 'symbol-label'

export type GeolocationPoint = [longitude: number, latitude: number]
export type GeolocationBounds = [SW: GeolocationPoint, NE: GeolocationPoint]

export const DEFAULT_MAX_BOUNDS = Object.freeze<GeolocationBounds>([
  [123.75, 31.5095706009476],
  [132.1875, 39.72517767999295],
])
