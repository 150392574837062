import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import maplibregl from 'maplibre-gl'

import { type MaplibreSymbol } from '@src/core/components/karrot-map/map/useAutoHideSymbol'
import { type WithLngLat } from '@src/core/components/karrot-map/types'
import { type LngLatArray } from '@src/core/types/geo'
import {
  FISH_SHAPED_BREAD_THEME_ID,
  isFishShapedBreadThemeId,
} from '@src/features/fish-shaped-bread/isFishShapedBreadCategoryId'

import { type ClusterWithLeaves } from '../clustering/cluster'
import {
  DARK_THEME_ICON,
  DOT_DARK_THEME_ICON,
  DOT_THEME_ICON,
  THEME_ICON,
  PIN_ICON,
} from '../constant/theme'
import * as css from '../marker.css'
import { createMarkerText, isOverlapping } from '../utils'

interface CreateClusterPinMarkerContext {
  map: maplibregl.Map
  cluster: ClusterWithLeaves<V1SearchResultLocalProfile>
  label: string
  description?: string
  onClick?: (e: MouseEvent) => void
}

interface CreateClusterMarkerContext {
  map: maplibregl.Map
  cluster: ClusterWithLeaves<V1SearchResultLocalProfile>
  label: string
  description?: string
  onClick?: (e: MouseEvent) => void
}

interface CreateMarkerContext {
  localProfile: WithLngLat<V1SearchResultLocalProfile>
  onClick: ((e: MouseEvent) => void) | null
  isSelected: boolean
  colorTheme: 'dark' | 'light'
  map: maplibregl.Map
}

export const MarkerUtils = {
  createClusterPinMarker(
    context: CreateClusterPinMarkerContext
  ): maplibregl.Marker {
    const element = createClusterPinMarkerElement(context)

    return new maplibregl.Marker({ element, anchor: 'bottom' })
      .setLngLat(context.cluster.geometry.coordinates as LngLatArray)
      .addTo(context.map)
  },

  createClusterMarker(context: CreateClusterMarkerContext): maplibregl.Marker {
    const element = createClusterMarkerElement(context)

    return new maplibregl.Marker({ element, anchor: 'center' })
      .setLngLat(context.cluster.geometry.coordinates as LngLatArray)
      .addTo(context.map)
  },

  createMarker(context: CreateMarkerContext): maplibregl.Marker | null {
    if (!context.localProfile.geoPoint) {
      return null
    }

    const element = createMarkerElement(context)

    return new maplibregl.Marker({
      element,
      anchor: 'center',
    })
      .setLngLat([
        context.localProfile.geoPoint.longitude,
        context.localProfile.geoPoint.latitude,
      ])
      .addTo(context.map)
  },

  enabledCollision(markers: maplibregl.Marker[], enabled: boolean) {
    if (!enabled) {
      return
    }

    markers.slice(0, -1).forEach((markerA, i) => {
      markers.slice(i + 1).forEach((markerB) => {
        if (markerA.getElement().dataset.type === 'pin') {
          return
        }

        if (isOverlapping(markerA.getElement(), markerB.getElement())) {
          markerA.getElement().dataset.type = 'dot'
        }
      })
    })
  },

  *toSymbols(localProfiles: WithLngLat<V1SearchResultLocalProfile>[]) {
    for (const localProfile of localProfiles) {
      if (localProfile.lngLat?.lng && localProfile.lngLat?.lat) {
        yield {
          name: localProfile.name,
          lngLat: [localProfile.lngLat.lng, localProfile.lngLat.lat],
        } as MaplibreSymbol
      }
    }
  },
}

function createClusterPinMarkerElement({
  cluster,
  label,
  description,
  onClick,
}: CreateClusterPinMarkerContext) {
  const clusterElement = document.createElement('div')
  clusterElement.classList.add(css.clusterPinRoot)
  clusterElement.dataset.type = 'cluster-selected'

  clusterElement.onclick = (e) => {
    e.stopPropagation()
    onClick?.(e)
  }

  const clusterPinAdjust = document.createElement('div')
  clusterPinAdjust.classList.add(css.clusterPinAdjust)
  clusterElement.append(clusterPinAdjust)

  const clusterPin = document.createElement('div')
  clusterPin.style.background = `url(${PIN_ICON})`
  clusterPin.classList.add(css.clusterPin)
  clusterPinAdjust.append(clusterPin)

  const clusterPinTextElement = document.createElement('span')
  clusterPinTextElement.classList.add(css.clusterPinLabel)
  clusterPinTextElement.textContent = `+${cluster.leaves.length.toString()}`
  clusterPin.append(clusterPinTextElement)

  const labelElement = createMarkerText({
    label,
    description,
  })
  labelElement.classList.add(css.markerLabel)
  clusterPinAdjust.append(labelElement)

  return clusterElement
}

function createClusterMarkerElement({
  cluster,
  label,
  description,
  onClick,
}: CreateClusterMarkerContext) {
  const clusterElement = document.createElement('div')
  clusterElement.classList.add(css.clusterRoot)
  clusterElement.onclick = (e) => {
    e.stopPropagation()
    onClick?.(e)
  }
  clusterElement.dataset.type = 'cluster'

  const clusterCircle = document.createElement('div')
  clusterCircle.classList.add(css.clusterCircle)
  clusterElement.append(clusterCircle)

  const clusterTextElement = document.createElement('span')
  clusterTextElement.classList.add(css.clusterLabel)
  clusterTextElement.textContent = `+${cluster.leaves.length.toString()}`
  clusterCircle.append(clusterTextElement)

  const labelElement = createMarkerText({
    label,
    description,
  })
  labelElement.classList.add(css.markerLabel)
  clusterElement.append(labelElement)

  return clusterElement
}

function createMarkerElement({
  localProfile,
  onClick,
  isSelected,
  colorTheme,
}: CreateMarkerContext) {
  const markerElement = document.createElement('div')
  markerElement.id = localProfile.id
  markerElement.role = 'button'
  markerElement.classList.add(css.markerRoot)
  markerElement.dataset.type = isSelected ? 'pin' : 'lp'
  markerElement.onclick = onClick

  const ICON = colorTheme === 'dark' ? DARK_THEME_ICON : THEME_ICON
  const DOT_ICON = colorTheme === 'dark' ? DOT_DARK_THEME_ICON : DOT_THEME_ICON

  const isFishShapeBread = isFishShapedBreadThemeId(
    localProfile.poiCategory?.themeIds.at(0) ?? ''
  )

  if (isFishShapeBread) {
    const markerIconElement = document.createElement('div')
    markerIconElement.classList.add(css.fishShapedBreadIcon)
    markerIconElement.style.background = `url(${
      ICON[FISH_SHAPED_BREAD_THEME_ID] ?? ICON.etc
    })`
    markerElement.append(markerIconElement)

    const dotIconElement = document.createElement('div')
    dotIconElement.classList.add(css.fishShapedBreadDotIcon)
    dotIconElement.style.backgroundImage = `url(${
      DOT_ICON[FISH_SHAPED_BREAD_THEME_ID] ?? ICON.dot
    })`
    markerElement.append(dotIconElement)
  } else {
    const markerIconElement = document.createElement('div')
    markerIconElement.classList.add(css.lpIcon)
    markerIconElement.style.background = `url(${
      ICON[localProfile.poiCategory?.themeIds.at(0) ?? 'etc'] ?? ICON.etc
    })`
    markerElement.append(markerIconElement)

    const dotIconElement = document.createElement('div')
    dotIconElement.classList.add(css.dotIcon)
    dotIconElement.style.backgroundImage = `url(${DOT_ICON['dot']})`
    markerElement.append(dotIconElement)

    const labelElement = createMarkerText({
      label: localProfile.name,
    })
    labelElement.classList.add(css.markerLabel)
    markerIconElement.append(labelElement)
  }

  return markerElement
}
