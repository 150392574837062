import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import { pipe } from 'ramda'

import { type WithLngLat } from '@src/core/components/karrot-map/types'
import { separateLngLat } from '@src/core/components/karrot-map/utils/separateLngLat'
import { type Coordinates } from '@src/core/types/geo'
import { isFishShapedBreadThemeId } from '@src/features/fish-shaped-bread/isFishShapedBreadCategoryId'

const isPinnable = (
  localProfile: V1SearchResultLocalProfile
):
  | {
      isPinnable: boolean
      coordinates: Coordinates
    }
  | {
      isPinnable: false
    } => {
  const coordinates = localProfile.geoPoint || localProfile.fallbackGeoPoint

  if (
    coordinates &&
    coordinates.latitude &&
    coordinates.longitude &&
    (!localProfile.disableOnMap ||
      /**
       * 붕어빵 LP 는 배경지도에 노출시키지 않고, 검색 결과만 노출시키고 있어요.
       * 검색결과에 핀을 노출시키려면 disableOnMap 값이 false 로 설정되어야 하는데,
       * disableOnMap 값은 배경지도 노출여부를 담당하고 있어서 붕어빵 LP 정책과 충돌해요.
       * 검색결과에서만 핀을 노출시키기 위해 예외적인 코드를 추가했어요. */
      isFishShapedBreadThemeId(localProfile.poiCategory?.themeIds.at(0) || ''))
  ) {
    return {
      isPinnable: true,
      coordinates,
    }
  }

  return {
    isPinnable: false,
  }
}

const assignPositionToLocalProfile = (
  localProfile: V1SearchResultLocalProfile,
  coordinates: Coordinates
) => ({
  ...localProfile,
  lngLat: {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  },
})

/**
 * local profile에서 좌표가 있고 지도에 표시되어야 하는 local profile만 필터링하고,
 * position으로 맵핑된 local profile을 반환합니다.
 */
const reduceToPlacesWithLocation = (
  localProfiles: V1SearchResultLocalProfile[]
): Array<WithLngLat<V1SearchResultLocalProfile>> => {
  return localProfiles.reduce<Array<WithLngLat<V1SearchResultLocalProfile>>>(
    (filteredAndMapped, localProfile) => {
      const typeSafeLngLat = isPinnable(localProfile)
      if (typeSafeLngLat.isPinnable) {
        filteredAndMapped.push(
          assignPositionToLocalProfile(localProfile, typeSafeLngLat.coordinates)
        )
      }

      return filteredAndMapped
    },
    []
  )
}
/**
 * 겹치는 좌표를 분리합니다.
 */
const separatePlaces = (places: WithLngLat<V1SearchResultLocalProfile>[]) =>
  separateLngLat(places, 0.00004)

export const placesWithCoordinates = pipe(
  reduceToPlacesWithLocation,
  separatePlaces
)
