// 지도 검색 필수 파라미터

import { type LngLat } from '@src/core/types/geo'
import { isEqualCoordinates } from '@src/core/utils/coordinate'
import { convertCoordinates } from '@src/core/utils/geo/convert'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '@src/pages/home-search-result-step/_store/search-business-places-query-request-params'

// https://www.notion.so/daangn/params-16028c3a9f8f8048af26f82132da0142?pvs=4
interface MapSearchLogParams {
  currentUserPosition: LngLat | null
}

const FIXED = 6 // 소수점 6번째랑 비교

export const getMapSearchLogParams = ({
  currentUserPosition,
}: MapSearchLogParams) => {
  const searchCenterCoordinates =
    useSearchBusinessPlacesQueryRequestParamsStore.getState()
      .searchCenterCoordinates

  // 현위치랑 같으면 searchCenterCoordinates null
  // https://www.notion.so/daangn/17628c3a9f8f80f3b327f64c0c218e74?pvs=4
  // * 보안검토에서 검색 기준좌표 로깅시에 현위치랑 동일할 가능성이 있어서 남기지 않고, 동일한 추이를 보기로 했어요. 그래서 현위치랑 같다면 null로 남겨요.
  if (
    !searchCenterCoordinates ||
    !currentUserPosition ||
    isEqualCoordinates(
      searchCenterCoordinates,
      convertCoordinates(currentUserPosition),
      FIXED
    )
  ) {
    return {
      searchCenterCoordinates: null,
    }
  }

  return {
    searchCenterCoordinates,
  }
}
