import { IS_PRODUCTION } from '@src/services/constants/constants'
import {
  FISH_SHAPED_BREAD_ALPHA_THEME_ID,
  FISH_SHAPED_BREAD_PRODUCTION_THEME_ID,
} from '@src/features/fish-shaped-bread/isFishShapedBreadCategoryId'

import academy from '../assets/academy.svg'
import beautyShop from '../assets/beautyShop.svg'
import cafe from '../assets/cafe.svg'
import cleaning from '../assets/cleaning.svg'
import academyDark from '../assets/dark/academy.svg'
import beautyDark from '../assets/dark/beauty.svg'
import cafeDark from '../assets/dark/cafe.svg'
import cleaningDark from '../assets/dark/cleaning.svg'
import deliveryDark from '../assets/dark/delivery.svg'
import dotDark from '../assets/dark/dot.svg'
import etcDark from '../assets/dark/etc.svg'
import fitnessDark from '../assets/dark/fitness.svg'
import hobbyDark from '../assets/dark/hobby.svg'
import repairDark from '../assets/dark/homeRepair.svg'
import hospitalDark from '../assets/dark/hospital.svg'
import interiorDark from '../assets/dark/interior.svg'
import kindergartenDark from '../assets/dark/kindergarten.svg'
import laundryDark from '../assets/dark/laundry.svg'
import petDark from '../assets/dark/petStore.svg'
import restaurantDark from '../assets/dark/restaurant.svg'
import salonDark from '../assets/dark/salon.svg'
import delivery from '../assets/delivery.svg'
import dot from '../assets/dot.svg'
import etc from '../assets/etc.svg'
import fishShapedBread from '../assets/fishShapedBread.svg'
import fitness from '../assets/fitness.svg'
import granulation from '../assets/granulation.svg'
import hobby from '../assets/hobby.svg'
import hospital from '../assets/hospital.svg'
import interior from '../assets/interior.svg'
import laundry from '../assets/laundry.svg'
import pet from '../assets/pet.svg'
import pin from '../assets/pin.svg'
import repair from '../assets/repair.svg'
import restaurant from '../assets/restaurant.svg'
import salon from '../assets/salon.svg'

const PROD_THEME_ICON: Record<string | number, string> = {
  '106': restaurant,
  '118': cafe,
  '214': cafe,
  '123': beautyShop,
  '130': hobby,
  '140': hospital,
  '156': delivery,
  '166': academy,
  '175': repair,
  '179': fitness,
  '189': granulation,
  '192': pet,
  '209': salon,
  '241': cleaning,
  '334': cleaning,
  '246': laundry,
  '258': interior,
  [FISH_SHAPED_BREAD_PRODUCTION_THEME_ID]: fishShapedBread,
  etc,
}

const PROD_DARK_THEME_ICON: Record<string | number, string> = {
  '106': restaurantDark,
  '118': cafeDark,
  '214': cafeDark,
  '123': beautyDark,
  '130': hobbyDark,
  '140': hospitalDark,
  '156': deliveryDark,
  '166': academyDark,
  '175': repairDark,
  '179': fitnessDark,
  '189': kindergartenDark,
  '192': petDark,
  '209': salonDark,
  '241': cleaningDark,
  '334': cleaningDark,
  '246': laundryDark,
  '258': interiorDark,
  [FISH_SHAPED_BREAD_PRODUCTION_THEME_ID]: fishShapedBread,
  etc: etcDark,
}

const ALPHA_THEME_ICON: Record<string | number, string> = {
  '107': restaurant,
  '108': cafe,
  '109': beautyShop,
  '111': hospital,
  '132': hobby,
  '150': delivery,
  '153': etcDark, // 꽃집/꽃배달
  '168': repair,
  '160': academy,
  '172': fitness,
  '185': granulation,
  '186': pet,
  '214': cafe,
  '334': cleaning,
  '340': repair,
  [FISH_SHAPED_BREAD_ALPHA_THEME_ID]: fishShapedBread,
  etc,
}

const ALPHA_DARK_THEME_ICON: Record<string | number, string> = {
  '107': restaurantDark,
  '108': cafeDark,
  '109': beautyDark,
  '111': hospitalDark,
  '132': hobbyDark,
  '150': deliveryDark,
  '159': etcDark, // 꽃집/꽃배달
  '160': academyDark,
  '168': repairDark,
  '172': fitnessDark,
  '185': kindergartenDark,
  '186': petDark,
  '214': cafeDark,
  '334': cleaningDark,
  '340': repairDark,
  [FISH_SHAPED_BREAD_ALPHA_THEME_ID]: fishShapedBread,
  etc: etcDark,
}

export const THEME_ICON = IS_PRODUCTION ? PROD_THEME_ICON : ALPHA_THEME_ICON
export const DARK_THEME_ICON = IS_PRODUCTION
  ? PROD_DARK_THEME_ICON
  : ALPHA_DARK_THEME_ICON

const PROD_DOT_THEME_ICON: Record<string | number, string> = {
  [FISH_SHAPED_BREAD_PRODUCTION_THEME_ID]: fishShapedBread,
  dot,
}

const PROD_DOT_DARK_THEME_ICON: Record<string | number, string> = {
  [FISH_SHAPED_BREAD_PRODUCTION_THEME_ID]: fishShapedBread,
  dot: dotDark,
}

const ALPHA_DOT_THEME_ICON: Record<string | number, string> = {
  [FISH_SHAPED_BREAD_ALPHA_THEME_ID]: fishShapedBread,
  dot,
}

const ALPHA_DOT_DARK_THEME_ICON: Record<string | number, string> = {
  [FISH_SHAPED_BREAD_ALPHA_THEME_ID]: fishShapedBread,
  dot: dotDark,
}

export const DOT_THEME_ICON = IS_PRODUCTION
  ? PROD_DOT_THEME_ICON
  : ALPHA_DOT_THEME_ICON
export const DOT_DARK_THEME_ICON = IS_PRODUCTION
  ? PROD_DOT_DARK_THEME_ICON
  : ALPHA_DOT_DARK_THEME_ICON

export const PIN_ICON = pin
