import UserLocationProvider from '@src/features/user-location/providers/UserLocationProvider'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

interface LocalMapUserLocationProviderProps {
  children: React.ReactNode
}

const LocalMapUserLocationProvider = ({
  children,
}: LocalMapUserLocationProviderProps) => {
  const bridgeInfo = useBridgeInfo()

  return (
    <UserLocationProvider bridgeInfo={bridgeInfo} useLocalStorageCache={true}>
      {children}
    </UserLocationProvider>
  )
}

export default LocalMapUserLocationProvider
