import maplibregl from 'maplibre-gl'
import { useEffect, useMemo, useRef } from 'react'

import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useExtendBoundaryStore } from '@src/features/map/components/map-floating-buttons/extendStore'
import { useMapFitBounds } from '@src/features/map/hooks/useMapFitBounds'
import { getMiddleOffset } from '@src/services/constants/anchor'
import { getPrevStep } from '@src/services/hooks/useStepRouter/prevStep'

import { useVisibleMapViewportPadding } from './useVisibleMapViewportPadding'
import { useSearchLocalProfilesApiQuery } from '../_api-queries/searchLocalProfiles'

const VISIBLE_MAKER_VIEWPORT_PADDING_PX = 64

const OPTIMIZE_BASE_ZOOM_LEVEL = 12
const OPTIMIZE_BASE_RESULT_COUNT = 7
const FIT_ANIMATION_DURATION = 300

export const useOptimizeMapViewportEffect = () => {
  const { placedLocalProfiles, status, rawData, searchAreaBounds } =
    useSearchLocalProfilesApiQuery()
  const { localMapFitBounds } = useMapFitBounds()
  const { map } = useMap()
  const { setShowExtend } = useExtendBoundaryStore()

  const visibleMapViewportPadding = useVisibleMapViewportPadding(
    VISIBLE_MAKER_VIEWPORT_PADDING_PX
  )

  const isBoundsReSearch = useMemo(() => {
    const requestHasBounds =
      searchAreaBounds?.centerLatitude && searchAreaBounds?.centerLongitude
    return status === 'success' && requestHasBounds
    // reqParams는 검색이 호출 되는 시점에 설정되기 때문에,
    // isBoundsResearch의 값의 갱신은 status에 의존하지 않는다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const ref = useRef({
    center: [NaN, NaN] as [number, number],
    localMapFitBounds,
    placedLocalProfiles,
    setShowExtend,
    visibleMapViewportPadding,
  })
  ref.current.localMapFitBounds = localMapFitBounds
  ref.current.placedLocalProfiles = placedLocalProfiles
  ref.current.setShowExtend = setShowExtend
  ref.current.visibleMapViewportPadding = visibleMapViewportPadding

  useEffect(() => {
    const { prevStep } = getPrevStep()

    if (status !== 'success') {
      return
    }

    // 페이지네이션의 1페이지만 적용
    if (
      ref.current.placedLocalProfiles.length === 0 ||
      (rawData && rawData?.pages.length > 1)
    ) {
      return
    }

    // 재검색일 경우 처리하지 않음
    if (isBoundsReSearch) {
      return
    }

    // 이전 스텝이 externalPage(프리뷰 확장) 경우 처리하지 않음
    if (prevStep === 'externalPage') {
      return
    }

    if (map) {
      const zoomLevel = fitSearchResultBoundaryLevel(map)

      zoomLevel >= OPTIMIZE_BASE_ZOOM_LEVEL
        ? fitSearchResultBoundary(FIT_ANIMATION_DURATION)
        : fitBaseCountBoundary(map)

      ref.current.center = map.getCenter().toArray()
    }

    function fitSearchResultBoundaryLevel(map: maplibregl.Map) {
      const beforeBounds = map.getBounds()
      fitSearchResultBoundary(0)

      const zoomLevel = map.getZoom()

      map.fitBounds(beforeBounds, { duration: 0 })

      return zoomLevel
    }

    /** 검색결과가 모두 보이도록 바운더리를 조정해요 */
    function fitSearchResultBoundary(duration: number) {
      ref.current.localMapFitBounds({
        places: ref.current.placedLocalProfiles,
        options: {
          padding: {
            top: ref.current.visibleMapViewportPadding.top,
            left: ref.current.visibleMapViewportPadding.left,
            right: ref.current.visibleMapViewportPadding.right,
            bottom: getMiddleOffset() + VISIBLE_MAKER_VIEWPORT_PADDING_PX,
          },
          duration,
        },
      })
    }

    /**
     * 기준 줌레벨보다 작은 경우 지도 중심으로부터 가까운 검색결과 N개가 보이도록 바운더리를 조정해요
     * (적절한 개수[N]의 마커가 보일 때, 마커 클릭 확률이 높다는 연구 결과를 참고했어요)
     */
    function fitBaseCountBoundary(map: maplibregl.Map) {
      const center = map.getCenter()
      const sortedPlaces = ref.current.placedLocalProfiles
        .slice()
        .sort((a, b) => {
          const distanceA = center.distanceTo(
            new maplibregl.LngLat(a.lngLat.lng, a.lngLat.lat)
          )
          const distanceB = center.distanceTo(
            new maplibregl.LngLat(b.lngLat.lng, b.lngLat.lat)
          )

          return distanceA - distanceB
        })

      const bounds = new maplibregl.LngLatBounds()

      sortedPlaces.slice(0, OPTIMIZE_BASE_RESULT_COUNT).forEach((v) => {
        bounds.extend([v.lngLat.lng, v.lngLat.lat])
      })

      map.fitBounds(bounds, {
        padding: ref.current.visibleMapViewportPadding,
        duration: FIT_ANIMATION_DURATION,
      })

      ref.current.setShowExtend(true)
    }
  }, [map, status, isBoundsReSearch, rawData?.pages, rawData])
}
