import { bbox } from '@turf/bbox'
import Supercluster, {
  type PointFeature,
  type AnyProps,
  type ClusterFeature,
} from 'supercluster'

import { type WithLngLat } from '@src/core/components/karrot-map/types'
import { type LngLat } from '@src/core/types/geo'

export type ClusterWithLeaves<P> = ClusterFeature<AnyProps> & {
  leaves: PointFeature<P>[]
}

export type ClusterNode<P> = ClusterWithLeaves<P> | PointFeature<P>

export const clusterData = <T, P extends WithLngLat<T>>({
  dataWithPosition,
}: {
  dataWithPosition: P[]
}) => {
  // 클러스터링을 위해 데이터를 PointFeature 배열로 변환
  const features = convertToPointFeatures(
    dataWithPosition,
    (item) => item.lngLat
  )

  const featureBbox = bbox({
    type: 'FeatureCollection',
    features,
  }) // [west, south, east, north]

  // Supercluster 설정
  const supercluster = new Supercluster<P>({
    radius: 25, // 클러스터 반경
    maxZoom: 20, // 최대 줌 레벨
    minPoints: 2, // 클러스터를 형성하기 위한 최소 포인트 수
  })

  supercluster.load(features)

  const clusters = supercluster.getClusters(
    featureBbox,
    19 // 클러스터링은 zoomLevel 정수단위로 재조정되는데, 19로 고정시켜서 19에서 20이될때 재조정 방지.
  )

  const clustersWithLeaves = clusters.map<ClusterNode<P>>((cluster) =>
    isCluster(cluster)
      ? ({
          ...cluster,
          leaves: supercluster.getLeaves(Number(cluster.id)),
        } as ClusterWithLeaves<P>)
      : cluster
  )

  return clustersWithLeaves
}

export const isCluster = (
  feature: PointFeature<any> | ClusterFeature<AnyProps>
): feature is ClusterFeature<AnyProps> => {
  return 'cluster' in feature.properties && feature.properties.cluster === true
}

/**
 * 데이터를 Supercluster에서 사용할 수 있는 PointFeature 배열로 변환합니다.
 * @template T
 * @param items Array<T> - 변환할 데이터 배열
 * @param getCoordinates 함수 - 각 데이터의 좌표를 반환하는 함수
 * @returns Array<PointFeature<T>>
 */
function convertToPointFeatures<T>(
  items: T[],
  getCoordinates: (item: T) => LngLat
): Array<PointFeature<T>> {
  return items.map((item) => {
    const coordinates = getCoordinates(item)

    if (
      !coordinates ||
      coordinates.lat === undefined ||
      coordinates.lng === undefined
    ) {
      throw new Error(`Invalid coordinates for item: ${JSON.stringify(item)}`)
    }

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [coordinates.lng, coordinates.lat],
      },
      properties: { ...item },
    }
  })
}
