import * as Sentry from '@sentry/react'
import { isUndefined } from 'lodash-es'
import { type LngLatBounds, type PaddingOptions } from 'maplibre-gl'

import { type Coordinates } from '@src/core/types/geo'

import { type MapLibreGLType } from './Map'
import { type BoundsArrayWithName } from './type'

type CoordinatesToBoundsParams = {
  coordinates: [number, number][]
  mapLibreGL: MapLibreGLType
}

export const coordinatesToBounds = ({
  coordinates,
  mapLibreGL,
}: CoordinatesToBoundsParams) => {
  return coordinates.reduce(
    (bounds, coord) => {
      return bounds.extend(coord)
    },
    new mapLibreGL.LngLatBounds(coordinates[0], coordinates[0])
  )
}

/**
 * 지도 좌측 하단 축적을 표시하는 영역의 Element를 가져온다.
 */
export const getAccumulationElement = () => {
  const accumulationElement = document.querySelector(
    '.maplibregl-ctrl-bottom-left'
  )

  return accumulationElement ? (accumulationElement as HTMLElement) : null
}

const toRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180
}

export const calculateDistanceHaversineKm = (
  coords1: Coordinates,
  coords2: Coordinates
): number => {
  const R = 6371 // Earth's radius in kilometers
  const dLat = toRadians(coords2.latitude - coords1.latitude)
  const dLon = toRadians(coords2.longitude - coords1.longitude)
  const lat1 = toRadians(coords1.latitude)
  const lat2 = toRadians(coords2.latitude)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceKm = R * c

  return distanceKm
}

export const getSafePadding = ({
  top,
  right,
  bottom,
  left,
}: Partial<PaddingOptions>) => {
  const getSafePaddingValue = (value: any) => {
    if (typeof value !== 'number' && !isUndefined(value)) {
      Sentry.captureException(
        new Error('Not a number is given as padding value')
      )
      return 0
    }
    if (isUndefined(value)) return 0
    if (!isFinite(value)) return 0
    if (value < 0) return 0

    return value
  }

  return {
    top: getSafePaddingValue(top),
    right: getSafePaddingValue(right),
    bottom: getSafePaddingValue(bottom),
    left: getSafePaddingValue(left),
  }
}

export const getBoundsArray: (bounds: LngLatBounds) => BoundsArrayWithName = (
  bounds
) => {
  return [bounds._sw.toArray(), bounds._ne.toArray()]
}
