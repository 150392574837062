import { IconCrosshairLine } from '@daangn/react-monochrome-icon'
import { Fab, type SeedFabProps } from '@daangn/sprout-components-fab'

import * as css from './GPSButton.css'

type FloatingGPSButtonProps = Omit<SeedFabProps, 'children'> & {
  children?: React.ReactNode
}

const GPSButton = ({ children, ...rest }: FloatingGPSButtonProps) => {
  return (
    <Fab UNSAFE_className={css.button} {...rest}>
      <IconCrosshairLine width={18.75} height={18.75} />
      {children}
    </Fab>
  )
}

export default GPSButton
