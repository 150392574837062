import {
  type LngLatNullable,
  type LngLat,
  type CoordinatesNullable,
  type Coordinates,
} from '@src/core/types/geo'

/**
 * Convert Coordinates to LngLat
 */
export const convertLngLat = (typeLatLng: Coordinates): LngLat => ({
  lng: typeLatLng.longitude,
  lat: typeLatLng.latitude,
})

/**
 * Convert Coordinates to LngLat with nullable
 */
export const convertLngLatNullable = (
  typeLatLng: CoordinatesNullable
): LngLatNullable => {
  if (!typeLatLng || !typeLatLng?.latitude || !typeLatLng?.longitude) {
    return null
  }

  return {
    lng: typeLatLng.longitude,
    lat: typeLatLng.latitude,
  }
}

export const convertCoordinates = (lngLat: LngLat): Coordinates => ({
  longitude: lngLat.lng,
  latitude: lngLat.lat,
})

/**
 * Convert LngLatString To LngLat
 */
export function convertLngLatStringToLngLat(
  stringLngLat: string
): LngLat | null {
  const result = stringLngLat.match(/[-+]?[0-9]*\.?[0-9]+/g)

  if (!result) {
    return null
  }

  const [lng, lat] = result as [string, string]

  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }
}
