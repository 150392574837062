import {
  createSuggestionSDK,
  UpdateModule,
} from '@daangn/local-profile-suggestion-sdk'

import { IS_PRODUCTION } from '@src/services/constants/constants'
import { karrotBridge } from '@src/core/lib/bridge/bridge'

const localProfileSuggestionSDK = createSuggestionSDK({
  webViewRouter: {
    openWebView: async ({ url }) => {
      await karrotBridge.pushRouter({
        router: {
          remote: url,
          backSwipable: false,
          navbar: false,
          scrollable: false,
        },
      })
    },
  },
  streamEvent: {
    emit: (eventName, data) => {
      karrotBridge.emitToStream({
        stream: {
          eventName,
          data: JSON.stringify(data),
        },
      })
    },
    subscribe: (eventName, listener) => {
      return karrotBridge.driver.compat.subscribeStream(eventName, listener)
    },
  },
  stage: IS_PRODUCTION ? 'production' : 'alpha',
  moduleFactories: [UpdateModule],
})

export default localProfileSuggestionSDK
