import React, { type ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useBoundedMove } from '@src/core/components/simple-bottom-sheet/hooks/useBoundedMove'
import { useMultipleEventListeners } from '@src/core/components/simple-bottom-sheet/hooks/useMultipleEventListeners'
import { useSwipeDown } from '@src/core/components/simple-bottom-sheet/hooks/useSwipeDown'
import * as css from '@src/core/components/simple-bottom-sheet/SimpleBottomSheet.css'

interface Props {
  children: ReactNode

  disableDim?: boolean
  disableHandleBar?: boolean

  isOpen: boolean
  onClose?: () => void
}

const SimpleBottomSheet = ({
  children,
  disableHandleBar,
  disableDim,
  isOpen,
  onClose,
}: Props) => {
  const dimRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  const handleBarRef = useRef<HTMLDivElement>(null)

  const openTransition = () => {
    bodyRef.current && (bodyRef.current.style.transform = `translateY(0)`)
  }
  const closeTransition = () => {
    bodyRef.current && (bodyRef.current.style.transform = `translateY(100%)`)
  }

  const swipeDownHandler = useSwipeDown({
    swipeDownFailCallback: () => {
      openTransition()
    },
    swipeDownCallback: () => {
      onClose && onClose()
    },
    thresholdDistance: (bodyRef.current?.clientHeight || 0) / 3,
    thresholdVelocity: 0.5,
  })
  const boundedMoveHandler = useBoundedMove(bodyRef, {})

  useMultipleEventListeners(
    handleBarRef,
    {
      touchend: [
        // 새로 정렬
        boundedMoveHandler.onTouchEnd,
        swipeDownHandler.onTouchEnd,
      ],
      touchmove: [
        // 새로 정렬
        boundedMoveHandler.onTouchMove,
        swipeDownHandler.onTouchMove,
      ],
      touchstart: [
        // 새로 정렬
        boundedMoveHandler.onTouchStart,
        swipeDownHandler.onTouchStart,
      ],
    },
    [swipeDownHandler, boundedMoveHandler]
  )

  useEffect(() => {
    if (isOpen) {
      openTransition()
    } else {
      closeTransition()
    }
  }, [isOpen])

  /**
   * 해당 컴포넌트에 포함되어 있는 Dim은 바텀시트를 제외한 다른 요소가 위로 올라오면 안돼요.
   * css나 dom 계층구조를 신경써서할 수도 있지만, 다 신경안쓰고 강제로 레이어 우선순위를 올리려고 createPortal을 사용해요. */
  return createPortal(
    <div className={css.base({ visible: isOpen })}>
      {!disableDim && (
        <div
          ref={dimRef}
          className={css.dim({ visible: isOpen })}
          onClick={() => {
            onClose && onClose()
          }}
        />
      )}

      <div ref={bodyRef} className={css.body}>
        {!disableHandleBar && (
          <div ref={handleBarRef} className={css.handleBar} />
        )}

        <div className={css.content}>{children}</div>
      </div>
    </div>,
    document.getElementById('root')!
  )
}

export default SimpleBottomSheet
