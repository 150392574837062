import { isOutsideMaxBoundary } from '@src/core/components/karrot-map/utils/isOutsideMaxBoundary'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'

import { BoundaryErrorBottomSheetContent } from './BoundaryErrorBottomSheetContent'
import { ErrorBottomSheetContent } from './ErrorBottomSheetContent'

export type ListFeedViewTypeErrorType =
  | 'granted'
  | 'empty-coordinates'
  | 'invalid-coordinates'

type ListFeedViewTypesExceptionProps = {
  type: ListFeedViewTypeErrorType
}

export default function ListFeedViewTypesException({
  type,
}: ListFeedViewTypesExceptionProps) {
  switch (type) {
    case 'granted':
      return <ErrorBottomSheetContent />
    case 'empty-coordinates':
      return <ErrorBottomSheetContent />
    case 'invalid-coordinates':
      return <BoundaryErrorBottomSheetContent />
    default:
      return null
  }
}

export function useListFeedViewTypesErrorType() {
  const { granted } = useGeolocation()
  const distancedCoordinates = coordinatesStore((s) => s.distancedCoordinates)

  // 위치 권한이 없는 경우
  if (!granted) {
    return 'granted'
  }

  // 위치 정보가 없는 경우
  if (
    !distancedCoordinates?.coordinates.latitude ||
    !distancedCoordinates?.coordinates.longitude
  ) {
    return 'empty-coordinates'
  }

  // 위치가 유효하지 않은 경우
  if (
    isOutsideMaxBoundary([
      distancedCoordinates.coordinates.longitude,
      distancedCoordinates.coordinates.latitude,
    ])
  ) {
    return 'invalid-coordinates'
  }

  return null
}
