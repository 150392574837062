import React from 'react'

interface Props {
  size?: number
  color?: string
}

const IconCategoryFishShapedBread = ({
  size = 28,
  color = '#E8AE2F',
}: Props = {}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_804_31027)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3433 7.42001L23.8233 9.57834C21.6767 6.27668 17.2667 4.00168 12.5883 4.00168C6.04333 4.00168 0 8.48168 0 14C0 19.5183 6.04333 23.9983 12.6 23.9983C17.2783 23.9983 21.6767 21.7233 23.835 18.4217L26.355 20.58C27.0083 21.14 28.0117 20.6733 28.0117 19.8217V8.17835C28.0117 7.32668 27.0083 6.86001 26.355 7.42001H26.3433ZM6.41667 15.0267C5.61167 15.0267 4.95833 14.3733 4.95833 13.5683C4.95833 12.7633 5.61167 12.11 6.41667 12.11C7.22167 12.11 7.875 12.7633 7.875 13.5683C7.875 14.3733 7.22167 15.0267 6.41667 15.0267ZM15.8083 16.1817C15.8083 17.465 14.9333 18.655 14.35 19.145C14.1983 19.2733 14.0117 19.3317 13.825 19.3317C13.5917 19.3317 13.3583 19.2267 13.195 19.04C12.9033 18.69 12.9617 18.1767 13.3 17.885C13.6617 17.5933 14.175 16.8117 14.175 16.1817C14.175 15.6217 13.93 15.0383 13.72 14.7117C13.4167 14.245 13.4167 13.65 13.72 13.1833C13.93 12.8567 14.175 12.2733 14.175 11.7133C14.175 11.0833 13.6617 10.3017 13.3 10.01C12.95 9.71834 12.9033 9.20501 13.195 8.85501C13.4867 8.50501 14 8.45834 14.35 8.75001C14.9333 9.24001 15.8083 10.43 15.8083 11.7133C15.8083 12.7283 15.3767 13.5917 15.1667 13.9533C15.3767 14.315 15.8083 15.1783 15.8083 16.1933V16.1817ZM20.09 15.61C20.09 16.6483 19.39 17.605 18.9233 17.99C18.7717 18.1183 18.585 18.1767 18.3983 18.1767C18.165 18.1767 17.9317 18.0717 17.7683 17.885C17.4767 17.535 17.535 17.0217 17.8733 16.73C18.1183 16.5317 18.445 16.0067 18.445 15.61C18.445 15.2367 18.2817 14.84 18.13 14.6183C17.8617 14.1983 17.8617 13.6733 18.13 13.2417C18.2817 13.0083 18.4333 12.6 18.4333 12.25C18.4333 11.8533 18.095 11.3283 17.8617 11.13C17.5117 10.8383 17.465 10.325 17.7567 9.97501C18.0483 9.62501 18.5617 9.57834 18.9117 9.87001C19.3783 10.255 20.0783 11.2117 20.0783 12.25C20.0783 12.7983 19.915 13.405 19.6233 13.93C19.915 14.455 20.0783 15.0617 20.0783 15.61H20.09Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default React.memo(IconCategoryFishShapedBread)
