import { useCallback, useMemo, useRef } from 'react'

interface Options {
  /*
   * moveRef 의 높이를 기본 값으로 사용해요 */
  maxPosition?: number
}
export const useBoundedMove = (
  moveRef: React.RefObject<HTMLElement>,
  options: Options = {}
) => {
  const touchDataRef = useRef({
    startY: 0,
    initialTop: 0,
    touching: false,
  })

  const onTouchStart = useCallback(
    (event: TouchEvent) => {
      const touch = event.touches[0]

      touchDataRef.current.touching = true

      if (!moveRef.current) return

      moveRef.current.style.transition = 'none'
      touchDataRef.current.startY = touch.clientY
      touchDataRef.current.initialTop = moveRef.current.offsetTop
    },
    [moveRef]
  )

  const onTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (!moveRef.current) return

      moveRef.current.style.transition = 'transform 300ms ease-out'
    },
    [moveRef]
  )

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      if (!touchDataRef.current.touching) return
      if (!moveRef.current) return

      const touch = event.touches[0]

      const newPositionY = touch.clientY - touchDataRef.current.startY
      const calculatedY = touchDataRef.current.initialTop - newPositionY
      const maxPositionY =
        options.maxPosition ||
        -(moveRef.current.clientHeight - window.innerHeight)

      if (calculatedY > maxPositionY) {
        moveRef.current.style.transform = `translateY(0)`
      } else {
        moveRef.current.style.transform = `translateY(${newPositionY}px)`
      }
    },
    [moveRef, options.maxPosition]
  )

  return useMemo(
    () => ({
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    }),
    [onTouchStart, onTouchMove, onTouchEnd]
  )
}
