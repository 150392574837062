import { useEffect, useRef } from 'react'

import { DEFAULT_ZOOM_LEVEL } from '@src/core/components/karrot-map/map/constants'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useMapPanTo } from '@src/features/map/hooks/useMapPanTo'

import { useUserLocation } from '../hooks/useUserLocation'

export function useUserLocationPointChange() {
  const { userLocations } = useUserLocation()
  const { map } = useMap()

  const housePlacePoint = userLocations?.find(
    (userLocation) => userLocation.place === 'PLACE_TYPE_HOUSE'
  )
  const housePlacePointLongitude = housePlacePoint?.point.longitude
  const housePlacePointLatitude = housePlacePoint?.point.latitude

  const { panToWithOffset } = useMapPanTo(map)
  const ref = useRef({
    lnglat: {
      longitude: housePlacePointLongitude,
      latitude: housePlacePointLatitude,
    },
    panToWithOffset,
  })
  ref.current.panToWithOffset = panToWithOffset

  useEffect(() => {
    if (!housePlacePointLongitude || !housePlacePointLatitude) return

    const isChanged =
      ref.current.lnglat.longitude !== housePlacePointLongitude ||
      ref.current.lnglat.latitude !== housePlacePointLatitude

    if (isChanged) {
      ref.current.panToWithOffset({
        zoom: DEFAULT_ZOOM_LEVEL,
        center: {
          lng: housePlacePointLongitude,
          lat: housePlacePointLatitude,
        },
      })

      ref.current.lnglat = {
        longitude: housePlacePointLongitude,
        latitude: housePlacePointLatitude,
      }
    }
  }, [map, housePlacePointLongitude, housePlacePointLatitude])
}
