import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useMemo, useRef } from 'react'

import { useGetSectionQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import FeedPublicPost from '@src/pages/home-feed-step/section/section-public-institution-post/SectionPublicPost'
import { PUBLIC_SECTION_INFO } from '@src/pages/home-feed-step/section/sectionKeyInfo'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

interface SectionPublicInstitutionRootProps {
  userCoordinates: TypeLatLng
}
const SectionPublicInstitutionRoot = ({
  userCoordinates,
}: SectionPublicInstitutionRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { data } = useGetSectionQuery({
    sectionViewTypes:
      'SECTION_VIEW_TYPE_PUBLIC_INSTITUTION_BUSINESS_POST_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_PUBLIC_INSTITUTION_BUSINESS_POST_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...PUBLIC_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const result = data?.sectionItems[0]?.publicInstitutionBusinessPostSection
  if (!result) return null

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div ref={sectionRef}>
        <FeedPublicPost
          title={`${result.region3Name} 공공기관 소식`}
          publicPost={result}
        />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(SectionPublicInstitutionRoot)
