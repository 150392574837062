import {
  HelpBubbleAnchor,
  type SeedHelpBubbleProps,
} from '@daangn/sprout-components-help-bubble'
import { useEffect, useState } from 'react'

interface TimeoutHelpBubbleProps
  extends Omit<SeedHelpBubbleProps, 'defaultOpen'> {
  timeout?: number // milliseconds
}

export const TimeoutHelpBubble = ({
  timeout = 5000,
  children,
  ...props
}: TimeoutHelpBubbleProps) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false)
      props.onOpenChange?.(false)
    }, timeout)

    return () => clearTimeout(timer)
  }, [props, timeout])

  return (
    <HelpBubbleAnchor {...props} isOpen={isOpen}>
      {children}
    </HelpBubbleAnchor>
  )
}
