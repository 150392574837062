export const checkProtoDuration = (duration: string) => {
  const regex = /^\d+(\.\d+)?s$/
  return regex.test(duration)
}

export const getDurationToMinutes = (duration: string) => {
  if (checkProtoDuration(duration)) {
    return null
  }

  return Math.floor(parseFloat(duration) / 60)
}

function convertToTimeStructure(duration: string) {
  if (checkProtoDuration(duration)) {
    return null
  }

  const totalSeconds = parseFloat(duration)

  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = Math.floor(totalSeconds % 60)
  const milliseconds = Math.round(
    (totalSeconds - Math.floor(totalSeconds)) * 1000
  )

  return { hours, minutes, seconds, milliseconds }
}

const WALK_LIMIT = 10 // 10 minutes

export const getWalkDuration = (
  duration?: string | null,
  limit: number = WALK_LIMIT
) => {
  if (!duration) {
    return null
  }

  const minutes = getDurationToMinutes(duration)

  if (minutes && minutes <= limit) {
    return `걸어서 ${limit}분`
  }

  return null
}

export const commaNumber = (numberValue: number) => {
  return Number(numberValue).toLocaleString('en')
}

export const displayDistance = (distance?: number) => {
  if (!distance) {
    return ''
  }

  const isDistanceInMeter = distance / 1000 < 1

  if (isDistanceInMeter) {
    if (distance < 10) {
      return `${Math.floor(distance)}m` // 10m 미만 정수로만 표시
    }

    return `${Math.floor(distance / 10) * 10}m` // 10m 단위로 표시
  }

  return `${Math.floor(distance / 100) / 10}km` // 소수점 버림, km는 소수점 첫째 자리까지 표시
}
