import * as css from './marker.css'

export function isOverlapping(
  element1: HTMLElement,
  element2: HTMLElement
): boolean {
  // 각 요소의 DOMRect를 가져옵니다.
  const rect1 = element1.getBoundingClientRect()
  const rect2 = element2.getBoundingClientRect()

  // 겹치지 않는 조건을 확인합니다.
  const notOverlapping =
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom

  // 겹치지 않는 조건의 반대가 겹치는 조건입니다.
  return !notOverlapping
}

export function createMarkerText({
  label,
  description,
  className = css.markerLabelText,
}: {
  label: string
  description?: string
  className?: string
}) {
  const svgElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  )
  svgElement.classList.add('lp-label')

  const textElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  )

  const textElementClassName = className.split(' ')
  textElement.classList.add(...textElementClassName)

  const labels = toOptimizedMarkerText(label)

  labels.forEach((chunk, i) => {
    const labelSpanElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan'
    )
    labelSpanElement.classList.add(css.labelFont)
    labelSpanElement.textContent = chunk

    labelSpanElement.setAttribute('x', '0')
    labelSpanElement.setAttribute('y', `${1.2 * i}em`)

    textElement.append(labelSpanElement)
  })

  if (description) {
    const descriptionSpanElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan'
    )
    descriptionSpanElement.classList.add(css.descriptionFont)
    descriptionSpanElement.textContent = description

    descriptionSpanElement.setAttribute('x', '0')
    descriptionSpanElement.setAttribute('y', `${1.35 * labels.length}em`)

    textElement.append(descriptionSpanElement)
  }

  const textShadowElement = textElement.cloneNode(true) as SVGTextElement

  const textShadowElementClassName = css.markerLabelTextShadow.split(' ')
  textShadowElement.classList.add(...textShadowElementClassName)

  svgElement.append(textShadowElement)
  svgElement.append(textElement)

  return svgElement
}

export function toOptimizedMarkerText(text: string): string[] {
  const OVERFLOW_LIMIT = 10

  const chunks = text
    .trim()
    .replaceAll('&', '& ')
    .replace(
      /[(].+[)]/g,
      (s) => ' ' + s.replaceAll('& ', '&').replaceAll(' ', '_')
    )
    .split(/[ ]{1,}/)

  const result = chunks.slice(0, 1)

  chunks.slice(1).forEach((chunk) => {
    if (((result.at(-1) + ` ${chunk}`).length ?? 0) > OVERFLOW_LIMIT) {
      result.push(chunk)
    } else {
      const hasSpace = !result.at(-1)?.endsWith('&')
      result[result.length - 1] += `${hasSpace ? ' ' : ''}${chunk}`
    }
  })

  return result
}
