import { useEffect } from 'react'

import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'

export const useSyncFeedCoordinatesEffect = () => {
  const { currentPosition } = useGeolocation()

  const setDistancedCoordinates = coordinatesStore(
    (s) => s.setDistancedCoordinates
  )

  useEffect(() => {
    if (currentPosition) {
      setDistancedCoordinates({
        type: 'currentPosition',
        coordinates: {
          latitude: currentPosition.lat,
          longitude: currentPosition.lng,
        },
      })
    }
  }, [currentPosition, setDistancedCoordinates])
}
