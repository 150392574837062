import { useEffect } from 'react'
import { v4 } from 'uuid'

import { localMapBridge } from '@src/core/lib/bridge/bridge'

export function useBackButtonEnabled() {
  useEffect(() => {
    const _instance: object = window
    window.addEventListener('navigate', () => {
      if ('navigation' in _instance) {
        const navigation = _instance.navigation as object
        if ('canGoBack' in navigation) {
          const enabled = !navigation.canGoBack

          setBackButtonEnabled(enabled)

          // localMapBridge?.driver.postMessage({
          //   id: 'LOCAL_MAP_TAB/BACK_HANDLER',
          //   type: 'REQ.BACK_BUTTON_PRESSED.CONFIGURE',
          //   payload: {
          //     ANDROID_ONLY_backButtonEnabled: enabled,
          //   },
          // })
        }
      }
    })
  }, [])

  useEffect(() => {
    localMapBridge?.driver.postMessage({
      id: v4(),
      type: 'REQ.BACK_BUTTON_PRESSED.SUBSCRIBE',
      payload: {},
    })

    localMapBridge?.driver.addMessageListener((e) => {
      if ('type' in e) {
        if (e.type === 'RES.BACK_BUTTON_PRESSED.EVENT') {
          setBackButtonEnabled(true)

          const url = new URL(window.location.href)
          const referrer = url.searchParams.get('referrer')
          if (referrer === 'serviceExploration') {
            location.href = 'karrot.alpha://tab/home'
          }
        }
      }
    })
  }, [])
}

function setBackButtonEnabled(enabled: boolean) {
  localMapBridge?.driver.postMessage({
    id: v4(),
    type: 'REQ.BACK_BUTTON_PRESSED.CONFIGURE',
    payload: {
      ANDROID_ONLY_backButtonEnabled: enabled,
    },
  })
}
