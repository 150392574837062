import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'

import { useQueryParams } from '@src/core/components/url/useQueryParams'
import { clientEnvironment } from '@src/core/lib/bridge/utils'
import { getCachedIOSSafeAreaInsetTop } from '@src/core/utils/safeAreaVariable'
import LocalMapBottomSheetSkeleton from '@src/features/feed/components/LocalMapBottomSheetSkeleton'
import HomeSearchInputSkeleton from '@src/pages/home-feed-step/search-input/HomeSearchInputSkeleton'
import SectionRecommendPostSkeleton from '@src/pages/home-feed-step/section/section-recommend-post/SectionRecommendPostSkeleton'
import { FeedStepKeys } from '@src/services/hooks/useStepRouter'

const HomeSkeletonFragment = () => {
  const { stepType: stepTypeParam } = useQueryParams<{
    stepType: FeedStepKeys
  }>()
  const stepType =
    stepTypeParam && FeedStepKeys.includes(stepTypeParam)
      ? stepTypeParam
      : 'home'

  const cachedSafeAreaInsetTop = getCachedIOSSafeAreaInsetTop()

  // iOS 초기 1회 덜그덕거리는 렌더링 수정
  if (
    clientEnvironment.isIOS &&
    (!cachedSafeAreaInsetTop || cachedSafeAreaInsetTop === '0px')
  ) {
    return null
  }

  return (
    <AppScreen>
      <div
        id="local-map-home-skeleton"
        style={{
          height: '100vh',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: vars.$semantic.color.paperDefault,
        }}
      >
        <HomeSearchInputSkeleton />
        <LocalMapBottomSheetSkeleton stepType={stepType}>
          {stepType === 'home' && <SectionRecommendPostSkeleton />}
        </LocalMapBottomSheetSkeleton>
      </div>
    </AppScreen>
  )
}

export default HomeSkeletonFragment
