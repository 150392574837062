import { useEffect } from 'react'
import { create } from 'zustand'

import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { useLatestRef } from '@src/core/hooks/useLatestRef'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useMapFitBounds } from '@src/features/map/hooks/useMapFitBounds'
import { useSearchLocalProfilesApiQuery } from '@src/pages/home-search-result-step/_api-queries/searchLocalProfiles'
import { useVisibleMapViewportPadding } from '@src/pages/home-search-result-step/_hooks/useVisibleMapViewportPadding'
import { useSearchExtendLogger } from '@src/services/log/hooks/useSearchExtendLogger'

const extendBoundaryStore = create<{
  showExtend: boolean
  setShowExtend: (showExtend: boolean) => void
}>((set) => ({
  showExtend: false,
  setShowExtend: (showExtend) => set({ showExtend }),
}))

const VISIBLE_MAKER_VIEWPORT_PADDING_PX = 64

export function useExtendBoundaryStore() {
  const store = extendBoundaryStore()
  const { stepType } = useStepRouter()
  const { placedLocalProfiles } = useSearchLocalProfilesApiQuery()
  const { localMapFitBounds } = useMapFitBounds()
  const visibleMapViewportPadding = useVisibleMapViewportPadding(
    VISIBLE_MAKER_VIEWPORT_PADDING_PX
  )
  const { snapPoint } = useBottomSheetSnapPoint()
  const {
    clickedLocalMapSearchResultViewDistantResultsButtonV1,
    impressedLocalMapSearchResultViewDistantResultsButtonV1,
  } = useSearchExtendLogger()
  const { map } = useMap()

  const ref = useLatestRef({
    map,
    store,
    impressedLocalMapSearchResultViewDistantResultsButtonV1,
  })

  useEffect(() => {
    if (!stepType.includes('search')) {
      ref.current.store.setShowExtend(false)
    }
  }, [ref, stepType])

  useEffect(() => {
    if (snapPoint === 'bottom') {
      ref.current.store.setShowExtend(false)
    }
  }, [ref, snapPoint])

  useEffect(() => {
    if (store.showExtend) {
      if (ref.current.map) {
        ref.current.impressedLocalMapSearchResultViewDistantResultsButtonV1({
          zoom_level: ref.current.map.getZoom(),
          center_coordinates: {
            latitude: ref.current.map.getCenter().lat,
            longitude: ref.current.map.getCenter().lng,
          },
        })
      }
    }
  }, [ref, store.showExtend])

  function setShowExtend(showExtend: boolean) {
    store.setShowExtend(showExtend)
  }

  function onExtend() {
    if (map) {
      clickedLocalMapSearchResultViewDistantResultsButtonV1({
        zoom_level: map.getZoom(),
        center_coordinates: {
          latitude: map.getCenter().lat,
          longitude: map.getCenter().lng,
        },
      })
    }

    store.setShowExtend(false)

    localMapFitBounds({
      places: placedLocalProfiles,
      options: {
        padding: {
          top: visibleMapViewportPadding.top,
          left: visibleMapViewportPadding.left,
          right: visibleMapViewportPadding.right,
          bottom: visibleMapViewportPadding.bottom,
        },
        duration: 300,
      },
    })
  }

  return {
    showExtend: store.showExtend,
    setShowExtend,
    onExtend,
  }
}
