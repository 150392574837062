import { useCallback, useRef } from 'react'

import { useTouchGesture } from '@src/core/components/touch-gesture/useTouchGesture'

interface usePreventSelfHorizontalScrollOnVerticalScrollProps {
  targetRef: React.RefObject<HTMLElement>
}
export function usePreventSelfHorizontalScrollOnVerticalScroll({
  targetRef,
}: usePreventSelfHorizontalScrollOnVerticalScrollProps) {
  const initialMoveTypeRef = useRef<'None' | 'Horizontal' | 'Vertical'>('None')

  const handleOnGestureSwipe: Required<
    Parameters<typeof useTouchGesture>[0]
  >['onSwipe'] = useCallback(({ moveType }) => {
    // 처음 move 이벤트 발생시에만 overflow hidden 스타일을 적용해요.
    if (initialMoveTypeRef.current !== 'None') return
    if (initialMoveTypeRef.current === 'None') {
      initialMoveTypeRef.current = moveType
    }

    if (moveType === 'Vertical' && targetRef.current) {
      targetRef.current.style.overflowX = 'hidden'
    }
  }, [])

  const handleOnGestureEnd: Required<
    Parameters<typeof useTouchGesture>[0]
  >['onGestureEnd'] = useCallback(() => {
    if (targetRef.current) {
      targetRef.current.style.overflowX = 'scroll'
      initialMoveTypeRef.current = 'None'
    }
  }, [])

  const { isTouchMove } = useTouchGesture({
    targetElementRef: targetRef,
    onSwipe: handleOnGestureSwipe,
    onGestureEnd: handleOnGestureEnd,
  })

  return {
    isSwiping: isTouchMove,
  }
}
