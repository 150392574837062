// 당근 앱버젼을 체크하는 함수
export function satisfyVersion({
  os,
  version,
  androidVersionCheck,
  iosVersionCheck,
}: {
  os: 'ANDROID' | 'IOS' | 'UNKNOWN'
  version: string | null
  androidVersionCheck?: string
  iosVersionCheck?: string
}) {
  if (!version) {
    return false
  }

  if (os === 'ANDROID' && androidVersionCheck) {
    return satisfiesNative(version, androidVersionCheck)
  }

  if (os === 'IOS' && iosVersionCheck) {
    return satisfiesNative(version, iosVersionCheck)
  }

  return false
}

function parseVersion(version: string) {
  const [major, minor, patch] = version.split('.').map(Number)
  return { major, minor, patch }
}

function compareVersions(v1: string, v2: string) {
  const version1 = parseVersion(v1)
  const version2 = parseVersion(v2)

  if (version1.major !== version2.major) {
    return version1.major - version2.major
  }
  if (version1.minor !== version2.minor) {
    return version1.minor - version2.minor
  }
  return version1.patch - version2.patch
}

function satisfiesNative(version: string, range: string): boolean {
  const operators = ['>=', '<=', '>', '<', '=']
  let operator = '='
  let rangeVersion = range

  for (const op of operators) {
    if (range.startsWith(op)) {
      operator = op
      rangeVersion = range.slice(op.length).trim()
      break
    }
  }

  const comparison = compareVersions(version, rangeVersion)

  switch (operator) {
    case '>=':
      return comparison >= 0
    case '<=':
      return comparison <= 0
    case '>':
      return comparison > 0
    case '<':
      return comparison < 0
    case '=':
    default:
      return comparison === 0
  }
}
