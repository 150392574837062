import { bbox } from '@turf/bbox'
import { featureCollection, point } from '@turf/helpers'

import { type LngLat } from '@src/core/types/geo'
import { type Point } from '@src/core/types/pixel'

export function lngLatToPoint({
  lngLat: { lng, lat },
  zoom,
  tileSize = 512,
}: {
  lngLat: LngLat
  zoom: number
  tileSize?: number
}): Point {
  const scale = tileSize * Math.pow(2, zoom)
  const lambda = (lng + 180) / 360
  const phi =
    (1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
    2
  return { x: lambda * scale, y: phi * scale }
}

export function pointToLngLat({
  point: { x, y },
  zoom,
  tileSize = 512,
}: {
  point: Point
  zoom: number
  tileSize?: number
}): LngLat {
  const scale = tileSize * Math.pow(2, zoom)
  const lng = (x / scale) * 360 - 180
  const n = Math.PI - 2 * Math.PI * (y / scale)
  const lat = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)))
  return { lng, lat }
}

/**
 * 경도/위도 좌표 목록으로부터 북서(NW)와 남동(SE) 경계 좌표를 계산합니다.
 *
 * @param lngLats - 경도(longitude)/위도(latitude) 좌표 배열
 * @returns 북서(northWest)와 남동(southEast) 경계 좌표
 *
 * @example
 * const points = [
 *   { lng: 127.02, lat: 37.49 },
 *   { lng: 127.03, lat: 37.50 }
 * ]
 * const bounds = getNwSeByLngLat(points)
 * // returns:
 * // {
 * //   northWest: { longitude: 127.02, latitude: 37.50 }, // 최소 경도, 최대 위도
 * //   southEast: { longitude: 127.03, latitude: 37.49 }  // 최대 경도, 최소 위도
 * // }
 *
 * @remarks
 * - bbox 함수는 [minX, minY, maxX, maxY] 형식으로 반환
 * - minX(box[0]) = 최서단 경도 = northWest.longitude
 * - minY(box[1]) = 최남단 위도 = southEast.latitude
 * - maxX(box[2]) = 최동단 경도 = southEast.longitude
 * - maxY(box[3]) = 최북단 위도 = northWest.latitude
 */
export function getNwSeByLngLat(lngLats: LngLat[] = []) {
  const fc = featureCollection(lngLats.map((p) => point([p.lng, p.lat], {})))
  // https://turfjs.org/docs/api/bbox
  const box = bbox(fc)

  return {
    northWest: {
      longitude: box[0],
      latitude: box[3],
    },
    southEast: {
      longitude: box[2],
      latitude: box[1],
    },
  }
}
