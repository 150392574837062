import { useEffect, useState } from 'react'

import SearchSuggestion from './SearchSuggestion'
import * as css from './SearchSuggestions.css'
import { useSearchLocalProfilesApiQuery } from '../_api-queries/searchLocalProfiles'
import { useSearchBusinessPlacesQueryRequestParamsStore } from '../_store/search-business-places-query-request-params'

const SearchSuggestions = () => {
  const { searchCategorySuggestions, status } = useSearchLocalProfilesApiQuery()

  const [suggestions, setSuggestion] = useState(searchCategorySuggestions)

  const query = useSearchBusinessPlacesQueryRequestParamsStore((s) => s.query)

  useEffect(() => {
    if (status === 'success') {
      setSuggestion(searchCategorySuggestions)
    }
  }, [searchCategorySuggestions, status])

  if (!suggestions || suggestions.suggestions.length === 0) {
    return null
  }

  return (
    <div className={css.chips} role="list" aria-label="연관 검색어">
      {suggestions.suggestions.map((suggestion, index) => (
        <SearchSuggestion
          key={suggestion.keyword}
          index={index}
          suggestion={suggestion}
          isActive={query === suggestion.keyword}
        />
      ))}
    </div>
  )
}

export default SearchSuggestions
