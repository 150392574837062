import HomeSkeletonFragment from '@src/fragments/HomeSkeletonFragment'

// Tech Spec: https://www.notion.so/daangn/Tech-Spec-Webview-Entry-Loading-FCP-48afd5f704d347a991be56b48be74df1
const ActivitySkeletons = () => {
  const url = new URL(window.location.href)

  switch (url.pathname) {
    case '/':
    case '/home':
    case '/home/':
      return <HomeSkeletonFragment />
  }

  return null
}

export default ActivitySkeletons
