import { createContext, useContext, useRef } from 'react'
import { createStore, useStore } from 'zustand'

import { type SnapPointType, type Anchor } from '../types'
import { getAnchorPosition } from '../utils'
/**
 * 컴포넌트 외부에서 접근할 필요가 있는 상태들을 담고 있어요.
 * height, snapPoint, ref을 제공해요.
 *
 * Why zustand?
 * React State를 사용할 경우, Provider의 State가 변경 되기 때문에
 * Provider 하위의 모든 컴포넌트가 리렌더링 되는 문제가 있어요.
 * Provider는 노출 범주를 예상할수 없이 넓을수 있어 퍼포먼스 이슈가 발생할 수 있어요.
 *
 * Why not global?
 * Zustand를 global 스콥에 정의해서 사용하는 경우, 의도치 않는 상태 변경이 발생할 수 있어요.
 * 또한 두번 이상 바텀시트를 사용하는 경우에 대한 대응이 되지 않아요.
 * 바텀시트는 UI 라이브러리이기 때문에 위와 같은 문제가 크르티컬해요.
 */

export interface BottomSheetStoreType {
  snapPoint: SnapPointType
  setSnapPoint: (snapPoint: SnapPointType) => void
  position: number
  setPosition: (position: number) => void
  bottomSheetRef: React.RefObject<HTMLDivElement>
}

type BottomSheetStore = ReturnType<typeof createBottomSheetStore>

const createBottomSheetStore = ({ position }: { position: number }) => {
  return createStore<BottomSheetStoreType>()((set) => ({
    snapPoint: 'middle',
    setSnapPoint: (id: SnapPointType) => {
      return set({ snapPoint: id })
    },
    position,
    setPosition: (position: number) => {
      return set({ position })
    },
    bottomSheetRef: { current: null },
  }))
}

const BottomSheetContext = createContext<BottomSheetStore | null>(null)

export const useBottomSheetSnapPoint = () => {
  const store = useContext(BottomSheetContext)
  if (!store) throw new Error('Missing bottom-sheet.Provider in the tree')

  const snapPoint = useStore(store, (s) => s.snapPoint)
  const setSnapPoint = useStore(store, (s) => s.setSnapPoint)
  return { snapPoint, setSnapPoint }
}

export const useBottomSheetHeight = () => {
  const store = useContext(BottomSheetContext)
  if (!store) throw new Error('Missing bottom-sheet.Provider in the tree')

  const position = useStore(store, (s) => s.position)
  return window.innerHeight - position
}

export const useBottomSheetPosition = () => {
  const store = useContext(BottomSheetContext)
  if (!store) throw new Error('Missing bottom-sheet.Provider in the tree')

  const position = useStore(store, (s) => s.position)
  const setPosition = useStore(store, (s) => s.setPosition)
  return { position, setPosition }
}

export const useBottomSheetRef = () => {
  const store = useContext(BottomSheetContext)
  if (!store) throw new Error('Missing bottom-sheet.Provider in the tree')

  const bottomSheetRef = useStore(store, (s) => s.bottomSheetRef)
  return bottomSheetRef
}

export const BottomSheetProvider = ({
  anchors,
  children,
}: {
  anchors: Anchor[]
  children: React.ReactNode
}) => {
  const initialAnchor = anchors.find((anchor) => anchor.snapPoint === 'middle')

  const initialPosition = initialAnchor
    ? getAnchorPosition(initialAnchor, window.innerHeight)
    : 0

  const store = useRef(
    createBottomSheetStore({
      position: initialPosition,
    })
  ).current

  return (
    <BottomSheetContext.Provider value={store}>
      {children}
    </BottomSheetContext.Provider>
  )
}
