import { IconCameraLine } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'
import React, { type MouseEvent } from 'react'

import * as css from '@src/pages/preview-step/preview-image/PreviewImage.css'

interface Props {
  imageUrl?: string
  onClick?: (e: MouseEvent) => void
}
const PreviewImage = ({ imageUrl, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className={css.base({
        appearance: imageUrl ? 'outline' : 'dashed',
      })}
    >
      {imageUrl ? (
        <img className={css.image} alt="프로필" src={imageUrl} />
      ) : (
        <>
          <IconCameraLine size={18} color={vars.$scale.color.gray500} />
          <span className={css.emptyDescription}>사진 추가</span>
        </>
      )}
    </button>
  )
}

export default PreviewImage
