import * as css from './BottomSheetSection.css'

interface BottomSheetPostSectionProps {
  children: React.ReactNode
  title: string
}
const BottomSheetPostSection = ({
  children,
  title,
}: BottomSheetPostSectionProps) => {
  return (
    <section className={css.postSectionContainer}>
      <h2
        className={`${css.postSectionTitleWrapper} ${css.postSectionPaddingAdjust}`}
      >
        {title}
      </h2>
      {children}
    </section>
  )
}

export default BottomSheetPostSection
