import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useRef, useMemo } from 'react'

import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import SectionBenefitCuration from '@src/pages/home-feed-step/section/section-benefit-curation/SectionBenefitCuration'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import { BENEFIT_CURATION_SECTION_INFO } from './sectionKeyInfo'
import { useGetSectionQuery } from '../_api-queries/useGetSectionQuery'
import { coordinatesStore } from '../_store/coordinatesStore'

interface SectionBenefitCurationRootProps {
  userCoordinates: TypeLatLng
}

const SectionBenefitCurationRoot = ({
  userCoordinates,
}: SectionBenefitCurationRootProps) => {
  const sectionRef = useRef<HTMLElement>(null)
  const { data } = useGetSectionQuery({
    sectionViewTypes: 'SECTION_VIEW_TYPE_LOCAL_PROFILE_WITH_BENEFIT_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_LOCAL_PROFILE_WITH_BENEFIT_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...BENEFIT_CURATION_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const { localProfileWithBenefitSection } = data?.sectionItems[0] ?? {}

  return (
    localProfileWithBenefitSection && (
      <LocalMapImpressionObserver
        log={impressedLogParams}
        observeTargetRef={sectionRef}
      >
        <SectionBenefitCuration
          section={localProfileWithBenefitSection}
          ref={sectionRef}
        />
      </LocalMapImpressionObserver>
    )
  )
}

export default React.memo(SectionBenefitCurationRoot)
