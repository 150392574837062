import { type V1LocalProfileWithBenefitPerTabSectionItem } from '@daangn/local-map-api'
import IconStarFill from '@daangn/react-monochrome-icon/lib/IconStarFill'
import React, { useMemo, useRef } from 'react'

import { LazyImage } from '@src/core/components/lazy-load-image'
import { Tag } from '@src/core/components/tag'
import { TagGroup } from '@src/core/components/tag-group'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'
import { color } from '@src/services/styles/color'

import * as css from './TabbedBenefitCurationItem.css'
import {
  TABBED_BENEFIT_CURATION_SECTION_INFO,
  genSectionReferrer,
} from '../sectionKeyInfo'

const REFERRER = genSectionReferrer(
  TABBED_BENEFIT_CURATION_SECTION_INFO.sectionKey
)

const TabbedBenefitCurationItem: React.FC<{
  item: V1LocalProfileWithBenefitPerTabSectionItem
  index: number
}> = ({ item, index }) => {
  const { openTarget } = useLocalMapOpenTarget()
  const {
    getImpressedHomeFeedSectionItemLogParams,
    clickedHomeFeedSectionItemLog,
  } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const {
    localProfileName,
    imageUrl,
    categoryName,
    reviewStarRating,
    reviewCount,
    benefitDescription,
    scheme,
    localProfileId,
    couponBoxId,
  } = item
  const impressionLogParams = useMemo(() => {
    return getImpressedHomeFeedSectionItemLogParams({
      itemDataType: 'local_profile',
      itemIndex: index,
      itemId: localProfileId,
      ...TABBED_BENEFIT_CURATION_SECTION_INFO,
    })
  }, [getImpressedHomeFeedSectionItemLogParams, index, localProfileId])
  const ref = useRef<HTMLButtonElement>(null)
  return (
    <LocalMapImpressionObserver
      log={impressionLogParams}
      observeTargetRef={ref}
    >
      <button
        ref={ref}
        className={css.container}
        aria-label={`${localProfileName}의 혜택 보기`}
        onClick={() => {
          clickedHomeFeedSectionItemLog({
            itemIndex: index,
            itemDataType: 'local_profile',
            itemId: localProfileId,
            relatedItems: [{ itemDataType: 'coupon', itemId: couponBoxId }],
            ...TABBED_BENEFIT_CURATION_SECTION_INFO,
            ...getMapLogParams(),
          })
          openTarget({
            targetUri: scheme,
            referrer: REFERRER,
          })
        }}
      >
        <LazyImage
          src={imageUrl}
          className={css.image}
          alt={`${localProfileName}의 대표 사진`}
        />
        <div className={css.summaries}>
          <div className={css.nameContainer}>
            <h3 className={css.name}>{localProfileName}</h3>
            <span className={css.category}>{categoryName}</span>
          </div>
          {reviewCount > 0 && (
            <TagGroup medium isSelected={false}>
              {reviewStarRating > 0 && (
                <Tag
                  size="medium"
                  icon={
                    <IconStarFill
                      color={color.carrot500}
                      width={15}
                      height={15}
                    />
                  }
                  color="gray900"
                  bold
                >
                  <span className={css.rating}>
                    {(Math.floor(reviewStarRating * 10) / 10).toFixed(1)}
                  </span>
                </Tag>
              )}
              <Tag size="medium" color="gray600">
                후기 {reviewCount}
              </Tag>
            </TagGroup>
          )}
          <p className={css.benefit}>{benefitDescription}</p>
        </div>
      </button>
    </LocalMapImpressionObserver>
  )
}

export default TabbedBenefitCurationItem
