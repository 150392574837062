import { type MoveCase } from './type'
import { type LocalMapStepKeys } from '../useStepRouter'

/**
 * 라우팅 케이스 추가하는 방법
 *
 * - step이 추가되었을 때
 *   1. CASE_FROM_{STEP} 생성 및 액션에 따른 이동 정의
 *   2. actionName을 키로 ActionDetail 생성
 *   3. MoveCase에 추가
 *   4. 신규 생성된 스탭으로 이동하는 케이스 추가
 *
 * - action만 추가되었을 때
 *   1. 이동하는 스텝에서 CASE_FROM_{STEP}에 actionName을 키로 ActionDetail 추가
 *   2. MoveCase에 추가
 */

/**
 * 홈스탭에서 이동하는 케이스
 */
const CASE_FROM_HOME = {
  click_searchBar: {
    target: 'searchDiscovery',
  },
  click_launchpad: {
    target: 'searchResult',
  },
  click_pickablePoi: {
    target: 'preview',
  },
  click_searchRecommendationChip: {
    target: 'searchResult',
  },
  click_map: {
    target: 'home',
    method: 'replace',
  },
} as const satisfies MoveCase

/**
 * 검색 디스커버리에서 이동하는 케이스
 */
const CASE_FROM_SEARCH_DISCOVERY = {
  click_recentKeyword: {
    target: 'searchResult',
    method: 'replace',
  },
  click_autoCompleteKeyword: {
    target: 'searchResult',
    method: 'replace',
  },
  click_autoCompleteLp: {
    target: 'searchedPreview',
    method: 'replace',
  },
  submit_search: {
    target: 'searchResult',
    method: 'replace',
  },
} as const satisfies MoveCase

/**
 * 검색 결과에서 이동하는 케이스
 */
const CASE_FROM_SEARCH_RESULT = {
  click_navigationClose: {
    target: 'home',
  },
  click_navigationInput: {
    target: 'searchDiscovery',
  },
  click_marker: {
    target: 'searchedPreview',
  },
  click_pickablePoi: {
    target: 'searchedPreview',
  },
  click_categorySuggestions: {
    target: 'searchResult',
    method: 'replace',
  },
  click_filter: {
    target: 'searchResult',
    method: 'replace',
  },
  click_clusterMarker: {
    target: 'searchedPreview',
  },
  afterSearch_singleSearchResult: {
    target: 'searchedPreview',
  },
  schemeAccess_emptyQuery: {
    target: 'home',
  },
} as const satisfies MoveCase

/**
 * 프리뷰에서 이동하는 케이스
 */
const CASE_FROM_PREVIEW = {
  click_pickablePoi: {
    target: 'preview',
    method: 'replace',
  },
  swipeUp_preview: {
    target: 'externalPage',
    method: 'push',
  },
} as const satisfies MoveCase

/**
 * 검색프리뷰에서 이동하는 케이스
 */
const CASE_FROM_SEARCHED_PREVIEW = {
  click_navigationClose: {
    target: 'home',
  },
  click_navigationInput: {
    target: 'searchDiscovery',
    method: 'replace',
  },
  click_marker: {
    target: 'searchedPreview',
    method: 'replace',
  },
  click_clusterMarker: {
    target: 'searchedPreview',
    method: 'replace',
  },
  click_pickablePoi: {
    target: 'searchedPreview',
    method: 'replace',
  },
  swipeUp_preview: {
    target: 'externalPage',
    method: 'push',
  },
  // 검색결과에서 replace로 검색프리뷰로 접근되었을 때 기존과 동일하게 back하면 홈스탭으로 가버리기 때문에, 이 경우에는 replace가 되어야 함.
  click_categorySuggestionsOnReplaceAccess: {
    target: 'searchResult',
    method: 'replace',
  },
} as const satisfies MoveCase

const CASE_FROM_EXTERNAL_PAGE = {} as const satisfies MoveCase

export const MOVE_CASE = {
  home: CASE_FROM_HOME,
  searchDiscovery: CASE_FROM_SEARCH_DISCOVERY,
  searchResult: CASE_FROM_SEARCH_RESULT,
  preview: CASE_FROM_PREVIEW,
  searchedPreview: CASE_FROM_SEARCHED_PREVIEW,
  externalPage: CASE_FROM_EXTERNAL_PAGE,
} as const satisfies Record<LocalMapStepKeys, MoveCase>
