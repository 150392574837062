import { BoxButton } from '@daangn/sprout-components-button'
import { assignInlineVars } from '@vanilla-extract/dynamic'

import { useBottomSheetHeight } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { useReloadGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'

import * as css from './ErrorBottomSheetContent.css'

export const ErrorBottomSheetContent = () => {
  const bottomSheetHeight = useBottomSheetHeight()
  const reloadGeolocation = useReloadGeolocation()

  const handleClick = () => {
    reloadGeolocation()
  }

  return (
    <div
      className={css.container}
      style={assignInlineVars({
        [css.containerHeight]: `${Math.max(bottomSheetHeight - 26, 300)}px`,
      })}
    >
      <div className={css.centerContent}>
        <div className={css.text}>
          주변 정보를 제공할 수 없습니다.
          <br />
          <span className={css.subText}>
            설정에서 위치 정보 사용을 허용해주세요.
          </span>
        </div>

        <BoxButton variant="secondary" onClick={handleClick}>
          위치 설정하기
        </BoxButton>
      </div>
    </div>
  )
}
