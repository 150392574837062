import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { LOCAL_MAP_PERSISTENT_STORAGE_STATE_KEY } from '@src/features/persistent/constants'

export interface PersistedRecentKeywordHistory {
  query: string
  eventTime: string
}

interface RecentKeywordStore {
  keywords: PersistedRecentKeywordHistory[]
  setRecentKeyword: (keyword: string) => void
  removeRecentKeyword: (keyword: string) => void
  removeAllRecentKeywords: () => void
}

export const usePersistedRecentKeywords = create<
  RecentKeywordStore,
  [['zustand/persist', RecentKeywordStore]]
>(
  persist(
    (set) => {
      const {
        state: existedValues,
      }: {
        state: RecentKeywordStore
      } = JSON.parse(
        window.localStorage.getItem(LOCAL_MAP_PERSISTENT_STORAGE_STATE_KEY) ??
          '{}'
      ) ?? {
        state: {},
      }

      const setRecentKeyword = (keyword: string) => {
        const query = keyword.trim()
        const newKeyword = {
          query,
          eventTime: new Date().toISOString(),
        }

        set((state) => ({
          keywords: [
            newKeyword,
            ...state.keywords.filter(
              (recentKeyword) => recentKeyword.query !== query
            ),
          ].slice(0, 31),
        }))
      }

      const removeRecentKeyword = (keyword: string) => {
        set((state) => ({
          keywords: state.keywords.filter(
            (recentKeyword) => recentKeyword.query !== keyword
          ),
        }))
      }

      const removeAllRecentKeywords = () => {
        set(() => ({ keywords: [] }))
      }

      return {
        keywords: existedValues?.keywords ?? [],
        setRecentKeyword,
        removeRecentKeyword,
        removeAllRecentKeywords,
      }
    },
    {
      // Local storage가 default로 사용되요.
      // TODO: 실제로 저장되는 key를 확인해서 적어두어요. (혹시 prefix같은게 붙을 수도 있어요.)
      name: `${LOCAL_MAP_PERSISTENT_STORAGE_STATE_KEY}/recent-keyword`,
    }
  )
)
