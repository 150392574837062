import {
  IconPlusCircleLine,
  IconPhoneLine,
  IconDot3HorizontalChatbubbleLeftLine,
  IconXmarkCircleLine,
  IconStarFill,
  IconPenHorizlineLine,
} from '@daangn/react-monochrome-icon'
import { CapsuleToggleButton } from '@daangn/sprout-components-button'
import { vars } from '@seed-design/design-token'
import { type MouseEvent, useEffect, useRef, useState } from 'react'

import { Badge } from '@src/core/components/badge'
import CertificatedBadge from '@src/core/components/certificated-badge/CertificatedBadge'
import { useMap } from '@src/core/components/karrot-map/map/MapProvider'
import { Tag } from '@src/core/components/tag'
import { TagGroup } from '@src/core/components/tag-group'
import { bizProfileSdk } from '@src/core/lib/bizProfileSdk'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import { useToastSnackbar } from '@src/core/lib/bridge/hooks/useToastSnackbar'
import { usePlantaeFetch } from '@src/core/lib/plantae/useFetchPlantae'
import { getDeviceType } from '@src/core/utils/deviceType'
import { convertLngLatNullable } from '@src/core/utils/geo/convert'
import { isFishShapedBreadCategoryId } from '@src/features/fish-shaped-bread/isFishShapedBreadCategoryId'
import { useSearchLocalProfilesApiQuery } from '@src/pages/home-search-result-step/_api-queries/searchLocalProfiles'
import { useOpenLocalProfileSuggestionUpdateBackgroundImage } from '@src/pages/preview-step/hooks/useOpenLocalProfileSuggestionUpdateBackgroundImage'
import PreviewImage from '@src/pages/preview-step/preview-image/PreviewImage'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useStepRouter } from '@src/services/hooks/useStepRouter'
import { useFrom } from '@src/services/log/hooks/useFrom'
import { useLpSearchLogger } from '@src/services/log/hooks/useLpSearchLogger'
import { useSearchLogger } from '@src/services/log/hooks/useSearchLogger'
import { useZeroDepthContextLogger } from '@src/services/log/hooks/useZeroDepthContextLogger'
import { getExternalReferrerEntry } from '@src/services/store/externalAccessValues'

import { usePreviewQuery } from './hooks/usePreviewQuery'
import { useSafeNumberQuery } from './hooks/useSafeNumberQuery'
import * as css from './Preview.css'
import PreviewMarker from './PreviewMarker'
import * as utils from './utils'

const Preview = () => {
  const geolocation = useGeolocation()
  const {
    getValidatedParams,
    prevStepInfo: { prevStep },
    stepType,
  } = useStepRouter()
  const params = getValidatedParams(stepType as 'preview' | 'searchedPreview')
  const toastSnackbar = useToastSnackbar()

  const { fetch } = usePlantaeFetch()
  const openLocalProfileSuggestionUpdateBackgroundImage =
    useOpenLocalProfileSuggestionUpdateBackgroundImage()
  const { openTarget } = useLocalMapOpenTarget()
  const { data, refetch } = usePreviewQuery(params.localProfileId!)
  const { fetchSafeNumber } = useSafeNumberQuery()
  const [loadingCall, setLoadingCall] = useState(false)
  const { map } = useMap()
  const { from } = useFrom()
  const { impressedLocalMapHomePreviewV1Log, clickedLocalMapHomePreviewV1Log } =
    useZeroDepthContextLogger()
  const {
    impressedLocalMapSearchResultPreviewV1,
    clickedLocalMapSearchResultPreviewV1,
  } = useLpSearchLogger()
  const { clickedSearchResultArticleLog } = useSearchLogger()
  const { placedLocalProfiles } = useSearchLocalProfilesApiQuery()

  const REFERRER =
    stepType === 'preview'
      ? 'localMap_home_map_preview'
      : 'localMap_searchResult_map_preview'

  const ref = useRef({
    from,
    previewRefetch: refetch,
    getFunnelFrom,
    impressedLocalMapHomePreviewV1Log,
    impressedLocalMapSearchResultPreviewV1,
  })
  ref.current.from = from
  ref.current.previewRefetch = refetch
  ref.current.getFunnelFrom = getFunnelFrom
  ref.current.impressedLocalMapHomePreviewV1Log =
    impressedLocalMapHomePreviewV1Log
  ref.current.impressedLocalMapSearchResultPreviewV1 =
    impressedLocalMapSearchResultPreviewV1

  const distance = utils.toDistanceTextFromLatLonInKm(
    geolocation.currentPosition,
    convertLngLatNullable(data?.localProfilePreview.latlng)
  )

  // 프리뷰 페이지에서 아래 추가 패딩 처리를 위한 변수 (네이티브 하단 탭바가 없어서)
  const isPreview = location.href.includes('internal.krrt.io')
  const isIOS = getDeviceType() === 'ios'
  const isPreviewInIOS = isPreview && isIOS

  const description =
    data?.localProfilePreview.description ||
    data?.localProfilePreview.roadAddress ||
    data?.localProfilePreview.landAddress

  const showBadge =
    data?.localProfilePreview.isReservable ||
    data?.localProfilePreview.hasCoupon

  const showCall =
    data?.localProfilePreview.phoneCall?.phoneNumber ||
    data?.localProfilePreview.phoneCall?.safeNumber ||
    data?.localProfilePreview.phoneCall?.createSafeNumberRequest
      ?.businessProfileId

  // 비즈인증 OR 개인 인증
  const isCertificated =
    data?.localProfilePreview.businessVerified ||
    data?.localProfilePreview.identityVerified

  useEffect(() => {
    const onVisibilityChange = () => ref.current.previewRefetch()
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        ref.current.impressedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          funnel_from: ref.current.getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        if (!params.localProfileId) {
          break
        }

        ref.current.impressedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId,
          item_data_type: 'local_profile',
          funnel_from: ref.current.getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      default:
        break
    }
  }, [impressedLocalMapHomePreviewV1Log, map, params.localProfileId])

  function getFunnelFrom() {
    if (prevStep === 'searchDiscovery') {
      return 'searchExcution_suggested'
    }

    if (
      placedLocalProfiles.findIndex((p) => p.id === params.localProfileId) > -1
    ) {
      return 'marker'
    }

    return 'backgroundPoi'
  }

  async function onCallClick() {
    if (loadingCall) {
      return
    }

    logCallEvent()
    setLoadingCall(true)

    const loggingCallbackUrl =
      data?.localProfilePreview.phoneCall?.createSafeNumberRequest
        ?.loggingCallbackUrl
    if (loggingCallbackUrl) {
      fetch(loggingCallbackUrl)
    }

    switch (data?.localProfilePreview.phoneCall?.phoneCallType) {
      case 'PHONE_CALL_TYPE_SAFE_NUMBER': {
        location.href = `tel:${data?.localProfilePreview.phoneCall?.safeNumber}`
        break
      }

      case 'PHONE_CALL_TYPE_PHONE_NUMBER': {
        location.href = `tel:${data?.localProfilePreview.phoneCall?.phoneNumber}`
        break
      }

      case 'PHONE_CALL_TYPE_CREATE_SAFE_NUMBER_REQUEST': {
        if (
          data?.localProfilePreview.phoneCall?.createSafeNumberRequest
            ?.businessProfileId
        ) {
          const { safeNumber } = await fetchSafeNumber(
            data?.localProfilePreview.phoneCall?.createSafeNumberRequest
              ?.businessProfileId
          )

          location.href = `tel:${safeNumber}`
        }

        break
      }
    }

    setLoadingCall(false)
  }

  function logCallEvent() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'call',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break
      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'call',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break
      default:
        break
    }
  }

  function logLpDetailVisit() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'viewLocalProfileHome',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'viewLocalProfileHome',
          funnel_from: getFunnelFrom(),
          zoom_level: map.getZoom(),
          center_coordinates: {
            latitude: map.getCenter().lat,
            longitude: map.getCenter().lng,
          },
        })
        clickedSearchResultArticleLog({
          documentId: params.localProfileId!,
          documentType: 'local_profile',
          viewType: 'single_pre_view',
        })
        break

      default:
        break
    }
  }

  function logChatClick() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'chat',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'chat',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      default:
        break
    }
  }

  function logReviewClick() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'review',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'review',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      default:
        break
    }
  }

  function logSuggestClick() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'suggest',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'suggest',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      default:
        break
    }
  }

  function logDeleteClick() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'delete',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'delete',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      default:
        break
    }
  }

  function logUploadImageClick() {
    if (!map) {
      return
    }

    const zoom_level = map.getZoom()
    const center = map.getCenter()

    switch (ref.current.from) {
      case 'home':
        clickedLocalMapHomePreviewV1Log({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'imageUpload',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      case 'search':
        clickedLocalMapSearchResultPreviewV1({
          item_id: params.localProfileId!,
          item_data_type: 'local_profile',
          action_type: 'imageUpload',
          funnel_from: getFunnelFrom(),
          zoom_level,
          center_coordinates: {
            latitude: center.lat,
            longitude: center.lng,
          },
        })
        break

      default:
        break
    }
  }

  function handleOnClickUploadPreviewImage(e: MouseEvent) {
    if (data?.localProfilePreview.previewImageUrl) {
      return
    }
    e.stopPropagation()

    if (!data?.localProfilePreview.id) {
      toastSnackbar.openSnackbar({
        body: '문제가 발생했어요. 잠시 후 다시 시도해주세요.',
      })

      return
    }

    logUploadImageClick()
    openLocalProfileSuggestionUpdateBackgroundImage({
      entry: 'localMap_preview',
      localProfileId: Number(data.localProfilePreview.id),
      cb: () => {
        return refetch()
      },
    })
  }

  function openTargetUri(
    targetUri: string,
    searchParams?: Record<string, string>
  ) {
    const targetUrl = new URL(targetUri)

    const remote = targetUrl.searchParams.get('remote')
    if (remote) {
      const remoteUrl = new URL(remote)

      if (searchParams) {
        for (const key in searchParams) {
          remoteUrl.searchParams.set(key, searchParams[key])
        }
      }

      targetUrl.searchParams.set('remote', remoteUrl.toString())
    }

    openTarget({
      targetUri: targetUrl.toString(),
      referrer: REFERRER,
    })
  }

  if (!data) {
    return null
  }

  const targetData = placedLocalProfiles.find(
    (lp) => lp.id === params.localProfileId
  )
  const previewName = targetData
    ? targetData.name
    : data.localProfilePreview.name
  const previewLatitude = targetData
    ? targetData.geoPoint?.latitude
    : data.localProfilePreview.latlng?.latitude
  const previewLongitude = targetData
    ? targetData.geoPoint?.longitude
    : data.localProfilePreview.latlng?.longitude

  return (
    <>
      <div
        className={css.root}
        style={{ paddingBottom: isPreviewInIOS ? '34px' : undefined }}
      >
        <div
          className={css.preview}
          onClick={() => {
            logLpDetailVisit()
            openTargetUri(data.localProfilePreview.localProfileTargetUri)
          }}
        >
          <div className={css.main}>
            <div>
              {data?.localProfilePreview.name && (
                <h2 className={css.header}>
                  <span className={css.title}>
                    {data?.localProfilePreview.name}
                  </span>
                  {data?.localProfilePreview.categoryName && (
                    <span className={css.category}>
                      {data?.localProfilePreview.categoryName}
                    </span>
                  )}
                  {isCertificated && (
                    <CertificatedBadge
                      style={{
                        marginLeft: '2px',
                        transform: 'translate(0, 2px)',
                      }}
                      enableHelpBubble={true}
                    />
                  )}
                </h2>
              )}

              <div className={css.info}>
                {description && (
                  <div className={css.description}>{description}</div>
                )}

                <div className={css.etc}>
                  <TagGroup isSelected={false}>
                    {data?.localProfilePreview.reviewStarRatingAverage && (
                      <Tag
                        bold
                        color="gray900"
                        icon={
                          <IconStarFill
                            width="15"
                            height="15"
                            color={vars.$semantic.color.primary}
                            style={{
                              marginRight: 2,
                              transform: 'translate(0, -2px)',
                            }}
                          />
                        }
                      >
                        {data?.localProfilePreview.reviewStarRatingAverage}
                      </Tag>
                    )}
                    {data?.localProfilePreview.reviewCount && (
                      <Tag color="gray700">
                        후기 {data?.localProfilePreview.reviewCount}
                      </Tag>
                    )}
                    {data?.localProfilePreview.followerCount && (
                      <Tag color="gray700">
                        단골 {data?.localProfilePreview.followerCount}
                      </Tag>
                    )}
                  </TagGroup>

                  <TagGroup isSelected={false}>
                    {distance !== null && <Tag color="gray700">{distance}</Tag>}
                    {data?.localProfilePreview.region3Name && (
                      <Tag color="gray700">
                        {data?.localProfilePreview.region3Name}
                      </Tag>
                    )}
                  </TagGroup>
                </div>
              </div>

              {showBadge && (
                <div className={css.badgeGroup}>
                  {data?.localProfilePreview.isReservable && (
                    <Badge
                      shape="square"
                      size="medium"
                      type="normal"
                      variant="accent"
                    >
                      예약
                    </Badge>
                  )}
                  {data?.localProfilePreview.hasCoupon && (
                    <Badge
                      shape="square"
                      size="medium"
                      type="normal"
                      variant="basic"
                    >
                      쿠폰
                    </Badge>
                  )}
                </div>
              )}
            </div>

            <PreviewImage
              onClick={handleOnClickUploadPreviewImage}
              imageUrl={data?.localProfilePreview.previewImageUrl}
            />
          </div>
        </div>

        <div className={css.actionGroup}>
          {data?.localProfilePreview.chatTargetUri && (
            <CapsuleToggleButton
              size="small"
              isSelected={false}
              onClick={() => {
                if (data?.localProfilePreview.chatTargetUri) {
                  if (data?.localProfilePreview.chatCallbackUrl) {
                    fetch(data?.localProfilePreview.chatCallbackUrl)
                  }

                  logChatClick()
                  openTargetUri(data?.localProfilePreview.chatTargetUri, {
                    referrer: REFERRER,
                  })
                }
              }}
            >
              <IconDot3HorizontalChatbubbleLeftLine
                size="16"
                style={{ marginRight: 4 }}
              />
              채팅
            </CapsuleToggleButton>
          )}

          {showCall && (
            <CapsuleToggleButton
              isLoading={loadingCall}
              minWidth={74}
              size="small"
              isSelected={false}
              onClick={onCallClick}
            >
              <IconPhoneLine size="16" style={{ marginRight: 4 }} />
              전화
            </CapsuleToggleButton>
          )}

          {(() => {
            /**
             * 리뷰는 유저별로 1개만 작성할 수 있어요
             * [정책] 리뷰를 작성했으면 후기보기 버튼이 보여요
             */
            if (data?.localProfilePreview.review?.viewerHasReview === true) {
              return (
                <CapsuleToggleButton
                  size="small"
                  isSelected={false}
                  onClick={() => {
                    if (data?.localProfilePreview.review?.targetUri) {
                      logReviewClick()
                      openTargetUri(data.localProfilePreview.review.targetUri, {
                        referrer: REFERRER,
                      })
                    }
                  }}
                >
                  <IconPenHorizlineLine size="16" style={{ marginRight: 4 }} />
                  내 후기 보기
                </CapsuleToggleButton>
              )
            } else if (
              data?.localProfilePreview.review?.viewerHasReview === false
            ) {
              return (
                <CapsuleToggleButton
                  size="small"
                  isSelected={false}
                  onClick={() => {
                    if (data?.localProfilePreview.review?.targetUri) {
                      logReviewClick()

                      openTargetUri(data.localProfilePreview.review.targetUri, {
                        referrer: REFERRER,
                      })
                    }
                  }}
                >
                  <IconPenHorizlineLine size="16" style={{ marginRight: 4 }} />
                  후기 작성
                </CapsuleToggleButton>
              )
            }

            /**
             * LP 주인이면 data.localProfilePreview.review가 null로 와요
             * [정책] LP 주인은 후기작성/보기 버튼을 노출하지 않아요
             */
            return null
          })()}

          {data?.localProfilePreview.suggestionTargetUri && (
            <CapsuleToggleButton
              size="small"
              isSelected={false}
              onClick={() => {
                if (data?.localProfilePreview.suggestionTargetUri) {
                  logSuggestClick()

                  openTargetUri(data.localProfilePreview.suggestionTargetUri, {
                    referrer: REFERRER,
                  })
                }
              }}
            >
              <IconPlusCircleLine size="16" style={{ marginRight: 4 }} />
              정보 제안
            </CapsuleToggleButton>
          )}

          {isFishShapedBreadCategoryId(
            data?.localProfilePreview.categoryId
          ) && (
            <CapsuleToggleButton
              size="small"
              isSelected={false}
              onClick={() => {
                logDeleteClick()

                const { referrer: externalReferrer } =
                  getExternalReferrerEntry()
                if (params.localProfileId) {
                  const dispose =
                    bizProfileSdk.webviewRouterWithEvent.openLocalProfileSuggestionDeleteWebviewWithStreamOn(
                      {
                        localProfileId: parseInt(params.localProfileId),
                        entry: REFERRER,
                        referrer: externalReferrer ?? REFERRER,
                      },
                      () => {
                        dispose()
                      }
                    )
                }
              }}
            >
              <IconXmarkCircleLine size="16" style={{ marginRight: 4 }} />
              없는 장소 제보
            </CapsuleToggleButton>
          )}
        </div>
      </div>
      {params.localProfileId && previewLatitude && previewLongitude && (
        <PreviewMarker
          localProfileId={params.localProfileId}
          name={previewName}
          lngLat={{
            lat: previewLatitude,
            lng: previewLongitude,
          }}
        />
      )}
    </>
  )
}

export default Preview
