import * as css from './CurrentPosition.css'

const CurrentPosition = () => {
  return (
    <div className={css.currentPosition}>
      <div className={css.dotBackground}></div>
      <div className={css.dot}></div>
    </div>
  )
}

export default CurrentPosition
