import { useEffect } from 'react'

import { IS_PRODUCTION, CLIENT_VERSION } from '@src/services/constants/constants'
import { logLevel } from '@src/services/log/logLevel'

import * as css from './VersionView.css'

const VersionView = () => {
  useEffect(() => {
    logLevel.log('Currently deployed version is: ', CLIENT_VERSION)
  }, [])

  if (IS_PRODUCTION) return null
  return <div className={css.container}>버전 {CLIENT_VERSION}</div>
}

export default VersionView
