import {
  IconChevronLeftFill,
  IconXmarkFill,
} from '@daangn/react-monochrome-icon'

import {
  MAP_SEARCH_RESULT_NAVBAR_INPUT_ID,
  iOSKeyboardFocusOn,
} from '@src/core/components/focus/iOSKeyboardFocus'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useExperiment } from '@src/services/experiment/experimentStore'

import * as css from './SearchSearchInput.css'

interface SearchPreviewSearchInputProps {
  initialQuery: string
}

const SearchPreviewSearchInput = (props: SearchPreviewSearchInputProps) => {
  const { stepMove, stepBack } = useLocalMapStepMovePolicy()

  const isBackButtonVisible =
    useExperiment('searched_result_back_btn')?.assignedGroupName !==
    'treatment1'

  const handleBackButtonClick = () => {
    stepBack()
  }

  const handleCloseButtonClick = () => {
    stepMove('searchedPreview', 'click_navigationClose', {
      selectedPin: null,
    })
  }

  const handleInputClick = () => {
    iOSKeyboardFocusOn(MAP_SEARCH_RESULT_NAVBAR_INPUT_ID)

    stepMove('searchedPreview', 'click_navigationInput', {
      query: props.initialQuery,
    })
  }

  return (
    <div className={css.container}>
      <div className={css.navbar}>
        {isBackButtonVisible && (
          <button
            aria-label="뒤로가기"
            className={css.backButton}
            onClick={handleBackButtonClick}
          >
            <IconChevronLeftFill width={22} height={22} />
          </button>
        )}
        <input
          role="search"
          type="search"
          aria-label="검색 화면 검색 창"
          placeholder="여기서 업체 검색"
          className={css.searchBar}
          value={props.initialQuery}
          onClick={handleInputClick}
          readOnly
        />
        <button
          aria-label="홈으로 가기"
          className={css.right}
          onClick={handleCloseButtonClick}
        >
          <IconXmarkFill width={22} height={22} />
        </button>
      </div>
    </div>
  )
}

export default SearchPreviewSearchInput
