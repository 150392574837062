import { useEffect, type RefObject } from 'react'

type EventHandler<K extends keyof HTMLElementEventMap> = (
  event: HTMLElementEventMap[K]
) => void
type EventMap = {
  [K in keyof HTMLElementEventMap]?: EventHandler<K> | EventHandler<K>[]
}

export function useMultipleEventListeners(
  ref: RefObject<HTMLElement | null>,
  eventMap: EventMap,
  dependencies: any[] = []
) {
  useEffect(() => {
    const element = ref.current
    if (!element) return

    Object.entries(eventMap).forEach(([eventType, handler]) => {
      if (handler) {
        if (Array.isArray(handler)) {
          handler.forEach((h) =>
            element.addEventListener(eventType, h as EventListener)
          )
        } else {
          element.addEventListener(eventType, handler as EventListener)
        }
      }
    })

    return () => {
      Object.entries(eventMap).forEach(([eventType, handler]) => {
        if (handler) {
          if (Array.isArray(handler)) {
            handler.forEach((h) =>
              element.removeEventListener(eventType, h as EventListener)
            )
          } else {
            element.removeEventListener(eventType, handler as EventListener)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...dependencies])
}
