import { getSafeAreaValue } from '@src/core/utils/getSafeAreaValue'

import { dom } from './dom'
import { type Anchor } from './types'

export const DOWN = -1 as const
export const UP = 1 as const
type Direction = typeof DOWN | typeof UP

/**
 * 적용된 position에 가장 가까운 anchor를 찾습니다.
 */
export function getNearestAnchor(
  position: number,
  positionerHeight: number,
  anchors: Anchor[]
): Anchor | undefined {
  if (anchors.length === 0) return undefined

  const anchorPositions = anchors.map((anchor) => {
    return getAnchorPosition(anchor, positionerHeight)
  })

  const nearestAnchorIndex = anchorPositions.reduce((prev, curr, index) => {
    const prevDiff = Math.abs(position - anchorPositions[prev])
    const currDiff = Math.abs(position - curr)
    if (prevDiff < currDiff) {
      return prev
    }
    return index
  }, 0)

  return anchors[nearestAnchorIndex]
}

/**
 * anchor의 position을 계산합니다.
 */
export function getAnchorPosition(
  anchor: Anchor,
  positionerHeight: number
): number {
  if (anchor.edge === 'top') {
    return anchor.offset + parseInt(getSafeAreaValue('top'), 10)
  }
  return positionerHeight - anchor.offset
}

/**
 * anchor의 sizingMode에 따라 position을 계산합니다.
 */
export function getAnchorSizingModePosition(
  anchor: Anchor,
  positionerId: string
) {
  return anchor.sizingMode === 'fixed'
    ? getAnchorPosition(anchor, dom.getPositionerHeight(positionerId))
    : Math.max(
        dom.getPositionerHeight(positionerId) -
          (dom.getContentScrollHeight(positionerId) +
            dom.getHandleHeight(positionerId)),
        0
      )
}

/*
  바텀시트가 드래그 가능한 최소, 최대 위치를 반환합니다.
*/
interface DragBoundary {
  min: number
  max: number
}

export function getDragBoundary(
  anchors: Anchor[],
  positionerId: string
): DragBoundary {
  const topAnchor = anchors.find((x) => x.snapPoint === 'top')
  const bottomAnchor = anchors.find((x) => x.snapPoint === 'bottom')

  const min = topAnchor
    ? getAnchorPosition(topAnchor, dom.getPositionerHeight(positionerId))
    : 0
  const max = bottomAnchor
    ? getAnchorPosition(bottomAnchor, dom.getPositionerHeight(positionerId))
    : dom.getPositionerHeight(positionerId)

  return { min, max }
}

/**
 * y축(세로) 이동 거리를 반환합니다.
 */
export function getMovementY(
  prevClientY: number | null,
  currentClientY: number
) {
  if (prevClientY === null) {
    return 0
  }

  return currentClientY - prevClientY
}

/**
 * 드래그 방향을 반환합니다.
 */
export function getMovementDirection(
  prevClientY: number | null,
  currentClientY: number
): Direction {
  return getMovementY(prevClientY, currentClientY) <= 0 ? DOWN : UP
}

/**
 * 스크롤바가 있는지 여부를 반환합니다.
 */
export function hasScrollBar(target: HTMLElement) {
  return target.scrollHeight > target.clientHeight
}

/**
 * 스크롤바가 맨 위에 있는지 여부를 반환합니다.
 */
export function isScrollAtTop(target: EventTarget & Element) {
  return target.scrollTop === 0
}
