export type BigPictureImageType =
  | 'crop'
  | 'cover' // crop 과 cover 는 동일한 행동자. object-fit: cover 와 동일
  | 'inside' // crop 되지 않은 전체 이미지
export interface BigPictureUrlParams {
  t?: BigPictureImageType
  q?: number // 이미지 품질. 0 ~ 100
  s?: {
    width: number
    height: number
  } // `${width}x${height}` 형태, ex) 300x300
  f?: 'jpg' | 'jpeg' | 'png' | 'webp' | 'svg' // 이미지 포맷. 기본값은 jpeg
}

// const BIG_PICTURE_ORIGIN = 'https://dtxw8q4qct0d4.cloudfront.net'

export const getBigPictureImageUrl = (
  url: string,
  options: BigPictureUrlParams
) => {
  // if (!isBigPictureImage(url)) {
  //   return url
  // }

  const [pathname, originalQuery] = url.split('?')
  const searchParams = new URLSearchParams(originalQuery)

  if (options.t) {
    searchParams.set('t', options.t)
  }
  if (options.q) {
    searchParams.set('q', options.q.toString())
  }
  if (options.s) {
    searchParams.set('s', `${options.s.width}x${options.s.height}`)
  }
  if (options.f) {
    searchParams.set('f', options.f)
  }
  return `${pathname}?${searchParams.toString()}`
}

// export const isBigPictureImage = (url: string) => {
//   return url.startsWith(BIG_PICTURE_ORIGIN)
// }
