import { useCallback, useMemo, useRef } from 'react'

interface SwipeOptions {
  /*
   * 스와이프 완료로 간주하는 최소 거리 (px) */
  thresholdDistance?: number
  /*
   * 스와이프 완료로 간주하는 최소 속도 (px/s) */
  thresholdVelocity?: number
  swipeDownCallback: () => void
  swipeDownFailCallback?: () => void
}

export function useSwipeDown({
  swipeDownCallback,
  swipeDownFailCallback,
  thresholdDistance = 50,
  thresholdVelocity = 0.5,
}: SwipeOptions) {
  const touchData = useRef({
    startY: 0,
    startTime: 0,
    isSwipingDown: false,
  })

  const onTouchStart = useCallback((event: TouchEvent) => {
    const touch = event.touches[0]

    touchData.current.startY = touch.clientY
    touchData.current.startTime = Date.now()
    touchData.current.isSwipingDown = false
  }, [])

  const onTouchMove = useCallback((event: TouchEvent) => {
    const touch = event.touches[0]

    const currentY = touch.clientY
    const distanceY = currentY - touchData.current.startY

    // 스와이프가 아래로 이동 중인지 확인
    if (distanceY > 0) {
      touchData.current.isSwipingDown = true
    }
  }, [])

  const onTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (touchData.current.isSwipingDown) {
        const touch = event.changedTouches[0]

        const endY = touch.clientY
        const endTime = Date.now()
        const distanceY = endY - touchData.current.startY
        const time = (endTime - touchData.current.startTime) / 1000
        const velocity = distanceY / time

        if (distanceY >= thresholdDistance && velocity >= thresholdVelocity) {
          // 스와이프 다운 완료
          swipeDownCallback()
        } else if (swipeDownFailCallback) {
          // 스와이프 다운 실패
          swipeDownFailCallback()
        }
      }
    },
    [
      swipeDownCallback,
      swipeDownFailCallback,
      thresholdDistance,
      thresholdVelocity,
    ]
  )

  return useMemo(
    () => ({ onTouchStart, onTouchMove, onTouchEnd }),
    [onTouchEnd, onTouchMove, onTouchStart]
  )
}
