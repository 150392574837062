import { getDocumentSeedScaleColor } from '@src/services/styles/seedDesign'

import { type MapType } from './Map'
import { type MapStyleType } from './mapResources'

interface GetSeedScaleColorChangedHandlerProps {
  mapInstance: MapType
  mapStyle: MapStyleType
}

export const getSeedScaleColorChangedHandler =
  ({ mapInstance, mapStyle }: GetSeedScaleColorChangedHandlerProps) =>
  (event: Event) => {
    const style =
      (event as CustomEvent).detail === 'dark' ? mapStyle.dark : mapStyle.light

    mapInstance.setStyle(style, {
      diff: true,
    })
  }

export const loadMapStyleAccordingToTheme = (mapStyle: MapStyleType) => {
  const { light: lightStyle, dark: darkStyle } = mapStyle

  return getDocumentSeedScaleColor() === 'dark' ? darkStyle : lightStyle
}
