import { type V1LocalStory } from '@daangn/local-map-api'
import { IconTriangleRightFill } from '@daangn/react-monochrome-icon'
import { useMemo, useRef } from 'react'

import { getBigPictureImageUrl } from '@src/core/components/big-picture/getBigPictureImageUrl'
import Impression from '@src/core/components/impression/Impression'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { SecondFullPrint } from '@src/core/utils/date'
import {
  genSectionReferrer,
  STORY_SECTION_INFO,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import * as css from './SectionLocalStoryItem.css'

interface SectionLocalBusinessStoryItemProps {
  localBusinessStory: V1LocalStory
  index: number
}

const REFERRER = genSectionReferrer(STORY_SECTION_INFO.sectionKey)

const SectionLocalBusinessStoryItem = ({
  localBusinessStory,
  index,
}: SectionLocalBusinessStoryItemProps) => {
  const {
    getImpressedHomeFeedSectionItemLogParams,
    clickedHomeFeedSectionItemLog,
  } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()
  const itemRef = useRef<HTMLDivElement>(null)

  const { openTarget } = useLocalMapOpenTarget()

  const handleClick = () => {
    clickedHomeFeedSectionItemLog({
      itemIndex: index,
      itemId: localBusinessStory.id,
      itemDataType: 'shortform',
      ...STORY_SECTION_INFO,
      ...getMapLogParams,
    })
    openTarget({
      targetUri: localBusinessStory.targetUri,
      referrer: REFERRER,
    })
  }

  const handleImpression = () => {
    if (localBusinessStory.impressionLoggingUrl) {
      fetch(localBusinessStory.impressionLoggingUrl, {
        method: 'GET',
      })
    }
  }

  const impressionLogParams = useMemo(
    () =>
      getImpressedHomeFeedSectionItemLogParams({
        itemIndex: index,
        itemId: localBusinessStory.id,
        itemDataType: 'shortform',
        ...STORY_SECTION_INFO,
      }),
    [getImpressedHomeFeedSectionItemLogParams, index, localBusinessStory.id]
  )

  return (
    <LocalMapImpressionObserver
      log={impressionLogParams}
      observeTargetRef={itemRef}
    >
      <Impression onImpression={handleImpression}>
        <div className={css.container}>
          <div className={css.card} onClick={handleClick} ref={itemRef}>
            <img
              className={css.image}
              src={getBigPictureImageUrl(localBusinessStory.thumbnailImageUrl, {
                s: {
                  width: 118 * 3,
                  height: 177 * 3,
                },
              })}
              alt="숏폼 썸네일"
            />
            <div className={css.overlay}>
              <div className={css.playBg}>
                <div className={css.play}>
                  <IconTriangleRightFill width={16} height={16} />
                </div>
              </div>
            </div>
          </div>
          <div className={css.text}>{localBusinessStory.description}</div>
        </div>
      </Impression>
    </LocalMapImpressionObserver>
  )
}

export default SectionLocalBusinessStoryItem
