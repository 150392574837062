import { useActivityParams } from '@stackflow/react'
import { LngLatBounds } from 'maplibre-gl'

import { useBottomSheetHeight } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import {
  INITIAL_MAP_CENTER,
  INITIAL_MAP_STATE,
  type T_INITIAL_MAP_STATE,
} from '@src/core/components/karrot-map/map/constants'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'
import { useGeolocation } from '@src/core/lib/bridge/context/GeolocationProvider'
import { type LngLat } from '@src/core/types/geo'
import { type Padding } from '@src/core/types/pixel'
import { lngLatToPoint, pointToLngLat } from '@src/core/utils/geo'
import { getSafeAreaValue } from '@src/core/utils/getSafeAreaValue'
import { MAP_TOP_AREA_SIZE_MAPPER } from '@src/services/constants/homeActivitySizeMapper'

/**
 * 정책에 따른 지도 초기 설정 값을 반환하는 훅
 *
 * @returns
 * initial map state
 */
export const useInitialMapState = (): T_INITIAL_MAP_STATE => {
  const urlParam = useActivityParams<{
    mapBounds?: string
  }>()
  const geolocation = useGeolocation()
  const { region } = useBridgeInfo()

  const bottomSheetHeight = useBottomSheetHeight()
  const homeTopAreaHeight = MAP_TOP_AREA_SIZE_MAPPER.home

  const initialMapCenter = getInitialMapCenter({
    geolocation,
    region,
  })

  const adjustedMapCenter = adjustMapCenter({
    offsetPadding: {
      top: homeTopAreaHeight + parseInt(getSafeAreaValue('top')),
      right: 0,
      bottom: bottomSheetHeight,
      left: 0,
    },
    center: initialMapCenter,
    zoom: INITIAL_MAP_STATE.zoom,
  })

  const bounds = getMapBoundsQuery({ mapBoundsQuery: urlParam.mapBounds })

  return {
    ...INITIAL_MAP_STATE,
    center: adjustedMapCenter,
    bounds: bounds,
  }
}

/**
 * 지도 초기 센터 좌표 정책
 * 1. url param
 * 2. 유저 현위치
 * 3. 현재 리젼 중앙 좌표
 * 4. 기본 센터 좌표 (INITIAL_MAP_CENTER 참고)
 */
const getInitialMapCenter = ({
  geolocation,
  region,
}: {
  geolocation: ReturnType<typeof useGeolocation>
  region: ReturnType<typeof useBridgeInfo>['region']
}): LngLat => {
  if (geolocation.currentPosition) {
    return {
      lng: geolocation.currentPosition.lng,
      lat: geolocation.currentPosition.lat,
    }
  }

  if (region.centerCoordinates) {
    return {
      lng: region.centerCoordinates.longitude,
      lat: region.centerCoordinates.latitude,
    }
  }

  return {
    lng: INITIAL_MAP_CENTER.lng,
    lat: INITIAL_MAP_CENTER.lat,
  }
}

const getMapBoundsQuery = ({ mapBoundsQuery }: { mapBoundsQuery?: string }) => {
  if (!mapBoundsQuery) {
    return undefined
  }
  const boundsNum = mapBoundsQuery.split(',').map(Number)

  if (boundsNum.length !== 4) {
    return undefined
  }

  const bounds = new LngLatBounds(boundsNum as [number, number, number, number])

  return bounds
}

const adjustMapCenter = ({
  offsetPadding,
  center,
  zoom,
}: {
  center: LngLat
  offsetPadding: Padding
  zoom: number
}) => {
  const currentCenterPixel = lngLatToPoint({
    lngLat: center,
    zoom,
  })

  const offsetY = (offsetPadding.bottom - offsetPadding.top) / 2
  const offsetX = (offsetPadding.right - offsetPadding.left) / 2

  // 적용된 offset을 역으로 적용
  const originalCenterPixel = {
    x: currentCenterPixel.x + offsetX,
    y: currentCenterPixel.y + offsetY,
  }

  const originalCenter = pointToLngLat({
    point: {
      x: originalCenterPixel.x,
      y: originalCenterPixel.y,
    },
    zoom,
  })

  return originalCenter
}
