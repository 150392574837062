import { useCallback } from 'react'

import {
  useMap,
  useMapLibreGL,
} from '@src/core/components/karrot-map/map/MapProvider'
import { calculateDistanceHaversineKm } from '@src/core/components/karrot-map/map/utils'
import { getBoundsWithPadding } from '@src/core/components/karrot-map/utils/getBoundsWithPadding'
import { useUserLocation } from '@src/features/user-location/hooks/useUserLocation'
import { useVisibleMapViewportPadding } from '@src/pages/home-search-result-step/_hooks/useVisibleMapViewportPadding'

export const useGetMapLogParamsCallback = () => {
  const { map } = useMap()
  const { mapLibreGL } = useMapLibreGL()
  const { userLocationHome } = useUserLocation()
  const visibleMapViewportPadding = useVisibleMapViewportPadding()

  return useCallback(() => {
    if (!map || !mapLibreGL) return {}
    const boundsWithPadding = getBoundsWithPadding({
      map,
      mapLibre: mapLibreGL,
      padding: visibleMapViewportPadding,
    })
    const centerCoordinates =
      boundsWithPadding.getCenter() &&
      boundsWithPadding.getCenter()?.lat &&
      boundsWithPadding.getCenter()?.lng
        ? {
            latitude: boundsWithPadding.getCenter().lat,
            longitude: boundsWithPadding.getCenter().lng,
          }
        : undefined

    const userLocationConverted =
      userLocationHome &&
      userLocationHome.point.latitude &&
      userLocationHome.point.longitude
        ? {
            latitude: userLocationHome.point.latitude,
            longitude: userLocationHome.point.longitude,
          }
        : undefined

    return {
      zoomLevel: map?.getZoom() ?? undefined,
      centerCoordinates,
      distanceBetweenUserLocationAndCenter:
        centerCoordinates && userLocationConverted
          ? calculateDistanceHaversineKm(
              centerCoordinates,
              userLocationConverted
            ) * 1000
          : null,
    }
  }, [map, mapLibreGL, userLocationHome, visibleMapViewportPadding])
}
