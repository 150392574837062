import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon'

import { getBigPictureImageUrl } from '@src/core/components/big-picture/getBigPictureImageUrl'
import { Avatar } from '@src/core/components/common-components/Avatar'
import {
  MAP_SEARCH_RESULT_NAVBAR_INPUT_ID,
  iOSKeyboardFocusOn,
} from '@src/core/components/focus/iOSKeyboardFocus'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useLocalMapStepMovePolicy } from '@src/services/hooks/useLocalMapStepMovePolicy'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import * as css from './HomeSearchInput.css'
import { useGetUserProfile } from '../_api-queries/getUserProfile'

const HomeSearchInput = () => {
  const { userProfile } = useGetUserProfile()
  const { openTarget } = useLocalMapOpenTarget()
  const { stepMove } = useLocalMapStepMovePolicy()

  const { clickedHomeSearchBoxLog, clickedHomeUserProfileClickLog } =
    useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const handleSearchBarClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    clickedHomeSearchBoxLog(getMapLogParams())

    iOSKeyboardFocusOn(MAP_SEARCH_RESULT_NAVBAR_INPUT_ID)

    stepMove('home', 'click_searchBar', {
      query: null,
    })
  }

  const handleUserProfileClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (userProfile?.profileDetailTargetUri) {
      clickedHomeUserProfileClickLog()

      openTarget({
        targetUri: userProfile?.profileDetailTargetUri,
        referrer: 'localMap_home_searchBar_userProfile',
      })
    }
  }

  return (
    <div className={css.container}>
      <div
        className={css.searchBar}
        role="search"
        aria-label="홈 화면 검색 창"
        onClick={handleSearchBarClick}
      >
        <div className={css.searchIconWrapper}>
          <IconMagnifyingglassLine width={20} height={20} />
        </div>
        <span className={css.placeholder}>여기서 업체 검색</span>
        <button
          className={css.userProfile}
          onClick={handleUserProfileClick}
          aria-label="유저 프로필 버튼"
        >
          <Avatar
            size="small"
            alt="user-profile"
            src={
              userProfile?.profileImageUrl
                ? getBigPictureImageUrl(userProfile.profileImageUrl, {
                    s: {
                      width: 32 * 3,
                      height: 32 * 3,
                    },
                  })
                : ''
            }
            type="User"
          />
        </button>
      </div>
    </div>
  )
}

export default HomeSearchInput
