import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useMemo, useRef } from 'react'

import { useGetSectionQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import SectionLocalStory from '@src/pages/home-feed-step/section/section-local-business-story/SectionLocalStory'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import { STORY_SECTION_INFO } from './sectionKeyInfo'
import { getSectionImpressLogParams } from '../_hooks/getSectionImpressLogParams'

interface FeedStoryRootProps {
  userCoordinates: TypeLatLng
}

const FeedStoryRoot = ({ userCoordinates }: FeedStoryRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { data } = useGetSectionQuery({
    sectionViewTypes: 'SECTION_VIEW_TYPE_LOCAL_STORY_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_LOCAL_STORY_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...STORY_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const result = data?.sectionItems[0]?.localStorySection
  if (!result) return null

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div ref={sectionRef}>
        <SectionLocalStory localBusinessStory={result} />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(FeedStoryRoot)
