import { type Anchor } from '@src/core/components/bottom-sheet/types'
import { type LocalMapStepKeys } from '@src/services/hooks/useStepRouter'

import {
  ANCHOR_SEARCH_BOTTOM_OFFSET,
  ANCHOR_SEARCH_TOP_OFFSET,
} from './homeActivitySize'

const HeightViewTypes = ['small', 'medium', 'large'] as const
export type HeightViewType = (typeof HeightViewTypes)[number]

export type LocalMapBottomSheetAnchorKeys = Record<LocalMapStepKeys, Anchor[]>
const LocalMapBottomSheetSnapPoints = ['top', 'middle', 'bottom'] as const
export type LocalMapBottomSheetSnapPoint =
  (typeof LocalMapBottomSheetSnapPoints)[number]

const BOTTOM_SHEET_MIN_HEIGHT = 260
const BOTTOM_SHEET_MAX_HEIGHT = 360

export const getMiddleOffset = () => {
  const value = Math.floor(window.innerHeight * 0.4)
  const clampValue = Math.min(
    BOTTOM_SHEET_MAX_HEIGHT,
    Math.max(BOTTOM_SHEET_MIN_HEIGHT, value)
  )
  return clampValue
}

const getSmallHeightBottomSheetAnchor = (
  stepType: LocalMapStepKeys
): Anchor[] => {
  switch (stepType) {
    default:
    case 'home':
      return [
        {
          snapPoint: 'top',
          edge: 'top',
          offset: 10,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: 40,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchResult':
      return [
        {
          snapPoint: 'top',
          edge: 'top',
          offset: ANCHOR_SEARCH_TOP_OFFSET,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: ANCHOR_SEARCH_BOTTOM_OFFSET,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchDiscovery':
      return [
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: 0,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
  }
}

const getMediumHeightBottomSheetAnchor = (
  stepType: LocalMapStepKeys
): Anchor[] => {
  switch (stepType) {
    default:
    case 'home':
      return [
        {
          snapPoint: 'top',
          edge: 'top',
          offset: 72,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: 40,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchResult':
      return [
        {
          snapPoint: 'top',
          edge: 'top',
          offset: ANCHOR_SEARCH_TOP_OFFSET,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: ANCHOR_SEARCH_BOTTOM_OFFSET,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchDiscovery':
      return [
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: 0,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
  }
}

const getLargeHeightBottomSheetAnchor = (
  stepType: LocalMapStepKeys
): Anchor[] => {
  switch (stepType) {
    default:
    case 'home':
      return [
        {
          snapPoint: 'top',
          edge: 'top',
          offset: 72,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'middle',
          edge: 'bottom',
          offset: getMiddleOffset(),
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: 60,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchResult':
      return [
        {
          snapPoint: 'top',
          edge: 'top',
          offset: ANCHOR_SEARCH_TOP_OFFSET,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'middle',
          edge: 'bottom',
          offset: Math.floor(window.innerHeight * 0.35),
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: ANCHOR_SEARCH_BOTTOM_OFFSET,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchDiscovery':
      return [
        {
          snapPoint: 'bottom',
          edge: 'bottom',
          offset: 0,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
  }
}

export const getBottomSheetAnchors = ({
  heightViewType,
  stepType,
}: {
  heightViewType: HeightViewType
  stepType: LocalMapStepKeys
}): Anchor[] => {
  switch (heightViewType) {
    case 'small':
      return getSmallHeightBottomSheetAnchor(stepType)
    case 'medium':
      return getMediumHeightBottomSheetAnchor(stepType)
    case 'large':
      return getLargeHeightBottomSheetAnchor(stepType)
    default:
      return getMediumHeightBottomSheetAnchor(stepType)
  }
}

export const getBottomSheetAnchorById = ({
  snapPoint,
  heightViewType,
  stepType,
}: {
  snapPoint: LocalMapBottomSheetSnapPoint
  heightViewType: HeightViewType
  stepType: LocalMapStepKeys
}): Anchor | undefined => {
  const anchors = getBottomSheetAnchors({ heightViewType, stepType })
  return anchors.find((anchor) => anchor.snapPoint === snapPoint)
}

export const getBottomSheetInitialSnapPoint = (
  heightViewType: HeightViewType
): LocalMapBottomSheetSnapPoint => {
  switch (heightViewType) {
    case 'large':
      return 'middle'
    case 'medium':
    case 'small':
      return 'bottom'
    default:
      return 'middle'
  }
}
