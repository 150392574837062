import { type SVGProps } from 'react'

export const IconHousePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7C9.58579 7 9.25 7.33579 9.25 7.75V9.75H7.25C6.83579 9.75 6.5 10.0858 6.5 10.5C6.5 10.9142 6.83579 11.25 7.25 11.25H9.25V13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V11.25H12.75C13.1642 11.25 13.5 10.9142 13.5 10.5C13.5 10.0858 13.1642 9.75 12.75 9.75H10.75V7.75C10.75 7.33579 10.4142 7 10 7Z"
      fill={props.color ?? '#1A1C20'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6668 7.95864C1.27841 8.18321 0.779256 8.05967 0.541508 7.67559C0.299271 7.28426 0.420134 6.77065 0.811464 6.52842L8.77343 1.59988C9.52404 1.13524 10.4725 1.13224 11.2259 1.5977C12.9197 2.64422 16.9402 5.12947 19.1903 6.52942C19.5811 6.77256 19.7008 7.28644 19.4576 7.67722C19.2193 8.06022 18.7209 8.18281 18.3333 7.95861V15.99C18.3333 17.3707 17.214 18.49 15.8333 18.49H4.16667C2.78616 18.49 1.66689 17.371 1.66675 15.9904C1.66654 13.955 1.66664 11.9195 1.66674 9.88411L1.6668 7.95864ZM9.65065 3.01701C9.86655 2.88337 10.1362 2.88355 10.3498 3.01556C11.6821 3.83874 14.4514 5.5504 16.6666 6.92414V15.99C16.6666 16.4502 16.2935 16.8233 15.8333 16.8233H4.16667C3.70647 16.8233 3.33346 16.4503 3.33341 15.9902C3.33321 13.955 3.33331 11.9198 3.33341 9.88459L3.3335 6.92739L9.65065 3.01701Z"
      fill={props.color ?? '#212124'}
    />
  </svg>
)
