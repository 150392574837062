import { z } from 'zod'

import { STAGE } from '@src/services/constants/constants'
import { cacheLocalStorageWithStaleWhileRevalidate } from '@src/core/utils/cacheLocalStorageWithStaleWhileRevalidate'

import { MAP_STYLE_URL } from './constants'

import 'maplibre-gl/dist/maplibre-gl.css'

export type MapGLType = typeof import('maplibre-gl')

let mapGLCache: MapGLType | null = null

export const loadMapGL = async () => {
  if (mapGLCache) {
    return Promise.resolve(mapGLCache)
  }

  return new Promise<MapGLType>((resolve, reject) => {
    ;(async () => {
      try {
        const mapGLModule = await import('maplibre-gl')
        mapGLCache = mapGLModule.default

        resolve(mapGLCache)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

/**
 * map style의 경우 동일한 경로에 파일 내용만 변하는 방식으로 동작해요.
 * 스타일이 바뀌어도 json의 경로는 항상 동일한것이 보장 되기 때문에 값이 있고 string인지의 여부만 확인해요.
 * TODO. 추후 파일명/경로에 버져닝이 추가될경우, 버젼에 대한 validation이 추가되어야 해요.
 */
const ZOD_MAP_STYLE = z.object({
  light: z.string(),
  dark: z.string(),
})
export type MapStyleType = z.infer<typeof ZOD_MAP_STYLE>
const MAP_STYLE_CACHE_KEY = `local-map/${STAGE}/map-style-caching`

let mapStyleCache: MapStyleType | null = null

export const loadMapStyle = async (isNotUseCache = false) => {
  if (isNotUseCache) {
    localStorage.removeItem(MAP_STYLE_CACHE_KEY)
    return await getStyle()
  }

  if (mapStyleCache) {
    return mapStyleCache
  }

  const mapStyleResource = await cacheLocalStorageWithStaleWhileRevalidate(
    MAP_STYLE_CACHE_KEY,
    getStyle,
    ZOD_MAP_STYLE
  )

  mapStyleCache = mapStyleResource

  return mapStyleCache
}

async function getStyle() {
  const response = await fetch(MAP_STYLE_URL)
  return (await response.json()) as MapStyleType
}
