import { type RefObject, useEffect } from 'react'

export type useIntersectionObserverProp = {
  onChangeVisibility: (entry: IntersectionObserverEntry) => void
  options: IntersectionObserverInit
  targetElementRef: RefObject<HTMLElement>
}

export function useIntersectionObserver({
  onChangeVisibility,
  options,
  targetElementRef,
}: useIntersectionObserverProp) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => onChangeVisibility(entry),
      options
    )

    if (targetElementRef.current) {
      observer.observe(targetElementRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [onChangeVisibility, options, targetElementRef])
}
