import { useActivityParams } from '@stackflow/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createStore, useStore } from 'zustand'

import { useBottomSheetSnapPoint } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { useStepRouter } from '@src/services/hooks/useStepRouter'

const SUB_STEP_TYPE = 'contribution'

const contributionBottomSheetStore = createStore<{
  isOpen: boolean
  handleOpen: () => void
  handleClose: () => void
}>((set) => ({
  isOpen: false,
  handleOpen: () => {
    set({ isOpen: true })
  },
  handleClose: () => {
    set({ isOpen: false })
  },
}))

/**
 * 바텀시트를 열고 닫는 핸들러를 반환해요.
 * (history 기반을 사용하기 위해 실제 Open 상태와는 무관해요) */
export const useContributionBottomSheetHandler = () => {
  const { stepPush, stepPop, steps, stepReplace } = useStepRouter()
  const bottomSheetStore = useStore(contributionBottomSheetStore)
  const [state, setState] = useState<'open' | 'close' | 'idle'>('idle')

  const handleOpen = useCallback(() => {
    stepPush({
      step: 'home',
      params: {
        selectedPin: null,
        subStepType: SUB_STEP_TYPE,
      },
    })
  }, [stepPush])
  const handleClose = useCallback(() => {
    if (steps.length > 1) {
      stepPop()
    } else {
      stepReplace({
        step: 'home',
        params: {
          selectedPin: null,
        },
      })
    }
  }, [stepPop, stepReplace, steps.length])

  useEffect(() => {
    // 대기 중인 상태를 구분하기 위해 state 를 사용해요.
    const IDLE_DURATION = 500

    if (bottomSheetStore.isOpen) {
      setState('open')
    } else {
      setState('close')

      setTimeout(() => {
        setState('idle')
      }, IDLE_DURATION)
    }
  }, [bottomSheetStore.isOpen])

  return useMemo(
    () => ({
      isOpen: bottomSheetStore.isOpen,
      handleOpen,
      handleClose,
      state,
    }),
    [bottomSheetStore.isOpen, handleClose, handleOpen, state]
  )
}

/**
 * step의 query parameter 를 보고 실제로 바텀시트의 상태를 조절해요.
 * effect 기반으로 하나의 스토어(contributionBottomSheetStore)를 핸들링하고 있어요.
 * 해당 훅이 여러번 호출되면 문제가 발생할 수 있어요. */
export const useContributionBottomSheetOpenStateHandlerEffect = () => {
  const { isOpen, handleOpen, handleClose } = useStore(
    contributionBottomSheetStore
  )
  const { step, getValidatedParams } = useStepRouter()
  const { setSnapPoint, snapPoint } = useBottomSheetSnapPoint()

  const prevSnapPoint = useRef(snapPoint)
  const { subStepType } = useActivityParams<{
    subStepType?: string
    step: string
  }>()

  useEffect(() => {
    if (
      step &&
      getValidatedParams('home').subStepType === SUB_STEP_TYPE &&
      !isOpen
    ) {
      prevSnapPoint.current = snapPoint
      setSnapPoint('bottom')
      handleOpen()

      return
    }

    if (isOpen) {
      handleClose()
      setSnapPoint(prevSnapPoint.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSnapPoint, subStepType])
}
