import { ImpressionObserver } from '@daangn/web-impression'
import { type LogType } from '@daangn/web-impression/lib/core/common/types'

import { type PropsOf } from '@src/core/types/utilTypes'

import { type LocalMapEventName } from './eventNames/types'

export interface LocalMapLogType extends LogType {
  name: LocalMapEventName
}

export interface LocalMapImpressionObserverProps
  extends PropsOf<typeof ImpressionObserver> {
  log: LocalMapLogType
}

const LocalMapImpressionObserver = ({
  children,
  log,
  observeTargetRef,
}: LocalMapImpressionObserverProps) => {
  return (
    <ImpressionObserver log={log} observeTargetRef={observeTargetRef}>
      {children}
    </ImpressionObserver>
  )
}

export default LocalMapImpressionObserver
