import { coordinatesStore } from '../_store/coordinatesStore'

interface sectionImpressLogParamsProps {
  sectionKey: string
  sectionName: string
  sectionIndex: number
  addParams?: {
    [key in string]: string
  }
}
export const getSectionImpressLogParams = ({
  sectionKey,
  sectionName,
  sectionIndex,
  addParams,
}: sectionImpressLogParamsProps) => {
  const distancedCoordinatesType = getDistancedCoordinatesType()
  return {
    name: 'client_impressed_localMap_home_feed_section_v1',
    params: {
      sectionIndex,
      sectionKey,
      sectionName,
      pageType: 'default',
      feedCoordinatesType: distancedCoordinatesType,
      ...addParams,
    },
  } as const
}

export const getDistancedCoordinatesType = () => {
  const { getState } = coordinatesStore
  const distancedCoordinates = getState().distancedCoordinates
  if (distancedCoordinates) {
    if (distancedCoordinates.type === 'userLocation') {
      return 'user_location'
    } else if (distancedCoordinates.type === 'currentPosition') {
      return 'current_position'
    }
  }

  return 'current_position'
}
