import { type V1ExpertQuestSection } from '@daangn/local-map-api'
import { BoxButton } from '@daangn/sprout-components-button'
import { useRef } from 'react'

import { usePreventParentHorizontalScroll } from '@src/core/components/scroll-control/usePreventParentHorizontalScroll copy'
import { usePreventSelfHorizontalScrollOnVerticalScroll } from '@src/core/components/scroll-control/usePreventSelfHorizontalScrollOnVerticalScroll'
import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import BottomSheetSection from '@src/pages/home-feed-step/section/lib/BottomSheetSection'
import {
  bottomButtonWrapper,
  bottomSheetScrollWrapper,
} from '@src/pages/home-feed-step/section/lib/BottomSheetSection.css'
import {
  EXPERT_QUEST_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'
import { useHomeLogger } from '@src/services/log/hooks/useHomeLogger'

import ExpertItem from './ExpertItem'

interface SectionExpertProps {
  expertProfile: V1ExpertQuestSection
}

const REFERRER = genSectionReferrer(EXPERT_QUEST_SECTION_INFO.sectionKey)

const SectionExpert = ({ expertProfile }: SectionExpertProps) => {
  const { openTarget } = useLocalMapOpenTarget()

  const { clickedHomeFeedSectionActionButtonLog } = useHomeLogger()
  const getMapLogParams = useGetMapLogParamsCallback()

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  usePreventParentHorizontalScroll({
    targetRef: scrollContainerRef,
  })
  usePreventSelfHorizontalScrollOnVerticalScroll({
    targetRef: scrollContainerRef,
  })

  const handleMoreButtonClick = () => {
    clickedHomeFeedSectionActionButtonLog({
      actionType: 'more',
      ...getMapLogParams(),
      ...EXPERT_QUEST_SECTION_INFO,
    })
    openTarget({
      targetUri: expertProfile.viewMoreTargetUri,
      referrer: REFERRER,
    })
  }

  return (
    <BottomSheetSection
      title="전문가 견적 한 번에 받기"
      subTitle="간단한 정보만 입력하고 평균 3개 견적 받기"
    >
      <div className={bottomSheetScrollWrapper} ref={scrollContainerRef}>
        {expertProfile.expertServices.map((expertProfile, index) => (
          <ExpertItem
            key={expertProfile.targetUri}
            expertPost={expertProfile}
            index={index}
          />
        ))}
      </div>
      <div className={bottomButtonWrapper}>
        <BoxButton
          variant="secondary"
          width="100%"
          size="medium"
          onClick={handleMoreButtonClick}
        >
          더보기
        </BoxButton>
      </div>
    </BottomSheetSection>
  )
}

export default SectionExpert
