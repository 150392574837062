import { useCallback } from 'react'

import { useBottomSheetHeight } from '@src/core/components/bottom-sheet/context/bottomSheetContext'
import { type MapType } from '@src/core/components/karrot-map/map/Map'
import { MAP_TOP_AREA_SIZE_MAPPER } from '@src/services/constants/homeActivitySizeMapper'
import {
  type LocalMapStepKeys,
  useStepRouter,
} from '@src/services/hooks/useStepRouter'
import { type LngLat } from '@src/core/types/geo'

interface PanToWithOffset {
  center: LngLat
  zoom?: number
}

export const useMapPanTo = (map: MapType | null) => {
  const bottomSheetHeight = useBottomSheetHeight()
  const { stepType } = useStepRouter()

  const panToWithOffset = useCallback(
    ({ center, zoom }: PanToWithOffset) => {
      if (!map) {
        return console.error('map is not initialized')
      }

      const offset = getOffset(stepType, bottomSheetHeight)

      map.easeTo({
        center: {
          lng: center.lng,
          lat: center.lat,
        },
        zoom,
        offset,
        duration: 300,
      })

      return center
    },
    [bottomSheetHeight, map, stepType]
  )

  return {
    panToWithOffset,
  }
}

export const getOffsetY = (top: number, bottom: number) =>
  (-1 * (bottom - top)) / 2

export const getOffset = (
  stepType: LocalMapStepKeys,
  bottomSheetHeight: number
) => {
  const searchAreaHeight = MAP_TOP_AREA_SIZE_MAPPER[stepType]

  return [0, getOffsetY(searchAreaHeight, bottomSheetHeight)] as [
    number,
    number,
  ]
}
