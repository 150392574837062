import { type V1SearchResultLocalProfile } from '@daangn/local-map-api'
import { useEffect, useRef } from 'react'

import {
  AUTO_HIDE_SYMBOL_SEARCH_MARKER_SOURCE_ID,
  useAutoHideSymbol,
} from '@src/core/components/karrot-map/map/useAutoHideSymbol'
import { type WithLngLat } from '@src/core/components/karrot-map/types'
import { useAppColorTheme } from '@src/core/hooks/useTheme'
import { arraysAreEqual } from '@src/core/utils/snippet'
import { useMapZoomend } from '@src/services/hooks/useMapZoomend'

import { MarkerUtils } from './markerUtils'
import {
  clusterData,
  isCluster,
  type ClusterWithLeaves,
} from '../clustering/cluster'

const CLUSTER_APPLIED_ZOOM_LEVEL = 19
const ENABLED_COLLISION = true

interface Context {
  map: maplibregl.Map | null
  localProfileId: string | undefined | null
  localProfileIds: string[] | undefined | null
  localProfiles: WithLngLat<V1SearchResultLocalProfile>[]
  onLocalProfileClick?: (
    idx: number,
    localProfile: WithLngLat<V1SearchResultLocalProfile>
  ) => void
  onClusterClick?: (
    cluster: ClusterWithLeaves<V1SearchResultLocalProfile>
  ) => void
  disabled?: boolean
}

export function useLocalProfileMarkerList(context: Context) {
  const markerRef = useRef({
    markers: [] as maplibregl.Marker[],
    onMarkerClick: context.onLocalProfileClick,
    onClusterClick: context.onClusterClick,
  })
  markerRef.current.onMarkerClick = context.onLocalProfileClick
  markerRef.current.onClusterClick = context.onClusterClick

  const listeners = useRef({ updateMarkerList })

  const autoHideSymbol = useAutoHideSymbol(
    context.map,
    AUTO_HIDE_SYMBOL_SEARCH_MARKER_SOURCE_ID
  )
  const { colorTheme } = useAppColorTheme()

  function updateMarkerList() {
    if (context.disabled) {
      removeMarkerList()
      return
    }

    removeMarkerList()
    createMarkerList()
    autoHideSymbol.setData(
      Array.from(MarkerUtils.toSymbols(context.localProfiles))
    )

    return () => {
      removeMarkerList()
    }
  }

  function removeMarkerList() {
    markerRef.current.markers = markerRef.current.markers.filter((marker) => {
      marker.remove()
      return false
    })
  }

  function createMarkerList() {
    if (!context.map || context.localProfiles.length === 0) {
      return
    }

    const reversedLocalProfiles = context.localProfiles.slice().reverse()
    const markerDrawType =
      context.map.getZoom() >= CLUSTER_APPLIED_ZOOM_LEVEL
        ? 'cluster'
        : 'original'

    switch (markerDrawType) {
      case 'cluster': {
        const clusters = clusterData({
          dataWithPosition: reversedLocalProfiles,
        })

        clusters.forEach((cluster) => {
          if (isCluster(cluster)) {
            const clusterWithLeaves =
              cluster as ClusterWithLeaves<V1SearchResultLocalProfile>
            const clusteredLocalProfilesIds = clusterWithLeaves.leaves.map(
              (feature: { properties: { id: string } }) => feature.properties.id
            )

            const clusteredLocalProfileIdSet = new Set(
              clusteredLocalProfilesIds
            )

            const firstOrderedLocalProfile =
              context.localProfiles.find((localProfile) =>
                clusteredLocalProfileIdSet.has(localProfile.id)
              ) ??
              clusterWithLeaves.leaves[clusterWithLeaves.leaves.length - 1]
                .properties

            const label = firstOrderedLocalProfile.name

            const leavesIds = clusterWithLeaves.leaves.map(
              (feature: { properties: { id: string } }) => feature.properties.id
            )

            const isSelected =
              context.localProfileIds &&
              arraysAreEqual(
                context.localProfileIds,
                leavesIds,
                (a, b) => a === b
              )

            if (isSelected) {
              const marker = MarkerUtils.createClusterPinMarker({
                cluster,
                label,
                map: context.map!,
              })
              markerRef.current.markers.push(marker)
              return
            }

            const marker = MarkerUtils.createClusterMarker({
              cluster,
              label,
              map: context.map!,
              onClick: (e) => {
                e.stopPropagation()
                markerRef.current.onClusterClick?.(cluster)
              },
            })
            markerRef.current.markers.push(marker)
            return
          }

          const localProfile = cluster.properties
          const marker = MarkerUtils.createMarker({
            map: context.map!,
            colorTheme,
            isSelected: context.localProfileId === localProfile.id,
            localProfile,
            onClick(e) {
              e.stopPropagation()
              const idx = context.localProfiles.findIndex(
                (lp) => lp.id === localProfile.id
              )
              markerRef.current.onMarkerClick?.(idx, localProfile)
            },
          })
          if (marker) {
            markerRef.current.markers.push(marker)
          }
        })
        break
      }

      default:
      case 'original': {
        reversedLocalProfiles.forEach((localProfile) => {
          const marker = MarkerUtils.createMarker({
            map: context.map!,
            colorTheme,
            isSelected: context.localProfileId === localProfile.id,
            localProfile,
            onClick(e) {
              e.stopPropagation()
              const idx = context.localProfiles.findIndex(
                (lp) => lp.id === localProfile.id
              )
              markerRef.current.onMarkerClick?.(idx, localProfile)
            },
          })

          if (marker) {
            markerRef.current.markers.push(marker)
          }
        })
        break
      }
    }

    // 충돌 체크
    MarkerUtils.enabledCollision(markerRef.current.markers, ENABLED_COLLISION)
  }

  listeners.current.updateMarkerList = updateMarkerList

  useMapZoomend(context.map, updateMarkerList)

  useEffect(() => {
    return listeners.current.updateMarkerList()
  }, [
    context.map,
    context.localProfileId,
    context.localProfiles,
    context.localProfileIds,
    colorTheme,
  ])

  return { updateMarkerList }
}
