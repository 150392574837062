import { type RecipeVariants } from '@vanilla-extract/recipes'
import classNames from 'classnames'
import { forwardRef, memo, type MouseEvent, type ReactNode } from 'react'

import { type TagSizeType } from './constants'
import * as css from './Tag.css'

export type TagProps = {
  className?: string
  children: ReactNode
  size?: TagSizeType
  bold?: boolean
  icon?: ReactNode
  leadingIcon?: ReactNode
  color?: NonNullable<RecipeVariants<typeof css.tag>>['color']
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void
  role?: string
  tabIndex?: number
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ size = 'medium', bold = false, color, ...props }, ref) => {
    return (
      <>
        {props.icon ? <span className={css.icon}>{props.icon}</span> : null}
        <span
          ref={ref}
          role={props.role}
          tabIndex={props.tabIndex}
          className={classNames(
            css.tag({
              size,
              bold,
              color,
            }),
            props.className
          )}
          onClick={props.onClick}
        >
          {props.children}
        </span>
        {props.leadingIcon ? (
          <span className={css.leadingIcon}>{props.leadingIcon}</span>
        ) : null}
      </>
    )
  }
)

Tag.displayName = 'Tag'

export default memo(Tag)
