import {
  SESSION_AUTH_TOKEN,
  getSessionStore,
} from '@src/core/store/sessionStore'
import {
  IS_DEBUG_APP,
  IS_LOCAL,
  IS_PRODUCTION,
} from '@src/services/constants/constants'

const debugAppLogger = () => {
  return {
    log: IS_DEBUG_APP ? console.log : function () {},
    error: IS_DEBUG_APP ? console.error : function () {},
  }
}

const POSSIBLE_LOG =
  IS_LOCAL ||
  !IS_PRODUCTION ||
  debugAppLogger() ||
  getSessionStore(SESSION_AUTH_TOKEN)

const logLevel = {
  log: POSSIBLE_LOG ? console.log.bind(window.console) : function () {},
  info: POSSIBLE_LOG ? console.info.bind(window.console) : function () {},
  warn: POSSIBLE_LOG ? console.warn.bind(window.console) : function () {},
  error: POSSIBLE_LOG ? console.error.bind(window.console) : function () {},
}

export { logLevel, debugAppLogger }
