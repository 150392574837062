import { type StateCreator } from 'zustand'

interface Bounds {
  centerLatitude: number
  centerLongitude: number
  northWestLatitude: number
  northWestLongitude: number
  southEastLatitude: number
  southEastLongitude: number
}

export interface SearchBoundsSlice {
  userViewBounds: Bounds | null
  searchAreaBounds: Bounds | null
  setSearchBounds: (bounds: {
    searchAreaBounds: Bounds | null
    userViewBounds: Bounds | null
  }) => void
}

export const searchBoundsSlice: StateCreator<SearchBoundsSlice> = (set) => ({
  userViewBounds: null,
  searchAreaBounds: null,
  setSearchBounds: ({
    searchAreaBounds,
    userViewBounds,
  }: {
    searchAreaBounds: Bounds | null
    userViewBounds: Bounds | null
  }) => set({ searchAreaBounds, userViewBounds }),
})
