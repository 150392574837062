import { create } from 'zustand'

const INITIAL_MAP_MOVED_STATE = false

export const useMapMoved = create<{
  mapMoved: boolean
  setMapMoved: (mapMoved: boolean) => void
  resetMapMoved: () => void
}>((set) => ({
  mapMoved: INITIAL_MAP_MOVED_STATE,
  setMapMoved: (mapMoved: boolean) => set({ mapMoved }),
  resetMapMoved: () => set({ mapMoved: INITIAL_MAP_MOVED_STATE }),
}))
