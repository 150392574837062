import { forwardRef, type Ref } from 'react'

import * as css from './CategoryChip.css'

interface CategoryChipProps {
  isActive: boolean
  label: string
  onClick?: () => void
}

export default forwardRef(function CategoryChip(
  { isActive, label, onClick }: CategoryChipProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      className={css.categoryChip({ isActive })}
      onClick={onClick}
    >
      {label}
    </button>
  )
})
