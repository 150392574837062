import {
  SESSION_AUTH_TOKEN,
  getSessionStore,
  setSessionStore,
} from '@src/core/store/sessionStore'

const params = new URLSearchParams(window.location.search)

export const IS_E2E = params.get('e2e') === 'true'

// const sample = { e2e: true, centerCoordinates: '', userAgent: '', userId: '', nickname: '', authToken: '', regionId: '', regionName: '', }

export const e2eBridgeInfo = (): any => {
  const centerCoordinates = params.get('centerCoordinates')?.split(',')
  const userAgent = params.get('userAgent') ?? ''
  const authToken =
    params.get('authToken') ||
    getSessionStore(SESSION_AUTH_TOKEN) ||
    window.prompt('auth token을 입력해주세요') ||
    ''
  if (authToken) setSessionStore({ key: SESSION_AUTH_TOKEN, value: authToken })
  const os = userAgent.split(' ')[1].split('/')[0].toUpperCase() as
    | 'ANDROID'
    | 'IOS'
  const osVersion = userAgent.split(' ')[1].split('/')[1]
  const version = userAgent.split(' ')[0].split('/')[1]
  return {
    app: {
      userAgent: userAgent,
      deviceIdentity: 'local',
      appHost: '',
      appVersion: 'local',
      appVersionCode: 'local',
      country: 'KR',
      locale: 'ko',
      os: os,
      osVersion: osVersion,
      version: version,
    },
    user: {
      id: Number(params.get('userId') ?? -1),
      nickname: params.get('nickname') ?? '',
      authToken: authToken,
    },
    region: {
      id: Number(params.get('regionId') ?? -1),
      name: params.get('regionName') ?? '',
      centerCoordinates: {
        latitude: Number(centerCoordinates?.[0] ?? 0),
        longitude: Number(centerCoordinates?.[1] ?? 0),
      },
    },
  }
}

export const e2eGeoLocation = () => {
  const geoLocation = params.get('geoLocation')?.split(',')
  return {
    currentPosition: {
      position: {
        latitude: Number(geoLocation?.[0] ?? 0),
        longitude: Number(geoLocation?.[1] ?? 0),
      },
    },
  }
}
