import { STAGE } from '@src/services/constants/constants'

import { type ExperimentsState } from './coreTypes'
import { type LiveExperimentKey } from './experimentStore'

const LOCAL_STORAGE_KEY = `local-map/${STAGE}/experiments/v1`

export const setExperimentStorage = (
  experiments: ExperimentsState<LiveExperimentKey>
) => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(experiments))
}

export const getExperimentStorage: () => ExperimentsState<LiveExperimentKey> =
  () => {
    try {
      const stringifiedExperiments =
        window.localStorage.getItem(LOCAL_STORAGE_KEY)

      if (!stringifiedExperiments) {
        return {}
      }

      return JSON.parse(stringifiedExperiments)
    } catch (e) {
      console.error('Failed to get experiments from local storage', e)
      return {}
    }
  }

export const initExpriementsStorage = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export const getStorageMergedExperiments = (
  experiments: ExperimentsState<LiveExperimentKey>
) => {
  const mergedExperiments = { ...experiments }

  const storedExperiments = getExperimentStorage()
  const storedExperimentsKeys = Object.keys(
    storedExperiments
  ) as LiveExperimentKey[]

  storedExperimentsKeys.forEach((key) => {
    if (mergedExperiments[key]) {
      mergedExperiments[key] = {
        ...mergedExperiments[key],
        ...storedExperiments[key],
      }
    }
  })

  return mergedExperiments
}
