import { Skeleton } from '@src/core/components/skeleton'

import * as css from './SectionTitle.css'

interface SectionTitleSkeletonProps {
  isRightButton?: boolean
}

const SectionTitleSkeleton = ({ isRightButton }: SectionTitleSkeletonProps) => {
  return (
    <div className={css.container}>
      <div className={css.titleFlex}>
        <Skeleton width="60%" height="1.5rem" />
        {isRightButton && <Skeleton width="15%" height="1.375rem" />}
      </div>
    </div>
  )
}

export default SectionTitleSkeleton
