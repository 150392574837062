import { type LocalMapServiceUpdateUserLocationRequest } from '@daangn/local-map-api'

import { localMapApi } from '@src/services/api'
import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'

export type UpdateUserLocationProps = {
  bridgeInfo: BridgeInfoType
  data: LocalMapServiceUpdateUserLocationRequest
}

export type UpdateUserLocationResponse = ReturnType<typeof updateUserLocation>

export function updateUserLocation({
  bridgeInfo,
  data,
}: UpdateUserLocationProps) {
  return localMapApi(bridgeInfo).localMapServiceUpdateUserLocation(data)
}
