export function toRad(x: number) {
  return (x * Math.PI) / 180.0
}

export function squared(x: number) {
  return x * x
}

export function hav(x: number) {
  return squared(Math.sin(x / 2))
}
