import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useCallback,
} from 'react'

import { type ActivitiesKeyType } from '@src/core/lib/stackflow'
import { type Coordinates } from '@src/core/types/geo'

export const GLOBAL_BUS_EVENTS = {
  CLICK_LOCATION_TERMS_AGREEMENT: 'CLICK_LOCATION_TERMS_AGREEMENT',
} as const

export type GlobalBusEventType =
  (typeof GLOBAL_BUS_EVENTS)[keyof typeof GLOBAL_BUS_EVENTS]

type ObservePayloadActivityKey = {
  key?: ActivitiesKeyType
}

type ObservePayloadWithoutActivityKey =
  | {
      type: GlobalBusEventType
      // TODO: improve type definition
      data: any
    }
  | {
      type: 'COMPLETED_USER_LOCATION_MUTATION'
      data: {
        completed: boolean
        coordinates?: Coordinates
      }
    }
  | {
      type: 'COMPLETED_USER_LOCATION_DELETE'
      data: {
        completed: boolean
      }
    }

type ObservePayload = ObservePayloadWithoutActivityKey &
  ObservePayloadActivityKey

type Observe = (payload: ObservePayload) => void

let installed = false

function installObserver() {
  if (installed) {
    throw new Error('installDetector is already installed.')
  }

  installed = true

  const observer: Set<Observe> = new Set()

  function subscribe(observe: Observe) {
    observer.add(observe)

    return () => {
      observer.delete(observe)
    }
  }

  function fireEvent(payload: ObservePayload) {
    observer.forEach((observe) => observe(payload))
  }

  return {
    subscribe,
    fireEvent,
  }
}

const ObserverContext = createContext<ReturnType<
  typeof installObserver
> | null>(null)

const observerInstance = installObserver()

export const GlobalEventBusProvider = (props: { children: ReactNode }) => {
  return (
    <ObserverContext.Provider value={observerInstance}>
      {props.children}
    </ObserverContext.Provider>
  )
}

export const useSubscribeAllGlobalEventEffect = (
  observe: Observe,
  deps?: any[]
) => {
  const observer = useContext(ObserverContext)

  useEffect(() => {
    return observer?.subscribe(observe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, ...(deps ?? [])])
}

// TODO: improve type definition
export const useSubscribeToGlobalEventCallback = (options?: {
  type?: ObservePayload['type']
  key?: ObservePayload['key']
  deps?: any[]
}) => {
  const observer = useContext(ObserverContext)

  return useCallback(
    function (observe: Observe) {
      if (!observer) return

      const unsubscribe = observer.subscribe((payload) => {
        if (options?.type && payload.type !== options.type) return
        if (options?.key && payload.key !== options.key) return

        observe(payload)

        unsubscribe()
      })

      return unsubscribe
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [observer, ...(options?.deps ?? [])]
  )
}

export const useGlobalEvent = () => {
  const observer = useContext(ObserverContext)

  if (!observer) {
    throw new Error('not found ObserverContext')
  }

  return {
    fireGlobalEvent: observer.fireEvent,
  }
}
