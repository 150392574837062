import { useStack } from '@stackflow/react'
import { useCallback, useEffect } from 'react'

import { useIOSScrollToTopEffect } from '@src/core/lib/stackflow/plugins/iosScrollToTopPlugin'
import { karrotBridge } from '@src/core/lib/bridge/bridge'
import { useSubscribeTabClick } from '@src/core/lib/bridge/hooks/useSubscribeTabClick'

interface useScrollTopProps {
  scrollRef: React.RefObject<HTMLElement>
  enabled?: boolean
}

/**
 * 탭 클릭, iOS 힌지 클릭시 scrollRef를 최상단으로 스크롤하는 훅
 */
export function useScrollTop({ scrollRef, enabled = true }: useScrollTopProps) {
  const { activities } = useStack()

  const scrollToTopElement = useCallback(
    (behavior: 'smooth' | 'instant' = 'smooth') => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({ top: 0, behavior })
      }
    },
    [scrollRef]
  )

  useSubscribeTabClick({
    onTabClick: (error, tabId) => {
      if (error) {
        // TODO. error handleing
        return
      }

      if (tabId && tabId.clickedTab === 'local-map') {
        scrollToTopElement('smooth')
      }
    },
  })

  useIOSScrollToTopEffect(() => {
    scrollToTopElement('smooth')
  }, [])

  useEffect(() => {
    karrotBridge.styleCurrentRouter({
      router: {
        backSwipable: activities.length <= 1,
        scrollable: enabled,
        navbar: false,
      },
    })
  }, [activities.length, enabled])

  return {
    scrollToTopElement,
  }
}
