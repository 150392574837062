import { type LocalMapServiceDeleteUserLocationByIdRequest } from '@daangn/local-map-api'

import { localMapApi } from '@src/services/api'
import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'

export type DeleteUserLocationProps = {
  bridgeInfo: BridgeInfoType
  data: LocalMapServiceDeleteUserLocationByIdRequest
}

export type DeleteUserLocationResponse = ReturnType<
  typeof deleteUserLocationById
>

export function deleteUserLocationById({
  bridgeInfo,
  data,
}: DeleteUserLocationProps) {
  localMapApi(bridgeInfo).localMapServiceDeleteUserLocationById(data)
}
