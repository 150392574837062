import { type SearchCategoryKeywordsSuggestionSuggestion } from '@daangn/local-map-api'
import { useRef } from 'react'

import { useSearchLogger } from '@src/services/log/hooks/useSearchLogger'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

import CategoryChip from './CategoryChip'

interface SuggestionRootCategoryChipProps {
  index: number
  onClick: (props: { keyword: string }) => void
  isActive: boolean
  suggestion: SearchCategoryKeywordsSuggestionSuggestion
}

const SuggestionRootCategoryChip = ({
  index,
  onClick,
  suggestion,
  isActive,
}: SuggestionRootCategoryChipProps) => {
  const impressionElementRef = useRef(null)

  const { getImpressedSearchResultCategorySuggestionButtonLogParams } =
    useSearchLogger()

  const impressedLogParams =
    getImpressedSearchResultCategorySuggestionButtonLogParams({
      categorySuggestionIndex: index,
      categorySuggestionName: suggestion.keyword,
    })

  const handleClick = () => {
    onClick({ keyword: suggestion.keyword })
  }

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={impressionElementRef}
    >
      <CategoryChip
        ref={impressionElementRef}
        isActive={isActive}
        label="전체"
        onClick={handleClick}
      />
    </LocalMapImpressionObserver>
  )
}

export default SuggestionRootCategoryChip
