import {
  type LocalMapServiceAddressAutoCompleteRequest,
  type LocalMapServiceApi,
  type V1AddressAutoCompleteResponse,
  type V1GetAddressByCoordinateResponse,
} from '@daangn/local-map-api'

import { awaitToJs } from '@src/core/utils/snippet'
import { localMapApi } from '@src/services/api'
import { type BridgeInfoType } from '@src/core/lib/bridge/context/BridgeInfoProvider'

let service: ReturnType<typeof addressService> | null = null

export function getAddressService(bridgeInfo: BridgeInfoType) {
  if (service) {
    return service
  }

  return (service = addressService(localMapApi(bridgeInfo)))
}

const addressService = (remote: LocalMapServiceApi) => {
  return {
    /**
     * 주소 검색 자동완성
     */
    getAddressAutoComplete: async (
      params: LocalMapServiceAddressAutoCompleteRequest
    ) => {
      const [error, payload] = await awaitToJs<V1AddressAutoCompleteResponse>(
        remote.localMapServiceAddressAutoComplete(params)
      )

      if (error) {
        // TODO: error handling
        return null
      }

      if (!payload || !payload.results) {
        return null
      }

      return payload
    },
    /**
     * 역지오코딩
     */
    getAddressByPoint: async (params: {
      latitude?: number
      longitude?: number
    }) => {
      const [error, payload] =
        await awaitToJs<V1GetAddressByCoordinateResponse>(
          remote.localMapServiceGetAddressByCoordinate({
            coordinateLatitude: params.latitude,
            coordinateLongitude: params.longitude,
          })
        )

      if (error || !payload) {
        // TODO: error handling
        return null
      }

      return payload
    },
  }
}
