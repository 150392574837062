import { type LngLat } from '@src/core/types/geo'
import {
  type Geolocation,
  useReloadGeolocation,
} from '@src/core/lib/bridge/context/GeolocationProvider'

import GPSButton from './GPSButton'

interface CurrentPositionButtonProps {
  bottomMargin?: number | string // number or css value
  zIndex?: number | string // number or css value
  onUpdateCurrentPosition?: (currentPosition: LngLat) => void
  onShowGPSAuthSnackbar?: () => void
  onClick?: (geoLocation: Geolocation) => void
}

const CurrentPositionButton = (props: CurrentPositionButtonProps) => {
  const reloadGeolocation = useReloadGeolocation()

  const handleCurrentPositionClick = async () => {
    const newGeolocation = await reloadGeolocation()

    if (newGeolocation.currentPosition) {
      props.onUpdateCurrentPosition?.({
        lat: newGeolocation.currentPosition.lat,
        lng: newGeolocation.currentPosition.lng,
      })

      props.onClick?.(newGeolocation)
    }
  }

  return (
    <GPSButton
      onClick={handleCurrentPositionClick}
      aria-label="현위치로 이동"
    />
  )
}

export default CurrentPositionButton
