import { v4 } from 'uuid'

const addFakeInput = () => {
  const RootEl = document.getElementById('root')!
  const fakeInput = document.createElement('input')
  fakeInput.setAttribute('type', 'search')
  fakeInput.style.width = '0'
  fakeInput.style.height = '0'
  fakeInput.style.caretColor = 'transparent'
  fakeInput.tabIndex = -1
  fakeInput.ariaHidden = 'true'
  RootEl.appendChild(fakeInput)

  return fakeInput
}

export const MAP_SEARCH_RESULT_NAVBAR_INPUT_ID = v4()
export const USER_LOCATION_SEARCH_NAVBAR_INPUT_ID = v4()

/**
 * 원인:
 * - Safari WebKit 정책으로 인해 user interaction으로 시작되지 않은 이벤트로 focus를 하면 키보드를 올려주지 않음
 * - 따라서 iOS에서 effect 등으로 focus를 트리거하면, input에 포커스는 되지만 키보드는 올라오지 않음
 *
 * 해결:
 * - 보이지 않는 fake input을 만들어서 focus를 트리거하고, 다음 화면의 input이 포커스를 이어받는 방식으로 구현
 **/
export const iOSKeyboardFocusOn = (
  targetElementId: string,
  options?: {
    delay?: number
  }
) => {
  const fakeInput = addFakeInput()
  fakeInput.focus()

  setTimeout(
    () => {
      const $el = document.getElementById(targetElementId)
      $el?.focus()
      fakeInput?.remove()
    },
    options?.delay ?? 500 // stackflow의 화면 전환 애니메이션이 350ms 정도 소요되는데, 500ms 정도 적용되어야 씹히지 않고 동작
  )
}

/**
 * 원인:
 * iOS 검색어를 input에 입력하는 도중에 지웠다가 재 입력 시 buffer가 제대로 비워지지 않는 현상 해결을 위해 사용
 *
 * 해결:
 * - 보이지 않는 fake input을 만들어서 focus를 트리거하고, 다음 화면의 input이 포커스를 이어받는 방식으로 구현
 **/
export const keepFocusAndClearIOSKeyboardBuffer = (
  inputElement?: HTMLInputElement | null,
  options?: {
    delay?: number
  }
) => {
  const fakeInput = addFakeInput()
  fakeInput.focus()

  setTimeout(
    () => {
      inputElement?.focus()
      fakeInput?.remove()
    },
    options?.delay ?? 0
  )
}
