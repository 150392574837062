import { vars } from '@seed-design/design-token'

interface Props {
  className?: string
  width?: string
  height?: string
  color?: string
}

export const IconUserLocationHomeMarker = ({
  className,
  width = '29',
  height = '42',
  color = vars.$static.color.staticWhite,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5228_6262)">
      <ellipse
        cx="14.5"
        cy="39.5"
        rx="7.5"
        ry="2.5"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M29 14.2435C29 16.2654 28.6152 18.0146 28.0076 19.5162C25.3006 26.2069 18.6713 30.9898 15.736 37.5812L15.3524 38.4425C15.2013 38.7818 14.8677 39 14.5 39C14.1323 39 13.7987 38.7818 13.6476 38.4425L13.264 37.5812C10.3287 30.9898 3.69941 26.2069 0.992367 19.5162C0.384781 18.0146 0 16.2654 0 14.2435C0 6.37705 6.49187 0 14.5 0C22.5081 0 29 6.37705 29 14.2435Z"
        fill="#393A40"
      />
      <path
        d="M28.5 14.2435C28.5 16.2001 28.128 17.8857 27.5441 19.3287C26.2241 22.5912 23.9418 25.4027 21.5721 28.2672C21.4979 28.357 21.4236 28.4467 21.3492 28.5366C19.0676 31.293 16.7327 34.1138 15.2792 37.3778L14.8957 38.2391C14.8238 38.4004 14.6677 38.5 14.5 38.5C14.3323 38.5 14.1762 38.4004 14.1043 38.2391L13.7208 37.3778C12.2673 34.1139 9.9324 31.2931 7.65079 28.5366C7.57642 28.4468 7.50209 28.357 7.42786 28.2672C5.05818 25.4027 2.77589 22.5912 1.45587 19.3287C0.872022 17.8857 0.5 16.2001 0.5 14.2435C0.5 6.66157 6.75956 0.5 14.5 0.5C22.2404 0.5 28.5 6.66157 28.5 14.2435Z"
        stroke="black"
        strokeOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23413 12.2419C7.89846 12.3866 7.49854 12.2676 7.30038 11.9475C7.08237 11.5953 7.19115 11.133 7.54334 10.915L13.5148 7.21862C14.1179 6.84532 14.8802 6.84283 15.4856 7.21694C16.7559 8.0018 19.7715 9.86584 21.4593 10.9159C21.811 11.1348 21.9187 11.5972 21.6999 11.9489C21.501 12.2686 21.1007 12.3867 20.7654 12.2412V18.1174C20.7654 19.1615 19.9189 20.008 18.8748 20.008H10.1248C9.08059 20.008 8.23413 19.1615 8.23413 18.1174V12.2419ZM15.875 12.875H16.875C17.2892 12.875 17.625 13.2108 17.625 13.625V14.625C17.625 15.0392 17.2892 15.375 16.875 15.375H15.875C15.4608 15.375 15.125 15.0392 15.125 14.625V13.625C15.125 13.2108 15.4608 12.875 15.875 12.875Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5228_6262">
        <rect width="29" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
