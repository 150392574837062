import { type Coordinates } from '@src/core/types/geo'

import { karrotBridgeLog } from '../bridgeLog'

type ClickedLocalMapHomePickPoiV1LogParams = {
  item_id: string
  item_data_type: 'local_profile'
  zoom_level: number
}

function clickedLocalMapHomePickPoiV1Log(
  params: ClickedLocalMapHomePickPoiV1LogParams
) {
  karrotBridgeLog('client_clicked_localMap_home_pickPoi_v1', params)
}

type ImpressedLocalMapHomePreviewV1LogParams = {
  item_id: string
  item_data_type: 'local_profile'
  funnel_from: 'backgroundPoi' | 'marker' | 'searchExcution_suggested'
  zoom_level: number
  center_coordinates: Coordinates
}

function impressedLocalMapHomePreviewV1Log(
  params: ImpressedLocalMapHomePreviewV1LogParams
) {
  karrotBridgeLog('client_impressed_localMap_home_preview_v1', params)
}

type ClickedLocalMapHomePreviewV1LogParams = {
  item_id: string
  item_data_type: 'local_profile'
  funnel_from: 'backgroundPoi' | 'marker' | 'searchExcution_suggested'
  action_type:
    | 'viewLocalProfileHome'
    | 'chat'
    | 'call'
    | 'review'
    | 'suggest'
    | 'imageUpload'
    | 'delete'
  zoom_level: number
  center_coordinates: Coordinates
}

function clickedLocalMapHomePreviewV1Log(
  params: ClickedLocalMapHomePreviewV1LogParams
) {
  karrotBridgeLog('client_clicked_localMap_home_preview_v1', params)
}

export function useZeroDepthContextLogger() {
  return {
    clickedLocalMapHomePickPoiV1Log,
    impressedLocalMapHomePreviewV1Log,
    clickedLocalMapHomePreviewV1Log,
  }
}
