import { type StateCreator } from 'zustand'

export interface QuerySlice {
  query: string
  setQuery: (newQuerySearchKey: string) => void
}
export const querySlice: StateCreator<QuerySlice> = (set) => ({
  query: '',
  setQuery: (newQuery: string) => set({ query: newQuery }),
})
