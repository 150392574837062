import { DEFAULT_MAX_BOUNDS, type GeolocationPoint } from '../map/constants'

/**
 * 입력받은 좌표가 DEFAULT_MAX_BOUNDS 밖에 위치하는지 여부를 반환합니다.
 */
export function isOutsideMaxBoundary(geolocationPoint: GeolocationPoint) {
  const [[SW_LON, SW_LAT], [NE_LON, NE_LAT]] = DEFAULT_MAX_BOUNDS

  if (geolocationPoint[0] < SW_LON || geolocationPoint[0] > NE_LON) {
    return true
  } else if (geolocationPoint[1] < SW_LAT || geolocationPoint[1] > NE_LAT) {
    return true
  } else {
    return false
  }
}
