import { useEffect } from 'react'

import {
  useStepRouter,
  type LocalMapStepKeys,
} from '@src/services/hooks/useStepRouter'
import { setPrevStep } from '@src/services/hooks/useStepRouter/prevStep'

/**
 * 동네지도 홈에서 뒤로가기가 발생했을 때를 감지하여 실행하는 hook
 *  - 이전 step을 기록함
 */
export function useHomePopState() {
  const { step } = useStepRouter()

  useEffect(() => {
    // 초기 실행시 이전 step 제거 (새로고침이나 링크로 진입시 제거해야됨)
    setPrevStep({
      prevStep: null,
      method: null,
    })
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      setPrevStep({
        prevStep: (step?.params?.step ?? 'home') as LocalMapStepKeys,
        method: 'pop',
      })
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
      //   setPrevStep(null)
    }
  }, [step?.params?.step])
}
