import { createPortal } from 'react-dom'

import { preventSwipeTrick } from './PreventSwipeBack.css'

const modalEl = document.getElementById('preventSwipeBack')

/**
 * AppScreen에 Props로 PreventSwipeBack을 넣었을 때 다른 사이드 이펙트가 생겨서 터치를 막는 컴포넌트를 추가했어요.
 * 앞으로도 이 코드가 지속되지는 않을거고 현재의 스와이프 업 다운 방식과 같은 라이프사이클을 가지리라 기대해요.
 */
interface PreventSwipeBackProps {
  isShow: boolean
}
const PreventSwipeBack = ({ isShow }: PreventSwipeBackProps) => {
  return modalEl && isShow
    ? createPortal(
        <div
          className={preventSwipeTrick}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        />,
        modalEl
      )
    : null
}

export default PreventSwipeBack
