import { type V1CreateBusinessProfileAndSuggestPoiButtonSection } from '@daangn/local-map-api'
import {
  IconLocationpinLine,
  IconStoreLine,
} from '@daangn/react-monochrome-icon'
import { TextButton } from '@daangn/sprout-components-button'

import { useLocalMapOpenTarget } from '@src/services/hooks/useLocalMapOpenTarget'
import { useOpenPoiSuggestionCreate } from '@src/core/hooks/useOpenPoiSuggestionCreate'
import { getDistancedCoordinatesType } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import SectionDivider from '@src/pages/home-feed-step/section/lib/section/SectionDivider'
import {
  CREATE_AND_SUGGESTION_SECTION_INFO,
  genSectionReferrer,
} from '@src/pages/home-feed-step/section/sectionKeyInfo'
import { karrotBridgeLog } from '@src/services/log/bridgeLog'
import { useGetMapLogParamsCallback } from '@src/services/log/hooks/useGetMapLogParamsCallback'

import {
  container,
  divider,
  buttonWrapper,
  iconWrapper,
} from './SectionCreateAndSuggestion.css'

const REFERRER = genSectionReferrer(
  CREATE_AND_SUGGESTION_SECTION_INFO.sectionKey
)
const SUGGESTION_POI_BUTTION_ID = 'suggest_poi'

interface SectionCreateAndSuggestionProps {
  createAndSuggestion: V1CreateBusinessProfileAndSuggestPoiButtonSection
}

const SectionCreateAndSuggestion = ({
  createAndSuggestion,
}: SectionCreateAndSuggestionProps) => {
  const { openTarget } = useLocalMapOpenTarget()
  const getMapLogParams = useGetMapLogParamsCallback()
  const openPoiSuggestionCreate = useOpenPoiSuggestionCreate()

  const handleClick = (target: string, buttonId: string) => {
    const feedCoordinatesType = getDistancedCoordinatesType()
    karrotBridgeLog(
      'client_clicked_localMap_home_feed_sectionActionButton_v1',
      {
        pageType: 'default',
        actionType: buttonId,
        feedCoordinatesType,
        ...CREATE_AND_SUGGESTION_SECTION_INFO,
        ...getMapLogParams(), // zoomLevel, centerCoordinates, distanceBetweenUserLocationAndCenter
      }
    )

    // 생성진입 FAB 버튼이 생기면서 해당 섹션을 제거할 예정이에요. (아직 남겨둠)
    // 생성 제안 페이지는 SDK 를 제공해서 라우팅하고 있는데, 이 부분은 SDK 를 사용하지 않고 있어요.
    // URL 파편화를 막기 위해 서버에서 받지 않고, SDK로 대체해요.
    if (buttonId === SUGGESTION_POI_BUTTION_ID) {
      openPoiSuggestionCreate({
        referrer: REFERRER,
        entry: REFERRER,
      })

      return
    }

    openTarget({
      targetUri: target,
      referrer: REFERRER,
    })
  }

  return (
    <div className={container}>
      <div className={buttonWrapper}>
        <IconStoreLine size={14} className={iconWrapper} />
        <a href={createAndSuggestion.createBusinessProfileTargetUri}>
          <TextButton
            variant="secondary"
            size="small"
            onClick={(e) => {
              e.preventDefault()
              handleClick(
                createAndSuggestion.createBusinessProfileTargetUri,
                'create_business_profile'
              )
            }}
          >
            내 업체 등록
          </TextButton>
        </a>
      </div>
      <div className={divider} />
      <div className={buttonWrapper}>
        <IconLocationpinLine size={14} className={iconWrapper} />
        <a href={createAndSuggestion.suggestPoiTargetUri}>
          <TextButton
            variant="secondary"
            size="small"
            onClick={(e) => {
              e.preventDefault()

              handleClick(
                createAndSuggestion.suggestPoiTargetUri,
                SUGGESTION_POI_BUTTION_ID
              )
            }}
          >
            장소 등록 제안
          </TextButton>
        </a>
      </div>
    </div>
  )
}

export default SectionCreateAndSuggestion
