import {
  type SeedSnackbarOptions,
  useSnackbarAdapter,
} from '@daangn/sprout-components-snackbar'
import { useActivity } from '@stackflow/react'
import { useCallback, useMemo } from 'react'

import { satisfyVersion } from '@src/core/utils/versionCheck'
import { useBridgeInfo } from '@src/core/lib/bridge/context/BridgeInfoProvider'

import { type TKarrotBridge, karrotBridge } from '../bridge'

type OpenToastProp = Parameters<TKarrotBridge['openToast']>[0]['toast']

export interface ActionProps {
  onClick: () => void
}

export function useToastSnackbar() {
  const snackbarAdapter = useSnackbarAdapter()
  const {
    app: { os, version },
  } = useBridgeInfo()

  const isWeb = karrotBridge.driver.getCurrentEnvironment() === 'Web'
  const { name } = useActivity()
  const isNearbyHome = name !== 'Home'

  const openWebviewSnackbar = useCallback(
    (props: SeedSnackbarOptions) => {
      snackbarAdapter.create(props)
    },
    [snackbarAdapter]
  )

  //TODO: openTarget 함수 자체를 넘길 수 있도록 리팩토링 필요
  const openSnackbar = useCallback(
    (props: Omit<OpenToastProp, 'type'>) => {
      const useWebviewSnackbar =
        !isNearbyHome ||
        isWeb ||
        satisfyVersion({
          os,
          version,
          androidVersionCheck: '<22.38.1',
          iosVersionCheck: '<6.8.8',
        })

      if (useWebviewSnackbar) {
        const action = props.button?.action
        const actionLink = action && 'link' in action ? action.link : null
        const actionProps = actionLink
          ? {
              actionLabel: props.button?.body,
              onAction: () => {
                window.location.href = actionLink.path
              },
            }
          : null

        return openWebviewSnackbar({
          message: props.body,
          type: 'default',
          timeout: 3000,
          removeDelay: 0,
          shouldCloseOnAction: true,
          ...actionProps,
        })
      }

      karrotBridge.openToast({
        toast: { ...props, type: 'SNACKBAR' },
      })
    },
    [isNearbyHome, isWeb, os, version, openWebviewSnackbar]
  )

  const openToast = useCallback(
    (body: OpenToastProp['body']) => {
      karrotBridge.openToast({
        toast: {
          body,
          type: 'TOAST',
        },
      })
    },
    [karrotBridge]
  )

  return useMemo(
    () => ({
      openSnackbar,
      openWebviewSnackbar,
      openToast,
    }),
    [openSnackbar, openToast, openWebviewSnackbar]
  )
}
