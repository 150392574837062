import { type TypeLatLng } from '@daangn/local-map-api'
import { type StateCreator } from 'zustand'

export interface SearchCenterCoordinatesSlice {
  searchCenterCoordinates: TypeLatLng | null
  setSearchCenterCoordinates: (distanceAnchor: TypeLatLng) => void
}
export const searchCenterCoordinatesSlice: StateCreator<
  SearchCenterCoordinatesSlice
> = (set) => ({
  searchCenterCoordinates: null,
  setSearchCenterCoordinates: (searchCenterCoordinates: TypeLatLng) =>
    set({ searchCenterCoordinates }),
})
