import { Skeleton } from '@src/core/components/skeleton'
import { HOME_SEARCH_INPUT_HEIGHT_PX } from '@src/services/constants/homeActivitySize'

import * as css from './HomeSearchInput.css'

const HomeSearchInputSkeleton = () => {
  return (
    <div className={css.container}>
      <Skeleton width="100%" height={`${HOME_SEARCH_INPUT_HEIGHT_PX}px`} />
    </div>
  )
}

export default HomeSearchInputSkeleton
