import type * as React from 'react'
import { useEffect, useId } from 'react'

import { useLatestRef } from '@src/core/hooks/useLatestRef'

import { dom } from './dom'
import { type BottomSheetProps } from './types'
import { useBottomSheetState } from './useBottomSheetState'
import { getAnchorPosition } from './utils'

export function useBottomSheet({ ...props }: BottomSheetProps) {
  const autoId = useId()
  const id = autoId

  const {
    anchors,
    snapPoint,
    setSnapPoint,
    interaction,
    isReady,
    position,
    setPosition,
    currentScrollBehavior,
    contentTouchStart,
    handleTouchStart,
    touchMove,
    touchUp,
    contentScroll,
    onTransitionEnd,
    bottomSheetRef,
    positionerRef,
  } = useBottomSheetState({ id, ...props })

  const setSnapPointRef = useLatestRef(setSnapPoint)

  useEffect(() => {
    if (props.snapPoint == null) return

    setSnapPointRef.current(props.snapPoint)
  }, [props.snapPoint, setSnapPointRef])

  return {
    snapPoint,
    position,
    setPosition,
    bottomSheetRef,

    positionerProps: {
      id: dom.getPositionerId(id),
      'data-part': 'positioner',
      style: {
        position: 'absolute',
        isolation: 'isolate',
        inset: 0,
        height: '100vh',
        overflow: 'hidden',
        pointerEvents: 'none',
        '--seed-bottom-sheet-position': `${position}px`,
        visibility: props.disabled ? 'hidden' : 'visible',
      },
      ref: positionerRef,
    } as React.HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, any>,

    rootProps: {
      id: dom.getRootId(id),
      'data-part': 'root',
      'data-state': interaction,
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: `calc(100vh - ${getAnchorPosition(
          anchors[0],
          dom.getPositionerHeight(id)
        )}px)`,
        willChange: 'transform',
        transform: `translateX(0px) translateY(var(--seed-bottom-sheet-position)) translateZ(0px)`,
        userSelect: 'none',
        touchAction: 'none',
        pointerEvents: 'auto',
      },
      onTransitionEnd: () => {
        onTransitionEnd()
      },
    } as React.HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, any>,

    handleProps: {
      id: dom.getHandleId(id),
      'data-part': 'handle',
      onTouchStart: (e) => {
        handleTouchStart(e)
      },
      onTouchMove: (e) => {
        touchMove(e)
      },
      onTouchEnd: (e) => {
        touchUp(e)
      },
    } as React.HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, any>,

    handlebarProps: {
      id: dom.getHandleId(id),
      'data-part': 'handlebar',
    } as React.HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, any>,

    contentProps: {
      id: dom.getContentId(id),
      'data-part': 'content',
      onTouchStart: (e) => {
        contentTouchStart(e)
      },
      onTouchCancel: (e) => {
        touchUp(e)
      },
      onTouchMove: (e) => {
        touchMove(e)
      },
      onScroll: (e) => {
        contentScroll(e)
      },
      onTouchEnd: (e) => {
        touchUp(e)
      },
      style: {
        userSelect: 'auto',
        overflow:
          interaction === 'dragging' || currentScrollBehavior === 'dragSheet'
            ? 'hidden'
            : 'auto',
        touchAction:
          interaction === 'dragging' || currentScrollBehavior === 'dragSheet'
            ? 'none'
            : 'auto',
        overscrollBehaviorY: 'none',
      },
    } as React.HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, any>,
  }
}
