import { type TypeLatLng } from '@daangn/local-map-api'
import React, { useMemo, useRef } from 'react'

import { useGetSectionQuery } from '@src/pages/home-feed-step/_api-queries/useGetSectionQuery'
import { getSectionImpressLogParams } from '@src/pages/home-feed-step/_hooks/getSectionImpressLogParams'
import { coordinatesStore } from '@src/pages/home-feed-step/_store/coordinatesStore'
import SectionFollowPost from '@src/pages/home-feed-step/section/section-follow-post/SectionFollowPost'
import { FOLLOWER_POST_SECTION_INFO } from '@src/pages/home-feed-step/section/sectionKeyInfo'
import LocalMapImpressionObserver from '@src/services/log/LocalMapImpressionObserver'

interface SectionFollowPostRootProps {
  userCoordinates: TypeLatLng
}
const FeedFollowPostRoot = ({
  userCoordinates,
}: SectionFollowPostRootProps) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { data } = useGetSectionQuery({
    sectionViewTypes: 'SECTION_VIEW_TYPE_FOLLOW_BUSINESS_POST_SECTION',
    queryKey: 'SECTION_VIEW_TYPE_FOLLOW_BUSINESS_POST_SECTION',
    userCoordinates,
  })

  const { sectionImpressReset } = coordinatesStore((s) => ({
    sectionImpressReset: s.sectionImpressReset,
  }))

  const impressedLogParams = useMemo(
    () =>
      getSectionImpressLogParams({
        ...FOLLOWER_POST_SECTION_INFO,
      }),
    [sectionImpressReset]
  )

  const result = data?.sectionItems[0]?.followBusinessPostSection
  if (!result) return null

  return (
    <LocalMapImpressionObserver
      log={impressedLogParams}
      observeTargetRef={sectionRef}
    >
      <div ref={sectionRef}>
        <SectionFollowPost
          title={'내 단골 업체 소식'}
          followPostResult={result}
        />
      </div>
    </LocalMapImpressionObserver>
  )
}

export default React.memo(FeedFollowPostRoot)
