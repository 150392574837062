import { type LocalMapStepKeys } from '@src/services/hooks/useStepRouter'
import { getSafeAreaValue } from '@src/core/utils/getSafeAreaValue'

import {
  HOME_SEARCH_INPUT_HEIGHT_PX,
  HOME_SEARCH_INPUT_TOP_PADDING_PX,
  SEARCH_SEARCH_BAR_HEIGHT_PX,
  SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX,
} from './homeActivitySize'

const safeAreaTop = parseInt(getSafeAreaValue('top'))

/** 홈 스탭 상단 영역 height px */
const HOME_TOP_AREA_HEIGHT_PX =
  safeAreaTop + // safe area top
  HOME_SEARCH_INPUT_HEIGHT_PX + // 검색 input
  HOME_SEARCH_INPUT_TOP_PADDING_PX // 검색 input top padding

/** 검색 상단 영역 height px */
const SEARCH_TOP_AREA_HEIGHT_PX =
  safeAreaTop + // safe area top
  SEARCH_SEARCH_BAR_HEIGHT_PX + // 검색 input
  SEARCH_SEARCH_CATEGORY_SUGGESTION_AREA_HEIGHT_PX // 검색 스탭 카테고리 추천 영역 크기

/** 지도 스탭에 따른 상단영역 height size px */
export const MAP_TOP_AREA_SIZE_MAPPER: Record<LocalMapStepKeys, number> = {
  home: HOME_TOP_AREA_HEIGHT_PX,
  searchResult: SEARCH_TOP_AREA_HEIGHT_PX,
  searchDiscovery: SEARCH_TOP_AREA_HEIGHT_PX,
  preview: HOME_TOP_AREA_HEIGHT_PX,
  searchedPreview: SEARCH_TOP_AREA_HEIGHT_PX,
  externalPage: 0,
}
